import { SignatureDraft } from '../types/signatures';
import baseApi from './baseApi';

const signaturesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    signMandate: build.mutation({
      query: (body: SignatureDraft) => ({
        url: '/signatures/sign_document',
        method: 'POST',
        body,
      }),
      transformResponse: (response: { message: string }) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useSignMandateMutation } = signaturesApi;
