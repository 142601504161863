import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { SecondaryBtn } from 'components/UI/Buttons';
import CLALink from 'components/UI/CLALink';
import DrawerContent from 'components/UI/DrawerContent';
import SideDrawer from 'components/UI/SideDrawer';
import TraceStepper from 'components/UI/TraceStepper';
import useIsCajaLosAndesTheme from 'hooks/useIsCajaLosAndesTheme';
import priceFormat, { UFFormat } from 'services/format/priceFormat';
import { Debt } from 'services/types/debt';

const normalizationProcessSteps = [
  {
    title: 'Deuda ingresada',
    description: 'Ingresaste esta deuda, pero aún no nos has pedido ayuda',
    traceStatus: 'created',
    traceDescription: 'onDebt',
  },
  {
    title: 'Buscando ofertas',
    description: 'Estamos en contacto con la institución para buscar una oferta',
    traceStatus: 'waitingForOptions',
    traceDescription: 'normalize',
  },
  {
    title: 'Nueva oferta',
    description: 'Hemos encontrado una oferta para ti',
    traceStatus: 'hasNewOptions',
    traceDescription: 'normalize',
  },
  {
    title: 'Oferta aceptada',
    description: 'Has aceptado la oferta',
    traceStatus: 'offerAccepted',
    traceDescription: 'normalize',
  },
];

const manualNormalizationProcessSteps = [
  {
    title: 'Deuda ingresada',
    description: 'Ingresaste esta deuda, pero aún no nos has pedido ayuda',
    traceStatus: 'created',
    traceDescription: 'onDebt',
  },
  {
    title: 'Comunicación con la institución',
    description: 'Necesitamos que te comuniques con la institución para buscar una oferta',
    traceStatus: 'refinanceActionsRequired',
    traceDescription: 'normalize',
  },
  {
    title: 'Nueva oferta',
    description: 'Conseguiste una oferta',
    traceStatus: 'hasNewOptions',
    traceDescription: 'normalize',
  },
  {
    title: 'Oferta aceptada',
    description: 'Has aceptado la oferta',
    traceStatus: 'offerAccepted',
    traceDescription: 'normalize',
  },
];

const refinanceProcessSteps = [
  {
    title: 'Deuda ingresada',
    description: 'Ingresaste esta deuda, pero aún no nos has pedido ayuda',
    traceStatus: 'created',
    traceDescription: 'onDue',
  },
  {
    title: 'Buscando alternativas',
    description: 'Estamos en contacto con diversas instituciones para buscar una oferta',
    traceStatus: 'waitingForOptions',
    traceDescription: 'refinance',
  },
  {
    title: 'Ofertas encontradas',
    description: 'Hemos encontrado una oferta para ti',
    traceStatus: 'hasNewOptions',
    traceDescription: 'refinance',
  },
];

const CajaLosAndesRefinanceProcessSteps = [
  {
    title: 'Deuda ingresada',
    description: 'Ingresaste esta deuda, pero aún no nos has pedido ayuda',
    traceStatus: 'created',
    traceDescription: 'onDue',
  },
  {
    title: 'Portabilidad Caja Los Andes',
    description: (
      <>
        Para pedir tu portabilidad financiera, debes seguir los siguientes pasos. Desde la página de Caja Los Andes:
        <ol>
          <li>
            <CLALink target="_blank" href="https://misucursal.cajalosandes.cl/personas/login">
              {' '}
              Ingresa a Mi Sucursal.
            </CLALink>
          </li>
          <li>Pincha Créditos en la parte superior.</li>
          <li>Elige Portabilidad financiera.</li>
          <li>Completa los pasos.</li>
        </ol>
      </>
    ),
    traceStatus: 'waitingForOptions',
    traceDescription: 'refinance',
  },
  {
    title: 'Ofertas de portabilidad',
    description: 'Caja Los Andes ha enviado una oferta de portabilidad',
    traceStatus: 'hasNewOptions',
    traceDescription: 'refinance',
  },
];

const getProcessSteps = (debt: Debt, isCajaTheme: boolean) => {
  const isOnDebt = Number(debt.monthsBehind) > 0;
  const isManualProcess = debt.debtTraces?.some((trace) => trace.status === 'refinanceActionsRequired');
  if (isOnDebt && isManualProcess) {
    return manualNormalizationProcessSteps;
  }
  if (isOnDebt) {
    return normalizationProcessSteps;
  }
  if (isCajaTheme) {
    return CajaLosAndesRefinanceProcessSteps;
  }
  return refinanceProcessSteps;
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  debt: Debt | null;
  handleRefinanceDebts: () => void;
  handleEditDebt: (debt: Debt) => void;
}

const DebtTracesDrawer: FC<Props> = ({ isOpen, onClose, debt, handleRefinanceDebts, handleEditDebt }) => {
  const isCajaTheme = useIsCajaLosAndesTheme();
  const sortedTraces = debt?.debtTraces
    ?.slice()
    .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
  const lastTrace = sortedTraces?.[sortedTraces.length - 1];
  const processSteps = debt ? getProcessSteps(debt, isCajaTheme) : [];
  const currentStepIndex = processSteps.findIndex(
    (step) => step.traceStatus === lastTrace?.status && step.traceDescription === lastTrace?.description
  );

  return (
    <SideDrawer open={isOpen} onClose={onClose} title="Estado de deuda" activeStep={null} setActiveStep={null}>
      <DrawerContent title={null}>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h3" fontWeight="bold">
            Detalle de la deuda
          </Typography>
        </Box>
        {debt && (
          <Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                my: 3,
                maxWidth: '350px',
              }}
            >
              <Typography variant="h3" fontSize={18}>
                Tipo de deuda
              </Typography>
              <Typography variant="body1">{debt.debtType}</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                my: 3,
                maxWidth: '350px',
              }}
            >
              <Typography variant="h3" fontSize={18}>
                Institución
              </Typography>
              <Typography variant="body1">{debt.institutionName}</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                my: 3,
                maxWidth: '350px',
              }}
            >
              <Typography variant="h3" fontSize={18}>
                Saldo vigente
              </Typography>
              <Typography variant="body1">
                {debt.currency === 'UF' ? UFFormat(Number(debt.amount)) : priceFormat.format(Number(debt.amount))}
              </Typography>
            </Box>
          </Box>
        )}
        <Typography variant="h3" fontWeight="bold" mt={5}>
          Estado de la deuda
        </Typography>
        <TraceStepper processSteps={processSteps} currentStepIndex={currentStepIndex} />
        {lastTrace?.status === 'hasNewOptions' && debt && debt.bankOffer?.uuid && (
          <Box p={2} display="flex" justifyContent="center">
            <SecondaryBtn
              onClick={() => {
                window.open(`${window.location.origin}/bank-offers?uuid=${debt.bankOffer?.uuid || ''}`, '_blank');
              }}
            >
              Ver oferta
            </SecondaryBtn>
          </Box>
        )}
        {debt && debt.status === 'incomplete' && (
          <>
            <Typography
              variant="body1"
              sx={{
                fontSize: 12,
                color: 'text.secondary',
                mt: 1,
              }}
            >
              Por favor, completa los datos de para que te podamos ayudar a mejorar tu deuda.
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 2,
              }}
            >
              <SecondaryBtn
                onClick={() => {
                  onClose();
                  handleEditDebt(debt);
                }}
              >
                Completar datos
              </SecondaryBtn>
            </Box>
          </>
        )}
        {lastTrace?.status === 'created' && lastTrace.description !== 'incomplete' && (
          <>
            <Typography variant="body1">
              Solicitar mejorar tus deudas si te interesa{' '}
              {lastTrace.description === 'onDue' ? 'refinanciar' : 'normalizar'} esta deuda.
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 2,
              }}
            >
              <SecondaryBtn
                onClick={() => {
                  onClose();
                  handleRefinanceDebts();
                }}
              >
                Mejorar mis deudas
              </SecondaryBtn>
            </Box>
          </>
        )}
        {lastTrace?.status === 'refinanceActionsRequired' && debt?.contactInfo?.instructions && (
          <>
            {debt?.contactInfo &&
              (debt.contactInfo.instructions.refinance !== '' ||
                debt.contactInfo.instructions.normalization !== '') && (
                <Typography variant="body1">
                  {lastTrace.description === 'onDue'
                    ? debt.contactInfo.instructions.refinance
                    : debt.contactInfo.instructions.normalization}
                </Typography>
              )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 2,
              }}
            >
              <SecondaryBtn
                onClick={() => {
                  window.open(debt?.contactInfo?.website, '_blank');
                }}
              >
                Ir a la institución
              </SecondaryBtn>
            </Box>
          </>
        )}
      </DrawerContent>
    </SideDrawer>
  );
};

export default DebtTracesDrawer;
