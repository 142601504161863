import React, { FC, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';

import { Box, Grid, TextField, Typography } from '@mui/material';

import AlertDialog from 'components/UI/AlertDialog';
import PrimaryBtn, { BlackBtn } from 'components/UI/Buttons';
import LoadingOverlay from 'components/UI/LoadingOverlay';
import { useEditOrCreateConstantMutation } from 'services/api/constants.api';
import { Constant, ConstantDraft } from 'services/types/constant';

interface Props {
  constant: Constant | null;
  handleBack: () => void;
}

const EditConstant: FC<Props> = ({ constant, handleBack }) => {
  const [editedConstant, setEditedConstant] = useState<ConstantDraft>(
    constant
      ? {
          numberValue: constant.numberValue,
          stringValue: constant.stringValue,
          description: constant.description,
        }
      : { numberValue: 0, stringValue: '', description: '' }
  );
  const [constantName, setConstantName] = useState(constant?.name || '');
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);

  const [editOrCreateConstant, { isLoading, isSuccess, isError }] = useEditOrCreateConstantMutation();

  const handleSave = () => {
    if (constantName && editedConstant.numberValue && editedConstant.stringValue && editedConstant.description) {
      editOrCreateConstant({ constant: editedConstant, name: constantName }).catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      handleBack();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      setErrorDialogOpen(true);
    }
  }, [isError]);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <LoadingOverlay visible={isLoading} />
      <AlertDialog
        openDialog={errorDialogOpen}
        setOpenDialog={setErrorDialogOpen}
        header="Error al editar constante"
        confirmMsg="aceptar"
        msg="Ha ocurrido un error al editar la constante, por favor intente nuevamente"
        confirmAction={() => setErrorDialogOpen(false)}
      />
      <Grid container width="100%">
        <Grid item xs={12}>
          <Typography variant="h6">{constant ? 'Editar' : 'Nueva'} constante</Typography>
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          <TextField
            value={constantName}
            onChange={(e) => setConstantName(e.target.value.toUpperCase().replace(/ /g, '_'))}
            fullWidth
            label="Nombre"
            disabled={constant !== null}
          />
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          <NumericFormat
            value={editedConstant.numberValue}
            onValueChange={(values) =>
              setEditedConstant({
                ...editedConstant,
                numberValue: values.floatValue || 0,
              })
            }
            fullWidth
            label="Valor numérico"
            customInput={TextField}
          />
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          <TextField
            value={editedConstant.stringValue}
            onChange={(e) =>
              setEditedConstant({
                ...editedConstant,
                stringValue: e.target.value,
              })
            }
            fullWidth
            label="Valor string"
          />
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          <TextField
            value={editedConstant.description}
            onChange={(e) =>
              setEditedConstant({
                ...editedConstant,
                description: e.target.value,
              })
            }
            fullWidth
            label="Descripción"
          />
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <BlackBtn onClick={handleBack} sx={{ mx: 1 }}>
          Volver
        </BlackBtn>
        <PrimaryBtn
          onClick={handleSave}
          disabled={
            constantName === '' ||
            editedConstant.numberValue === 0 ||
            editedConstant.stringValue === '' ||
            editedConstant.description === ''
          }
          sx={{ mx: 1 }}
        >
          Guardar
        </PrimaryBtn>
      </Box>
    </Box>
  );
};

export default EditConstant;
