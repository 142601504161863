import React from 'react';

import Typography from '@mui/material/Typography';

import definitionsData from 'assets/data/definitionsData';
import CajaTooltip from 'components/UI/CajaTooltip';

interface Props {
  definitionName: string;
  placement?: 'top' | 'bottom';
  children: React.ReactNode;
}

const DefinitionWrapper = ({ definitionName, children, placement }: Props) => {
  const definition = definitionsData.find((def) => def.name.toLowerCase() === definitionName.toLowerCase());
  if (!definition) {
    return <div>{children}</div>;
  }
  return (
    <CajaTooltip
      placement={placement}
      title={
        <>
          <Typography variant="body1" textAlign="justify" mt={1} color="#fff">
            {definition.description}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mt: 1,
              textDecoration: 'underline',
              textAlign: 'center',
            }}
            color="#fff"
          >
            <a href={definition.link} target="_blank" rel="noreferrer" style={{ color: 'inherit' }}>
              Ver más
            </a>
          </Typography>
        </>
      }
    >
      <span
        style={{
          textDecoration: 'underline',
          textDecorationStyle: 'dotted',
        }}
      >
        {children}
      </span>
    </CajaTooltip>
  );
};

DefinitionWrapper.defaultProps = {
  placement: 'top',
};

export default DefinitionWrapper;
