import type {
  BankOfferRequestAttributes,
  BankOfferRequestWithUser,
  BulkRejectRequestsBody,
  BulkRejectRequestsResponse,
} from '@relif/backend';

import baseApi from './baseApi';

const bankOfferRequestsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    bulkRejectRequests: build.mutation({
      query: (body: BulkRejectRequestsBody) => ({
        url: `/admin/bankOfferRequests/bulk-reject`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: BulkRejectRequestsResponse) => response,
    }),
    adminListBankOfferRequests: build.query({
      query: () => ({
        url: `/admin/bankOfferRequests`,
        method: 'GET',
      }),
      transformResponse: (response: BankOfferRequestWithUser[]): BankOfferRequestWithUser[] => response,
    }),
    adminGetBankOfferRequest: build.query({
      query: (id: number) => ({
        url: `/admin/bankOfferRequests/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: BankOfferRequestAttributes): BankOfferRequestAttributes => response,
    }),
    adminUpdateBankOfferRequest: build.mutation({
      query: (body: BankOfferRequestAttributes) => ({
        url: `/admin/bankOfferRequests/${body.id}`,
        method: 'PATCH',
        body,
      }),
      transformResponse: (response: BankOfferRequestAttributes): BankOfferRequestAttributes => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  useBulkRejectRequestsMutation,
  useAdminListBankOfferRequestsQuery,
  useAdminGetBankOfferRequestQuery,
  useAdminUpdateBankOfferRequestMutation,
} = bankOfferRequestsApi;
