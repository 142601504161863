import React from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';

import { DebtsIcon, RefreshIcon } from 'assets/icons';
import { SectionHeaderBtn } from 'components/UI/Buttons';
import CajaTooltip from 'components/UI/CajaTooltip';
import SectionHeader from 'components/layout/SectionHeader';

interface Props {
  showRefreshBtn: boolean;
  handleRefreshDebts: () => void;
}

const DebtSectionHeader = ({ showRefreshBtn, handleRefreshDebts }: Props) => {
  const navigate = useNavigate();
  return (
    <SectionHeader
      text="Mis Deudas"
      button={
        <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
          {showRefreshBtn && (
            <CajaTooltip title="Aprieta este botón para actualizar tus deudas con la clave única" enterDelay={400}>
              <Box paddingRight={1}>
                <SectionHeaderBtn
                  text="Actualizar deudas"
                  onClick={handleRefreshDebts}
                  sx={{ minWidth: '50px ', ml: 1 }}
                  icon={<RefreshIcon />}
                />
              </Box>
            </CajaTooltip>
          )}
          <SectionHeaderBtn
            text="Ver boletín comercial"
            onClick={() => {
              navigate('/debt/debts-report');
            }}
            sx={{ minWidth: '50px', ml: 1 }}
            icon={<DebtsIcon />}
          />
        </Box>
      }
    />
  );
};

export default DebtSectionHeader;
