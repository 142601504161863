import React, { FC } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Origin } from 'services/types/origin';

import styles from '../UniqueDebtFlow.module.scss';
import HeaderLogos from './HeaderLogos';

interface Props {
  origin: Origin;
  title: string;
  children: React.ReactNode;
}

const ModalWithHeader: FC<Props> = ({ origin, title, children }) => (
  <Box className={styles.ModalContainer}>
    <Box className={styles.Modal}>
      <HeaderLogos origin={origin} />
      <Typography variant="h2" textAlign="center" width="100%" py={1} pb={2} fontSize={36}>
        {title}
      </Typography>
      {children}
    </Box>
  </Box>
);

export default ModalWithHeader;
