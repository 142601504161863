import React, { FC, useState } from 'react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';

import { ExpandLessIcon, ExpandMoreIcon } from 'assets/icons';

interface Props {
  title: string;
  // titleVariant: 'h3' | 'h4' | 'h5';
  titleSize: '36px' | '30px' | '26px' | '24px';
}

const Dropdown: FC<Props> = ({ title, titleSize, children }) => {
  const [open, setOpen] = useState(true);
  return (
    <>
      <Box display="flex" mt={2} alignItems="center" justifyContent="space-between" onClick={() => setOpen(!open)}>
        <Typography variant="h3" fontSize={titleSize}>
          {title}
        </Typography>
        <Avatar
          sx={{
            borderRadius: '10px',
            bgcolor: 'black',
            width: '24px',
            height: '24px',
          }}
        >
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Avatar>
      </Box>
      <Box mb={2}>
        <Collapse in={open}>{children}</Collapse>
      </Box>
    </>
  );
};

export default Dropdown;
