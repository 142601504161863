import React from 'react';

import { OnlineCompanyMetrics, User } from '@relif/backend';
import { useGetCLAMetricsQuery } from 'services/api/HRAdmin.api';
import { getFinancialStatusMetrics } from 'utils/metricsUtils';

const getAverageQuizScore = (usersWithQuizResults: User[]) => {
  if (!usersWithQuizResults.length) {
    return 0;
  }
  const averageQuizScore =
    usersWithQuizResults.reduce((acc, user) => acc + (user.quizResults?.[0]?.score || 0), 0) /
    usersWithQuizResults.length;
  return averageQuizScore.toFixed(2);
};

const getTotalsRow = (metrics: OnlineCompanyMetrics[], visitorsData: number[]) => {
  const totalVisitors = visitorsData.reduce((acc, visitors) => acc + visitors, 0);
  const totalUsers = metrics.reduce((acc, metric) => acc + metric.userMetrics.registeredUsers.length, 0);
  const totalConversions = (totalUsers / (totalVisitors || 1)) * 100;
  const totalVideocalls = metrics.reduce((acc, metric) => acc + metric.userMetrics.usersWithHubspotContact.length, 0);
  const totalWhatsappChats = metrics.reduce((acc, metric) => acc + metric.userMetrics.usersWithWhatsappChats.length, 0);
  const totalUsersWithUnpaidDebts = metrics.reduce(
    (acc, metric) => acc + metric.userMetrics.usersWithUnpaidDebts.length,
    0
  );
  const totalUsersWithPaidDebts = totalUsers - totalUsersWithUnpaidDebts;
  const totalAverageRCR = metrics.reduce(
    (acc, metric) => acc + Number(getFinancialStatusMetrics(metric.userMetrics.usersWithFinancialStatus).averageRCR),
    0
  );
  const totalAverageVR = metrics.reduce(
    (acc, metric) => acc + Number(getFinancialStatusMetrics(metric.userMetrics.usersWithFinancialStatus).averageVR),
    0
  );
  const totalUsersByDebtGoal = metrics.reduce(
    (acc, metric) => {
      const { usersByDebtGoal } = metric.userMetrics;
      Object.keys(usersByDebtGoal).forEach((debtGoal) => {
        acc[debtGoal] = (acc[debtGoal] || 0) + usersByDebtGoal[debtGoal].length;
      });
      return acc;
    },
    {} as Record<string, number>
  );
  const totalAverageQuizScore = getAverageQuizScore(
    metrics.reduce((acc, metric) => acc.concat(metric.userMetrics.usersWithQuizResults), [] as User[])
  );
  const totalBudgets = metrics.reduce((acc, metric) => acc + metric.userMetrics.usersWithBudgets.length, 0);
  const totalGoals = metrics.reduce((acc, metric) => acc + metric.userMetrics.usersWithGoals.length, 0);
  const totalPortabilityRequests = metrics.reduce(
    (acc, metric) => acc + (metric.userMetrics.usersThatRequestedPortability?.length || 0),
    0
  );
  const totalSocialCreditRequests = metrics.reduce(
    (acc, metric) => acc + (metric.userMetrics.usersThatRequestedSocialCredit?.length || 0),
    0
  );
  return {
    totalVisitors,
    totalUsers,
    totalConversions,
    totalVideocalls,
    totalWhatsappChats,
    totalUsersWithPaidDebts,
    totalUsersWithUnpaidDebts,
    totalAverageRCR: (totalAverageRCR / metrics.length).toFixed(2),
    totalAverageVR: (totalAverageVR / metrics.length).toFixed(2),
    totalUsersByDebtGoal,
    totalAverageQuizScore,
    totalBudgets,
    totalGoals,
    totalPortabilityRequests,
    totalSocialCreditRequests,
  };
};

const CLAMetrics = () => {
  const { data, isLoading } = useGetCLAMetricsQuery(null);
  const totalsRow = getTotalsRow(data?.last8weeksOnlineMetrics || [], data?.last8WeekVisitorsData || []);
  if (isLoading) {
    return <p>Loading...</p>;
  }

  const totalMetricsRow = () => {
    if (!data?.totalVisitors) {
      return null;
    }
    const financialStatusMetrics = getFinancialStatusMetrics(data.totalMetrics.userMetrics.usersWithFinancialStatus);
    return (
      <tr>
        <td style={{ fontWeight: 'bold' }}>Acumulado Histórico</td>
        <td style={{ fontWeight: 'bold' }}>{data.totalVisitors}</td>
        <td style={{ fontWeight: 'bold' }}>{data.totalMetrics.userMetrics.registeredUsers.length}</td>
        <td style={{ fontWeight: 'bold' }}>
          {((data.totalMetrics.userMetrics.registeredUsers.length / (data.totalVisitors || 1)) * 100).toFixed(1)} %
        </td>
        <td style={{ fontWeight: 'bold' }}>{data.totalMetrics.userMetrics.usersWithHubspotContact.length}</td>
        <td style={{ fontWeight: 'bold' }}>{data.totalMetrics.userMetrics.usersWithWhatsappChats.length}</td>
        <td style={{ fontWeight: 'bold' }}>
          {(
            ((data.totalMetrics.userMetrics.registeredUsers.length -
              data.totalMetrics.userMetrics.usersWithUnpaidDebts.length) /
              (data.totalMetrics.userMetrics.registeredUsers.length || 1)) *
            100
          ).toFixed(1)}
          %
        </td>
        <td style={{ fontWeight: 'bold' }}>
          {(
            (data.totalMetrics.userMetrics.usersWithUnpaidDebts.length /
              (data.totalMetrics.userMetrics.registeredUsers.length || 1)) *
            100
          ).toFixed(1)}
          %
        </td>
        <td style={{ fontWeight: 'bold' }}>{financialStatusMetrics.averageRCR}</td>
        <td style={{ fontWeight: 'bold' }}>{financialStatusMetrics.averageVR}</td>
        {Object.keys(data.totalMetrics.userMetrics.usersByDebtGoal || {}).map((debtGoal) => (
          <td key={debtGoal} style={{ fontWeight: 'bold' }}>
            {data.totalMetrics.userMetrics.usersByDebtGoal[debtGoal].length}
          </td>
        ))}
        <td style={{ fontWeight: 'bold' }}>
          {getAverageQuizScore(data.totalMetrics.userMetrics.usersWithQuizResults)}
        </td>
        <td style={{ fontWeight: 'bold' }}>{data.totalMetrics.userMetrics.usersWithBudgets.length}</td>
        <td style={{ fontWeight: 'bold' }}>{data.totalMetrics.userMetrics.usersWithGoals.length}</td>
        <td style={{ fontWeight: 'bold' }}>
          {data.totalMetrics.userMetrics.usersThatRequestedPortability?.length || 0}
        </td>
        <td style={{ fontWeight: 'bold' }}>
          {data.totalMetrics.userMetrics.usersThatRequestedSocialCredit?.length || 0}
        </td>
      </tr>
    );
  };

  return (
    <div>
      <h1>Métricas Caja Los Andes</h1>
      <table
        style={{
          width: '100%',
          border: '1px solid black',
          borderCollapse: 'collapse',
          textAlign: 'right',
        }}
      >
        <thead>
          <tr style={{ fontSize: '12px', fontWeight: 'bold', textAlign: 'center' }}>
            <th style={{ width: 200 }}>Semana</th>
            <th>Visitantes en Landing</th>
            <th>Registros</th>
            <th>% Conversión</th>
            <th>Videollamadas</th>
            <th>Chats WSP</th>
            <th>% Al día</th>
            <th style={{ width: 80 }}>% Morosos</th>
            <th>Carga financiera promedio</th>
            <th>Veces Renta Promedio</th>
            {Object.keys(data?.last8weeksOnlineMetrics[0].userMetrics.usersByDebtGoal || {}).map((debtGoal) => (
              <th key={debtGoal}>Interés: {debtGoal}</th>
            ))}
            <th>Promedio evaluación financiera</th>
            <th>Presupuestos</th>
            <th>Objetivos</th>
            <th>Solicitudes Portabilidad</th>
            <th>Solicitudes Crédito Social</th>
          </tr>
        </thead>
        <tbody>
          {data?.last8weeksOnlineMetrics.map((metrics, index) => {
            const financialStatusMetrics = getFinancialStatusMetrics(metrics.userMetrics.usersWithFinancialStatus);
            const endDate = new Date(metrics.endDate);
            endDate.setDate(endDate.getDate() - 1);
            return (
              <tr key={new Date(metrics.startDate).getTime()}>
                <td>
                  {new Date(metrics.startDate).toLocaleDateString('es-CL')} - {endDate.toLocaleDateString('es-CL')}
                </td>
                <td>{data?.last8WeekVisitorsData[index]}</td>
                <td>{metrics.userMetrics.registeredUsers.length}</td>
                <td>
                  {(
                    (metrics.userMetrics.registeredUsers.length / (data?.last8WeekVisitorsData[index] || 1)) *
                    100
                  ).toFixed(1)}{' '}
                  %
                </td>
                <td>{metrics.userMetrics.usersWithHubspotContact.length}</td>
                <td>{metrics.userMetrics.usersWithWhatsappChats.length}</td>
                <td>
                  {(
                    ((metrics.userMetrics.registeredUsers.length - metrics.userMetrics.usersWithUnpaidDebts.length) /
                      (metrics.userMetrics.registeredUsers.length || 1)) *
                    100
                  ).toFixed(1)}
                  %
                </td>
                <td>
                  {(
                    (metrics.userMetrics.usersWithUnpaidDebts.length /
                      (metrics.userMetrics.registeredUsers.length || 1)) *
                    100
                  ).toFixed(1)}
                  %
                </td>
                <td>{financialStatusMetrics.averageRCR}</td>
                <td>{financialStatusMetrics.averageVR}</td>
                {Object.keys(metrics.userMetrics.usersByDebtGoal || {}).map((debtGoal) => (
                  <td key={debtGoal}>{metrics.userMetrics.usersByDebtGoal[debtGoal].length}</td>
                ))}
                <td>{getAverageQuizScore(metrics.userMetrics.usersWithQuizResults)}</td>
                <td>{metrics.userMetrics.usersWithBudgets.length}</td>
                <td>{metrics.userMetrics.usersWithGoals.length}</td>
                <td>{metrics.userMetrics.usersThatRequestedPortability?.length || 0}</td>
                <td>{metrics.userMetrics.usersThatRequestedSocialCredit?.length || 0}</td>
              </tr>
            );
          })}
          <tr>
            <td style={{ fontWeight: 'bold' }}>Acumulado 8 semanas</td>
            <td style={{ fontWeight: 'bold' }}>{totalsRow.totalVisitors}</td>
            <td style={{ fontWeight: 'bold' }}>{totalsRow.totalUsers}</td>
            <td style={{ fontWeight: 'bold' }}>{totalsRow.totalConversions.toFixed(1)} %</td>
            <td style={{ fontWeight: 'bold' }}>{totalsRow.totalVideocalls}</td>
            <td style={{ fontWeight: 'bold' }}>{totalsRow.totalWhatsappChats}</td>
            <td style={{ fontWeight: 'bold' }}>
              {(
                ((totalsRow.totalUsers - totalsRow.totalUsersWithUnpaidDebts) / (totalsRow.totalUsers || 1)) *
                100
              ).toFixed(1)}
              %
            </td>
            <td style={{ fontWeight: 'bold' }}>
              {((totalsRow.totalUsersWithUnpaidDebts / (totalsRow.totalUsers || 1)) * 100).toFixed(1)} %
            </td>
            <td style={{ fontWeight: 'bold' }}>{totalsRow.totalAverageRCR}</td>
            <td style={{ fontWeight: 'bold' }}>{totalsRow.totalAverageVR}</td>
            {Object.keys(totalsRow.totalUsersByDebtGoal).map((debtGoal) => (
              <td key={debtGoal} style={{ fontWeight: 'bold' }}>
                {totalsRow.totalUsersByDebtGoal[debtGoal]}
              </td>
            ))}
            <td style={{ fontWeight: 'bold' }}>{totalsRow.totalAverageQuizScore}</td>
            <td style={{ fontWeight: 'bold' }}>{totalsRow.totalBudgets}</td>
            <td style={{ fontWeight: 'bold' }}>{totalsRow.totalGoals}</td>
            <td style={{ fontWeight: 'bold' }}>{totalsRow.totalPortabilityRequests}</td>
            <td style={{ fontWeight: 'bold' }}>{totalsRow.totalSocialCreditRequests}</td>
          </tr>
          {totalMetricsRow()}
        </tbody>
      </table>
    </div>
  );
};

export default CLAMetrics;
