import React, { FC } from 'react';

import { Typography, Box } from '@mui/material';

import type { BankOffer } from '@relif/backend';
import { dateFormat } from 'services/format/dateFormat';
import priceFormat from 'services/format/priceFormat';

import OfferHeader from './OfferHeader';
import TermOptionCard from './TermOptionCard';

interface Props {
  bankOffer: BankOffer;
  setInterestedCash: () => void;
  cashInterest: boolean;
  setInterested: (id: number, term: number) => void;
  selectedTerm: number | null;
}

const NormalizeOffer: FC<Props> = ({ bankOffer, setInterestedCash, cashInterest, setInterested, selectedTerm }) => {
  const title = `Conseguimos una oferta para ${bankOffer.cashPaymentAmount ? 'saldar' : 'renegociar '} tu deuda con ${
    bankOffer.bank
  }`;
  const discountAmount = bankOffer.originalAmount
    ? (1 - Number(bankOffer.cashPaymentAmount) / bankOffer.originalAmount) * 100
    : 0;

  const options = bankOffer.options || bankOffer.terms || [];
  let amountDescription = '';
  if (bankOffer.interestRate) {
    amountDescription = `${((bankOffer.interestRate || 0) * 100).toFixed(2)}% interés mensual`;
  } else if (discountAmount > 0) {
    amountDescription = `descuento del ${Math.round(discountAmount)}% de tu deuda`;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: { xs: '100%', md: '80%', lg: '50%', xl: '40%' },
        }}
      >
        <OfferHeader
          title={title}
          amountTitle="Monto de la oferta"
          amount={Number(bankOffer.paymentPlanAmount || bankOffer.cashPaymentAmount)}
          amountDescription={amountDescription}
        />
        <Box mt={4} px={3} py={4} width="100%">
          <Typography
            variant="body1"
            sx={{
              width: '100%',
              fontSize: 18,
              fontWeight: 600,
              color: 'primary.main',
              textAlign: 'left',
            }}
          >
            Elige una opción
          </Typography>
          {Number(bankOffer.cashPaymentAmount) > 0 && (
            <TermOptionCard
              key={bankOffer.id}
              isSelected={cashInterest}
              boldTitle="Pago al contado"
              title=""
              amount={bankOffer.cashPaymentAmount || 0}
              onClick={() => {
                setInterestedCash();
              }}
            />
          )}
          {options.map((option) => (
            <TermOptionCard
              key={option.term}
              isSelected={selectedTerm === option.term}
              boldTitle={`${option.term} `}
              title="cuotas de"
              amount={option.monthlyPayment || 0}
              onClick={() => setInterested(bankOffer.id, option.term)}
            />
          ))}
          <Typography
            variant="body1"
            sx={{
              width: '100%',
              fontSize: 14,
              fontWeight: 400,
              color: 'primary.main',
              textAlign: 'left',
            }}
          >
            * Estos son montos aproximados y podrían variar al hablar con el banco
          </Typography>
          <ul>
            {bankOffer.originalAmount && (
              <li>
                <Typography
                  variant="body1"
                  sx={{
                    width: '100%',
                    fontSize: 16,
                    fontWeight: 400,
                    color: 'primary.main',
                    textAlign: 'left',
                  }}
                >
                  El monto original de tu deuda es de {priceFormat.format(bankOffer.originalAmount)}
                </Typography>
              </li>
            )}
            {bankOffer.description && (
              <li>
                <Typography
                  variant="body1"
                  sx={{
                    width: '100%',
                    fontSize: 16,
                    fontWeight: 400,
                    color: 'primary.main',
                    textAlign: 'left',
                  }}
                >
                  {bankOffer.description}
                </Typography>
              </li>
            )}
            {bankOffer.firstPaymentDate && (
              <li>
                <Typography
                  variant="body1"
                  sx={{
                    width: '100%',
                    fontSize: 16,
                    fontWeight: 400,
                    color: 'primary.main',
                    textAlign: 'left',
                  }}
                >
                  La fecha del primer pago sería el: {dateFormat(new Date(bankOffer.firstPaymentDate))}
                </Typography>
              </li>
            )}
          </ul>
          <Typography
            variant="body1"
            sx={{
              width: '100%',
              fontSize: 16,
              fontWeight: 400,
              color: 'black',
              textAlign: 'left',
            }}
          >
            ¿Tienes dudas? ¿No encontraste un plazo que te acomode? <br />
          </Typography>
          <Typography
            variant="body1"
            sx={{
              width: '100%',
              fontSize: 16,
              fontWeight: 600,
              color: 'info.main',
              textAlign: 'left',
              textDecoration: 'underline',
            }}
            onClick={() => {
              window.open('/book-meeting', '_blank');
            }}
          >
            Agenda aquí una asesoría con el equipo de Relif
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default NormalizeOffer;
