import React, { FC } from 'react';

import { StepLabel } from '@mui/material';
import Step from '@mui/material/Step';
import Stepper from '@mui/material/Stepper';

import { CustomConnector } from 'components/UI/CustomStepper';

interface Props {
  activeStep: number;
  steps: string[];
}

const DrawerStepper: FC<Props> = ({ activeStep, steps }) => {
  return (
    <Stepper sx={{ m: 2, width: '250px' }} connector={<CustomConnector />} activeStep={activeStep}>
      {steps.map((label) => {
        const stepProps: { completed?: boolean } = {};
        const labelProps: { optional?: React.ReactNode } = {};
        return (
          <Step
            key={label}
            {...stepProps}
            sx={{
              '& .MuiStepIcon-root': { color: 'info.main' },
              '& .MuiStepLabel-iconContainer .Mui-completed': { color: 'info.main' },
              '& .MuiStepLabel-iconContainer .Mui-active': { color: 'info.main' },
            }}
          >
            <StepLabel sx={{ textAlign: 'left', fontWeight: 700 }} {...labelProps} />
          </Step>
        );
      })}
    </Stepper>
  );
};

export default DrawerStepper;
