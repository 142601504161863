import React, { useState } from 'react';

import { Box } from '@mui/material';

import PrimaryBtn from 'components/UI/Buttons';
import SectionHeader from 'components/layout/SectionHeader';
import { Constant } from 'services/types/constant';

import ConstantsTable from './ConstantsTable';
import EditConstant from './EditConstant';

const Constants = () => {
  const [selectedConstant, setSelectedConstant] = useState<Constant | null>(null);
  const [constantsRefetchFlag, setConstantsRefetchFlag] = useState(false);
  const [openEditConstant, setOpenEditConstant] = useState(false);

  const handleEditConstant = (constant: Constant) => {
    setSelectedConstant(constant);
    setOpenEditConstant(true);
  };

  const handleNewConstant = () => {
    setSelectedConstant(null);
    setOpenEditConstant(true);
  };

  const handleBack = () => {
    setOpenEditConstant(false);
    setConstantsRefetchFlag(!constantsRefetchFlag);
  };

  return (
    <Box>
      <SectionHeader text="Constantes" button={<PrimaryBtn onClick={handleNewConstant}>Nueva constante</PrimaryBtn>} />
      <Box p={1}>
        {openEditConstant ? (
          <EditConstant constant={selectedConstant} handleBack={handleBack} />
        ) : (
          <ConstantsTable handleEditConstant={handleEditConstant} refetchFlag={constantsRefetchFlag} />
        )}
      </Box>
    </Box>
  );
};

export default Constants;
