import { DebtsReport } from '@relif/backend';
import { BukError, BukSettlementResponse } from 'services/types/buk';

import { EditUserBody, FilterUsersBody, SignInResponse, User } from '../types/user';
import baseApi from './baseApi';

const usersApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.mutation({
      query: (body: FilterUsersBody) => ({
        url: '/admin/users/filter',
        body,
        method: 'PATCH',
      }),
      transformResponse: (response: {
        totalItems: number;
        rows: User[];
        totalPages: number;
        currentPage: number;
      }): { totalItems: number; rows: User[]; totalPages: number; currentPage: number } => response,
    }),
    adminGetUser: build.mutation({
      query: (id: number) => ({
        url: `/admin/users/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: User): User => response,
    }),
    getUsersSheet: build.mutation({
      query: () => ({
        url: '/admin/users_sheet',
        method: 'GET',
      }),
      transformResponse: (response: { url: string }): { url: string } => response,
    }),
    getUserMandate: build.mutation({
      query: (userId: number) => ({
        url: `/admin/firmaYa/${userId}`,
        method: 'GET',
      }),
      transformResponse: (response: { url: string }): { url: string } => response,
    }),
    editUser: build.mutation({
      query: (body: { user: EditUserBody; id: number }) => ({
        url: `/admin/users/${body.id}`,
        method: 'PATCH',
        body: body.user,
      }),
      transformResponse: (response: User): User => response,
    }),
    updateActionPlan: build.mutation({
      query: (body: { id: number; actionPlan: string }) => ({
        url: `/admin/users/${body.id}`,
        method: 'PATCH',
        body: { actionPlan: body.actionPlan },
      }),
      transformResponse: (response: User): User => response,
    }),
    updateAdminNotes: build.mutation({
      query: (body: { id: number; adminNotes: string }) => ({
        url: `/admin/users/${body.id}`,
        method: 'PATCH',
        body: { adminNotes: body.adminNotes },
      }),
    }),
    logInAsUser: build.mutation({
      query: (body: { userId: number; password: string }) => ({
        url: `/admin/users/${body.userId}/token`,
        method: 'POST',
        body: { password: body.password },
      }),
      transformResponse: (response: SignInResponse): SignInResponse => response,
    }),
    signOutAsUser: build.mutation({
      query: () => ({
        url: '/admin/users/token',
        method: 'DELETE',
      }),
      transformResponse: (response: SignInResponse): SignInResponse => response,
    }),
    adminGetDebtsReport: build.mutation({
      query: (userId: number) => ({
        url: `/admin/users/${userId}/debts_report`,
        method: 'GET',
      }),
      transformResponse: (response: DebtsReport): DebtsReport => response,
    }),
    getPayrollInfo: build.mutation({
      query: (userId: number) => ({
        url: `/admin/hrSoftware/${userId}/hr_payroll`,
        method: 'GET',
      }),
      transformResponse: (response: BukSettlementResponse | BukError): BukSettlementResponse | BukError => response,
    }),
    getPayrollFile: build.mutation({
      query: (props: { userId: number; year: number; month: number }) => ({
        url: `/admin/hrSoftware/${props.userId}/hr_payroll_file/${props.year}/${props.month}`,
        method: 'GET',
      }),
      transformResponse: (response: { url: string }): { url: string } => response,
    }),
    resetTestUser: build.mutation({
      query: (userId: number) => ({
        url: `/admin/users/${userId}/reset`,
        method: 'POST',
      }),
      transformResponse: (response: User): User => response,
    }),
  }),
});

export const {
  useGetUsersMutation,
  useGetUsersSheetMutation,
  useGetUserMandateMutation,
  useEditUserMutation,
  useUpdateActionPlanMutation,
  useUpdateAdminNotesMutation,
  useAdminGetUserMutation,
  useLogInAsUserMutation,
  useSignOutAsUserMutation,
  useAdminGetDebtsReportMutation,
  useGetPayrollInfoMutation,
  useGetPayrollFileMutation,
  useResetTestUserMutation,
} = usersApi;
