import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { AttachFile, Send } from '@mui/icons-material';
import { Box, Grid, IconButton, TextField } from '@mui/material';

import Conversation from 'components/common/Conversation';
import useQuery from 'hooks/useQuery';
import Pusher from 'pusher-js';
import {
  useCreateChatMessageMutation,
  useGetChatUserMessagesQuery,
  useGetUserChatIdentifierQuery,
  useSendFileMessageMutation,
} from 'services/api/chat.api';

import ChatWrapper from './ChatWrapper';

const PUSHER_APP_KEY = import.meta.env.VITE_PUSHER_APP_KEY || '';

const Chat = () => {
  const { companyIdentifier } = useParams<{ companyIdentifier: string }>();
  const query = useQuery();
  const refetchFlagParam = query.get('refetchFlag');
  const firstMessage = query.get('firstMessage');
  const disableOnboarding = query.get('disableOnboarding');
  const parsedDisableOnboarding = disableOnboarding === 'true';

  const [messageToSend, setMessageToSend] = useState(firstMessage || '');
  const [createChatMessage] = useCreateChatMessageMutation();

  const { data: userChatIdentifier } = useGetUserChatIdentifierQuery(null);

  const { data: messages, refetch } = useGetChatUserMessagesQuery(null);
  const [refetchFlag, setRefetchFlag] = useState<number>(1);
  const [chatInputHeight, setChatInputHeight] = useState<number>(0);

  const handleSendMessage = () => {
    if (!userChatIdentifier || !messageToSend) {
      return;
    }
    createChatMessage({ message: messageToSend })
      .then(() => {
        setRefetchFlag(refetchFlag + 1);
        setMessageToSend('');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error sending demo chat message', error);
      });
  };

  const [sendFileMessage] = useSendFileMessageMutation();

  const handleSendFile = (file: File) => {
    console.log('Sending file', file); // eslint-disable-line no-console
    sendFileMessage({ file })
      .then(() => {
        setRefetchFlag(refetchFlag + 1);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error sending file message', error);
      });
  };

  useEffect(() => {
    refetch().catch(() => {
      // eslint-disable-next-line no-console
      console.error('Error refetching messages');
    });
  }, [refetchFlag]);

  useEffect(() => {
    if (refetchFlagParam) {
      setRefetchFlag((prev) => prev + 1);
    }
  }, [refetchFlagParam]);

  useEffect(() => {
    const pusher = new Pusher(PUSHER_APP_KEY, { cluster: 'sa1' });

    const channel = pusher.subscribe(`chat-${userChatIdentifier}`);
    channel.bind('new-message', (data: object) => {
      setRefetchFlag((prev) => prev + 1);
      console.log('New message', JSON.stringify(data)); // eslint-disable-line no-console
    });
    return () => {
      channel.unbind_all();
      pusher.unsubscribe(`chat-${userChatIdentifier}`);
      pusher.disconnect();
    };
  }, [userChatIdentifier, setRefetchFlag]);

  useEffect(() => {
    if (firstMessage && messageToSend === firstMessage) {
      handleSendMessage();
    }
  }, [firstMessage, messageToSend]);

  // send message on enter
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        handleSendMessage();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleSendMessage]);

  useEffect(() => {
    const chatInput = document.getElementById('chat-input');
    if (chatInput) {
      setChatInputHeight(chatInput.clientHeight);
    }
  }, []);

  return (
    <ChatWrapper companyIdentifier={companyIdentifier} disableOnboarding={parsedDisableOnboarding}>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: `calc(100% - ${chatInputHeight}px)`,
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          <Conversation conversation={messages || []} scheduledMessages={[]} isClientView />
        </Box>
        <Grid
          container
          p={2}
          justifyContent="space-between"
          sx={{ position: 'fixed', bottom: 0 }}
          id="chat-input"
          width={{ xs: '100%', md: '60%' }}
        >
          <IconButton
            sx={{
              height: '3.5rem',
            }}
            component="label"
          >
            <AttachFile
              sx={{
                fontSize: '1.5rem',
              }}
            />
            {/* just allow png, jpg and pdf */}
            <input
              type="file"
              accept="image/*,.pdf"
              hidden
              onChange={(e) => e.target.files && handleSendFile(e.target.files[0])}
            />
          </IconButton>
          <TextField
            id="outlined-basic-email"
            label="Mensaje"
            fullWidth
            multiline
            sx={{
              maxWidth: '70%',
              backgroundColor: '#fff',
              borderRadius: '40px',
              opacity: 1,
              mr: 1,
              '& .MuiOutlinedInput-root': { borderRadius: '40px' },
            }}
            value={messageToSend}
            onChange={(e) => setMessageToSend(e.target.value)}
          />
          <IconButton
            sx={{
              height: '3.5rem',
            }}
            onClick={handleSendMessage}
          >
            <Send
              sx={{
                fontSize: '1.5rem',
              }}
            />
          </IconButton>
        </Grid>
      </Box>
    </ChatWrapper>
  );
};

export default Chat;
