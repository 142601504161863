import React, { useState, FC } from 'react';

import {
  Avatar,
  Grid,
  Typography,
  AlertColor,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  TableRow,
  Box,
} from '@mui/material';

import { MoreIcon, EditIcon } from 'assets/icons';
import StyledTableCell from 'components/UI/StyledTableCell';
import DeleteButton from 'components/common/DeleteButton';
import { useDeleteGoalMutation } from 'services/api/goals.api';
import priceFormat from 'services/format/priceFormat';
import { Goal, GoalTemplatesResponse } from 'services/types/goals';
import getIcon from 'utils/goalUtils';

import AddGoalModal from './AddGoalModal';

interface GoalCardInterface {
  goal: Goal;
  filterGoal: (id: number) => void;
  handleAlert: (severity: AlertColor, text: string) => void;
  templatesData: GoalTemplatesResponse;
  handleGoalsRefetch: () => void;
}

const GoalCard: FC<GoalCardInterface> = ({ goal, filterGoal, handleAlert, templatesData, handleGoalsRefetch }) => {
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState(goal.name);
  const [amount, setAmount] = useState(goal.amount);
  const [term, setTerm] = useState(goal.term);
  const [deleteGoal, { isLoading }] = useDeleteGoalMutation();
  const currentGoal = { ...goal, name, amount, term };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const setMenuEdit = () => {
    setAnchorEl(null);
    setEditMode(true);
  };

  const onSuccessAction = () => {
    handleAlert('success', `Objetivo fue eliminado correctamente!`);
    filterGoal(goal.id);
  };

  const onErrorAction = () => {
    handleAlert('error', 'Error! Intenta eliminar objetivo nuevamente!');
  };

  const handleDelete = async (objectId: number) => {
    await deleteGoal(objectId);
  };

  const handleUpdateGoal = (newName: string, newAmount: number, newTerm: number) => {
    setName(newName);
    setAmount(newAmount);
    setTerm(newTerm);
    handleGoalsRefetch();
  };

  const getMonthlyAmount = () => {
    if (amount && term) {
      return priceFormat.format(Math.round(Number(amount) / Number(term)));
    }
    return priceFormat.format(0);
  };

  const showActionsMobile = () => {
    return (
      <Grid
        item
        xs={1}
        sx={{
          height: { xs: '40%', lg: '100%' },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        }}
      >
        <IconButton aria-label="goals actions" onClick={handleMenuClick}>
          <MoreIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={setMenuEdit}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Editar</ListItemText>
          </MenuItem>
          <DeleteButton
            isMenuIcon
            objectId={goal.id}
            isLoading={isLoading}
            deleteFunction={handleDelete}
            confirmationText={`Estas seguro que quieres eliminar el objetivo: ${goal.name}`}
            onErrorAction={onErrorAction}
            onSuccessAction={onSuccessAction}
          />
        </Menu>
      </Grid>
    );
  };

  const showContent = () => {
    return (
      <>
        <TableRow>
          <td>
            <AddGoalModal
              open={editMode}
              setOpen={setEditMode}
              addGoal={() => {}}
              prevGoal={currentGoal}
              handleUpdateGoal={handleUpdateGoal}
              handleAlert={handleAlert}
              templatesData={templatesData}
            />
          </td>
        </TableRow>
        <TableRow>
          <StyledTableCell width="35%" align="left">
            <Box width="100%" sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                sx={{
                  display: { xs: 'none', lg: 'flex' },
                  width: 32,
                  height: 32,
                  backgroundColor: 'backgroundGrey.main',
                  color: '#51526B',
                }}
              >
                {getIcon(name)}
              </Avatar>
              <Typography
                variant="body1"
                fontWeight={600}
                ml={1}
                sx={{
                  fontSize: { xs: '14px', lg: '16px' },
                }}
              >
                {name}
              </Typography>
            </Box>
          </StyledTableCell>
          <StyledTableCell width="20%" align="left">
            <Typography
              variant="body1"
              fontWeight={600}
              sx={{
                fontSize: { xs: '14px', lg: '16px' },
              }}
            >
              {priceFormat.format(Number(amount))}
            </Typography>
          </StyledTableCell>
          <StyledTableCell width="15%" align="left">
            <Typography
              variant="body1"
              sx={{
                fontSize: { xs: '14px', lg: '16px' },
              }}
            >
              {term}
            </Typography>
          </StyledTableCell>
          <StyledTableCell width="15%" align="left">
            <Typography
              variant="body1"
              sx={{
                fontSize: { xs: '14px', lg: '16px' },
              }}
            >
              {getMonthlyAmount()}
            </Typography>
          </StyledTableCell>
          <StyledTableCell width="5%" align="left">
            {showActionsMobile()}
          </StyledTableCell>
        </TableRow>
      </>
    );
  };
  return showContent();
};

export default GoalCard;
