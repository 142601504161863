import React, { FC } from 'react';

import { Box, Link, Typography } from '@mui/material';

import { ChildIcon, EntertainmentIcon, HomeIcon, InstitutionIcon, ProfileIcon } from 'assets/icons';
import PaperContainer from 'components/UI/PaperContainer';
import priceFormat from 'services/format/priceFormat';

import BudgetSummaryCard from './BudgetSummaryCard';
import BudgetSummaryExpenseItem from './BudgetSummaryExpenseItem';
import PercentBar from './PercentBar';

interface Props {
  totalIncome: number;
  totalHome: number;
  totalFamily: number;
  totalChild: number;
  totalEntertain: number;
  totalFinance: number;
  totalGoal: number;
}
const BudgetSummary: FC<Props> = ({
  totalIncome,
  totalHome,
  totalFamily,
  totalChild,
  totalEntertain,
  totalFinance,
  totalGoal,
}) => {
  const totalExpanse = totalHome + totalFamily + totalEntertain + totalFinance + totalChild;
  const difference = totalIncome - totalExpanse;
  const leftAmount = Math.max(0, difference);
  const freeSpend = Math.max(0, totalIncome - totalExpanse - totalGoal);
  const savingRatio = totalIncome > 0 ? (leftAmount * 100) / totalIncome : 0;
  return (
    <PaperContainer fullWidth title="Resumen de presupuesto 📊">
      <Box sx={{ width: '100%' }}>
        <Box my={2}>
          <BudgetSummaryCard title="Ingreso Total" amount={totalIncome} color="success" clickable>
            <Box pl={4} pb={2}>
              <Typography fontSize="18px" fontWeight={700}>
                Cómo aumentar mis ingresos
              </Typography>
              <ul>
                <li>Servicios esporádicos: Cornershop, uber, etc</li>
                <li>Vende cosas que ya no uses por mercado libre</li>
                <li>Podrías hacer clases de algo. Matematicas, musica y deportes son algunas de las favoritas</li>
                <li>¿Te sobra una pieza en tu casa? La podrias arrendar</li>
                <li>Puedes participar en las ferias los fines de semana vendiendo algún producto</li>
                <li>
                  <Link
                    href="https://acreencias.cmfchile.cl/"
                    target="_blank"
                    rel="noreferrer"
                    color="success.main"
                    fontWeight={700}
                  >
                    Los bancos le deben dinero a 200.000+ personas, revisa aquí si estás en la lista
                  </Link>
                </li>
                <li>¿Youtuber? Quien sabe...</li>
              </ul>
              <Typography fontSize="18px" fontWeight={700}>
                ¿Qué cosas evitar?
              </Typography>
              <ul>
                <li>Casas de apuestas o casinos. Suena tentador, pero acuerdate, la casa siempre gana</li>
                <li>
                  No faltes a tu trabajo o pierdas el foco con tal de ganar más. Recuerda que mantener tu ingreso
                  principal es lo más importante
                </li>
                <li>Obviamente, nada ilegal 🏴‍☠️</li>
              </ul>
            </Box>
          </BudgetSummaryCard>
        </Box>
        <Box my={2}>
          <BudgetSummaryCard title="Gasto Total" amount={totalExpanse} color="warning" clickable>
            <Box pl={4} pb={2}>
              <BudgetSummaryExpenseItem Icon={HomeIcon} text="Hogar" amount={totalHome} />
              <BudgetSummaryExpenseItem Icon={ProfileIcon} text="Personal" amount={totalFamily} />
              <BudgetSummaryExpenseItem Icon={ChildIcon} text="Hijos" amount={totalChild} />
              <BudgetSummaryExpenseItem Icon={EntertainmentIcon} text="Entretención" amount={totalEntertain} />
              <BudgetSummaryExpenseItem Icon={InstitutionIcon} text="Financiero" amount={totalFinance} />
              <Typography fontSize="18px" fontWeight={700} my={1} mt={2}>
                Consejos para bajar gastos y poder ahorrar
              </Typography>
              <li>Bajar al mínimo los gastos de entretención, lamentablemente son los más fáciles de bajar</li>
              <li>
                Disminuye tus gastos financieros con Relif!{' '}
                <Link href="/debt" target="_blank" rel="noreferrer" color="warning.main" fontWeight={700}>
                  Refinancia aquí
                </Link>
              </li>
              <li>
                Planifica tus compras de supermercado, anda a comprar los días de descuento. Lider los lunes{' '}
                <strong>6% dcto</strong>, Jumbo los lunes <strong>5% dcto</strong>, Santa Isabel los martes{' '}
                <strong>8% dcto</strong> para adultos mayores, etc
              </li>
              <li>
                <strong>Ahorra 44%</strong> en tu mercadería, comprando directamente a las fábricas en{' '}
                <Link href="https://12tren.com/" target="_blank" rel="noreferrer" color="warning.main" fontWeight={700}>
                  12tren.com
                </Link>
              </li>
              <li>Trata de no eliminar el gasto de objetivo! Tu futuro lo agradecerá</li>
              <li>Quizás reduce el gasto bencina y aumenta el de Bip!</li>
              <li>
                <Link
                  href="https://www.cmfchile.cl/sitio/siac4/ingresoWebCiudadano.php"
                  target="_blank"
                  rel="noreferrer"
                  color="warning.main"
                  fontWeight={700}
                >
                  Revisa aquí si tienes seguros que ya no uses
                </Link>
              </li>
              <li>Prefiere cocinar en tu casa antes que salir</li>
              <li>Evita usar plataformas de delivery, igual casi siempre llega frío</li>
            </Box>
          </BudgetSummaryCard>
        </Box>
        <Box>
          <BudgetSummaryCard title="Ahorro Total" amount={difference} color="info" clickable>
            <Box pl={4} pr={2} pb={2}>
              <PercentBar
                value={Number(savingRatio)}
                text="porcentaje ahorrado respecto a ingreso total"
                title="Ahorro"
                bgColor="#fff"
              />
              <Typography color="info.main" fontSize="18px" fontWeight={700} mt={1}>
                {priceFormat.format(totalGoal)}
              </Typography>
              <Typography fontSize="18px">se deberían usar para cumplir tus objetivos de ahorro</Typography>
              <Typography color="info.main" fontSize="18px" fontWeight={700} mt={1}>
                {priceFormat.format(freeSpend)}
              </Typography>
              <Typography fontSize="18px">son ahorrados para gastos libres en el futuro</Typography>
              <Typography fontSize="18px" fontWeight={700} mt={1}>
                Consejo Relif
              </Typography>
              <ul>
                <li>
                  No te preocupes si ahora no ahorras mucho, más de la mitad de los chilenos no llega a fin de mes
                </li>
                <li>
                  ¡Hay mucho que se puede hacer! ¿Intentaste{' '}
                  <Link href="/debt" target="_blank" rel="noreferrer" color="info.main" fontWeight={700}>
                    refinanciar tus créditos con Relif
                  </Link>
                  ?
                </li>
                <li>¿Hay gastos que se puedan bajar? Mira arriba para más consejos</li>
                <li>¿Tienes alguna otra fuente de ingresos? Mira arriba para más consejos</li>
              </ul>
            </Box>
          </BudgetSummaryCard>
        </Box>
      </Box>
    </PaperContainer>
  );
};

export default BudgetSummary;
