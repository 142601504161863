import React, { FC } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import PaperContainer from 'components/UI/PaperContainer';
import SectionHeader from 'components/layout/SectionHeader';
import { useGetPageQuery, useGetDatabasePagesQuery } from 'services/api/notion.api';
import { longDateFormat } from 'services/format/dateFormat';

import AuthorLabel from './components/AuthorLabel';
import BlogBreadCrumbs from './components/BlogBreadCrumbs';
import ArticleRecommendations from './components/Recommendations';

const Article: FC = () => {
  const { id: slug } = useParams<{ id: string }>();
  const navigate = useNavigate();
  if (!slug) {
    navigate('/education');
  }
  const { data: blogPage, isFetching } = useGetPageQuery(slug || '');
  const { data: blogPagePartials, isFetching: isFetchingPartials } = useGetDatabasePagesQuery(null);
  const recommendation = blogPagePartials
    ?.filter((page) => page.slug !== slug)
    .sort(() => Math.random() - 0.5)
    .slice(0, 5);
  const loadNotionIframe = () => {
    const iframe = document.getElementById('notion-iframe') as HTMLIFrameElement;
    if (iframe.contentDocument !== null) {
      iframe.contentDocument.body.getElementsByTagName('header')[0].remove();
      iframe.style.height = `${iframe.contentDocument.documentElement.offsetHeight.toString()}px`;
    }
  };
  return (
    <Box>
      <SectionHeader text="Educación Financiera" button={null} />
      <Box
        sx={{
          mt: 2,
          textAlign: 'start',
          pr: { xs: 0, lg: 8, xl: 10 },
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <PaperContainer
          sx={{
            width: { xs: '100%', lg: '60%' },
          }}
        >
          <BlogBreadCrumbs title={blogPage?.title || ''} isHome={false} />
          <Box sx={{ width: '100%', textAlign: 'justify', pb: '50px' }}>
            {!isFetching ? (
              <img
                src={blogPage?.imageUrl}
                alt="article"
                style={{
                  height: '300px',
                  objectFit: 'cover',
                  width: '100%',
                }}
              />
            ) : (
              <Skeleton variant="rectangular" height={300} />
            )}
            <Typography fontWeight={500} mt={1} textAlign="start" fontSize="36px" lineHeight="44px">
              {!isFetching ? blogPage?.title : <Skeleton sx={{ fontSize: '36px' }} />}
            </Typography>
            {!isFetching ? (
              <AuthorLabel
                author={blogPage?.author || 'Relif'}
                authorAvatar={blogPage?.authorAvatarUrl || ''}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                date={longDateFormat(blogPage?.publishedDate) || 'Enero 1, 2023'}
                timeRead={blogPage?.readingTime || 5}
              />
            ) : (
              <Skeleton variant="rectangular" />
            )}
            {!isFetching ? (
              <iframe
                id="notion-iframe"
                onLoad={() => loadNotionIframe()}
                title="article"
                frameBorder="0"
                width="100%"
                srcDoc={blogPage?.html}
                height="100%"
              />
            ) : (
              <Skeleton variant="rectangular" height={500} />
            )}

            {!isFetching && blogPage?.youtubeURL && (
              <Box
                sx={{
                  mt: 2,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <iframe
                  title={blogPage?.title}
                  src={blogPage?.youtubeURL}
                  width="100%"
                  style={{ aspectRatio: '16/9' }}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                />
              </Box>
            )}
          </Box>
        </PaperContainer>
        <ArticleRecommendations blogPages={recommendation} isFetching={isFetchingPartials} />
      </Box>
    </Box>
  );
};

export default Article;
