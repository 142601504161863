import React from 'react';

import { Box } from '@mui/material';

import SectionHeader from 'components/layout/SectionHeader';

import DebtsTable from './DebtsTable';

const Debts = () => {
  return (
    <Box>
      <SectionHeader text="Deudas" button={null} />
      <DebtsTable selectedDebt={null} userId={null} setSelectedDebt={() => {}} refetchFlag={false} />
    </Box>
  );
};

export default Debts;
