/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

// @ts-ignore
import { Button } from '@dscla/ds-button/react';
// @ts-ignore
import { Select, SelectOption } from '@dscla/ds-select/react';
import { AddIcon, DeleteIcon, EditIcon } from 'assets/icons';
import AlertDialog from 'components/UI/AlertDialog';
import PrimaryBtn, { SectionHeaderBtn, PrimaryBtnWithIcon } from 'components/UI/Buttons';
import OptionsDialog from 'components/UI/OptionsDialog';
import PaperContainer from 'components/UI/PaperContainer';
import SectionHeader from 'components/layout/SectionHeader';
import useIsCajaLosAndesTheme from 'hooks/useIsCajaLosAndesTheme';
import InitialPaper from 'pages/Debts/components/InitialPaper';
import { useGetBudgetsV2Query, useDeleteBudgetMutation } from 'services/api/budgets.api';
import { Budget, BudgetRow } from 'services/types/budget';

import BudgetForm from './components/BudgetForm';

const BudgetView = () => {
  const { data: budgets, isLoading, refetch } = useGetBudgetsV2Query(null);
  const [deleteBudget, { data: deleteBudgetData }] = useDeleteBudgetMutation();
  const [budget, setBudget] = useState<Budget | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openNewBudgetDialog, setOpenNewBudgetDialog] = React.useState(false);
  const navigate = useNavigate();
  const isCajaTheme = useIsCajaLosAndesTheme();

  const handleDeleteButton = () => {
    setOpenDeleteDialog(true);
  };

  const handleDelete = async () => {
    if (budget?.id) {
      await deleteBudget(budget.id);
    }
  };

  useEffect(() => {
    if (deleteBudgetData) {
      refetch().catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
      setOpenDeleteDialog(false);
    }
  }, [deleteBudgetData, refetch]);

  useEffect(() => {
    if (budgets && budgets.length > 0) {
      setBudget(budgets[0]);
    } else {
      setBudget(null);
    }
  }, [budgets, deleteBudgetData]);

  if (!budget) {
    return (
      <>
        <SectionHeader
          text="Presupuestos"
          button={<SectionHeaderBtn text="Presupuesto" onClick={() => navigate('/budget/new')} icon={<AddIcon />} />}
        />
        <InitialPaper
          button={
            isCajaTheme ? (
              <Button onClick={() => navigate('/budget/new')} behaviour="fijo">
                <span slot="start">
                  <AddIcon />{' '}
                </span>
                Agregar Presupuesto
              </Button>
            ) : (
              <PrimaryBtn startIcon={<AddIcon />} onClick={() => navigate('/budget/new')}>
                Agregar Presupuesto
              </PrimaryBtn>
            )
          }
          width={500}
          height={300}
          title="Aún no has ingresado un presupuesto"
          isLoading={isLoading}
          paragraph="Agrega un presupuesto para analizar tus ingresos y gastos."
        />
      </>
    );
  }

  const incomeRows = budget.rows.filter((row: BudgetRow) => !row.isExpense);
  const homeRows = budget.rows.filter((row: BudgetRow) => row.category === 'Hogar');
  const personalRows = budget.rows.filter((row: BudgetRow) => row.category === 'Personal');
  const childRows = budget.rows.filter((row: BudgetRow) => row.category === 'Hijos');
  const entreteRows = budget.rows.filter((row: BudgetRow) => row.category === 'Entretención');
  const financeRows = budget.rows.filter((row: BudgetRow) => row.category === 'Financiero');
  const goalRows = budget.rows.filter((row: BudgetRow) => row.category === 'Objetivos');

  const handleChange = (e: { detail: string }) => {
    setBudget(budgets?.find((budgetElement) => budgetElement.id === Number(e.detail)) || null);
  };
  return (
    <Box>
      <SectionHeader
        text=""
        button={
          <Box display="flex" alignItems="end">
            <Box paddingRight="20px">
              <Select label="Presupuesto" value={budget.id.toString()} onClickOption={handleChange}>
                {budgets?.map((budgetElement) => (
                  <SelectOption
                    key={budgetElement.id}
                    value={budgetElement.id.toString()}
                    selected={budgetElement.id === budget.id}
                  >
                    {budgetElement.name}
                  </SelectOption>
                ))}
              </Select>
            </Box>
            <Box sx={{ display: { xs: 'none', lg: 'block' }, pb: '4px' }}>
              <SectionHeaderBtn text="Presupuesto" onClick={() => setOpenNewBudgetDialog(true)} icon={<AddIcon />} />
            </Box>
          </Box>
        }
      />
      <PaperContainer title="Acciones" fullWidth sx={{ mt: 5, display: { xs: 'block', lg: 'none' } }}>
        <Stack pb={1} spacing={1} alignItems="center">
          <PrimaryBtnWithIcon
            text="Crear nuevo presupuesto"
            onClick={() => setOpenNewBudgetDialog(true)}
            icon={<AddIcon />}
            behaviour="fijo"
          />
          <PrimaryBtnWithIcon
            text="Editar presupuesto actual"
            onClick={() => navigate('/budget/edit', { state: { budgetData: budget } })}
            icon={<EditIcon />}
            behaviour="fijo"
          />
          <PrimaryBtnWithIcon
            text="Eliminar presupuesto"
            onClick={handleDeleteButton}
            icon={<DeleteIcon />}
            behaviour="fijo"
          />
        </Stack>
      </PaperContainer>
      <BudgetForm
        incomeRows={incomeRows}
        homeRows={homeRows}
        goalRows={goalRows}
        childRows={childRows}
        entertainmentRows={entreteRows}
        familyRows={personalRows}
        financeRows={financeRows}
        actionButtons={
          <Box sx={{ display: { xs: 'none', lg: 'flex' } }}>
            <Box pr={1}>
              <PrimaryBtnWithIcon
                text="Editar"
                onClick={() => navigate('/budget/edit', { state: { budgetData: budget } })}
                icon={<EditIcon />}
              />
            </Box>
            <PrimaryBtnWithIcon text="Eliminar" onClick={handleDeleteButton} icon={<DeleteIcon />} />
          </Box>
        }
      />
      <AlertDialog
        openDialog={openDeleteDialog}
        setOpenDialog={setOpenDeleteDialog}
        header="¿Estás seguro que quieres eliminar este presupuesto?"
        msg="Esta acción no se puede deshacer"
        confirmAction={handleDelete}
        confirmMsg="Eliminar"
      />
      <OptionsDialog
        openDialog={openNewBudgetDialog}
        setOpenDialog={setOpenNewBudgetDialog}
        header="¿Cómo quieres crear tu nuevo presupuesto?"
        description=""
      >
        {isCajaTheme ? (
          <>
            <Box sx={{ display: { xs: 'none', lg: 'flex' }, justifyContent: 'space-evenly', pb: 2, px: 1 }}>
              <Box pr={1}>
                <Button onClick={() => navigate('/budget/new')} variant="secundario">
                  Crear desde cero
                </Button>
              </Box>
              <Button onClick={() => navigate('/budget/new', { state: { budgetData: budget } })}>
                Usar el presupuesto actual
              </Button>
            </Box>
            <Box sx={{ display: { xs: 'grid', lg: 'none' }, pb: 2, px: 1 }}>
              <Box pb={1}>
                <Button onClick={() => navigate('/budget/new')} behaviour="fijo" variant="secundario">
                  Crear desde cero
                </Button>
              </Box>
              <Button onClick={() => navigate('/budget/new', { state: { budgetData: budget } })} behaviour="fijo">
                Usar el presupuesto actual
              </Button>
            </Box>
          </>
        ) : (
          <Box sx={{ display: { xs: 'grid', lg: 'flex' }, justifyContent: 'space-evenly', pb: 2 }}>
            <Box pb={1} pr={1}>
              <PrimaryBtn onClick={() => navigate('/budget/new')}>Crear desde cero</PrimaryBtn>
            </Box>
            <PrimaryBtn onClick={() => navigate('/budget/new', { state: { budgetData: budget } })}>
              Usar el presupuesto actual
            </PrimaryBtn>
          </Box>
        )}
      </OptionsDialog>
    </Box>
  );
};

export default BudgetView;
