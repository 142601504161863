import React, { FC } from 'react';

import { Check, Close } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

import styles from './IconsWithText.module.scss';

interface Props {
  text: string;
  fontSize?: '20px' | '22px';
}

export const CheckWithText: FC<Props> = ({ text, fontSize }) => (
  <Box display="flex" justifyContent="flex-start" alignItems="center">
    <Box className={styles.IconContainer}>
      <Check color="success" />
    </Box>
    <Typography variant="body1" ml={1} fontSize={fontSize}>
      {text}
    </Typography>
  </Box>
);

CheckWithText.defaultProps = {
  fontSize: '20px',
};

export const CrossWithText: FC<Props> = ({ text, fontSize }) => (
  <Box display="flex" justifyContent="flex-start" alignItems="center">
    <Box className={styles.IconContainer}>
      <Close color="warning" />
    </Box>
    <Typography variant="body1" ml={1} fontSize={fontSize}>
      {text}
    </Typography>
  </Box>
);

CrossWithText.defaultProps = {
  fontSize: '20px',
};

export const BigCrossWithText: FC<Props> = ({ text, fontSize }) => (
  <Box display="flex" justifyContent="flex-start" alignItems="center" mt={2}>
    <Box sx={{ bgcolor: 'warning.main' }} className={styles.IconContainer}>
      <Close sx={{ color: '#fff' }} />
    </Box>
    <Typography variant="body1" ml={1} fontWeight={600} fontSize={fontSize}>
      {text}
    </Typography>
  </Box>
);

BigCrossWithText.defaultProps = {
  fontSize: '20px',
};

export const BigCheckWithText: FC<Props> = ({ text, fontSize }) => (
  <Box display="flex" justifyContent="flex-start" alignItems="center">
    <Box sx={{ bgcolor: 'success.main' }} className={styles.IconContainer}>
      <Check sx={{ color: '#fff' }} />
    </Box>
    <Typography variant="body1" ml={1} fontWeight={600} fontSize={fontSize}>
      {text}
    </Typography>
  </Box>
);

BigCheckWithText.defaultProps = {
  fontSize: '20px',
};
