import React, { useState, FC, useEffect } from 'react';

import { Provider, ErrorBoundary } from '@rollbar/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpBatchLink } from '@trpc/client';
import Layout from 'components/layout';
import config from 'config';
import { TranslationProvider } from 'contexts/translationContext';
import useAccessToken from 'hooks/useAccessToken';
import ErrorLoadFailed from 'pages/Error/ErrorLoadFailed';
import AppRoutes from 'routes';
import superjson from 'superjson';
import trpc from 'trpc';

const rollbarConfig = {
  accessToken: config.rollbarAccessToken,
  environment: config.environment,
  enabled: config.environment !== 'development',
  payload: {
    client: {
      javascript: {
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
  },
};

const App: FC = () => {
  const token = useAccessToken();
  const [queryClient] = useState(() => new QueryClient());

  const createTrpcClient = () =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url: `${config.apiUrl}/trpc`,
          headers() {
            if (token && token.accessToken) {
              return { authorization: `Bearer ${token.accessToken}` };
            }
            return {};
          },
        }),
      ],
      transformer: superjson,
    });

  const [trpcClient, setTrpcClient] = useState(createTrpcClient());

  useEffect(() => {
    setTrpcClient(createTrpcClient());
  }, [token]);

  return (
    <Provider config={rollbarConfig}>
      <TranslationProvider>
        <ErrorBoundary fallbackUI={ErrorLoadFailed}>
          <trpc.Provider client={trpcClient} queryClient={queryClient}>
            <QueryClientProvider client={queryClient}>
              <Layout>
                <AppRoutes />
              </Layout>
            </QueryClientProvider>
          </trpc.Provider>
        </ErrorBoundary>
      </TranslationProvider>
    </Provider>
  );
};

export default App;
