import React from 'react';

import { Card, Avatar, Typography, Box, Collapse } from '@mui/material';

import { IncomeIcon, ExpenseIcon, SavingsIcon, ExpandMoreIcon, ExpandLessIcon } from 'assets/icons';
import priceFormat from 'services/format/priceFormat';

import styles from '../EditOrCreateBudget.module.scss';

interface Props {
  color: 'success' | 'primary' | 'error' | 'warning' | 'info';
  title: string;
  amount: number;
  clickable: boolean;
}

const BudgetSummaryCard: React.FC<Props> = ({ color, title, amount, children, clickable }) => {
  const bgColor = color.concat('.light');
  const textColor = color.concat('.main');
  const [open, SetOpen] = React.useState(false);

  const handleClick = () => {
    if (clickable) {
      SetOpen(!open);
    }
  };

  const getIcon = () => {
    if (color === 'success') {
      return <IncomeIcon sx={{ width: '70%', height: '70%' }} />;
    }
    if (color === 'error') {
      return <ExpenseIcon sx={{ width: '70%', height: '70%' }} />;
    }
    return <SavingsIcon sx={{ width: '70%', height: '70%' }} />;
  };
  return (
    <Card
      sx={{
        backgroundColor: bgColor,
        cursor: clickable ? 'pointer' : 'initial',
        userSelect: 'none',
        borderRadius: '20px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
      }}
      onClick={handleClick}
    >
      <Box className={styles.SummaryCard}>
        <Avatar
          sx={{
            width: { xs: '60px', lg: '72px' },
            height: { xs: '60px', lg: '72px' },
            bgcolor: textColor,
          }}
        >
          {getIcon()}
        </Avatar>
        <Box ml={1}>
          <Typography fontWeight={600} mb={1} fontSize="24px">
            {title}
          </Typography>
          <Typography color={textColor} fontSize={{ xs: '28px', lg: '36px' }} fontWeight={700} mb={1}>
            {priceFormat.format(amount)}
          </Typography>
          <Typography fontWeight={400} fontSize="24px">
            al mes
          </Typography>
        </Box>

        {clickable ? (
          <Avatar
            sx={{
              borderRadius: '10px',
              bgcolor: textColor,
              width: '24px',
              height: '24px',
              ml: 'auto',
            }}
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Avatar>
        ) : null}
      </Box>
      {clickable ? <Collapse in={open}>{children}</Collapse> : null}
    </Card>
  );
};

export default BudgetSummaryCard;
