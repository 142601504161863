import React, { FC, useEffect } from 'react';

import Typography from '@mui/material/Typography';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Stepper, Step } from '@dscla/ds-stepper/react';

interface Props {
  processSteps: { title: string; description: string | React.ReactElement }[];
  currentStepIndex: number;
}

const TraceStepper: FC<Props> = ({ processSteps, currentStepIndex }) => {
  const [selectedStep, setSelectedStep] = React.useState(currentStepIndex);
  useEffect(() => {
    setSelectedStep(currentStepIndex);
  }, [currentStepIndex, processSteps]);
  return (
    <Stepper size="small" vertical>
      {processSteps?.map((step, index) => (
        <Step
          slot="step"
          size="small"
          key={step.title + index.toString()}
          text={(index + 1).toString()}
          success={currentStepIndex > index}
          onClick={() => setSelectedStep(index)}
        >
          {/* TODO: let the component handle the font size and weight */}
          <Typography slot="label" fontSize={18} fontWeight={650}>
            {step.title}
          </Typography>
          <Typography
            slot="content"
            fontSize={16}
            fontWeight={500}
            sx={{ display: selectedStep === index ? 'block' : 'none' }}
          >
            {step.description}
          </Typography>
        </Step>
      ))}
    </Stepper>
  );
};

export default TraceStepper;
