import React, { FC } from 'react';

import { Grid, Typography } from '@mui/material';

import { ForwardIcon } from 'assets/icons';
import PrimaryBtn from 'components/UI/Buttons';
import RoundedInput from 'components/UI/RoundedInput';
import priceFormat from 'services/format/priceFormat';

interface Props {
  extraIncome: string;
  setExtraIncome: (extraIncome: string) => void;
  averageNetIncome: string;
  setAverageNetIncome: (averageNetIncome: string) => void;
  handleNext: () => void;
}

const GetIncomeStep: FC<Props> = ({
  extraIncome,
  setExtraIncome,
  averageNetIncome,
  setAverageNetIncome,
  handleNext,
}) => {
  const processIncomeInput = (stringValue: string) => {
    const value = Number(stringValue.replace(/\D+/g, ''));
    return priceFormat.format(value);
  };
  return (
    <Grid container>
      <Grid item xs={12} py={1}>
        <Typography variant="body2" textAlign="justify">
          Ingresa un valor aproximado de tu ingreso líquido mensual promedio.
        </Typography>
      </Grid>
      <Grid item xs={12} py={1}>
        <RoundedInput
          variant="outlined"
          value={averageNetIncome}
          required
          fullWidth
          label="Ingreso Líquido Mensual Promedio"
          onChange={(e) => {
            setAverageNetIncome(processIncomeInput(e.target.value));
          }}
        />
      </Grid>
      <Grid item xs={12} py={1}>
        <Typography variant="body2" textAlign="justify">
          Ingresa un promedio de tus ingresos extra mensuales. Déjalo en blanco si no tienes.
        </Typography>
      </Grid>
      <Grid item xs={12} py={1}>
        <RoundedInput
          variant="outlined"
          value={extraIncome}
          fullWidth
          label="Ingreso Extra"
          onChange={(e) => {
            setExtraIncome(processIncomeInput(e.target.value));
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <PrimaryBtn
          variant="contained"
          sx={{ my: 2 }}
          fullWidth
          onClick={handleNext}
          disabled={averageNetIncome === ''}
          endIcon={<ForwardIcon />}
        >
          Siguiente
        </PrimaryBtn>
      </Grid>
    </Grid>
  );
};

export default GetIncomeStep;
