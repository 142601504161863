import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import priceFormat from 'services/format/priceFormat';
import { CompanyDebtMetrics } from 'services/types/companyMetrics';

interface DebtMetricsSummaryProps {
  debtMetrics: CompanyDebtMetrics;
}

const DebtMetricsSummary: FC<DebtMetricsSummaryProps> = ({ debtMetrics }) => {
  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        borderRadius: 2,
        p: 3,
      }}
    >
      <Typography variant="h5">Resumen de deudas</Typography>
      <table>
        <thead>
          <tr>
            <th>Categoría</th>
            <th>Cantidad</th>
            <th>Saldo adeudado</th>
            <th>Pagos mensuales</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>Tipo de institución</strong>
            </td>
          </tr>
          {Object.keys(debtMetrics.byInstitutionType).map((institutionType) => (
            <tr key={institutionType}>
              <td>{institutionType}</td>
              <td>{debtMetrics.byInstitutionType[institutionType].debtQuantity}</td>
              <td>{priceFormat.format(debtMetrics.byInstitutionType[institutionType].totalDebtAmount)}</td>
              <td>{priceFormat.format(debtMetrics.byInstitutionType[institutionType].totalDebtPayments)}</td>
            </tr>
          ))}
          <tr>
            <td>
              <strong>Tipo de deuda</strong>
            </td>
          </tr>
          {Object.keys(debtMetrics.byDebtType).map((debtType) => (
            <tr key={debtType}>
              <td>{debtType}</td>
              <td>{debtMetrics.byDebtType[debtType].debtQuantity}</td>
              <td>{priceFormat.format(debtMetrics.byDebtType[debtType].totalDebtAmount)}</td>
              <td>{priceFormat.format(debtMetrics.byDebtType[debtType].totalDebtPayments)}</td>
            </tr>
          ))}
          <tr>
            <td>
              <strong>Institución</strong>
            </td>
          </tr>
          {Object.keys(debtMetrics.byInstitutionName).map((institution) => (
            <tr key={institution}>
              <td>{institution}</td>
              <td>{debtMetrics.byInstitutionName[institution].debtQuantity}</td>
              <td>{priceFormat.format(debtMetrics.byInstitutionName[institution].totalDebtAmount)}</td>
              <td>{priceFormat.format(debtMetrics.byInstitutionName[institution].totalDebtPayments)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
};

export default DebtMetricsSummary;
