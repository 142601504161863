import { OnlineCompanyMetrics } from '@relif/backend';

import { AllowedUser } from '../types/allowedUsers';
import baseApi from './baseApi';

const allowedUserHRAdminAPI = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllowedUsersHRAdmin: build.query({
      query: () => ({
        url: '/hr',
        method: 'GET',
      }),
      transformResponse: (response: AllowedUser[]): AllowedUser[] => response,
    }),
    createAllowedUserHRAdmin: build.mutation({
      query: (body: { email?: string; rut?: string }) => ({
        url: '/hr',
        method: 'POST',
        body,
      }),
      transformResponse: (response: AllowedUser): AllowedUser => response,
    }),
    deleteAllowedUserHRAdmin: build.mutation({
      query: (id: number) => ({
        url: `/hr/${id}`,
        method: 'DELETE',
      }),
      transformResponse: (response: AllowedUser): AllowedUser => response,
    }),
    uploadFile: build.mutation({
      query: (body: FormData) => ({
        url: `/hr/upload`,
        method: 'POST',
        formData: true,
        body,
      }),
      transformResponse: (response: { notValidRows: string[]; createdRows: string[] }) => response,
    }),
    getCLAMetrics: build.query({
      query: () => ({
        url: 'claMetrics/summary',
        method: 'GET',
      }),
      transformResponse: (response: {
        last8WeekVisitorsData: number[];
        last8weeksOnlineMetrics: OnlineCompanyMetrics[];
        totalMetrics: OnlineCompanyMetrics;
        totalVisitors: number;
      }) => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllowedUsersHRAdminQuery,
  useCreateAllowedUserHRAdminMutation,
  useDeleteAllowedUserHRAdminMutation,
  useUploadFileMutation,
  useGetCLAMetricsQuery,
} = allowedUserHRAdminAPI;
