import React, { FC } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { ForwardIcon } from 'assets/icons';
import DebtTermOption from 'assets/images/debt_term_option.svg';
import SelectedDebtTermOption from 'assets/images/selected_debt_term_option.svg';
import priceFormat from 'services/format/priceFormat';

interface Props {
  boldTitle: string;
  title: string;
  amount: number;
  isSelected: boolean;
  onClick: () => void;
}

const TermOptionCard: FC<Props> = ({ boldTitle, title, amount, isSelected, onClick }) => {
  return (
    <Box
      sx={{
        borderRadius: '20px',
        border: '1px solid',
        borderColor: isSelected ? 'white' : 'info.main',
        backgroundColor: isSelected ? '#CAE5FF' : 'white',
        width: '100%',
        my: 2,
        px: 1,
        py: 2,
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <Grid container spacing={1}>
        <Grid item xs={2} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <img
            src={isSelected ? SelectedDebtTermOption : DebtTermOption}
            alt="debt term option"
            width="38px"
            height="38px"
          />
        </Grid>
        <Grid item xs={8}>
          <Typography
            variant="body1"
            sx={{
              width: '100%',
              fontSize: 18,
              fontWeight: 400,
              textAlign: 'left',
            }}
          >
            <b>{boldTitle}</b>
            {title}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              width: '100%',
              fontSize: 24,
              fontWeight: 600,
              textAlign: 'left',
            }}
          >
            {priceFormat.format(amount)}
          </Typography>
        </Grid>
        <Grid item xs={2} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <ForwardIcon
            sx={{
              width: '12px',
              height: '12px',
              color: isSelected ? 'newBlue.A400' : 'info.main',
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TermOptionCard;
