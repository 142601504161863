import React, { FC } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface Props {
  title: string | null;
}

const DrawerContent: FC<Props> = ({ title, children }) => {
  return (
    <Box py="10px" px="20px">
      {title && (
        <Typography my={2} color="primary" fontWeight={700} fontSize="20px">
          {title}
        </Typography>
      )}
      {children}
    </Box>
  );
};

export default DrawerContent;
