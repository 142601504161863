import React, { FC, useEffect } from 'react';

import { Box, Grid, Paper, Typography } from '@mui/material';

import MoneyInput from 'components/UI/MoneyInput';
import { useCreateDebtMutation } from 'services/api/debt.api';
import priceFormat from 'services/format/priceFormat';
import { DebtToCategorize } from 'services/types/debt';

interface DebtTypeCategoryInterface {
  display: boolean;
  debtType: string;
  maxValue: number;
  totalAmount: number;
  approximationError: number;
  totalCategoryAmount: number;
  setTotalCategoryAmount: (value: number) => void;
  totalCategoryMonthlyPayment: number;
  setTotalCategoryMonthlyPayment: ((value: number) => void) | null;
}

const DebtTypeCategory: FC<DebtTypeCategoryInterface> = ({
  display,
  debtType,
  maxValue,
  totalAmount,
  approximationError,
  totalCategoryAmount,
  setTotalCategoryAmount,
  totalCategoryMonthlyPayment,
  setTotalCategoryMonthlyPayment,
}) => {
  if (!display) return null;
  return (
    <>
      <Grid item xs={3} display="flex" flexDirection="column" alignItems="flex-start" justifyContent="flex-end">
        <Typography variant="body1">{debtType}</Typography>
        {maxValue > 0 && (
          <Typography
            variant="body1"
            fontSize="15px"
            color="primary"
            onClick={() => {
              setTotalCategoryAmount(maxValue);
            }}
            sx={{ cursor: 'pointer', textDecoration: 'underline' }}
          >
            {totalCategoryAmount === 0 && maxValue >= totalAmount && 'Asignar todo'}
            {maxValue > 0 && totalCategoryAmount === 0 && maxValue < totalAmount && 'Asignar el resto'}
          </Typography>
        )}
      </Grid>
      <Grid item xs={5}>
        <MoneyInput
          label="Total"
          value={totalCategoryAmount}
          setValue={setTotalCategoryAmount}
          maxValue={maxValue ? maxValue + totalCategoryAmount + approximationError : undefined}
        />
      </Grid>
      <Grid item xs={4}>
        {setTotalCategoryMonthlyPayment && (
          <MoneyInput
            label="Cuota Mensual"
            value={totalCategoryMonthlyPayment}
            setValue={setTotalCategoryMonthlyPayment}
            maxValue={totalCategoryAmount}
          />
        )}
      </Grid>
      {totalCategoryAmount > 0 && setTotalCategoryMonthlyPayment && totalCategoryMonthlyPayment === 0 && (
        <Grid item xs={12}>
          <Typography variant="body1" fontSize="15px" color="error">
            *Debes ingresar el monto de la cuota mensual
          </Typography>
        </Grid>
      )}
      {totalCategoryMonthlyPayment > 0 && totalCategoryMonthlyPayment > totalCategoryAmount && (
        <Grid item xs={12}>
          <Typography variant="body1" fontSize="15px" color="error">
            *El monto total no puede ser menor a la cuota mensual
          </Typography>
        </Grid>
      )}
    </>
  );
};

interface CategorizeInstitutionDebtsInterface {
  directDebt: DebtToCategorize;
  save: boolean;
  index: number;
  disabled: boolean[];
  setDisabled: (disabled: boolean[]) => void;
}

const CategorizeInstitutionDebts: FC<CategorizeInstitutionDebtsInterface> = ({
  directDebt,
  save,
  index,
  disabled,
  setDisabled,
}) => {
  const [mortgage, setMortgage] = React.useState(0);
  const [mortgageMonthlyPayment, setMortgageMonthlyPayment] = React.useState(0);
  const [consumerCredit, setConsumerCredit] = React.useState(0);
  const [consumerCreditMonthlyPayment, setConsumerCreditMonthlyPayment] = React.useState(
    directDebt.debtType === 'Crédito de consumo' ? directDebt.totalMonthlyPayment : 0
  );
  const [creditCard, setCreditCard] = React.useState(0);
  const [creditLine, setCreditLine] = React.useState(0);
  const [createDebt] = useCreateDebtMutation();

  const maxValue =
    directDebt.totalAmount > 0 ? directDebt.totalAmount - mortgage - consumerCredit - creditCard - creditLine : 0;

  const approximationError = 0.05 * Number(directDebt.totalAmount);

  const createNewDebts = async () => {
    const debtBase = {
      institutionType: directDebt.institutionType || 'Banco',
      institutionName: directDebt.institutionName || 'Institución',
      currency: 'CLP',
      term: 0,
      interestRate: 0,
      pendingPayments: 0,
      monthsBehind: 0,
      source: directDebt.source || 'Manual',
    };
    const promises = [];
    if (mortgage > 0) {
      promises.push(
        await createDebt({
          ...debtBase,
          monthlyPayment: mortgageMonthlyPayment,
          amount: mortgage,
          debtType: 'crédito hipotecario',
        })
      );
    }
    if (consumerCredit > 0) {
      promises.push(
        await createDebt({
          ...debtBase,
          monthlyPayment: consumerCreditMonthlyPayment,
          amount: consumerCredit,
          debtType: 'crédito de consumo',
        })
      );
    }
    if (creditCard > 0) {
      promises.push(
        await createDebt({
          ...debtBase,
          monthlyPayment: 0,
          amount: creditCard,
          debtType: 'tarjeta de crédito',
        })
      );
    }
    if (creditLine > 0) {
      promises.push(
        await createDebt({
          ...debtBase,
          monthlyPayment: 0,
          amount: creditLine,
          debtType: 'linea de crédito',
        })
      );
    }
    await Promise.all(promises);
  };

  const updateDisabled = () => {
    const items = [...disabled];
    const isNotAllTotalAmountAssigned =
      (mortgage > 0 && mortgageMonthlyPayment === 0) ||
      (consumerCredit > 0 && consumerCreditMonthlyPayment === 0) ||
      maxValue > approximationError;
    const isNotAllMonthlyPaymentAssigned =
      mortgage + consumerCredit + creditCard + creditLine < directDebt.totalMonthlyPayment;

    items[index] = directDebt.totalAmount > 0 ? isNotAllTotalAmountAssigned : isNotAllMonthlyPaymentAssigned;
    setDisabled(items);
  };

  useEffect(() => {
    updateDisabled();
  }, [mortgage, mortgageMonthlyPayment, consumerCredit, consumerCreditMonthlyPayment, creditCard, creditLine]);

  useEffect(() => {
    if (save) {
      createNewDebts().catch((error) => console.error(error)); // eslint-disable-line no-console
    }
  }, [save]);

  return (
    <Grid container spacing={1} rowSpacing={2} pt={{ xs: '10px', lg: '22px' }}>
      <Grid item xs={12}>
        <Paper
          sx={{
            width: '100%',
            px: '20px',
            py: '8px',
            borderRadius: '21px',
            bgcolor: 'backgroundGrey.main',
          }}
        >
          {directDebt.totalAmount > 0 ? (
            <>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h4" color="primary" fontWeight={600}>
                  Por asignar
                </Typography>
                <Typography variant="h4" color="primary" fontWeight={600}>
                  {priceFormat.format(maxValue)}
                </Typography>
              </Box>
              {maxValue > approximationError && (
                <Typography variant="body1" color="primary">
                  *Debes asignar el monto completo
                </Typography>
              )}
            </>
          ) : (
            <>
              <Typography variant="h4" color="primary" fontWeight={600}>
                Indícanos el monto vigente de tu deuda
              </Typography>
              <Typography variant="body1" color="primary">
                *Sabemos la cuota mensual de esta deuda, pero no el monto que te queda por pagar
              </Typography>
            </>
          )}
        </Paper>
      </Grid>
      <DebtTypeCategory
        debtType="Crédito hipotecario"
        maxValue={maxValue}
        totalAmount={directDebt.totalAmount}
        approximationError={approximationError}
        totalCategoryAmount={mortgage}
        setTotalCategoryAmount={setMortgage}
        totalCategoryMonthlyPayment={mortgageMonthlyPayment}
        setTotalCategoryMonthlyPayment={setMortgageMonthlyPayment}
        display={directDebt.debtType === 'Crédito hipotecario' || !directDebt.debtType}
      />
      <DebtTypeCategory
        debtType="Crédito de consumo"
        maxValue={maxValue}
        totalAmount={directDebt.totalAmount}
        approximationError={approximationError}
        totalCategoryAmount={consumerCredit}
        setTotalCategoryAmount={setConsumerCredit}
        totalCategoryMonthlyPayment={consumerCreditMonthlyPayment}
        setTotalCategoryMonthlyPayment={setConsumerCreditMonthlyPayment}
        display={directDebt.debtType === 'Crédito de consumo' || !directDebt.debtType}
      />
      <DebtTypeCategory
        debtType="Tarjeta de crédito"
        maxValue={maxValue}
        totalAmount={directDebt.totalAmount}
        approximationError={approximationError}
        totalCategoryAmount={creditCard}
        setTotalCategoryAmount={setCreditCard}
        totalCategoryMonthlyPayment={0}
        setTotalCategoryMonthlyPayment={null}
        display={directDebt.debtType === 'Tarjeta de crédito' || !directDebt.debtType}
      />
      <DebtTypeCategory
        debtType="Línea de crédito"
        maxValue={maxValue}
        totalAmount={directDebt.totalAmount}
        approximationError={approximationError}
        totalCategoryAmount={creditLine}
        setTotalCategoryAmount={setCreditLine}
        totalCategoryMonthlyPayment={0}
        setTotalCategoryMonthlyPayment={null}
        display={directDebt.debtType === 'Línea de crédito' || !directDebt.debtType}
      />
    </Grid>
  );
};

export default CategorizeInstitutionDebts;
