import React, { FC } from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { DataTable } from '@dscla/ds-data-table/react';
import CLALink from 'components/UI/CLALink';
import DefinitionWrapper from 'components/common/DefinitionWrapper';

import BodyTypography from './BodyTypography';

export const DS19Definition: FC = () => (
  <BodyTypography>
    El Programa de Integración Social y Territorial (DS19) cuenta con proyectos habitacionales que incorporan a familias
    de diferentes realidades socioeconómicas, en barrios bien localizados y cercanos a servicios, con estándares de
    calidad en diseño, equipamiento y áreas verdes.
    <Box mt={2}>
      A este programa pueden acceder familias que buscan adquirir su primera vivienda con apoyo del Estado, ya sea que
      cuenten con un subsidio habitacional para comprar una vivienda nueva y aún no lo han aplicado, como también
      familias sin subsidio, pero que cumplen con los requisitos de postulación de sectores medios.
    </Box>
    <Box mt={2}>
      A este programa puedes acceder con o sin <CLALink href="/new-home/mortgages">crédito hipotecario.</CLALink>
    </Box>
  </BodyTypography>
);

export const DS19WithSubsidy: FC = () => (
  <>
    <Typography variant="h3" mt={2} fontSize="26px">
      Sin deuda hipotecaria:
    </Typography>
    <BodyTypography>
      Puede acceder a este programa si cuenta con alguno de los siguientes Subsidios:
      <ul>
        <li>Fondo Solidario de Elección de Vivienda (DS49), para familias vulnerables.</li>
        <li>Subsidio para familias de sectores medios (DS1) Tramo 1.</li>
        <li>Subsidio destinado a damnificados a partir del 2014.</li>
      </ul>
      Con certificado de subsidio en su poder permite comprar una vivienda de hasta 1.100 UF. Sólo en caso de no existir
      cupo para viviendas de 1.100 UF puede optar a viviendas de hasta 1.400 UF.
    </BodyTypography>
    <Typography variant="h3" mt={2} fontSize="26px">
      Con deuda hipotecaria:
    </Typography>
    <BodyTypography>
      Puede acceder a este programa si cuenta con subsidio para familias de sectores medios (DS1) Tramo I, II o III.
    </BodyTypography>
    <Paper
      elevation={4}
      sx={{
        borderRadius: '10px',
        mt: 2,
      }}
    >
      <DataTable variant="card">
        <table slot="card">
          <thead>
            <tr>
              <td>Con certificado de subsidio DS1 Tramo I en su poder:</td>
              <td>Con certificado de subsidio DS1 Tramo II o III en su poder</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Typography variant="body1" fontSize="20px">
                  Permite comprar una vivienda de hasta 1.400 UF con posibilidad de solicitar un{' '}
                  <CLALink href="/new-home/mortgages">crédito hipotecario</CLALink> para complementar el valor de la
                  vivienda (ya que el subsidio en estos rangos no cubre la totalidad de la vivienda).
                </Typography>
              </td>
              <td>
                <Typography variant="body1" fontSize="20px">
                  Permite comprar una vivienda de hasta 2.200 UF con posibilidad de solicitar un{' '}
                  <CLALink href="/new-home/mortgages">crédito hipotecario</CLALink> para complementar el valor de la
                  vivienda (ya que el subsidio en estos rangos no cubre la totalidad de la vivienda).
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </DataTable>
    </Paper>
    <BodyTypography>
      Si te interesa este subsidio te recomendamos revisar todos los detalles en la{' '}
      <CLALink
        href="https://www.minvu.gob.cl/beneficio/vivienda/subsidio-de-integracion-social-y-territorial-ds19/"
        target="_blank"
        rel="noreferrer"
      >
        página oficial (presiona acá para ir).
      </CLALink>
    </BodyTypography>
  </>
);

export const DS19WithoutSubsidy: FC = () => (
  <>
    <Typography variant="h3" mt={2} fontSize="26px">
      Si no tengo subsidio, los requisitos para postular son:
    </Typography>
    <BodyTypography>
      <ul>
        <li>Tener el ahorro mínimo de 40 u 80 UF (según el valor de la vivienda). No es necesario antiguedad.</li>
        <li>
          Pertenecer hasta el 90% en el{' '}
          <CLALink target="_blank" rel="noreferrer" href="https://registrosocial.gob.cl/inicio">
            Registro Social de Hogares.
          </CLALink>
        </li>
        <li>
          No tener una propiedad (postulante, cónyuge, conviviente civil o integrantes del grupo familiar declarado).
        </li>
        <li>
          No tener{' '}
          <DefinitionWrapper definitionName="Dicom" placement="bottom">
            DICOM
          </DefinitionWrapper>
          .
        </li>
      </ul>
      Permite comprar una vivienda de hasta 2.200 UF con posibilidad de solicitar un{' '}
      <CLALink href="/new-home/mortgages">crédito hipotecario</CLALink> para complementar el valor de la vivienda.
      <Box mt={2}>
        Si te interesa este subsidio te recomendamos revisar todos los detalles en la{' '}
        <CLALink
          href="https://www.minvu.gob.cl/beneficio/vivienda/subsidio-de-integracion-social-y-territorial-ds19/"
          target="_blank"
          rel="noreferrer"
        >
          página oficial (presiona acá para ir).
        </CLALink>
      </Box>
    </BodyTypography>
  </>
);
