import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Header = () => {
  return (
    <Box
      sx={{
        backgroundImage: 'url("/landing_page/img/headerBackground.png")',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        borderRadius: { xs: '', sm: '0 0 0 200px' },
        pt: { xs: '130px' },
      }}
    >
      <Box display="flex" mx="auto" justifyContent="center" flexDirection={{ xs: 'column', lg: 'row' }}>
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            width: { lg: '50%', xs: '100%' },
            ml: { lg: 4 },
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'inline',
              maxWidth: { lg: '80%', xs: '90%' },
              margin: '0 auto',
              textAlign: { xs: 'center', lg: 'left' },
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontWeight: 700,
                fontSize: { xs: 20, sm: 24 },
                lineHeight: '30px',
                mt: { xs: 8, lg: 0 },
                color: 'white',
                mb: { xs: 2, sm: 0 },
              }}
            >
              La plataforma de salud financiera
            </Typography>
            <Typography
              sx={{
                color: '#9FC6EA',
                fontFamily: 'Roboto',
                fontWeight: '800',
                fontSize: { xs: 35, sm: 45 },
                lineHeight: { xs: '38px', sm: '50px' },
                mb: 3,
              }}
            >
              que refinancia tus deudas en 3 clicks
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            flex: 1,
            pb: 0,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box display={{ xs: 'none', lg: 'flex' }} alignItems="end">
            <img alt="" src="/landing_page/img/phone.png" width="auto" />
          </Box>
          <Box display={{ xs: 'flex', lg: 'none' }} alignItems="end" justifyContent="center">
            <img alt="" src="/landing_page/img/phone.png" width="80%" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
