import { UserRefinanceStatus } from 'services/types/user';

const getRefinanceTypeDescription = (type: UserRefinanceStatus['type'], isCajaTheme: boolean) => {
  if (isCajaTheme && type === 'refinance') {
    return 'Detectamos que tienes deudas al día y puedes calificar para una compra de cartera con Caja Los Andes. Esto significa que podrás unificar todas tus deudas en una sola, con una tasa de interés más baja y un plazo más largo para pagar.';
  }
  switch (type) {
    case 'normalize':
      return 'Detectamos que tienes deudas atrasadas, para poder ayudarte necesitamos que regularices tu situación. Para esto, te ayudaremos a buscar ofertas de pago con tus acreedores. De esta manera podrás volver a estar al día con tus deudas.';
    case 'refinance':
      return 'Detectamos que tienes deudas al día y calificas para una compra de cartera con nuestras instituciones financieras aliadas. Esto significa que podrás unificar todas tus deudas en una sola, con una tasa de interés más baja y un plazo más largo para pagar.';
    default:
      return '';
  }
};

const getRefinanceTypeTitle = (type: UserRefinanceStatus['type']) => {
  switch (type) {
    case 'normalize':
      return 'Normalización de deudas atrasadas';
    case 'refinance':
      return 'Refinanciamiento de deudas al día';
    default:
      return '';
  }
};

const hasActionsRequired = (step: UserRefinanceStatus['step']) => {
  return step.toLocaleLowerCase().includes('actionsrequired');
};

export { getRefinanceTypeDescription, getRefinanceTypeTitle, hasActionsRequired };
