import React, { FC } from 'react';

import { Typography, Box } from '@mui/material';

import type {
  BankOfferMetrics,
  Last12MonthOnlineCompanyMetrics,
  MetricName,
  OnlineCompanyMetrics as OnlineMetrics,
} from '@relif/backend';
import PaperContainer from 'components/UI/PaperContainer';
import priceFormat from 'services/format/priceFormat';
import {
  getFinancialStatusMetrics,
  getFinancialStatusMetricsByMonth,
  metricNameParser,
  offerNameParser,
  financialStatusNameParser,
  navigateToUser,
  navigateToBankOffer,
  getMetricPercentage,
} from 'utils/metricsUtils';

import styles from '../../Admin.module.scss';
import { OffersTable, UsersTable } from './OnlineMetricsSummary';

interface MonthMetricTDProps {
  value: number | string;
  percentage: string | null;
  isSelected: boolean;
  onClick: () => void;
}

const MonthMetricTD: FC<MonthMetricTDProps> = ({ value, percentage, isSelected, onClick }) => {
  return (
    <td style={{ cursor: 'pointer' }} className={isSelected ? styles.SelectedCell : undefined} onClick={onClick}>
      {value}
      <span style={{ fontSize: '10px' }}>{percentage ? ` (${percentage})` : null}</span>
    </td>
  );
};

interface Props {
  metricsByMonthData: Last12MonthOnlineCompanyMetrics;
}

const MetricsByMonth: FC<Props> = ({ metricsByMonthData }) => {
  const [selectedMonthMetricIndex, setSelectedMonthMetricIndex] = React.useState<number>(0);
  const [selectedMonthMetric, setSelectedMonthMetric] = React.useState<MetricName>('registeredUsers');

  const selectedMonthMetricData = metricsByMonthData?.metricsByMonth[selectedMonthMetricIndex];
  const selectedMonthMetricUsers =
    selectedMonthMetric === 'refinanceOffersMetrics' || selectedMonthMetric === 'normalizeOffersMetrics'
      ? selectedMonthMetricData?.bankOfferMetrics[selectedMonthMetric].bankOfferUsers
      : selectedMonthMetricData?.userMetrics[selectedMonthMetric];
  const selectedMonthMetricOffers =
    selectedMonthMetric === 'refinanceOffersMetrics' || selectedMonthMetric === 'normalizeOffersMetrics'
      ? selectedMonthMetricData?.bankOfferMetrics[selectedMonthMetric].bankOffers
      : [];

  const financialStatusMetrics = getFinancialStatusMetrics(
    metricsByMonthData.metricsByMonth[0].userMetrics.usersWithFinancialStatus
  );

  const financialStatusMetricsByMonth = getFinancialStatusMetricsByMonth(metricsByMonthData);

  const handleMonthMetricClick = (index: number, key: MetricName) => {
    setSelectedMonthMetricIndex(index);
    setSelectedMonthMetric(key);
  };
  return (
    <PaperContainer fullWidth>
      <Typography variant="h5">Últimos 12 meses</Typography>
      {metricsByMonthData && (
        <Box sx={{ width: '100%', overflowX: 'auto', boxShadow: 1, borderRadius: 2, mt: 2, p: 2 }}>
          <Box sx={{ minWidth: '2000px', overflowX: 'auto' }}>
            <table style={{ width: '100%' }} className={styles.MetricsTable}>
              <thead style={{ fontSize: '12px' }}>
                <tr>
                  <th>
                    <div style={{ width: '80px' }}>Mes</div>
                  </th>
                  {Object.keys(metricsByMonthData.metricsByMonth[0].userMetrics).map((key) => {
                    if (key === 'usersByDebtGoal') {
                      return null;
                    }
                    return <th key={key}>{metricNameParser(key as keyof OnlineMetrics['userMetrics'])}</th>;
                  })}
                  {Object.keys(metricsByMonthData.metricsByMonth[0].bankOfferMetrics).map((typeKey) => {
                    const parsedTypeKey = typeKey as keyof OnlineMetrics['bankOfferMetrics'];
                    const type = typeKey === 'refinanceOffersMetrics' ? 'refinanciamiento' : 'normalización';
                    return Object.keys(metricsByMonthData.metricsByMonth[0].bankOfferMetrics[parsedTypeKey]).map(
                      (key) => <th key={key}>{offerNameParser(key as keyof BankOfferMetrics, type)}</th>
                    );
                  })}
                  {Object.keys(financialStatusMetrics).map((key) => (
                    <th key={key}>{financialStatusNameParser(key)}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {metricsByMonthData.metricsByMonth.map((metric, index) => (
                  <tr key={new Date(metric.startDate).toISOString()} style={{ textAlign: 'right' }}>
                    <td style={{ width: '200px' }}>
                      {new Date(metric.startDate).toLocaleDateString('es-CL', {
                        month: 'short',
                        year: '2-digit',
                      })}
                    </td>
                    {Object.keys(metric.userMetrics).map((key) => {
                      const parsedKey = key as keyof OnlineMetrics['userMetrics'];
                      const tdKey = `${key}-${new Date(metric.startDate).toISOString()}`;
                      const isSelected = selectedMonthMetricIndex === index && selectedMonthMetric === parsedKey;
                      const percentage = getMetricPercentage(parsedKey, metric);
                      const value =
                        parsedKey !== 'usersByDebtGoal' ? Number(metric.userMetrics[parsedKey]?.length) : null;
                      if (value === null) {
                        return null;
                      }
                      return (
                        <MonthMetricTD
                          key={tdKey}
                          value={value}
                          isSelected={isSelected}
                          onClick={() => handleMonthMetricClick(index, parsedKey)}
                          percentage={percentage}
                        />
                      );
                    })}
                    {Object.keys(metric.bankOfferMetrics).map((typeKey) => {
                      const parsedTypeKey = typeKey as keyof OnlineMetrics['bankOfferMetrics'];
                      return Object.keys(metric.bankOfferMetrics[parsedTypeKey]).map((key) => {
                        const parsedKey = key as keyof BankOfferMetrics;
                        const tdKey = `${typeKey}-${key}-${new Date(metric.startDate).toISOString()}`;
                        const isSelected = selectedMonthMetricIndex === index && selectedMonthMetric === parsedTypeKey;
                        const value =
                          parsedKey === 'totalAmount'
                            ? priceFormat.format(metric.bankOfferMetrics[parsedTypeKey][parsedKey])
                            : metric.bankOfferMetrics[parsedTypeKey][parsedKey].length;
                        const percentage =
                          parsedKey === 'bankOfferUsers' ? getMetricPercentage(parsedTypeKey, metric) : null;
                        return (
                          <MonthMetricTD
                            key={tdKey}
                            value={value}
                            isSelected={isSelected}
                            onClick={() => handleMonthMetricClick(index, parsedTypeKey)}
                            percentage={percentage}
                          />
                        );
                      });
                    })}
                    {Object.keys(financialStatusMetricsByMonth[index]).map((key) => {
                      const parsedKey = key as keyof typeof financialStatusMetrics;
                      const tdKey = `${key}-${new Date(metric.startDate).toISOString()}`;
                      const isSelected =
                        selectedMonthMetricIndex === index && selectedMonthMetric === 'usersWithFinancialStatus';
                      return (
                        <MonthMetricTD
                          key={tdKey}
                          value={financialStatusMetricsByMonth[index][parsedKey]}
                          isSelected={isSelected}
                          onClick={() => handleMonthMetricClick(index, 'usersWithFinancialStatus')}
                          percentage={null}
                        />
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
        </Box>
      )}
      <Typography variant="h5" sx={{ mt: 2 }}>
        Detalle de métricas entre {new Date(selectedMonthMetricData?.startDate || '').toLocaleDateString('es-CL')} -{' '}
        {new Date(selectedMonthMetricData?.endDate || '').toLocaleDateString('es-CL')}
      </Typography>
      <Box
        sx={{
          p: 3,
        }}
      >
        <Typography variant="h5">
          {metricNameParser(selectedMonthMetric as keyof OnlineMetrics['userMetrics'])}{' '}
        </Typography>
        <UsersTable
          users={Array.isArray(selectedMonthMetricUsers) ? selectedMonthMetricUsers : []}
          onClick={navigateToUser}
        />
      </Box>
      {selectedMonthMetric === 'refinanceOffersMetrics' || selectedMonthMetric === 'normalizeOffersMetrics' ? (
        <Box
          sx={{
            p: 3,
            mt: 2,
          }}
        >
          <Typography variant="h5">Ofertas</Typography>
          <OffersTable offers={selectedMonthMetricOffers || []} onClick={navigateToBankOffer} />
        </Box>
      ) : null}
    </PaperContainer>
  );
};

export default MetricsByMonth;
