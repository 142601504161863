import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const RefiRobot = () => {
  return (
    <Box py={{ xs: 12, md: 12 }}>
      <Grid
        container
        justifyContent="center"
        spacing={{ xs: 0, lg: 8 }}
        pb={8}
        flexDirection={{ xs: 'column-reverse', md: 'row' }}
      >
        <Grid item justifyContent="center" display="flex">
          <Box width={{ xs: '80%', sm: '40%', md: '70%', lg: '80%' }}>
            <img alt="" src="/landing_page/img/robot.png" style={{ width: '100%' }} />
          </Box>
        </Grid>
        <Grid item alignSelf="center">
          <Typography fontFamily="Roboto" fontWeight={700} fontSize={22} lineHeight="30px" textAlign="center">
            Nuestro Refi Robot
          </Typography>
          <Typography
            fontFamily="Roboto"
            fontWeight={800}
            fontSize={{ xs: '35px', md: '45px' }}
            lineHeight={{ xs: '38px', md: '50px' }}
            textAlign="center"
            color="#2C5889"
          >
            Consolida tus créditos <br />
            en uno solo
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RefiRobot;
