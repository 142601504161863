import React, { FC } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import priceFormat from 'services/format/priceFormat';
import { Debt } from 'services/types/debt';

import styles from './Simulator.module.scss';
import StatusGaugeChart from './StatusGaugeChart';

interface Props {
  newRCR: number | null;
  selectedSimulation: Debt;
  paymentsDifference: number;
  monthlyDifference: number;
  totalDifference: number;
}

const SimulatedStatusCard: FC<Props> = ({
  newRCR,
  selectedSimulation,
  paymentsDifference,
  monthlyDifference,
  totalDifference,
}) => {
  const paymentsDifferenceColor = paymentsDifference < 0 ? 'success.main' : 'warning.main';
  const paymentsDifferenceText =
    paymentsDifference < 0 ? `-${paymentsDifference} meses` : `+${paymentsDifference} meses`;
  const monthlyDifferenceColor = monthlyDifference < 0 ? 'success.main' : 'warning.main';
  const monthlyDifferenceText =
    monthlyDifference < 0
      ? `-${priceFormat.format(Math.abs(monthlyDifference))}`
      : `+${priceFormat.format(monthlyDifference)}`;
  const totalDifferenceColor = totalDifference < 0 ? 'success.main' : 'warning.main';
  const totalDifferenceText =
    totalDifference < 0
      ? `-${priceFormat.format(Math.abs(totalDifference))}`
      : `+${priceFormat.format(totalDifference)}`;

  return (
    <Box
      sx={{
        backgroundColor: '#EDF1FA',
        borderRadius: '10px',
        width: '100%',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '20px',
          backgroundColor: 'info.main',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          height: '50px',
        }}
      >
        <Typography variant="h5" textAlign="center" color="white" fontWeight={600}>
          Tu carga financiera con la nueva oferta
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {Number(newRCR) > 0 && <StatusGaugeChart realValue={Math.round(Number(newRCR))} />}
      </Box>
      <Grid container p={1}>
        <Grid item xs={6} p={1}>
          <Box className={styles.SimulationItemCard}>
            <Box className={styles.Status} bgcolor={paymentsDifferenceColor} />
            <Box className={styles.SimulationItemDetails}>
              <Typography variant="h6" color="primary.main">
                {selectedSimulation.term}
              </Typography>
              <Typography variant="body1" color="primary.main">
                Meses plazo
              </Typography>
              <Typography variant="body1" color={paymentsDifferenceColor}>
                ({paymentsDifferenceText})
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} p={1}>
          <Box className={styles.SimulationItemCard}>
            <Box className={styles.Status} bgcolor={monthlyDifferenceColor} />
            <Box className={styles.SimulationItemDetails}>
              <Typography variant="h6" color="primary.main">
                {priceFormat.format(Number(selectedSimulation.monthlyPayment))}
              </Typography>
              <Typography variant="body1" color="primary.main">
                Cuota mensual
              </Typography>
              <Typography variant="body1" color={monthlyDifferenceColor}>
                ( {monthlyDifferenceText})
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} p={1}>
          <Box className={styles.SimulationItemCard}>
            <Box className={styles.Status} bgcolor={totalDifferenceColor} />
            <Box className={styles.SimulationItemDetails}>
              <Typography variant="h6" color="primary.main">
                {priceFormat.format(Number(selectedSimulation.monthlyPayment) * Number(selectedSimulation.term))}
              </Typography>
              <Typography variant="body1" color="primary.main">
                Total a pagar
              </Typography>
              <Typography variant="body1" color={totalDifferenceColor}>
                ( {totalDifferenceText})
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} p={1}>
          <Box className={styles.SimulationItemCard}>
            <Box className={styles.Status} />
            <Box className={styles.SimulationItemDetails}>
              <Typography variant="h6" color="primary.main">
                $0
              </Typography>
              <Typography variant="body1" color="primary.main">
                Pago mensual tarjetas
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SimulatedStatusCard;
