import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

interface CompactPercentBarInterface {
  value: number;
  title: string;
  barColor?: string;
  bgColor?: string;
}

interface PercentBarInterface extends CompactPercentBarInterface {
  text: string | null;
}

export const CompactPercentBar: FC<CompactPercentBarInterface> = ({ value, title, bgColor, barColor }) => {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          minWidth: 35,
          justifyContent: 'start',
        }}
      >
        <Typography variant="body2" color={barColor}>{`${Math.round(value)}%`}</Typography>
        <Typography variant="h4" fontSize="15px" ml={1}>
          {title}
        </Typography>
      </Box>
      <Box sx={{ width: '100%' }}>
        <LinearProgress
          variant="determinate"
          value={value}
          sx={{
            height: 10,
            borderRadius: 10,
            [`&.${linearProgressClasses.colorPrimary}`]: {
              backgroundColor: bgColor,
            },
            [`& .${linearProgressClasses.bar}`]: {
              borderRadius: 15,
              backgroundColor: barColor,
            },
          }}
        />
      </Box>
    </Box>
  );
};
const PercentBar: FC<PercentBarInterface> = ({ value, text, title, bgColor, barColor }) => {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          minWidth: 35,
          justifyContent: 'end',
          my: 1,
        }}
      >
        <Typography fontSize="18px" fontWeight={700} sx={{ mr: 'auto' }}>
          {title}
        </Typography>
        <Typography fontSize="18px" color="info.main" fontWeight={700}>{`${Math.round(value)}%`}</Typography>
      </Box>
      <Box sx={{ width: '100%' }}>
        <LinearProgress
          variant="determinate"
          value={value}
          sx={{
            height: 15,
            borderRadius: 15,
            [`&.${linearProgressClasses.colorPrimary}`]: {
              backgroundColor: bgColor,
            },
            [`& .${linearProgressClasses.bar}`]: {
              borderRadius: 15,
              backgroundColor: barColor,
            },
          }}
        />
      </Box>
      <Typography variant="body1" sx={{ mr: 'auto' }} fontSize="18px">
        {text}
      </Typography>
    </Box>
  );
};

CompactPercentBar.defaultProps = {
  barColor: 'info.main',
  bgColor: 'info.light',
};

PercentBar.defaultProps = {
  barColor: 'info.main',
  bgColor: 'info.light',
};

export default PercentBar;
