import React, { FC, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { EditIcon } from 'assets/icons';
import { EditBtn } from 'components/UI/Buttons';
import SearchBar from 'components/UI/SearchBar';
import { useGetAllRoiCalculatorsQuery } from 'services/api/roiCalculators.api';
import { RoiCalculator } from 'services/types/roiCalculators';

interface Props {
  handleEditCalculator: (calculator: RoiCalculator) => void;
  refetchFlag: boolean;
}

const CalculatorsTable: FC<Props> = ({ handleEditCalculator, refetchFlag }) => {
  const { data, refetch } = useGetAllRoiCalculatorsQuery(null);
  const [filteredCalculators, setFilteredCalculators] = useState(data || []);

  useEffect(() => {
    refetch().catch((e) => console.error(e)); // eslint-disable-line no-console
  }, [refetchFlag]);

  useEffect(() => {
    if (data) {
      setFilteredCalculators(data);
    }
  }, [data]);

  return (
    <Box>
      <Box display="flex" justifyContent="flex-start" mb={2} alignItems="center">
        <Typography variant="h3">Lista de calculadoras</Typography>
        <SearchBar data={data || []} setFilteredData={setFilteredCalculators as (data: unknown[]) => void} />
      </Box>
      <TableContainer
        sx={{ height: 'calc(100vh - 300px)', overflow: 'auto', backgroundColor: 'white', borderRadius: 4, p: 2 }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Cantidad de empleados</TableCell>
              <TableCell>Creado en</TableCell>
              <TableCell>Actualizado en</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCalculators?.map((calculator) => (
              <TableRow key={calculator.id}>
                <TableCell>{calculator.id}</TableCell>
                <TableCell>{calculator.email}</TableCell>
                <TableCell>{calculator.employeeCount}</TableCell>
                <TableCell>{new Date(calculator.createdAt).toLocaleString('es-CL')}</TableCell>
                <TableCell>{new Date(calculator.updatedAt).toLocaleString('es-CL')}</TableCell>
                <TableCell>
                  <EditBtn onClick={() => handleEditCalculator(calculator)}>
                    <EditIcon />{' '}
                  </EditBtn>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CalculatorsTable;
