import React, { useState } from 'react';
import { NumberFormatValues, NumericFormat } from 'react-number-format';

import { Box, Container, TextField, Typography, Switch } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

import { DebtsIcon, BlogIcon, BudgetsIcon } from 'assets/icons';
import PrimaryBtn from 'components/UI/Buttons';
import priceFormat, { UFFormat } from 'services/format/priceFormat';
import { RoiCalculatorDraft } from 'services/types/roiCalculators';

import styles from './RoiCalculator.module.scss';
import SectionCard from './SectionCard';

const PRODUCTIVITY_IMPROVEMENT = 0.01;
const ABSENTEEISM_IMPROVEMENT = 0.1;
const ROTATION_IMPROVEMENT = 0.1;

const ROTATION_COST_AS_PERCENTAGE_OF_SALARY = 0.5;
const UFvalue = 35588;

interface Props {
  data: RoiCalculatorDraft;
}

const RoiCalculatorComponent = ({ data }: Props) => {
  const [moreDetails, setMoreDetails] = useState<boolean>(false);
  const [showYearlyValues, setShowYearlyValues] = useState<boolean>(false);
  const [employeeCount, setEmployeeCount] = useState<string>(data.employeeCount.toString());
  const [averageSalary, setAverageSalary] = useState<string>(data.averageSalary.toString());
  const [expectedActiveUsersRate, setExpectedActiveUsersRate] = useState<string>(
    (data.expectedActiveUsersRate * 100).toString()
  );
  const [absenteeismRate, setAbsenteeismRate] = useState<string>((data.absenteeismRate * 100).toString());
  const [rotationRate, setRotationRate] = useState<string>((data.rotationRate * 100).toString());

  const activeUsers = (Number(employeeCount) * Number(expectedActiveUsersRate)) / 100;
  const activeUsersSalary = activeUsers * Number(averageSalary);

  const absenteeismImpact = (Number(absenteeismRate) / 100) * ABSENTEEISM_IMPROVEMENT * activeUsersSalary;
  const rotationImpact =
    ROTATION_COST_AS_PERCENTAGE_OF_SALARY * (Number(rotationRate) / 100) * ROTATION_IMPROVEMENT * activeUsersSalary;
  const productivityImpact = PRODUCTIVITY_IMPROVEMENT * activeUsersSalary;
  const totalImpact = absenteeismImpact + rotationImpact + productivityImpact;

  // Max(Active Users, 0.15 * Employee Count)
  const payingUsers = Math.max(activeUsers, data.minimumActiveUsersChargeRate * Number(employeeCount));

  const segment1Users = Math.min(Number(data.segment2startEmployees), payingUsers);
  const segment2Users = Math.min(
    data.segment3startEmployees - data.segment2startEmployees, // 700 (1000 - 300)
    Math.max(payingUsers - data.segment2startEmployees, 0)
  );
  const segment3Users = Math.min(
    data.segment4startEmployees - data.segment3startEmployees, // 1000 (2000 - 1000)
    Math.max(payingUsers - data.segment3startEmployees, 0)
  );
  const segment4Users = Math.max(payingUsers - data.segment4startEmployees, 0); // 2500 - 2000

  let relifCostUF =
    segment1Users * data.segment1pricePerUser +
    segment2Users * data.segment2pricePerUser +
    segment3Users * data.segment3pricePerUser +
    segment4Users * data.segment4pricePerUser;
  if (data.flatFee != null) {
    relifCostUF = data.flatFee;
  }

  const relifCost = relifCostUF * UFvalue;
  const netValue = totalImpact - relifCost;

  const months = showYearlyValues ? 12 : 1;

  return (
    <>
      <div
        style={{
          backgroundImage: "url('./relif_background.webp')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: '100vh',
          width: '100vw',
          filter: 'blur(10px)',
          margin: 0,
          padding: 0,
        }}
      />
      <Container
        sx={{
          margin: { xs: 0, lg: 4 },
          marginTop: { xs: 8, lg: 0 },

          backgroundImage: 'url("/contact-background.svg")',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          borderRadius: '30px',
          padding: { lg: '50px', xs: '20px' },
          position: 'absolute',
          top: '10%',
          left: '50%',
          transform: 'translate(-50%, -10%)',
        }}
      >
        <Box
          sx={{
            flexDirection: { xs: 'column', lg: 'row' },
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              width: { xs: '100%', lg: '40%' },
              padding: 2,
              borderRadius: '10px',
              border: '1px solid #fff',
              mb: { xs: 2, lg: 0 },
            }}
            className={styles.sectionCard}
          >
            <Typography variant="h2" mb={4} mt={1} color="white">
              Rellena con la información de tu empresa
            </Typography>
            <NumericFormat
              label="Número de empleados"
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={0}
              value={employeeCount}
              valueIsNumericString
              allowNegative={false}
              onValueChange={(values: NumberFormatValues) => {
                setEmployeeCount(values.value);
              }}
              sx={{ mb: 2, width: '100%', bgcolor: '#fff', borderRadius: '10px' }}
              customInput={TextField}
              variant="filled"
            />
            <NumericFormat
              label="Salario promedio mensual"
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={0}
              value={String(averageSalary)}
              valueIsNumericString
              allowNegative={false}
              onValueChange={(values: NumberFormatValues) => {
                setAverageSalary(values.value);
              }}
              sx={{ mb: 2, width: '100%', bgcolor: '#fff', borderRadius: '10px' }}
              customInput={TextField}
              variant="filled"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
            <NumericFormat
              label="Porcentaje de usuarios esperados"
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={0}
              value={expectedActiveUsersRate}
              valueIsNumericString
              allowNegative={false}
              allowLeadingZeros={false}
              onValueChange={(values: NumberFormatValues) => {
                setExpectedActiveUsersRate(values.value);
              }}
              sx={{ mb: 2, width: '100%', bgcolor: '#fff', borderRadius: '10px' }}
              customInput={TextField}
              variant="filled"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
            <NumericFormat
              label="Tasa de ausentismo actual (%)"
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={1}
              value={absenteeismRate}
              valueIsNumericString
              allowNegative={false}
              onValueChange={(values: NumberFormatValues) => {
                setAbsenteeismRate(values.value);
              }}
              sx={{ mb: 2, width: '100%', bgcolor: '#fff', borderRadius: '10px' }}
              customInput={TextField}
              variant="filled"
              helperText="% de días que un empleado se ausenta al trabajo"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
            <NumericFormat
              label="Tasa de rotación actual (%)"
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={1}
              value={rotationRate}
              valueIsNumericString
              allowNegative={false}
              onValueChange={(values: NumberFormatValues) => {
                setRotationRate(values.value);
              }}
              sx={{ mb: 2, width: '100%', bgcolor: '#fff', borderRadius: '10px' }}
              customInput={TextField}
              variant="filled"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              helperText="% de empleados que dejan la empresa en un año"
            />
          </Box>
          <Box sx={{ width: { xs: '100%', lg: '58%' } }}>
            <Box display="flex" sx={{ flexDirection: { xs: 'column', lg: 'row' } }} justifyContent="space-evenly">
              <SectionCard
                title={`Beneficios Relif (${showYearlyValues ? 'año' : 'mes'})`}
                description={priceFormat.format(totalImpact * months)}
                icon={DebtsIcon}
                color="success"
              />
              <SectionCard
                title={`Costo Relif (${showYearlyValues ? 'año' : 'mes'})`}
                description={priceFormat.format(relifCost * months)}
                subDescription={`(${UFFormat(relifCostUF * months)})`}
                icon={BlogIcon}
                color="warning"
              />
              <SectionCard
                title={`Beneficio Neto (${showYearlyValues ? 'año' : 'mes'})`}
                description={priceFormat.format(netValue * months)}
                subDescription={`ROI: ${Math.round((netValue / relifCost) * 100)}%`}
                icon={BudgetsIcon}
                color="info"
              />
            </Box>
            <Box className={styles.sectionCard} style={{ backgroundColor: 'transparent' }}>
              <Typography variant="h4" mb={2} align="right" color="white">
                Ver costo anual:{' '}
                <Switch
                  checked={showYearlyValues}
                  onChange={() => setShowYearlyValues(!showYearlyValues)}
                  color="secondary"
                  sx={{
                    '& .MuiSwitch-track': {
                      backgroundColor: 'grey',
                    },
                  }}
                />
              </Typography>
            </Box>
            <Box sx={{ padding: 2, mt: 2, borderRadius: 2, border: '1px solid #fff' }} className={styles.sectionCard}>
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Typography variant="h2" mb={2} color="white">
                  Beneficios de una mejor salud financiera
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Typography variant="h4" mb={2} color="white">
                  Productividad
                </Typography>
                <Typography variant="h4" mb={2} color="info.main">
                  {priceFormat.format(productivityImpact * months)}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Typography variant="h4" mb={2} color="white">
                  Ausentismo
                </Typography>
                <Typography variant="h4" mb={2} color="info.main">
                  {priceFormat.format(absenteeismImpact * months)}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Typography variant="h4" mb={2} color="white">
                  Rotación
                </Typography>
                <Typography variant="h4" mb={2} color="info.main">
                  {priceFormat.format(rotationImpact * months)}
                </Typography>
              </Box>
            </Box>
            <Box m={1} mt={4} display="flex" flexDirection="row" alignItems="end">
              <Box>
                <Typography variant="h4" mb={2} color="white">
                  Con Relif, tu empresa estará {netValue > 0 ? 'ahorrando ' : 'gastando '}
                  <span className={styles.boldText}>{priceFormat.format(netValue * 12)}</span> anualmente, lo que
                  equivale a <span className={styles.boldText}>{priceFormat.format(netValue)}</span> al mes
                </Typography>
                <Typography variant="body1" color="white" fontSize={12} lineHeight={2}>
                  * Los valores son estimaciones y pueden variar según la realidad de cada empresa.
                </Typography>
                <Typography variant="body1" color="white" fontSize={12} lineHeight={1}>
                  * Valor UF de referencia: {priceFormat.format(UFvalue)} (marzo 2023)
                </Typography>
              </Box>
              <PrimaryBtn onClick={() => setMoreDetails(!moreDetails)}>Ver más detalles</PrimaryBtn>
            </Box>
          </Box>
        </Box>
        <Box mt={2} display={moreDetails ? 'block' : 'none'}>
          <Typography variant="h4" color="white">
            Para hacer las estimaciones de beneficio para la empresa ocupamos los siguientes datos. Siempre usamos
            estimaciones CONSERVADORAS sobre los beneficios que obtendría la empresa con Relif. Estos datos consideran
            SOLO los empleados que efectivamente usan Relif.
          </Typography>
          <Typography variant="h5" color="white">
            <ul style={{ marginBottom: 0 }}>
              <li>
                Empleados sanos financieramente son un {Math.round(PRODUCTIVITY_IMPROVEMENT * 100 * 10) / 10}% más
                productivos.
              </li>
              <li>
                Empleados sanos financieramente tienen un {Math.round(ABSENTEEISM_IMPROVEMENT * 100)}% menos de
                ausentismo. ({absenteeismRate}% &rarr;{' '}
                {Math.round(Number(absenteeismRate) * (1 - ABSENTEEISM_IMPROVEMENT) * 100) / 100}
                %)
              </li>
              <li>
                Empleados sanos financieramente tienen un {Math.round(ABSENTEEISM_IMPROVEMENT * 100)}% menos de
                rotación. ({rotationRate}% &rarr;{' '}
                {Math.round(Number(rotationRate) * (1 - ROTATION_IMPROVEMENT) * 100) / 100}
                %)
              </li>
              <li>Referencias:</li>
              <li style={{ marginLeft: '20px', fontSize: '0.8em' }}>
                Productividad: PwC. (2022). Employee Financial Wellness Survey
              </li>
              <li style={{ marginLeft: '20px', fontSize: '0.8em' }}>
                Ausentismo: Bertera, Robert. (1990). “The effects of workplace health promotion on absenteeism and
                employment costs in a large industrial population”
              </li>
              <li style={{ marginLeft: '20px', fontSize: '0.8em' }}>
                Rotación: Australian Human Resources Institute 2008, “Love&apos;em don&apos;t lose&apos;em - identifying
                retention strategies that work”, HRpulse Research Report.
              </li>
              <li style={{ marginLeft: '20px', fontSize: '0.8em' }}>
                Beneficios de binenestar en empresas: Gobierno de Australia. (2017). Benefits to business - The evidence
                for investing in worker health and wellbeing.
              </li>
              <li>¿Tienes más dudas? Escríbenos a carlos@relif.cl o benjamin@relif.cl</li>
            </ul>
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default RoiCalculatorComponent;
