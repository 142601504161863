import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Button, Modal, TextField, Typography } from '@mui/material';

import PrimaryBtn from 'components/UI/Buttons';
import { setAccessToken } from 'redux/slices/accessToken.slice';
import { useZellerSignUpUserMutation, useSignInUserMutation } from 'services/api/user.api';
import getReCaptchaToken from 'utils/getReCaptchaToken';
import { identifyPosthogUser } from 'utils/posthogUtils';
import validateEmail from 'utils/validations/validateEmail';
import validatePassword, { getPasswordErrorMessage } from 'utils/validations/validatePassword';

interface ZellerAuthModalProps {
  open: boolean;
  onClose: () => void;
  companyId: number;
}

const ZellerAuthModal = ({ open, onClose, companyId }: ZellerAuthModalProps) => {
  const [isExistingUser, setIsExistingUser] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordConfirmError, setPasswordConfirmError] = useState(false);
  const [signIn, { data: signInData, isLoading: signInLoading }] = useSignInUserMutation();
  const [signUp, { data: signUpData, isLoading: signUpLoading }] = useZellerSignUpUserMutation();
  const dispatch = useDispatch();

  const emailVerify = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    if (!event.target.value) {
      setEmailError(false);
    } else {
      setEmailError(!validateEmail(event.target.value));
    }
  };

  const passwordVerify = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    if (!event.target.value) {
      setPasswordError(false);
    } else {
      setPasswordError(!validatePassword(event.target.value, '', email));
    }
  };

  const passwordConfirmVerify = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordConfirm(event.target.value);
    if (!event.target.value) {
      setPasswordConfirmError(false);
    } else {
      setPasswordConfirmError(password !== event.target.value);
    }
  };

  const verifySignUpForm = () => {
    const values = !!email && !!password && !!passwordConfirm;
    const errors = emailError || passwordError || passwordConfirmError;
    return values && !errors;
  };

  const verifySignInForm = () => {
    const values = !!email && !!password;
    const errors = emailError || passwordError;
    return values && !errors;
  };

  const verifyForm = () => {
    return isExistingUser ? verifySignInForm() : verifySignUpForm();
  };

  const handleSignIn = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const recaptchaToken = await getReCaptchaToken();
    await signIn({ email, password, recaptchaToken }).catch(() => {
      console.error('Error signing in'); // eslint-disable-line no-console
    });
  };

  const handleSignUp = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const recaptchaToken = await getReCaptchaToken();

    await signUp({ email, password, recaptchaToken, companyId }).catch(() => {
      console.error('Error signing up'); // eslint-disable-line no-console
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!verifyForm()) return;
    if (isExistingUser) {
      await handleSignIn(event).catch(() => {});
    } else {
      await handleSignUp(event).catch(() => {});
    }
  };

  useEffect(() => {
    const data = signInData || signUpData;
    if (data) {
      identifyPosthogUser(data);
      dispatch(setAccessToken({ accessToken: data.accessToken }));
    }
  }, [signInData, signUpData]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        component="form"
        // eslint-disable-next-line no-void
        onSubmit={(e) => void handleSubmit(e)}
        sx={{
          bgcolor: 'background.paper',
          borderRadius: '10px',
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          justifyContent: 'center',
          alignItems: 'center',
          width: { xs: '100%', sm: '500px' },
        }}
      >
        <Box>
          <Typography variant="h6">{isExistingUser ? 'Iniciar sesión' : 'Registrarse'}</Typography>
        </Box>
        {isExistingUser ? (
          <Box>
            <Typography variant="body1">Inicia sesión con tus datos</Typography>
          </Box>
        ) : (
          <Box>
            <Typography variant="body1">
              Para poder chatear con el asistente, necesitamos que te crees una cuenta
            </Typography>
          </Box>
        )}
        <Box>
          <TextField
            label="Correo electrónico"
            value={email}
            onChange={emailVerify}
            error={emailError}
            helperText={emailError ? 'Correo electrónico inválido' : ''}
            sx={{ width: '300px' }}
          />
        </Box>
        <Box>
          <TextField
            label="Contraseña"
            value={password}
            onChange={passwordVerify}
            error={passwordError}
            type="password"
            helperText={passwordError && getPasswordErrorMessage(password, '', email)}
            sx={{ width: '300px' }}
          />
        </Box>
        {!isExistingUser && (
          <Box>
            <TextField
              label="Confirmar contraseña"
              value={passwordConfirm}
              onChange={passwordConfirmVerify}
              error={passwordConfirmError}
              type="password"
              helperText={passwordConfirmError ? 'Las contraseñas no coinciden' : ''}
              sx={{ width: '300px' }}
            />
          </Box>
        )}
        <Box>
          <PrimaryBtn
            type="submit"
            variant="contained"
            color="primary"
            disabled={signInLoading || signUpLoading || !verifyForm()}
          >
            {isExistingUser ? 'Iniciar sesión' : 'Registrarse'}
          </PrimaryBtn>
        </Box>
        <Box>
          <Button onClick={() => setIsExistingUser(!isExistingUser)}>
            {isExistingUser ? 'Crear cuenta' : 'Ya tengo una cuenta'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ZellerAuthModal;
