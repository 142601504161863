import React, { FC } from 'react';

import { Box, Container } from '@mui/material';

const OfferContainer: FC<{
  children: React.ReactNode;
}> = ({ children }) => (
  <Box sx={{ margin: 0, padding: 0 }}>
    <div
      style={{
        backgroundImage: "url('./../relif_background.webp')",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        width: '100vw',
        filter: 'blur(10px)',
        margin: 0,
        padding: 0,
      }}
    />
    <Container
      sx={{
        backgroundImage: 'url("/../contact-background.svg")',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        borderRadius: '30px',
        padding: { lg: '20px 50px 50px', xs: '20px 10px' },
        position: 'absolute',
        mt: 3,
        top: '10%',
        left: '50%',
        transform: 'translate(-50%, -10%)',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          width: '100%',
        }}
      >
        <Box flexDirection="row" alignItems="flex-end" display="flex" m={1}>
          <a href="/">
            <img alt="Relif" src="/relif_logo_white.webp" height="120px" />
          </a>
        </Box>
        {children}
      </Box>
    </Container>
  </Box>
);

export default OfferContainer;
