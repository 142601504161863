import React, { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';

import translations from 'assets/data/translations';
import { Translation } from 'assets/data/translations/interface';

const TranslationContext = createContext(
  {} as { translation: Translation; setTranslation: React.Dispatch<React.SetStateAction<Translation>> }
);

const TranslationProvider = ({ children }: { children: ReactNode }) => {
  const [translation, setTranslation] = useState(translations.es);

  const path = window.location.pathname;
  const language = path.split('/').pop();
  // if url is a language, set translation to that language
  useEffect(() => {
    if (language && ['en', 'usa'].includes(language)) {
      setTranslation(translations.en);
    } else if (language && ['pt', 'br'].includes(language)) {
      setTranslation(translations.pt);
    } else if (language && ['es', 'cl'].includes(language)) {
      setTranslation(translations.es);
    } else {
      setTranslation(translations.es);
    }
  }, [language]);

  const value = useMemo(() => {
    return {
      translation,
      setTranslation,
    };
  }, [translation]);

  return <TranslationContext.Provider value={value}>{children}</TranslationContext.Provider>;
};

const useTranslation = () => useContext(TranslationContext);

export { TranslationProvider, useTranslation };
