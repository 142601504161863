import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import { BackIcon, CloseIcon } from 'assets/icons';
import { BlackBtn } from 'components/UI/Buttons';
import { useListDebtsQuery } from 'services/api/debt.api';

import ManualDebtFormSteps from '../ManualDebt/ManualDebtFormSteps';

const steps = ['Tipo de institución', 'Nombre de institución', 'Tipo de producto', 'Detalles de deuda'];

interface ManualDebtInterface {
  debtCreationFormOpen: boolean;
  setDebtCreationFormOpen: (value: boolean) => void;
}

const ManualDebtLoop: FC<ManualDebtInterface> = ({
  debtCreationFormOpen,
  setDebtCreationFormOpen,
}: ManualDebtInterface) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const { refetch: refetchDebtData } = useListDebtsQuery(null);
  const handleClose = () => {
    setDebtCreationFormOpen(false);
    setActiveStep(0);
  };

  const handleManualDebtSave = () => {
    refetchDebtData().catch((e) => console.error(e)); // eslint-disable-line no-console
    setDebtCreationFormOpen(false);
  };

  if (debtCreationFormOpen) {
    return (
      <Box maxWidth="600px" width="100%">
        <Box
          sx={{
            height: 50,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {activeStep !== -1 && (
            <BlackBtn
              onClick={() => setActiveStep(activeStep - 1)}
              disabled={activeStep === 0}
              startIcon={<BackIcon />}
              sx={{
                border: 'none',
                padding: '0px',
              }}
            >
              Atrás
            </BlackBtn>
          )}
          <Typography variant="h2" paddingLeft="45px">
            {steps[activeStep]}
          </Typography>
          <BlackBtn sx={{ border: 'none' }} onClick={handleClose} endIcon={<CloseIcon />}>
            Cerrar
          </BlackBtn>
        </Box>
        <ManualDebtFormSteps
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          onSave={handleManualDebtSave}
          debtToEdit={null}
          setFormOpen={setDebtCreationFormOpen}
          setDebtToEdit={() => {}}
          adminMode={false}
          showBanks={false}
        />
      </Box>
    );
  }
  return null;
};

export default ManualDebtLoop;
