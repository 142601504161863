import React, { FC } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import type {
  OnlineCompanyMetrics as OnlineMetrics,
  BankOfferMetrics,
  BankOffer,
  UserWithFinancialStatus,
  OnlineCompanyMetrics,
  MetricName,
} from '@relif/backend';
import PaperContainer from 'components/UI/PaperContainer';
import priceFormat from 'services/format/priceFormat';
import {
  getFinancialStatusMetrics,
  metricNameParser,
  offerNameParser,
  financialStatusNameParser,
  navigateToUser,
  navigateToBankOffer,
  getMetricPercentage,
} from 'utils/metricsUtils';

import styles from '../../Admin.module.scss';

interface MetricCardProps {
  metricName: string;
  metricValue: number | string;
  percentage: string | null;
  isSelected: boolean;
  onClick: () => void;
}

const MetricCard: FC<MetricCardProps> = ({ metricName, metricValue, percentage, isSelected, onClick }) => {
  return (
    <Box
      sx={{
        border: '1px solid #ccc',
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        backgroundColor: isSelected ? '#f5f5f5' : '#fff',
        cursor: 'pointer',
        color: isSelected ? '#000' : '#ccc',
        p: 1,
        minHeight: '100px',
      }}
      onClick={onClick}
    >
      <Typography variant="body1">{metricName}</Typography>
      <Box sx={{ flexGrow: 1 }} />
      <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
        {metricValue}
        <span style={{ fontSize: '13px' }}>{percentage ? ` (${percentage})` : null}</span>
      </Typography>
    </Box>
  );
};

interface UsersTableProps {
  users: OnlineCompanyMetrics['userMetrics']['registeredUsers'];
  onClick: (userId: number) => void;
}

export const UsersTable: FC<UsersTableProps> = ({ users, onClick }) => {
  const firstUser = users[0] as unknown as UserWithFinancialStatus;
  return (
    <Box
      sx={{
        width: '100%',
        maxHeight: '300px',
        overflow: 'auto',
        borderRadius: 5,
        boxShadow: 1,
        p: 2,
      }}
    >
      <table className={styles.MetricsTable}>
        <thead style={{ fontSize: '12px' }}>
          <tr>
            <th>Id</th>
            <th>Email</th>
            <th>Rut</th>
            {firstUser && firstUser.income !== undefined ? <th>Ingreso</th> : null}
            {firstUser && firstUser.totalDebtsAmount !== undefined ? <th>Monto de deudas</th> : null}
            {firstUser && firstUser.totalDebtsMonthlyPayment !== undefined ? <th>Total de cuotas</th> : null}
            {firstUser && firstUser.VR !== undefined ? <th>VR</th> : null}
            {firstUser && firstUser.RCR !== undefined ? <th>Carga financiera</th> : null}
          </tr>
        </thead>
        <tbody>
          {users.map((user) => {
            const parsedUser = user as unknown as UserWithFinancialStatus;
            const id = user.id || 0;
            return (
              <tr key={id} style={{ cursor: 'pointer' }} onClick={() => onClick(id)}>
                <td>{user.id}</td>
                <td style={{ textAlign: 'left' }}>{user.email}</td>
                <td style={{ textAlign: 'left' }}>{user.rut}</td>
                {parsedUser.income !== undefined ? <td>{priceFormat.format(parsedUser.income)}</td> : null}
                {parsedUser.totalDebtsAmount !== undefined ? (
                  <td>{priceFormat.format(parsedUser.totalDebtsAmount)}</td>
                ) : null}
                {parsedUser.totalDebtsMonthlyPayment !== undefined ? (
                  <td>{priceFormat.format(parsedUser.totalDebtsMonthlyPayment)}</td>
                ) : null}
                {parsedUser.VR !== undefined ? <td>{parsedUser.VR.toFixed(2)}</td> : null}
                {parsedUser.RCR !== undefined ? <td>{`${Math.round(parsedUser.RCR * 100)} %`}</td> : null}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Box>
  );
};

interface OffersTableProps {
  offers: BankOffer[];
  onClick: (offerUUID: string) => void;
}

export const OffersTable: FC<OffersTableProps> = ({ offers, onClick }) => {
  return (
    <Box sx={{ width: '100%', maxHeight: '300px', overflow: 'auto', borderRadius: 5, boxShadow: 1, p: 2 }}>
      <table className={styles.MetricsTable}>
        <thead style={{ fontSize: '12px' }}>
          <tr>
            <th>Id oferta</th>
            <th>Id usuario</th>
            <th>Banco</th>
            <th>Monto</th>
            <th>Reacción del usuario</th>
          </tr>
        </thead>
        <tbody>
          {offers.map((offer) => (
            <tr key={offer.id} style={{ cursor: 'pointer' }} onClick={() => onClick(offer.uuid || '')}>
              <td>{offer.id}</td>
              <td>{offer.userId}</td>
              <td style={{ textAlign: 'left' }}>{offer.bank}</td>
              <td>{priceFormat.format(offer.cashPaymentAmount || offer.paymentPlanAmount || 0)}</td>
              <td style={{ textAlign: 'left' }}>{offer.userReaction}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
};

interface Props {
  onlineMetricsData: OnlineMetrics;
}

const OnlineMetricsSummary: FC<Props> = ({ onlineMetricsData }) => {
  const [selectedMetricKey, setSelectedMetricKey] = React.useState<MetricName>('registeredUsers');

  const selectedMetric =
    selectedMetricKey === 'refinanceOffersMetrics' || selectedMetricKey === 'normalizeOffersMetrics'
      ? onlineMetricsData.bankOfferMetrics[selectedMetricKey].bankOfferUsers
      : onlineMetricsData.userMetrics[selectedMetricKey];
  const selectedMetricUsers = Array.isArray(selectedMetric) ? selectedMetric : [];
  const selectedMetricOffers =
    selectedMetricKey === 'refinanceOffersMetrics' || selectedMetricKey === 'normalizeOffersMetrics'
      ? onlineMetricsData.bankOfferMetrics[selectedMetricKey].bankOffers
      : [];

  const financialStatusMetrics = getFinancialStatusMetrics(onlineMetricsData.userMetrics.usersWithFinancialStatus);

  return (
    <PaperContainer fullWidth>
      <Typography variant="h5">
        Métricas acumuladas entre {new Date(onlineMetricsData.startDate).toLocaleDateString('es-CL')} -{' '}
        {new Date(onlineMetricsData.endDate).toLocaleDateString('es-CL')}
      </Typography>
      <Grid container>
        {Object.keys(onlineMetricsData.userMetrics).map((key) => {
          const parsedKey = key as keyof OnlineMetrics['userMetrics'];
          const metric = onlineMetricsData.userMetrics[parsedKey];
          const percentage = getMetricPercentage(parsedKey, onlineMetricsData);
          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={key} p={2}>
              <MetricCard
                metricName={metricNameParser(parsedKey)}
                metricValue={Array.isArray(metric) ? metric.length : 0}
                isSelected={selectedMetricKey === parsedKey}
                onClick={() => setSelectedMetricKey(parsedKey)}
                percentage={percentage}
              />
            </Grid>
          );
        })}
        {Object.keys(onlineMetricsData.bankOfferMetrics).map((typeKey) => {
          const parsedTypeKey = typeKey as keyof OnlineMetrics['bankOfferMetrics'];
          const type = typeKey === 'refinanceOffersMetrics' ? 'refinanciamiento' : 'normalización';
          const metric = onlineMetricsData.bankOfferMetrics[parsedTypeKey];
          return Object.keys(metric).map((key) => {
            const parsedKey = key as keyof BankOfferMetrics;
            const metricName = offerNameParser(parsedKey, type);
            const metricValue =
              parsedKey === 'totalAmount' ? priceFormat.format(metric[parsedKey]) : metric[parsedKey].length;
            const percentage =
              parsedKey === 'bankOfferUsers' ? getMetricPercentage(parsedTypeKey, onlineMetricsData) : null;
            return (
              <Grid item xs={12} sm={6} md={4} lg={3} key={key} p={2}>
                <MetricCard
                  metricName={metricName}
                  metricValue={metricValue}
                  isSelected={selectedMetricKey === parsedTypeKey}
                  onClick={() => setSelectedMetricKey(parsedTypeKey)}
                  percentage={percentage}
                />
              </Grid>
            );
          });
        })}
        {Object.keys(financialStatusMetrics).map((key) => {
          const parsedKey = key as keyof typeof financialStatusMetrics;
          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={key} p={2}>
              <MetricCard
                metricName={financialStatusNameParser(parsedKey)}
                metricValue={financialStatusMetrics[parsedKey]}
                isSelected={selectedMetricKey === 'usersWithFinancialStatus'}
                onClick={() => setSelectedMetricKey('usersWithFinancialStatus')}
                percentage={null}
              />
            </Grid>
          );
        })}
      </Grid>
      <Typography variant="h5" sx={{ mt: 2 }}>
        Detalle de métrica
      </Typography>
      <Box
        sx={{
          p: 3,
        }}
      >
        <Typography variant="h5">{metricNameParser(selectedMetricKey)}</Typography>
        <UsersTable users={selectedMetricUsers} onClick={navigateToUser} />
        <Typography variant="h5" sx={{ mt: 2 }}>
          Ofertas
        </Typography>
        <OffersTable offers={selectedMetricOffers} onClick={navigateToBankOffer} />
      </Box>
    </PaperContainer>
  );
};

export default OnlineMetricsSummary;
