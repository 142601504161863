import { Constant, ConstantDraft } from 'services/types/constant';

import baseApi from './baseApi';

const constantsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getConstants: build.query({
      query: () => ({
        url: '/constants',
        method: 'GET',
      }),
      transformResponse: (response: Constant[]) => response,
    }),
    getSingleConstant: build.query({
      query: (name: string) => ({
        url: `/constants/${name}`,
        method: 'GET',
      }),
      transformResponse: (response: Constant) => response,
    }),
    editOrCreateConstant: build.mutation({
      query: (props: { constant: ConstantDraft; name: string }) => ({
        url: `admin/constants/${props.name}`,
        method: 'PUT',
        body: props.constant,
      }),
      transformResponse: (response: Constant) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useGetConstantsQuery, useGetSingleConstantQuery, useEditOrCreateConstantMutation } = constantsApi;
