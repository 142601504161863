import React, { FC } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Button as CajaButton } from '@dscla/ds-button/react';
import { RefreshIcon } from 'assets/icons';
import PrimaryBtn, { EditBtn } from 'components/UI/Buttons';
import PaperContainer from 'components/UI/PaperContainer';
import useIsCajaLosAndesTheme from 'hooks/useIsCajaLosAndesTheme';
import { useGetMeetingsQuery, useGetActionPlanQuery } from 'services/api/user.api';
import { HubspotMeeting } from 'services/types/hubspotMeetings';

interface NextMeetingCardProps {
  meeting: HubspotMeeting;
}

const NextMeetingCard = ({ meeting }: NextMeetingCardProps) => {
  const { properties } = meeting;
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 1,
        borderRadius: '20px',
        backgroundColor: 'secondary.light',
        py: { xs: '18px', lg: '24px' },
        px: { xs: '15px', lg: '30px' },
      }}
    >
      <Typography variant="h4" lineHeight={2} sx={{ color: 'primary.main' }} fontWeight="bold">
        {new Date(properties.hs_meeting_start_time).toLocaleString('es-CL', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })}
      </Typography>
      {properties.hs_meeting_location && (
        <a href={properties.hs_meeting_location} target="_blank" rel="noreferrer">
          <Button
            sx={{
              border: 'none',
              borderRadius: '20px',
              color: 'primary.main',
              textDecoration: 'underline',
            }}
          >
            Link de la reunión
          </Button>
        </a>
      )}
    </Box>
  );
};

const ActionPlan: FC = () => {
  const { data, isLoading, refetch } = useGetActionPlanQuery(null);
  const { data: meetingsData, isLoading: meetingsLoading } = useGetMeetingsQuery(null);
  const isCajaTheme = useIsCajaLosAndesTheme();

  const nextMeetings = meetingsData?.meetings?.filter((meeting) => {
    if (meeting.properties.hs_meeting_outcome === 'CANCELED') {
      return false;
    }
    const now = new Date();
    const meetingDate = new Date(meeting.properties.hs_meeting_start_time);
    return meetingDate.getTime() + 1000 * 60 * 60 > now.getTime();
  });

  const hasNextMeetings = nextMeetings && nextMeetings.length > 0;

  return (
    <PaperContainer
      fullWidth
      fullHeight
      title="Plan de acción"
      actionBtn={
        isCajaTheme ? (
          <CajaButton
            behaviour="fluido"
            variant="secundario"
            onClick={() => {
              refetch().catch((e) => console.error(e)); // eslint-disable-line no-console
            }}
          >
            <span slot="start">
              <RefreshIcon />
            </span>
            Recargar
          </CajaButton>
        ) : (
          <EditBtn
            onClick={() => {
              refetch().catch((e) => console.error(e)); // eslint-disable-line no-console
            }}
          >
            <RefreshIcon />
          </EditBtn>
        )
      }
    >
      {isLoading && <CircularProgress />}
      {!isLoading && !data?.actionPlan && (
        <>
          <Typography variant="h3" lineHeight={2}>
            Aún no tienes un plan de acción 😪
          </Typography>
          <Typography variant="h5" lineHeight={2}>
            Pero, puedes agendar una hora con nuestro equipo para crear uno!
          </Typography>
        </>
      )}
      {!isLoading && data?.actionPlan && (
        <TextField
          multiline
          fullWidth
          rows={10}
          value={data?.actionPlan}
          disabled
          variant="outlined"
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '20px',
            },
            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
            },
          }}
        />
      )}
      {nextMeetings && nextMeetings.length > 0 && (
        <>
          <Typography variant="h3" lineHeight={2} sx={{ mt: 2 }}>
            Próxima reunión 🗓️
          </Typography>
          <NextMeetingCard meeting={nextMeetings[0]} />
        </>
      )}
      {!hasNextMeetings && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          {isCajaTheme ? (
            <CajaButton
              onClick={() => window.open('/book-meeting', '_blank')}
              disabled={isLoading || meetingsLoading}
              behaviour="fluido"
            >
              Agendar hora
            </CajaButton>
          ) : (
            <PrimaryBtn onClick={() => window.open('/book-meeting', '_blank')} disabled={isLoading || meetingsLoading}>
              Agendar hora
            </PrimaryBtn>
          )}
        </Box>
      )}
    </PaperContainer>
  );
};

export default ActionPlan;
