import { WhatsAppFlow } from '../types/whatsAppFlow';
import baseApi from './baseApi';

const whatsAppApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    activateFlow: build.mutation({
      query: (userId: number) => ({
        url: `/admin/whatsAppFlowInstance/activate-last-flow/${userId}`,
        method: 'POST',
      }),
      transformResponse: (response: WhatsAppFlow | string) => response,
    }),
    pauseFlow: build.mutation({
      query: (userId: number) => ({
        url: `/admin/whatsAppFlowInstance/pause-all-flows/${userId}`,
        method: 'POST',
      }),
      transformResponse: (response: WhatsAppFlow | string) => response,
    }),
    getIsLastFlowActive: build.query({
      query: (userId: number) => ({
        url: `/admin/whatsAppFlowInstance/has-active-flow/${userId}`,
        method: 'GET',
      }),
      transformResponse: (response: boolean) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useActivateFlowMutation, useGetIsLastFlowActiveQuery, usePauseFlowMutation } = whatsAppApi;
