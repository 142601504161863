import React, { FC, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import styles from '../WelcomePanel.module.scss';

interface Props {
  title: string;
  source: string;
  setInterval: (value: number) => void;
}

const VideoSwiperButton: FC<{ onClick: () => void; children: React.ReactNode; position: string }> = (props) => {
  const { onClick, children, position } = props;
  return (
    <Box
      className={styles.VideoSwiperButton}
      sx={{
        position: 'absolute',
        top: '50%',
        left: position === 'left' ? '-10px' : 'auto',
        right: position === 'right' ? '-10px' : 'auto',
        zIndex: 100,
        width: { xs: 34, sm: 36 },
        height: { xs: 40, sm: 42 },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};
const DemoVideo: FC<Props> = (props: Props) => {
  const { title, source, setInterval } = props;
  return (
    <>
      <Box sx={{ position: 'relative', width: '100%', height: '0', paddingBottom: '56.25%' }}>
        <iframe
          onMouseEnter={() => {
            setInterval(80_000);
          }}
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
          title={title}
          src={source}
          width="560"
          height="315"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      </Box>
      <Typography variant="h4" pt={2}>
        {title}
      </Typography>
    </>
  );
};

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const videos = [
  {
    title: 'Aprende sobre Dicom',
    source: 'https://www.youtube.com/embed/JMBTsMKfm50?rel=0',
  },
  {
    title: 'Aprende sobre el refinanciamiento de créditos',
    source: 'https://www.youtube.com/embed/shNPAwIXBSs?rel=0',
  },
  {
    title: 'Aprende sobre la normalización de deudas morosas',
    source: 'https://www.youtube.com/embed/Hei0o9mZAvs?rel=0',
  },
  {
    title: 'Aprende sobre la morosidad',
    source: 'https://www.youtube.com/embed/AcpL-2DcIB4?rel=0',
  },
  {
    title: 'Aprende sobre la cobranza de las instituciones',
    source: 'https://www.youtube.com/embed/6XEWj9Xo0Y8?rel=0',
  },
];

const SwipeableTextMobileStepper = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [interval, setInterval] = useState(5000);
  const maxSteps = videos.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep - 1 === -1 ? maxSteps - 1 : prevActiveStep - 1));
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Box>
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <VideoSwiperButton onClick={handleBack} position="left">
          <KeyboardArrowLeft />
        </VideoSwiperButton>
        <AutoPlaySwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          interval={interval}
        >
          {videos.map((step) => (
            <DemoVideo title={step.title} source={step.source} setInterval={setInterval} key={step.title} />
          ))}
        </AutoPlaySwipeableViews>
        <VideoSwiperButton onClick={handleNext} position="right">
          <KeyboardArrowRight />
        </VideoSwiperButton>
      </Box>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={<div />}
        backButton={<div />}
      />
    </Box>
  );
};

export default SwipeableTextMobileStepper;
