import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FixedSizeList } from 'react-window';

import CircleIcon from '@mui/icons-material/Circle';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { PrimaryBtn } from 'components/UI/Buttons';
import Conversation from 'components/common/Conversation';
import SectionHeader from 'components/layout/SectionHeader';
import useQuery from 'hooks/useQuery';
import {
  useGetConversationMutation,
  useGetWhatsAppUsersQuery,
  useReadConversationMutation,
  useUnreadConversationMutation,
  useGetScheduledWAMessagesMutation,
} from 'services/api/whatsApp.api';
import { Message, WhatsAppUser } from 'services/types/whatsApp';

import AdvancedSearch from './AdvancedSearch';
import ChatFooter from './ChatFooter';
import TemplateForm from './TemplateForm';
import ChatHeader from './chatHeader';

const Chat = () => {
  const [selectedUser, setSelectedUser] = useState<WhatsAppUser | null>(null);
  const [advancedSearchModalOpen, setAdvancedSearchModalOpen] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const { data: users, refetch: refetchUsers } = useGetWhatsAppUsersQuery(null);
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [getConversation, { data: conversationData }] = useGetConversationMutation();
  const [getScheduledMessages, { data: scheduledMessages }] = useGetScheduledWAMessagesMutation();
  const [conversation, setConversation] = useState<Message[]>([]);
  const [readConversationMutation] = useReadConversationMutation();
  const [unreadConversationMutation] = useUnreadConversationMutation();
  const navigate = useNavigate();
  const query = useQuery();

  const userId = query.get('userId');

  const handleGetConversation = (userIdToGet: number) => {
    getConversation(userIdToGet).catch((err) => console.error(err)); // eslint-disable-line no-console
    getScheduledMessages(userIdToGet).catch((err) => console.error(err)); // eslint-disable-line no-console
  };

  const handleSelectUser = (user: WhatsAppUser) => {
    setSelectedUser(user);
    handleGetConversation(Number(user.id));
    readConversationMutation(Number(user.id))
      .then(() => refetchUsers().catch((err) => console.error(err))) // eslint-disable-line no-console
      .catch((err) => console.error(err)); // eslint-disable-line no-console
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set('userId', user.id.toString());
    navigate(`/admin/chat?${queryParams.toString()}`);
  };

  const handleUnreadConversation = (userIdToUnread: number) => {
    unreadConversationMutation(userIdToUnread)
      .then(() => refetchUsers().catch((err) => console.error(err))) // eslint-disable-line no-console
      .catch((err) => console.error(err)); // eslint-disable-line no-console
    setSelectedUser(null);
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.delete('userId');
    navigate(`/admin/chat?${queryParams.toString()}`);
  };

  useEffect(() => {
    if (users) {
      setFilteredUsers(users);
    }
  }, [users]);

  useEffect(() => {
    if (userId && users) {
      const user = users.find((u) => u.id.toString() === userId);
      if (user) {
        handleSelectUser(user);
      }
    }
  }, [userId, users]);

  useEffect(() => {
    if (conversationData) {
      setConversation(conversationData);
    }
  }, [conversationData]);

  const search = (searchText: string) => {
    if (searchText !== '') {
      const lowerCaseSearch = searchText.toLowerCase();
      setFilteredUsers(
        users?.filter((user) => {
          return (
            user.firstName.toLowerCase().includes(lowerCaseSearch) ||
            user.lastName.toLowerCase().includes(lowerCaseSearch) ||
            user.phone.includes(lowerCaseSearch) ||
            user.id.toString() === lowerCaseSearch ||
            user.companyName?.toLowerCase().includes(lowerCaseSearch)
          );
        })
      );
    } else {
      setFilteredUsers(users);
    }
  };

  const [allMessagesSearch, setAllMessagesSearch] = useState<string>('');

  const headerButtonComponent = (
    <Box>
      <PrimaryBtn onClick={() => navigate(-1)} sx={{ mr: 4 }}>
        Volver
      </PrimaryBtn>
      <TextField
        id="outlined-basic-email"
        label="Buscar en todos los mensajes"
        sx={{ width: '300px' }}
        variant="standard"
        onChange={(e) => setAllMessagesSearch(e.target.value)}
        value={allMessagesSearch}
      />
      <PrimaryBtn onClick={() => setAdvancedSearchModalOpen(true)}>Buscar</PrimaryBtn>
    </Box>
  );

  const renderUser = ({ index, style }: { index: number; style: React.CSSProperties }) => {
    if (!filteredUsers) return <>Item vacío</>;
    const user = filteredUsers[index];
    return (
      <div style={style}>
        <ListItemButton selected={selectedUser === user} onClick={() => handleSelectUser(user)}>
          <ListItemText
            primary={
              <Typography fontSize="17px">
                {user.firstName} {user.lastName} ({user.id}) - {user.companyName || ''}
              </Typography>
            }
            secondary={
              <Typography variant="h5" fontSize="15px" height="3em" overflow="hidden">
                {user.phone} - {user.lastMessageText}
              </Typography>
            }
          />
          {((user.isLastMessageFromUser && user.lastMessageStatus !== 'read') || user.read === false) && (
            <CircleIcon color="secondary" fontSize="small" />
          )}
        </ListItemButton>
        <Divider />
      </div>
    );
  };

  return (
    <Paper>
      {advancedSearchModalOpen && (
        <AdvancedSearch
          searchQuery={allMessagesSearch}
          open={advancedSearchModalOpen}
          setOpen={setAdvancedSearchModalOpen}
          selectUser={handleSelectUser}
        />
      )}
      <SectionHeader text="Chat WhatsApp" button={headerButtonComponent} />
      <Grid container>
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '400',
            }}
          >
            <Paper elevation={3} sx={{ p: 4, width: '400' }}>
              <TemplateForm openModal={openModal} setOpenModal={setOpenModal} selectedPhone={selectedUser?.phone} />
            </Paper>
          </Box>
        </Modal>
        <Grid item xs={4} borderRight="1px solid #e0e0e0">
          <Grid item xs={12} style={{ padding: '10px' }}>
            <TextField
              id="outlined-basic-email"
              label="Buscar"
              variant="outlined"
              fullWidth
              onChange={(e) => search(e.target.value)}
            />
          </Grid>
          <Divider />
          {filteredUsers && filteredUsers.length > 0 && (
            <FixedSizeList width="100%" height={750} itemCount={filteredUsers?.length} itemSize={100}>
              {renderUser}
            </FixedSizeList>
          )}
        </Grid>
        <Grid
          item
          xs={8}
          sx={{
            backgroundImage: 'url("/whatsapp-background.png")',
            backgroundColor: '#efeae2',
            backgroundRepeat: 'repeat',
            backgroundSize: '412.5px 749.25px',
          }}
        >
          {selectedUser && (
            <>
              <ChatHeader
                selectedUser={selectedUser}
                handleGetConversation={handleGetConversation}
                setOpenModal={setOpenModal}
                handleUnreadConversation={handleUnreadConversation}
              />
              <Divider />
              {conversation.length > 0 && (
                <Box sx={{ height: 'calc(100vh - 300px)', overflowY: 'auto' }}>
                  <Conversation conversation={conversation} scheduledMessages={scheduledMessages ?? []} fullHeight />
                </Box>
              )}
              <Divider />
              <ChatFooter selectedUser={selectedUser} conversation={conversation} setConversation={setConversation} />
            </>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Chat;
