import { useSelector } from 'react-redux';

import { DebtFlowState } from 'redux/slices/debtFlow.slice';
import { RootState } from 'redux/store';

const useDebtFlow = (): DebtFlowState => {
  const debtFlow = useSelector<RootState, RootState['debtFlow']>((state) => state.debtFlow);
  return debtFlow;
};

export default useDebtFlow;
