import React, { FC } from 'react';

import { Box, Avatar, Typography } from '@mui/material';

interface AuthorLabelInterface {
  author: string;
  authorAvatar: string;
  date: string;
  timeRead: number;
}

const AuthorLabel: FC<AuthorLabelInterface> = ({ author, authorAvatar, date, timeRead }) => {
  const authorInitials = author ? author[0] : 'N/A';
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
      <Avatar sx={{ bgcolor: '#0d72ba' }} src={authorAvatar}>
        {authorInitials}
      </Avatar>
      <Box sx={{ ml: 2, textAlign: 'start' }}>
        <Typography variant="body1" color="initial">
          Escrito por {author}
        </Typography>
        <Typography variant="body1" color="#8a8a8a">
          {date} • {timeRead} minutos de lectura
        </Typography>
      </Box>
    </Box>
  );
};

export default AuthorLabel;
