import React, { FC, useEffect, useState } from 'react';

import { Box, TextField } from '@mui/material';

import { SearchIcon } from 'assets/icons';
import { BaseModel } from 'services/types/baseModel';

interface Props {
  data?: unknown[];
  setFilteredData?: (data: unknown[]) => void;
  searchTerm?: string;
  setSearchTerm?: (searchTerm: string) => void;
}

const SearchBar: FC<Props> = ({
  data,
  setFilteredData,
  searchTerm: propsSearchTerm,
  setSearchTerm: setPropsSearchTerm,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>(() => {
    if (propsSearchTerm) {
      return propsSearchTerm;
    }
    const params = new URLSearchParams(window.location.search);
    return params.get('searchTerm') || '';
  });

  useEffect(() => {
    if (setPropsSearchTerm) {
      setPropsSearchTerm(searchTerm);
    }
    if (!data || !setFilteredData) {
      return;
    }
    const results = data.filter((item) => {
      const itemAsObject = item as BaseModel;
      const itemValue = Object.values(itemAsObject).join(' ');
      return itemValue.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setFilteredData(results);
  }, [searchTerm]);
  return (
    <Box>
      <TextField
        type="text"
        placeholder="Buscar"
        onChange={(e) => setSearchTerm(e.target.value)}
        value={searchTerm}
        sx={{
          '& .MuiInputBase-input': {
            fontSize: '14px',
            color: 'text.secondary',
            fontWeight: 'bold',
          },
          '& .MuiInputBase-root': {
            border: '1px solid #E5E5E5',
            borderRadius: '10px',
            padding: '0 10px',
          },
          backgroundColor: 'primary.contrastText',
          mx: 2,
        }}
        InputProps={{
          endAdornment: <SearchIcon />,
        }}
      />
    </Box>
  );
};

SearchBar.defaultProps = {
  data: [],
  setFilteredData: () => null,
  searchTerm: '',
  setSearchTerm: () => null,
};

export default SearchBar;
