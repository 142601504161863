const chartColorPalette: string[] = [
  '#6DBFD3',
  '#6293C8',
  '#26859C',
  '#9EC5EC',
  '#3CABC6',
  '#9EC5EC',
  '#6BA5E2',
  '#1F4168',
  '#6BA5E2',
  '#1A424B',
  '#40BCDA',
];
export default chartColorPalette;
