import React, { FC, useState, Ref, useCallback } from 'react';

import { CircularProgress, MenuItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Button as CajaButton } from '@dscla/ds-button/react';
import { DeleteIcon } from 'assets/icons';
import { DeleteBtn } from 'components/UI/Buttons';

interface Props {
  isMenuIcon: boolean;
  objectId: string | number;
  isLoading: boolean;
  deleteFunction: (objectId: number) => Promise<void>;
  confirmationText: string;
  onSuccessAction: () => void;
  onErrorAction: () => void;
  compact?: boolean;
  disabled?: boolean;
  isCajaTheme?: boolean;
  behaviour?: 'fluido' | 'fijo';
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteButton: FC<Props> = (props: Props) => {
  const {
    isMenuIcon,
    objectId,
    isLoading,
    deleteFunction,
    confirmationText,
    onSuccessAction,
    onErrorAction,
    compact,
    disabled,
    isCajaTheme,
    behaviour,
  } = props;
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleDelete = useCallback(async () => {
    await deleteFunction(Number(objectId))
      .then(() => setOpen(false))
      .then(() => onSuccessAction())
      .catch(() => onErrorAction());
  }, [objectId]);

  const deleteButton = () => {
    if (isMenuIcon) {
      return (
        <MenuItem onClick={handleOpen} sx={{ color: 'error.main' }}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" sx={{ color: 'error.main' }} />
          </ListItemIcon>
          <ListItemText>Borrar</ListItemText>
        </MenuItem>
      );
    }
    if (!isCajaTheme) {
      return (
        <DeleteBtn onClick={handleOpen} disabled={disabled}>
          <DeleteIcon /> {compact ? null : 'Borrar'}
        </DeleteBtn>
      );
    }
    return (
      <CajaButton onClick={handleOpen} disabled={disabled} behaviour={behaviour} variant="texto">
        Eliminar
      </CajaButton>
    );
  };

  return (
    <>
      {deleteButton()}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted={false}
        onClose={handleCancel}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Confirmar acción</DialogTitle>
        <DialogContent sx={{ minWidth: 'min(450px, 90vw)' }}>
          <DialogContentText id="alert-dialog-slide-description">
            {isLoading ? (
              <div>
                <Typography>Procesando la solicitud....</Typography>
                <CircularProgress />
              </div>
            ) : (
              confirmationText
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancelar</Button>
          <Button
            onClick={() => {
              handleDelete().catch((e) => {
                // eslint-disable-next-line no-console
                console.error(e);
              });
            }}
            color="error"
          >
            Borrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

DeleteButton.defaultProps = {
  compact: false,
  disabled: false,
  isCajaTheme: false,
  behaviour: undefined,
};

export default DeleteButton;
