/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// @ts-ignore
import { Input } from '@dscla/ds-input/react';
import AlertDialog from 'components/UI/AlertDialog';
import PrimaryBtn from 'components/UI/Buttons';
import CloseableAlert from 'components/UI/CloseableAlert';
import LoadingOverlay from 'components/UI/LoadingOverlay';
import { BigCheckWithText, CheckWithText } from 'components/common/IconsWithText';
import { useChangePasswordUniqueFlowMutation, useGetUserInformationQuery } from 'services/api/user.api';
import { Origin } from 'services/types/origin';
import validatePassword, { getPasswordErrorMessage } from 'utils/validations/validatePassword';

import styles from '../UniqueDebtFlow.module.scss';
import ModalWithHeader from './ModalWithHeader';

interface Props {
  onSuccess: () => void;
  origin: Origin;
  email: string;
}

const CreatePassword: FC<Props> = ({ onSuccess, origin, email }) => {
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordConfirmError, setPasswordConfirmError] = useState(false);

  const [openErrorDialog, setOpenErrorDialog] = useState(false);

  const [changePassword, { isError, isLoading, isSuccess }] = useChangePasswordUniqueFlowMutation();

  const { data: currentUser } = useGetUserInformationQuery(null);

  const passwordVerify = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    if (!event.target.value) {
      setPasswordError(false);
    } else {
      setPasswordError(!validatePassword(event.target.value, currentUser?.rut || '', email));
    }
  };

  const passwordConfirmVerify = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordConfirm(event.target.value);
    if (!event.target.value) {
      setPasswordConfirmError(false);
    } else {
      setPasswordConfirmError(password !== event.target.value);
    }
  };

  const verifyForm = () => {
    const values = !!password && !!passwordConfirm;
    const errors = passwordError || passwordConfirmError;
    return values && !errors;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (verifyForm()) {
      if (password === passwordConfirm) {
        changePassword({ newPassword: password }).catch((err: Error) => console.log(err)); // eslint-disable-line no-console
        event.preventDefault();
      }
    }
  };

  const getPasswordInputVariant = () => {
    if (!password) {
      return '';
    }
    return passwordError ? 'error' : 'success';
  };

  const getConfirmPasswordInputVariant = () => {
    if (!passwordConfirm) {
      return '';
    }
    return passwordConfirmError ? 'error' : 'success';
  };

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess]);

  return (
    <ModalWithHeader origin={origin} title="¡Felicitaciones!">
      <LoadingOverlay visible={isLoading} />
      {isError && <CloseableAlert msg="Error actualizando la contraseña" severity="error" />}
      <AlertDialog
        openDialog={openErrorDialog}
        setOpenDialog={setOpenErrorDialog}
        header="Hubo un error, si el problema persiste contáctanos a través del chat"
        msg=""
        confirmMsg="Cerrar"
        confirmAction={() => setOpenErrorDialog(false)}
        cancelMsg=""
      />
      <Box
        component="form"
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => handleSubmit(e)}
        className={styles.ContentBox}
      >
        <Typography variant="body1" width="100%" textAlign="left" pt={1} pb={2} fontSize="20px">
          Has completado tus deudas, ya estamos buscando las mejores alternativas para mejorarlas.
          <br />
          <br />
          Crea una contraseña para acceder a las otras funcionalidades de Relif.
        </Typography>
        <Box>
          <Input
            id="password"
            name="password"
            autoComplete="new-password"
            label="Contraseña"
            type="password"
            variant={getPasswordInputVariant()}
            value={password}
            helperMessage={getPasswordInputVariant() === '' ? 'La contraseña debe tener 8 caracteres' : ''}
            errorMessage={passwordError && getPasswordErrorMessage(password, currentUser?.rut || '', email)}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => passwordVerify(e)}
          />

          <Input
            id="passwordConfirm"
            name="passwordConfirm"
            label="Confirmar contraseña"
            type="password"
            variant={getConfirmPasswordInputVariant()}
            value={passwordConfirm}
            errorMessage={passwordConfirmError && 'Las contraseñas no coinciden'}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => passwordConfirmVerify(e)}
          />

          <Box mt={2}>
            <BigCheckWithText text="¿Qué mas puedo hacer en Relif?" />
            <CheckWithText text="Crear tu presupuesto mensual" />
            <CheckWithText text="Educación financiera" />
            <CheckWithText text="Aprende cómo te evalúan los bancos" />
            <CheckWithText text="Ahorra" />
            <CheckWithText text="Y mucho más..." />
          </Box>
        </Box>
        <Box display="grid" justifyContent="center" alignItems="center" py={3}>
          <PrimaryBtn type="submit" disabled={!verifyForm()}>
            Ingresar
          </PrimaryBtn>
        </Box>
      </Box>
    </ModalWithHeader>
  );
};

export default CreatePassword;
