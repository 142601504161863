import React, { FC } from 'react';

import { Box, Grid, Tooltip, Typography } from '@mui/material';

import { HelpIcon } from 'assets/icons';
import priceFormat from 'services/format/priceFormat';

import styles from './Simulator.module.scss';
import StatusGaugeChart from './StatusGaugeChart';

interface CurrentStatusCardProps {
  currentRCR: number | null;
  chosenMonthlyPayment: number;
  maxPendingPayments: number;
  debtsMonthlyTotal: number;
  debtsTotal: number;
}

const CurrentStatusCard: FC<CurrentStatusCardProps> = ({
  currentRCR,
  chosenMonthlyPayment,
  maxPendingPayments,
  debtsMonthlyTotal,
  debtsTotal,
}) => {
  return (
    <Box
      sx={{
        backgroundColor: '#EDF1FA',
        borderRadius: '10px',
        width: '100%',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '20px',
          backgroundColor: 'primary.main',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          height: '50px',
        }}
      >
        <Typography variant="h5" textAlign="center" color="white" fontWeight={600}>
          Tu carga financiera actual
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {Number(currentRCR) > 0 && <StatusGaugeChart realValue={Math.round(Number(currentRCR))} />}
      </Box>
      <Grid container p={1}>
        <Grid item xs={6} p={1}>
          <Box className={styles.SimulationItemCard}>
            <Box className={styles.Status} />
            <Box className={styles.SimulationItemDetails}>
              <Typography variant="h6" color="primary.main">
                {maxPendingPayments}
              </Typography>
              <Tooltip
                title="Es el plazo que te demorarías en pagar toda la deuda. Para tarjetas o lineas de crédito, es una estimación en base al pago mínimo."
                arrow
              >
                <Typography variant="body1" color="primary.main">
                  Meses plazo <HelpIcon sx={{ fontSize: '14px' }} />
                </Typography>
              </Tooltip>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} p={1}>
          <Box className={styles.SimulationItemCard}>
            <Box className={styles.Status} />
            <Box className={styles.SimulationItemDetails}>
              <Typography variant="h6" color="primary.main">
                {priceFormat.format(debtsMonthlyTotal)}
              </Typography>
              <Tooltip
                title="Es lo que debes pagar en total todos los meses, incluyendo el pago mínimo de tarjetas de crédito."
                arrow
              >
                <Typography variant="body1" color="primary.main">
                  Cuota mensual <HelpIcon sx={{ fontSize: '14px' }} />
                </Typography>
              </Tooltip>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} p={1}>
          <Box className={styles.SimulationItemCard}>
            <Box className={styles.Status} />
            <Box className={styles.SimulationItemDetails}>
              <Typography variant="h6" color="primary.main">
                {priceFormat.format(debtsTotal)}
              </Typography>
              <Tooltip title="Es lo que le terminarás pagando a todas las instituciones a las que les debes" arrow>
                <Typography variant="body1" color="primary.main">
                  Total a pagar <HelpIcon sx={{ fontSize: '14px' }} />
                </Typography>
              </Tooltip>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} p={1}>
          <Box className={styles.SimulationItemCard}>
            <Box className={styles.Status} />
            <Box className={styles.SimulationItemDetails}>
              <Typography variant="h6" color="primary.main">
                {priceFormat.format(chosenMonthlyPayment)}
              </Typography>
              <Tooltip title="Es un aproximado del pago mínimo de tus tarjetas" arrow>
                <Typography variant="body1" color="primary.main">
                  Pago mensual tarjetas <HelpIcon sx={{ fontSize: '14px' }} />
                </Typography>
              </Tooltip>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CurrentStatusCard;
