import { combineReducers } from 'redux';
import baseApi from 'services/api/baseApi';

import accessToken from './accessToken.slice';
import debtFlow from './debtFlow.slice';

export default combineReducers({
  debtFlow,
  accessToken,
  [baseApi.reducerPath]: baseApi.reducer,
});
