import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import PauseIcon from '@mui/icons-material/Pause';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RefreshIcon from '@mui/icons-material/Refresh';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { useGetIsLastFlowActiveQuery, usePauseFlowMutation } from 'services/api/whatsAppFlows.api';
import { WhatsAppUser } from 'services/types/whatsApp';
import trpc from 'trpc';

import ScheduleTemplateMessageModal from './ScheduleTemplateMessageModal';

interface Props {
  selectedUser: WhatsAppUser;
  handleGetConversation: (userId: number) => void;
  setOpenModal: (open: boolean) => void;
  handleUnreadConversation: (userId: number) => void;
}

const ChatHeader: FC<Props> = ({ selectedUser, handleGetConversation, setOpenModal, handleUnreadConversation }) => {
  const userId = Number(selectedUser.id);
  const { data: isLastFlowActive, refetch: refetchIsLastFlowActive } = useGetIsLastFlowActiveQuery(userId);
  const [pauseLastFlow] = usePauseFlowMutation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openScheduleTemplateModal, setOpenScheduleTemplateModal] = React.useState(false);
  const [isScheduleTemplateMessage, setIsScheduleTemplateMessage] = React.useState(false);

  const openDropdownMenu = Boolean(anchorEl);
  const handleDropdownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDropdownClose = () => {
    setAnchorEl(null);
  };
  const changeAISuggestionsState = trpc.users.changeAISuggestionsState.useMutation();
  const getAISuggestionsState = trpc.users.getAISuggestionsState.useQuery({ userId });
  const handleAISuggestionsStateChange = () => {
    if (getAISuggestionsState.data !== undefined) {
      changeAISuggestionsState.mutate({ userId: Number(selectedUser.id), newState: !getAISuggestionsState.data });
    }
    handleDropdownClose();
  };

  const handlePauseFlow = () => {
    pauseLastFlow(userId)
      .then(() => refetchIsLastFlowActive()) // .catch((e) => console.error(e) eslint-disable-line no-console
      .catch((e) => console.error(e)); // eslint-disable-line no-console
  };
  const navigateToUser = () => {
    navigate(`/admin/users?userId=${userId}&prevPath=/admin/chat?userId=${userId}`);
  };

  const suggestionState = (): boolean => {
    if (typeof changeAISuggestionsState.data === 'boolean') {
      return changeAISuggestionsState.data;
    }
    return getAISuggestionsState.data || false;
  };

  return (
    <Grid item xs={12} style={{ padding: '10px', backgroundColor: 'white' }}>
      <ScheduleTemplateMessageModal
        userId={userId}
        open={openScheduleTemplateModal}
        setOpen={setOpenScheduleTemplateModal}
        refetch={() => handleUnreadConversation(Number(selectedUser.id))}
        schedule={isScheduleTemplateMessage}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button variant="contained" onClick={() => setOpenModal(true)}>
          Envío Múltiple
        </Button>
        <Typography onClick={navigateToUser} sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
          {selectedUser.firstName} {selectedUser.lastName} - {selectedUser.companyName} - {selectedUser.phone} - id{' '}
          {selectedUser.id}
        </Typography>
        <Box>
          {isLastFlowActive && (
            <Tooltip title="Pausar flujo" arrow>
              <IconButton onClick={handlePauseFlow} size="large">
                <PauseIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Marcar como no leído" arrow>
            <IconButton onClick={() => handleUnreadConversation(Number(selectedUser.id))} size="large">
              <RadioButtonUncheckedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Recargar conversación" arrow>
            <IconButton onClick={() => handleGetConversation(Number(selectedUser.id))} size="large">
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          <IconButton onClick={handleDropdownClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            open={openDropdownMenu}
            onClose={handleDropdownClose}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={handleAISuggestionsStateChange}>
              {suggestionState() ? 'Desactivar' : 'Activar'} Jian Yang
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenScheduleTemplateModal(true);
                setIsScheduleTemplateMessage(false);
              }}
            >
              Enviar Template
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenScheduleTemplateModal(true);
                setIsScheduleTemplateMessage(true);
              }}
            >
              Programar Template
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </Grid>
  );
};

export default ChatHeader;
