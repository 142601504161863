import React, { FC, useState } from 'react';

import { Box, Checkbox, Container, FormControlLabel, Grid, TextField, Typography } from '@mui/material';

import LogoCaja from 'assets/images/logos/caja_la_araucana_wide.svg';
import { useCreateProspectMutation } from 'services/api/prospects.api';

const AraucanaProspects: FC = () => {
  const [createProspect, { isLoading }] = useCreateProspectMutation();
  const [message, setMessage] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [attendWebinar, setAttendWebinar] = useState<boolean>(true);

  const send = () => {
    if (!email || !name || !companyName || !phone) {
      setMessage('Debes completar todos los campos. Intenta nuevamente');
      return;
    }
    setMessage('Enviando ... (puede tomar unos segundos)');

    createProspect({
      email,
      phone,
      reason: `Araucana prospect. Name: ${name}. Company name: ${companyName}. Webinar: ${attendWebinar.toString()}`,
    })
      .then((response) => {
        // eslint-disable-next-line no-prototype-builtins
        if (response.hasOwnProperty('error')) {
          throw new Error('Api error');
        }
        setEmail('');
        setName('');
        setCompanyName('');
        setPhone('');
        setMessage('¡Gracias! Estaremos en contacto');
      })
      .catch((error) => {
        setMessage('Error al validar tus datos. Intente nuevamente');
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  const title = '¡Gracias por tu interés en Relif! ';
  const description =
    'Juntos ayudemos a tus colaboradores a mejorar su salud financiera. Completa los siguientes datos y te escribiremos para contarte sobre Relif y de qué se trata nuestra alianza con Caja La Araucana';
  return (
    <Box sx={{ margin: 0, padding: 0 }}>
      <div
        style={{
          backgroundImage: "url('./relif_background.webp')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: '100vh',
          width: '100vw',
          filter: 'blur(10px)',
          margin: 0,
          padding: 0,
        }}
      />
      <Container
        sx={{
          backgroundImage: 'url("/contact-background.svg")',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          borderRadius: '30px',
          padding: { lg: '20px 50px 50px', xs: '20px 10px' },
          position: 'absolute',
          mt: 3,
          top: '10%',
          left: '50%',
          transform: 'translate(-50%, -10%)',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            width: '100%',
          }}
        >
          <Box flexDirection="row" alignItems="flex-end" display="flex" mt={1} mb={3}>
            <div style={{ backgroundColor: 'white', paddingLeft: '10px', paddingRight: '5px', paddingTop: '5px' }}>
              <img alt="Caja La Araucana" src={LogoCaja} height="75px" />
            </div>
            <img alt="Relif" src="/relif_logo_white.webp" height="90px" />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              mr: 2,
              ml: 2,
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: { lg: '35px', xs: '25px' },
                width: '100%',
                mb: '15px',
                textTransform: 'uppercase',
                fontFamily: 'Roboto',
                fontWeight: 800,
                color: 'white',
                textAlign: 'center',
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontSize: { lg: 20, xs: 16 },
                mb: 3,
                fontWeight: 500,
                color: 'white',
                marginBottom: 3,
                textAlign: 'center',
                lineHeight: '28px',
              }}
            >
              {description}
            </Typography>
          </Box>
          <Box className="contact-form-container" flexDirection="column" width={{ xs: '100%', lg: '70%' }}>
            <form className="contact-form" style={{ flexDirection: 'column' }}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="email"
                    value={email}
                    required
                    autoFocus
                    autoComplete="email"
                    type="email"
                    fullWidth
                    label="Email"
                    variant="filled"
                    sx={{ bgcolor: '#fff', borderRadius: '10px' }}
                    InputLabelProps={{
                      style: {
                        color: 'grey',
                      },
                    }}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="phone"
                    value={phone}
                    required
                    fullWidth
                    label="Teléfono"
                    variant="filled"
                    sx={{ bgcolor: '#fff', borderRadius: '10px' }}
                    InputLabelProps={{
                      style: {
                        color: 'grey',
                      },
                    }}
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="name"
                    value={name}
                    required
                    fullWidth
                    label="Nombre"
                    variant="filled"
                    sx={{ bgcolor: '#fff', borderRadius: '10px' }}
                    InputLabelProps={{
                      style: {
                        color: 'grey',
                      },
                    }}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="companyName"
                    value={companyName}
                    required
                    fullWidth
                    label="Nombre de la empresa"
                    variant="filled"
                    sx={{ bgcolor: '#fff', borderRadius: '10px' }}
                    InputLabelProps={{
                      style: {
                        color: 'grey',
                      },
                    }}
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
              <Typography color={message.includes('nuevamente') ? 'orange' : 'white'} pt={1}>
                {message}
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={attendWebinar}
                    onChange={(e) => {
                      setAttendWebinar(e.target.checked);
                    }}
                  />
                }
                label="Me interesa participar en un webinar informativo"
                color="white"
                componentsProps={{ typography: { color: 'white' } }}
                sx={{ color: 'white', label: { color: 'white' }, labelProps: { color: 'white' } }}
              />
              <button
                type="button"
                className="sign-mandate-button"
                onClick={(e) => {
                  e.preventDefault();
                  send();
                }}
                disabled={!email || !name || !companyName || !phone || isLoading}
              >
                Enviar
              </button>
            </form>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default AraucanaProspects;
