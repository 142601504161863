import React, { useState } from 'react';

import { Box } from '@mui/material';

import PrimaryBtn from 'components/UI/Buttons';
import SectionHeader from 'components/layout/SectionHeader';
import { RoiCalculator } from 'services/types/roiCalculators';

import CalculatorsTable from './CalculatorsTable';
import EditCalculator from './EditCalculator';

const RoiCalculators = () => {
  const [selectedCalculator, setSelectedCalculator] = useState<RoiCalculator | null>(null);
  const [refetchFlag, setRefetchFlag] = useState(false);
  const [openEditCalculator, setOpenEditCalculator] = useState(false);

  const handleEditCalculator = (calculator: RoiCalculator) => {
    setSelectedCalculator(calculator);
    setOpenEditCalculator(true);
  };

  const handleNewCalculator = () => {
    setSelectedCalculator(null);
    setOpenEditCalculator(true);
  };

  const handleBack = () => {
    setOpenEditCalculator(false);
    setRefetchFlag(!refetchFlag);
  };

  return (
    <Box>
      <SectionHeader
        text="Calculadoras"
        button={<PrimaryBtn onClick={handleNewCalculator}>Nueva Calculadora</PrimaryBtn>}
      />
      <Box p={1}>
        {openEditCalculator ? (
          <EditCalculator calculator={selectedCalculator} handleBack={handleBack} />
        ) : (
          <CalculatorsTable handleEditCalculator={handleEditCalculator} refetchFlag={refetchFlag} />
        )}
      </Box>
    </Box>
  );
};

export default RoiCalculators;
