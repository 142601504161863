import * as React from 'react';

import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import style from './RoiCalculator.module.scss';

export type Props = {
  title: string;
  description: string;
  subDescription?: string;
  icon: React.FunctionComponent;
  color: 'success' | 'primary' | 'error' | 'warning' | 'info';
};

const SectionCard: React.FC<Props> = ({ icon, title, description, subDescription, color }) => {
  const bgColor = color.concat('.light');
  const textColor = color.concat('.main');

  const Icon = icon;
  return (
    <Card
      sx={{
        width: { xs: '100%', lg: '32%' },
        minHeight: { xs: '55px', lg: '120px' },
        px: { lg: '10px' },
        mb: 1,
        bgcolor: bgColor,
      }}
      className={style.subSectionCard}
    >
      <CardHeader
        avatar={
          <Avatar className={style.iconAvatar} sx={{ bgcolor: textColor }}>
            <Icon />
          </Avatar>
        }
        title={title}
        titleTypographyProps={{ fontSize: '15px', fontWeight: 600, color: 'primary' }}
        sx={{ p: 1, pt: 2 }}
      />
      <CardContent sx={{ py: 0, textAlign: 'end', color: textColor, fontWeight: 'bold' }}>
        {description}
        <br />
        {subDescription}
      </CardContent>
    </Card>
  );
};

SectionCard.defaultProps = {
  subDescription: '',
};

export default SectionCard;
