import React, { FC, useEffect } from 'react';

import Box from '@mui/material/Box';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Stepper, Step } from '@dscla/ds-stepper/react';
import ClaveUnicaModal from 'components/common/ClaveUnicaModal';
import useDebtFlow from 'hooks/useDebtFlow';
import CategorizeCMFData from 'pages/Debts/components/DebtFlow/CategorizeCMFData';
import UserDebtProfiling from 'pages/Debts/components/DebtFlow/UserDebtProfiling';
import { useDeleteOldCMFDebtsMutation, useListDebtsQuery, useRefinanceDebtsMutation } from 'services/api/debt.api';
import { useGetParsedScraperDebtsQuery } from 'services/api/scraperRequests.api';
import { useGetUserDebtProfileQuery, useGetUserInformationQuery } from 'services/api/user.api';
import { Origin } from 'services/types/origin';
import { captureUserEvent } from 'utils/posthogUtils';

import styles from '../UniqueDebtFlow.module.scss';
import HeaderLogos from './HeaderLogos';

interface Props {
  onSuccess: () => void;
  origin: Origin;
}

const ClaveUnicaDebtForm: FC<Props> = ({ onSuccess, origin }) => {
  const { refetch: refetchDebtData } = useListDebtsQuery(null);
  const { refetch: refetchDebtProfileData } = useGetUserDebtProfileQuery(null);
  const debtFlow = useDebtFlow();
  const [deleteOldCMFDebts] = useDeleteOldCMFDebtsMutation();
  const [activeStep, setActiveStep] = React.useState(0);
  const [refinanceDebts] = useRefinanceDebtsMutation();
  const { refetch: refetchParsedDebtsData } = useGetParsedScraperDebtsQuery(null);

  const { data: currentUser } = useGetUserInformationQuery(null);

  const handleSaveClaveUnica = () => {
    setActiveStep(1);
    refetchParsedDebtsData().catch((e) => console.error(e)); // eslint-disable-line no-console
    refetchDebtProfileData().catch((e) => console.error(e)); // eslint-disable-line no-console
  };

  const handleSaveUserDebtProfiling = () => {
    captureUserEvent('Unique Debt flow: Finalized');
    setActiveStep(2);
  };

  const saveCategorizeDebtData = async () => {
    await deleteOldCMFDebts(null);
    const data = await refetchDebtData();
    if (data.data) {
      await refinanceDebts({
        debtIds: data.data.filter((debt) => !debt.refinance).map((debt) => debt.id) || [],
      });
    }
    setActiveStep(3);
    captureUserEvent('Unique Debt flow: Categorized debts success');
  };

  const handleSaveCategorizeCMFData = () => {
    saveCategorizeDebtData()
      .then(() => {
        onSuccess();
      })
      .catch((e) => console.error(e)); // eslint-disable-line no-console
    captureUserEvent('Unique Debt flow: Confirmed debts info success');
  };

  useEffect(() => {
    // skip clave unica step if user has already done it
    if (debtFlow?.CMFResponse && activeStep === 0) {
      setActiveStep(1);
    }
  }, [debtFlow?.CMFResponse]);

  const steps = ['Clave Única', 'Perfilamiento', 'Categorizar deudas al dia'];
  return (
    <Box className={styles.ModalContainer}>
      <Box className={styles.Modal}>
        <Box>
          <HeaderLogos origin={origin} />
          <Box py={2}>
            <Stepper size="small">
              {steps.map((label, index) => (
                <Step slot="step" size="small" key={label} text={(index + 1).toString()} success={activeStep > index} />
              ))}
            </Stepper>
          </Box>
          <Box className={styles.ContentBox}>
            <Box display="flex" justifyContent="center" alignContent="center">
              {activeStep === 0 && currentUser && currentUser.rut && (
                <ClaveUnicaModal rut={currentUser.rut} onSave={handleSaveClaveUnica} isCMFUpdate={false} />
              )}
              {activeStep === 1 && <UserDebtProfiling onSave={handleSaveUserDebtProfiling} />}
              {activeStep === 2 && <CategorizeCMFData onSave={handleSaveCategorizeCMFData} />}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ClaveUnicaDebtForm;
