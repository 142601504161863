import React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Button as CajaButton } from '@dscla/ds-button/react';
import { CloseIcon } from 'assets/icons';
import useIsCajaLosAndesTheme from 'hooks/useIsCajaLosAndesTheme';

const PrimaryBtn = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.info.main,
  backgroundColor: theme.palette.info.light,
  boxShadow: 'none',
  transition: 'all 0.15s ease-in-out',
  borderRadius: '20px',
  height: '43px',
  minWidth: '125px',
  fontSize: '15px',
  lineHeight: '22px',
  fontWeight: 600,
  textTransform: 'uppercase',
  paddingRight: '12px',
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: theme.palette.info.main,
    color: '#fff',
  },
  [theme.breakpoints.up('xl')]: {
    minWidth: '201px',
  },
}));

const SecondaryBtn = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  backgroundColor: theme.palette.secondary.main,
  boxShadow: 'none',
  transition: 'all 0.15s ease-in-out',
  borderRadius: '20px',
  height: '43px',
  minWidth: '125px',
  fontSize: '15px',
  lineHeight: '22px',
  fontWeight: 600,
  textTransform: 'uppercase',
  // TODO: check if this is necessary
  '&:disabled': {
    backgroundColor: '#F3F3F5',
    color: '#CDCDCD',
  },
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: theme.palette.secondary.contrastText,
    color: '#fff',
  },
  [theme.breakpoints.up('xl')]: {
    minWidth: '201px',
  },
}));

interface BtnProp extends ButtonProps {
  icon: React.ReactNode;
  text: string;
  behaviour?: 'fijo' | 'adaptable';
}

const SectionHeaderBtn: React.FC<BtnProp> = ({ icon, text, behaviour, ...props }) => {
  const isCajaTheme = useIsCajaLosAndesTheme();
  if (!isCajaTheme) {
    return (
      <PrimaryBtn {...props}>
        {icon}
        <Typography variant="inherit" sx={{ display: { xs: 'none', lg: 'block' }, ml: 0.8 }}>
          {text}
        </Typography>
      </PrimaryBtn>
    );
  }
  return (
    <CajaButton behaviour={behaviour} disabled={props.disabled} onClick={props.onClick}>
      <span slot="start">{icon}</span>
      {text}
    </CajaButton>
  );
};

SectionHeaderBtn.defaultProps = {
  behaviour: 'adaptable',
};

const EditBtn = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  color: theme.palette.warning.main,
  backgroundColor: theme.palette.warning.light,
  '&:hover': {
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
  },
  // TODO: check if this is necessary
  '&:disabled': {
    backgroundColor: '#F3F3F5',
    color: '#CDCDCD',
  },
}));

const EditBtnLarge = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.warning.main,
  backgroundColor: theme.palette.warning.light,
  borderRadius: '50px',
  paddingLeft: '16px',
  paddingRight: '16px',
  '&:hover': {
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
  },
  '&:disabled': {
    backgroundColor: '#F3F3F5',
    color: '#CDCDCD',
  },
  [theme.breakpoints.up('lg')]: {
    borderRadius: '20px',
  },
}));

const DeleteBtn = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  color: theme.palette.error.main,
  backgroundColor: theme.palette.error.light,
  '&:hover': {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
  },
  '&:disabled': {
    backgroundColor: '#F3F3F5',
    color: '#CDCDCD',
  },
}));

const DeleteBtnLarge = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.error.main,
  backgroundColor: theme.palette.error.light,
  boxShadow: 'none',
  transition: 'all 0.15s ease-in-out',
  borderRadius: '20px',
  height: '43px',
  minWidth: '125px',
  fontSize: '15px',
  lineHeight: '22px',
  fontWeight: 600,
  textTransform: 'uppercase',
  '&:disabled': {
    backgroundColor: '#F3F3F5',
    color: '#CDCDCD',
  },
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: theme.palette.error.main,
    color: '#fff',
  },
  [theme.breakpoints.up('xl')]: {
    minWidth: '201px',
  },
}));

const CancelBtn = styled(Button)<ButtonProps>(({ theme }) => ({
  borderRadius: '20px',
  padding: '0px 27px',
  lineHeight: '22px',
  backgroundColor: 'transparent',
  border: `1px solid ${theme.palette.error.main}`,
  color: theme.palette.error.main,
  height: '43px',
  textTransform: 'uppercase',
  fontWeight: 600,
  transition: 'all 0.15s ease-in-out',
  fontSize: '15px',
  '&:hover': {
    backgroundColor: theme.palette.error.light,
  },
}));

const BlackBtn = styled(Button)<ButtonProps>(({ theme }) => ({
  borderRadius: '20px',
  padding: '0px 27px',
  lineHeight: '22px',
  backgroundColor: 'transparent',
  border: `1px solid ${theme.palette.primary.main}`,
  color: theme.palette.primary.main,
  height: '43px',
  textTransform: 'uppercase',
  fontWeight: 600,
  transition: 'all 0.15s ease-in-out',
  fontSize: '15px',
  '&:hover': {
    backgroundColor: '#EDF1FA',
  },
}));

interface CajaStatusButtonProps {
  text: string;
  onClick: () => void;
  value: number;
  behaviour: 'fijo' | 'fluido' | 'adaptable';
}

const CajaStatusButton: React.FC<CajaStatusButtonProps> = ({ text, onClick, value, behaviour }) => {
  return (
    <CajaButton
      variant="secundario"
      color={value === 100 ? 'amarillo' : 'celeste'}
      onClick={onClick}
      behaviour={behaviour}
    >
      {value < 100 ? (
        <span slot="start">
          <CircularProgress thickness={7} sx={{ color: '#3E4545' }} size={18} value={value} variant="determinate" />
        </span>
      ) : (
        <span slot="start">
          <Box
            sx={{
              display: 'flex',
              borderRadius: '50%',
              border: '2px solid',
              borderColor: 'black',
            }}
          >
            <CheckIcon fontSize="small" />
          </Box>
        </span>
      )}
      {text}
    </CajaButton>
  );
};

const PrimaryBtnWithIcon: React.FC<BtnProp> = ({ icon, text, behaviour, ...props }) => {
  const isCajaTheme = useIsCajaLosAndesTheme();
  if (!isCajaTheme) {
    return (
      <PrimaryBtn {...props}>
        {icon}
        <Typography variant="inherit" sx={{ display: { xs: 'none', lg: 'block' }, ml: 0.8 }}>
          {text}
        </Typography>
      </PrimaryBtn>
    );
  }
  return (
    <CajaButton behaviour={behaviour} disabled={props.disabled} onClick={props.onClick}>
      <span slot="start">{icon}</span>
      {text}
    </CajaButton>
  );
};

PrimaryBtnWithIcon.defaultProps = {
  behaviour: 'adaptable',
};

const CloseButton: React.FC<IconButtonProps> = ({ ...props }) => {
  const isCajaTheme = useIsCajaLosAndesTheme();
  if (!isCajaTheme) {
    return (
      <IconButton {...props}>
        <CloseIcon />
      </IconButton>
    );
  }
  return (
    <CajaButton class="closeBtn" variant="texto" onclick={props.onClick} color="celeste" behaviour="adaptable">
      <span>Cerrar</span>
      <span slot="end" className="closeIcon">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
            fill="#008EC4"
          />
        </svg>
      </span>
    </CajaButton>
  );
};

export default PrimaryBtn;
export {
  PrimaryBtn,
  SecondaryBtn,
  EditBtn,
  DeleteBtn,
  CancelBtn,
  BlackBtn,
  EditBtnLarge,
  DeleteBtnLarge,
  SectionHeaderBtn,
  PrimaryBtnWithIcon,
  CloseButton,
  CajaStatusButton,
};
