import React, { FC } from 'react';

import Unsubscribe from '@mui/icons-material/Unsubscribe';
import { Box, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';

import { EmailIcon } from 'assets/icons';

import style from '../SignUp.module.scss';

interface Props {
  setRutRegisterOpen: (state: boolean) => void;
  setMailRegisterOpen: (state: boolean) => void;
  setChooseRegisterOpen: (state: boolean) => void;
}

const ChooseRegister: FC<Props> = (props: Props) => {
  const { setRutRegisterOpen, setMailRegisterOpen, setChooseRegisterOpen } = props;
  const handleMailRegister = () => {
    setMailRegisterOpen(true);
    setChooseRegisterOpen(false);
  };
  const handleRutRegister = () => {
    setRutRegisterOpen(true);
    setChooseRegisterOpen(false);
  };
  return (
    <Box display="flex" justifyContent="space-between" flexDirection={{ xs: 'column', lg: 'row' }}>
      <Card
        sx={{
          width: { xs: '100%', lg: '45%' },
          height: { xs: '55px', lg: '183px' },
          px: '10px',
          py: { xs: '50px', lg: '10px' },
          bgcolor: 'secondary.light',
          mb: { xs: '20px', lg: '0px' },
          boxShadow: '1px 1px 2px 0 rgba(0,0,0,.25)',
        }}
        className={style.sectionCard1}
        onClick={() => handleMailRegister()}
      >
        <Box
          sx={{
            p: 1,
            height: '80%',
            display: { xs: 'flex', lg: 'flex' },
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Avatar className={style.iconAvatar} sx={{ color: 'secondary.light', bgcolor: 'secondary.main' }}>
            <EmailIcon />
          </Avatar>
          <Typography
            fontSize={{ lg: '24px', xl: '30px' }}
            pl="20px"
            fontWeight={700}
            lineHeight="35px"
            width="80%"
            color="inherit"
          >
            Tengo correo de empresa
          </Typography>
        </Box>
      </Card>
      <Card
        sx={{
          width: { xs: '100%', lg: '45%' },
          height: { xs: '55px', lg: '183px' },
          px: '10px',
          py: { xs: '50px', lg: '10px' },
          bgcolor: 'secondary.light',
          boxShadow: '1px 1px 2px 0 rgba(0,0,0,.25)',
        }}
        className={style.sectionCard1}
        onClick={() => handleRutRegister()}
      >
        <Box
          sx={{
            p: 1,
            height: '80%',
            display: { xs: 'flex', lg: 'flex' },
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Avatar className={style.iconAvatar} sx={{ color: 'secondary.light', bgcolor: 'secondary.main' }}>
            <Unsubscribe />
          </Avatar>
          <Typography
            fontSize={{ lg: '24px', xl: '30px' }}
            pl="20px"
            fontWeight={700}
            lineHeight="35px"
            width="80%"
            color="inherit"
          >
            No tengo correo de empresa
          </Typography>
        </Box>
      </Card>
    </Box>
  );
};

export default ChooseRegister;
