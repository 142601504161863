import React, { FC, useState } from 'react';

import { Box, Container, Typography, CircularProgress, Dialog } from '@mui/material';

import { BankOffer } from '@relif/backend';
import ErrorImg from 'assets/images/404.webp';
import { SecondaryBtn } from 'components/UI/Buttons';
import ChatWrapper from 'components/common/ChatWrapper';
import useQuery from 'hooks/useQuery';
import trpc from 'trpc';
import getBankOfferInstructions from 'utils/getBankOfferInstructions';

import NormalizeOffer from './NormalizeOffer';
import OfferContainer from './OfferContainer';
import RefinanceOffer from './RefinanceOffer';

const BankOffers: FC = () => {
  const query = useQuery();
  const bankOfferObject = query.get('bankOffer');
  const parsedBankOffer = bankOfferObject ? (JSON.parse(bankOfferObject) as BankOffer) : null;
  const uuid = query.get('uuid') || '';
  const bankOffer = parsedBankOffer
    ? {
        data: parsedBankOffer,
        isLoading: false,
        isSuccess: true,
      }
    : trpc.bankOffers.list.useQuery({ uuid });
  const setUserReaction = trpc.bankOffers.userReaction.useMutation();
  const [selectedTerm, setSelectedTerm] = useState<number | null>(null);
  const [cashInterest, setCashInterest] = useState(false);
  const [openSelectedOptionDialog, setOpenSelectedOptionDialog] = useState(false);

  const instructions = bankOffer.data ? getBankOfferInstructions(bankOffer.data) : '';

  if (bankOffer.isLoading) {
    return <CircularProgress />;
  }

  if (!bankOffer.data) {
    return (
      <Container sx={{ mt: 4 }}>
        <Typography variant="h2" mb={2}>
          Oops... La oferta que buscas no existe. Revisa la url o quizás la oferta expiró
        </Typography>
        <ChatWrapper>
          <SecondaryBtn>Contáctanos</SecondaryBtn>
        </ChatWrapper>
        <img src={ErrorImg} alt="Error" style={{ width: 'min(600px, 98%)' }} />
      </Container>
    );
  }

  const setInterested = (id: number, term: number): void => {
    if (!bankOffer.data) {
      return;
    }
    setSelectedTerm(term);
    setCashInterest(false);
    setOpenSelectedOptionDialog(true);
    setUserReaction.mutate({
      uuid,
      id,
      userReaction: `interested in ${term} months`,
    });
  };

  const setInterestedCash = (): void => {
    if (!bankOffer.data) {
      return;
    }
    setCashInterest(true);
    setSelectedTerm(null);
    setOpenSelectedOptionDialog(true);
    setUserReaction.mutate({
      uuid,
      id: bankOffer.data.id,
      userReaction: 'interested in cash payment',
    });
  };

  const acceptedTitle = `¡Felicidades! Según nuestros registros, aceptaste la oferta para ${
    bankOffer.data.cashPaymentAmount ? 'saldar' : 'renegociar '
  } tu deuda con ${bankOffer.data.bank}${bankOffer.data.description ? '*' : ''}`;

  if (bankOffer.data.isAccepted) {
    return (
      <OfferContainer>
        <Typography
          variant="h2"
          sx={{
            fontSize: { lg: '25px', xs: '25px' },
            width: '100%',
            mb: '15px',
            fontFamily: 'Roboto',
            fontWeight: 800,
            color: 'white',
            textAlign: 'center',
          }}
        >
          {acceptedTitle}
        </Typography>
        <Typography color="white" sx={{ fontWeight: 'bold', mt: 1, textAlign: 'center' }}>
          ¿Tienes dudas? ¿Crees que fue un error? <br />
          <a
            href="/book-meeting"
            style={{ color: 'white', textDecoration: 'underline' }}
            target="_blank"
            rel="noreferrer"
          >
            Agenda aquí una asesoría con el equipo de Relif
          </a>
        </Typography>
      </OfferContainer>
    );
  }

  if (bankOffer.data.isBankClient && !bankOffer.data.cashPaymentAmount && !bankOffer.data.paymentPlanAmount) {
    return (
      <OfferContainer>
        <Typography
          variant="h2"
          sx={{
            fontSize: { lg: '25px', xs: '25px' },
            width: '100%',
            mb: '15px',
            fontFamily: 'Roboto',
            fontWeight: 800,
            color: 'white',
            textAlign: 'center',
          }}
        >
          Aún no tenemos los detalles de tu oferta 😞. Pero, un ejecutivo de {bankOffer.data.bank} ya se está
          contactando contigo para indicarte los detalles de la oferta
        </Typography>
        <Typography color="white" sx={{ fontWeight: 'bold', mt: 1, textAlign: 'center' }}>
          Si se demoran en contactarte, por favor indícalo en el chat para que podamos ayudarte
        </Typography>
        <Typography color="white" sx={{ fontWeight: 'bold', mt: 1, textAlign: 'center' }}>
          ¿Tienes dudas? ¿Crees que fue un error? <br />
          <a
            href="/book-meeting"
            style={{ color: 'white', textDecoration: 'underline' }}
            target="_blank"
            rel="noreferrer"
          >
            Agenda aquí una asesoría con el equipo de Relif
          </a>
        </Typography>
      </OfferContainer>
    );
  }

  return (
    <Box>
      <Dialog
        open={openSelectedOptionDialog}
        onClose={() => setOpenSelectedOptionDialog(false)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 2,
        }}
      >
        <Typography variant="body1" m={2}>
          {instructions}
        </Typography>
      </Dialog>
      {['normalize', null].includes(bankOffer.data.refinanceType) && (
        <NormalizeOffer
          bankOffer={bankOffer.data}
          cashInterest={cashInterest}
          setInterested={setInterested}
          setInterestedCash={setInterestedCash}
          selectedTerm={selectedTerm}
        />
      )}
      {bankOffer.data.refinanceType === 'refinance' && (
        <RefinanceOffer bankOffer={bankOffer.data} setInterested={setInterested} selectedTerm={selectedTerm} />
      )}
    </Box>
  );
};

export default BankOffers;
