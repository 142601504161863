import React, { FC } from 'react';

import AccountBalance from '@mui/icons-material/AccountBalance';
import CreditCard from '@mui/icons-material/CreditCard';
import DirectionsCar from '@mui/icons-material/DirectionsCar';
import Handshake from '@mui/icons-material/Handshake';
import Home from '@mui/icons-material/Home';
import School from '@mui/icons-material/School';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Button } from '@dscla/ds-button/react';
import { institutionTypeList } from 'assets/data/institutionTypeList';
import { DebtType as DebtTypeInterface } from 'services/types/debt';

interface Props {
  institutionType: string;
  setDebtType: (debtType: DebtTypeInterface | null) => void;
  setActiveStep: (step: number) => void;
}

const NewDebtStepThree: FC<Props> = ({ institutionType, setDebtType, setActiveStep }) => {
  const debtTypes =
    institutionTypeList.find((institutionTypeItem) => institutionTypeItem.name === institutionType)?.debtTypes || [];

  const getDebtTypeIcon = (debtType: DebtTypeInterface) => {
    const style = { width: '70%', height: '70%' };
    switch (debtType.name) {
      case 'crédito de consumo':
        return <ShoppingCart sx={style} />;
      case 'crédito automotriz':
        return <DirectionsCar sx={style} />;
      case 'tarjeta de crédito':
        return <CreditCard sx={style} />;
      case 'linea de crédito':
        return <AccountBalance sx={style} />;
      case 'crédito hipotecario':
        return <Home sx={style} />;
      case 'crédito de educación':
        return <School sx={style} />;
      case 'crédito repactado':
        return <Handshake sx={style} />;
      default:
        return <CreditCard sx={style} />;
    }
  };

  return (
    <Grid container>
      {debtTypes.map((debtType) => (
        <Grid item xs={12} key={debtType.name} sx={{ minHeight: '77px' }} p={1} width="100%">
          <Button
            variant="secundario"
            behaviour="fijo"
            onClick={() => {
              setDebtType(debtType);
              setActiveStep(3);
            }}
          >
            <Box display="flex" alignItems="center">
              <Avatar sx={{ backgroundColor: 'backgroundGrey.main', color: '#3E4545' }}>
                {getDebtTypeIcon(debtType)}
              </Avatar>
              <Typography variant="body2" color="#3E4545" fontSize={{ xs: '14px', lg: '18px' }} mx={2}>
                {debtType.name.charAt(0).toUpperCase() + debtType.name.slice(1)}
              </Typography>
            </Box>
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};

export default NewDebtStepThree;
