import { useSelector } from 'react-redux';

import { AccessToken } from 'redux/slices/accessToken.slice';
import { RootState } from 'redux/store';

const useAccessToken = (): AccessToken | null => {
  const token = useSelector<RootState, RootState['accessToken']>((state) => state.accessToken);
  return token;
};

export default useAccessToken;
