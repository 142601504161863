/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// @ts-ignore
import { Button } from '@dscla/ds-button/react';
// @ts-ignore
import { DataTable } from '@dscla/ds-data-table/react';
// @ts-ignore
import { MessageAlerta } from '@dscla/ds-message-alerta/react';
// @ts-ignore
import { Select, SelectOption } from '@dscla/ds-select/react';
import type { DebtsReport as DebtsReportResponse } from '@relif/backend';
import logo from 'assets/images/logo.webp';
import { SecondaryBtn } from 'components/UI/Buttons';
import PaperContainer from 'components/UI/PaperContainer';
import DefinitionWrapper from 'components/common/DefinitionWrapper';
import useQuery from 'hooks/useQuery';
import { useAdminGetDebtsReportMutation } from 'services/api/admin-users.api';
import { useGetDebtsReportMutation } from 'services/api/user.api';
import priceFormat from 'services/format/priceFormat';

interface ReportHeaderProps {
  reportDate: Date;
  debtsReportData: DebtsReportResponse;
}

const ReportHeader: FC<ReportHeaderProps> = ({ reportDate, debtsReportData }) => {
  return (
    <Box sx={{ width: '100%', borderTopRightRadius: 10, borderTopLeftRadius: 10 }} bgcolor="info.light" p={2}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <img src={logo} alt="logo" style={{ width: '150px' }} />
          <Typography variant="h4" sx={{ textAlign: 'left' }}>
            {' '}
            Fecha:{' '}
            {reportDate.toLocaleString('es-CL', {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            })}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} display="flex" flexDirection="column" justifyContent="flex-end">
          <Typography variant="h4" sx={{ textAlign: 'right' }}>
            Empresa: {debtsReportData.user.company}
          </Typography>
          <Typography variant="h4" sx={{ textAlign: 'right' }}>
            {' '}
            {debtsReportData.user.firstName} {debtsReportData.user.lastName}
          </Typography>
          <Typography variant="h4" sx={{ textAlign: 'right' }}>
            {debtsReportData.user.rut}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const DebtsReport = () => {
  const [getDebtsReport, { data: userData }] = useGetDebtsReportMutation();
  const [adminGetDebtsReport, { data: adminData }] = useAdminGetDebtsReportMutation();
  const [selectedCamaraComercioReport, setSelectedCamaraComercioReport] = useState<
    DebtsReportResponse['camaraComercioReports'][0] | null
  >();
  const [selectedCMFReport, setSelectedCMFReport] = useState<DebtsReportResponse['cmfReports'][0] | null>();

  const [reportType, setReportType] = useState<'CAMARA_COMERCIO' | 'CMF'>('CAMARA_COMERCIO');
  const debtsReportData = userData || adminData;
  const hasUnpaidCMFDebts = selectedCMFReport?.directDebt?.some(
    (debt) => Number(debt.between30To89Days) > 0 || Number(debt.over90Days) > 0
  );
  const navigate = useNavigate();
  const getNextReportDate = (lastReportDate: string) => {
    const date = new Date(lastReportDate);
    date.setMonth(date.getMonth() + 1);
    return date;
  };
  // get user id param
  const query = useQuery();
  const userId = query.get('userId');
  useEffect(() => {
    if (userId) {
      adminGetDebtsReport(Number(userId)).catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
    } else {
      getDebtsReport(null).catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
    }
  }, [getDebtsReport, userId]);

  useEffect(() => {
    if (debtsReportData) {
      setSelectedCamaraComercioReport(debtsReportData.camaraComercioReports[0]);
      setSelectedCMFReport(debtsReportData.cmfReports[0]);
    }
  }, [debtsReportData]);

  return (
    <Box sx={{ px: 2, py: { xs: 0, lg: 4 }, display: 'flex', flexDirection: 'column' }}>
      <Button
        onClick={() => {
          if (userId) {
            navigate(`/admin/users?userId=${userId}`);
          } else {
            navigate('/debt');
          }
        }}
      >
        Volver
      </Button>
      <Box pt={2}>
        <Select
          label="¿Que tipo de reporte deseas ver?"
          value={reportType}
          onClickOption={(e: { detail: 'CAMARA_COMERCIO' | 'CMF' }) => setReportType(e.detail)}
        >
          <SelectOption value="CAMARA_COMERCIO" selected={reportType === 'CAMARA_COMERCIO'}>
            Boletín comercial
          </SelectOption>
          <SelectOption value="CMF" selected={reportType === 'CMF'}>
            Informe de deudas CMF
          </SelectOption>
        </Select>
      </Box>

      <Box pt={2}>
        <Select
          label="¿De qué fecha?"
          value={selectedCamaraComercioReport?.reportDate}
          onClickOption={(e: { detail: string }) => {
            if (reportType === 'CMF') {
              setSelectedCMFReport(
                debtsReportData?.cmfReports.find((report) => report.reportDate === e.detail) || null
              );
            } else {
              setSelectedCamaraComercioReport(
                debtsReportData?.camaraComercioReports.find((report) => report.reportDate === e.detail) || null
              );
            }
          }}
        >
          {reportType === 'CAMARA_COMERCIO'
            ? debtsReportData?.camaraComercioReports.map((report) => (
                <SelectOption
                  value={report.reportDate}
                  selected={selectedCamaraComercioReport?.reportDate === report.reportDate}
                  key={report.reportDate}
                >
                  {new Date(report.reportDate).toLocaleString('es-CL', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  })}
                </SelectOption>
              ))
            : debtsReportData?.cmfReports.map((report) => (
                <SelectOption
                  value={report.reportDate}
                  selected={selectedCMFReport?.reportDate === report.reportDate}
                  key={report.reportDate}
                >
                  {new Date(report.reportDate).toLocaleString('es-CL', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  })}
                </SelectOption>
              ))}
        </Select>
      </Box>

      {reportType === 'CAMARA_COMERCIO' && debtsReportData && selectedCamaraComercioReport && (
        <PaperContainer fullWidth>
          <ReportHeader
            reportDate={new Date(selectedCamaraComercioReport.reportDate)}
            debtsReportData={debtsReportData}
          />
          <Box p={2}>
            <Box py={1} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Typography variant="h4">
                Deudas publicadas en el{' '}
                <DefinitionWrapper definitionName="boletín comercial" placement="bottom">
                  boletín comercial
                </DefinitionWrapper>
              </Typography>
            </Box>
            {selectedCamaraComercioReport.bicDebts.length === 0 ? (
              <Typography variant="body1" mb={2}>
                No tienes deudas publicadas en el boletín comercial.
              </Typography>
            ) : (
              <Box paddingBottom={1}>
                <DataTable variant="card">
                  <table slot="card">
                    <thead>
                      <tr>
                        <td>Institución financiera</td>
                        <td>Fecha de publicación</td>
                        <td>Monto adeudado</td>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedCamaraComercioReport.bicDebts.map((debt) => (
                        <tr key={`${debt.institutionName}-${debt.amount}`}>
                          <td data-mobile data-first="" data-mobile-width="100%">
                            {debt.institutionName}
                          </td>
                          <td data-desktop>{debt.institutionName}</td>
                          <td data-mobile data-label="Fecha de publicación">
                            {new Date(debt.dueDate).toLocaleString('es-CL', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                            })}
                          </td>
                          <td data-desktop>
                            {new Date(debt.dueDate).toLocaleString('es-CL', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                            })}
                          </td>
                          <td data-mobile data-label="Monto adeudado">
                            {priceFormat.format(debt.amount)}
                          </td>
                          <td data-desktop>{priceFormat.format(debt.amount)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </DataTable>
              </Box>
            )}
            <Box py={1} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Typography variant="h4">
                <DefinitionWrapper definitionName="deuda morosa">Deudas morosas</DefinitionWrapper> no publicadas en el
                boletín comercial
              </Typography>
            </Box>
            {selectedCamaraComercioReport.molDebts.length === 0 ? (
              <Typography variant="body1" mb={2}>
                No tienes deudas morosas no publicadas en el boletín comercial.
              </Typography>
            ) : (
              <Box paddingBottom={1}>
                <DataTable variant="card">
                  <table slot="card">
                    <thead>
                      <tr>
                        <td>Institución financiera</td>
                        <td>Fecha de vencimiento</td>
                        <td>Monto adeudado</td>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedCamaraComercioReport.molDebts.map((debt) => (
                        <tr key={`${debt.institutionName}-${debt.amount}`}>
                          <td data-desktop>{debt.institutionName}</td>
                          <td data-mobile data-mobile-width="100%" data-first="">
                            {debt.institutionName}
                          </td>
                          <td data-mobile data-label="Fecha de publicación">
                            {new Date(debt.dueDate).toLocaleString('es-CL', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                            })}
                          </td>
                          <td data-desktop>
                            {new Date(debt.dueDate).toLocaleString('es-CL', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                            })}
                          </td>
                          <td data-mobile data-label="Monto adeudado">
                            {priceFormat.format(debt.amount)}
                          </td>
                          <td data-desktop>{priceFormat.format(debt.amount)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </DataTable>
              </Box>
            )}
            <Typography variant="h4" py={1}>
              Nuestro consejo:
            </Typography>
            {selectedCamaraComercioReport.molDebts.length > 0 || selectedCamaraComercioReport.bicDebts.length > 0 ? (
              <Typography variant="body1">
                Tienes deudas que no has pagado, lo que puede afectar tu evaluación crediticia. No te preocupes, esto le
                pasa a mucha gente. Nosotros te podemos ayudar a conseguir algún descuento en estas deudas para que
                puedas ponerte al dia 😉.
              </Typography>
            ) : (
              <>
                <Typography variant="body1">
                  No hemos detectado deudas que no hayas pagado. ¡Estás en el buen camino! ☺️ Asegúrate de seguir
                  pagando a tiempo. Es un buen momento para comenzar a ahorrar. Para eso, te recomendamos que revises la
                  sección &quot;Mi plan&quot;.
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <SecondaryBtn
                    onClick={() => {
                      navigate('/my-plan');
                    }}
                  >
                    Ver mi plan
                  </SecondaryBtn>
                </Box>
              </>
            )}
          </Box>
          <Box p={2} mt={4}>
            <MessageAlerta color="informativo" close-button={false}>
              <span slot="label">
                Esta información se obtuvo de la base de datos de la Cámara de Comercio de Santiago.
                <br />
                Podrás ver una versión más actualizada el{' '}
                {getNextReportDate(selectedCamaraComercioReport.reportDate).toLocaleString('es-CL', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                })}
              </span>
            </MessageAlerta>
          </Box>
        </PaperContainer>
      )}

      {reportType === 'CMF' && debtsReportData && selectedCMFReport && (
        <PaperContainer fullWidth>
          <ReportHeader reportDate={new Date(selectedCMFReport.reportDate)} debtsReportData={debtsReportData} />
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }} p={2}>
            <Box py={1} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Typography variant="h4">
                <DefinitionWrapper definitionName="Informe de deudas CMF" placement="bottom">
                  Informe de deudas CMF
                </DefinitionWrapper>
              </Typography>
            </Box>
            {selectedCMFReport.directDebt.length === 0 ? (
              <Typography variant="body1" mb={2}>
                No encontramos deudas en la CMF.
              </Typography>
            ) : (
              <Box paddingBottom={1}>
                <DataTable variant="card">
                  <table slot="card">
                    <thead>
                      <tr>
                        <td>Institución financiera</td>
                        <td>Saldo vigente</td>
                        <td>Atraso: 30 a 89 días</td>
                        <td>Atraso: 90 a más días</td>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedCMFReport.directDebt.map((debt) => (
                        <tr key={`${debt.institution}-${debt.total}`}>
                          <td data-desktop>{debt.institution}</td>
                          <td data-mobile data-mobile-width="100%" data-first="">
                            {debt.institution}
                          </td>
                          <td data-desktop>{priceFormat.format(Number(debt.currentDebt))}</td>
                          <td data-mobile data-label="Saldo vigente" data-mobile-width="100%">
                            {priceFormat.format(Number(debt.currentDebt))}
                          </td>
                          <td data-desktop>{priceFormat.format(Number(debt.between30To89Days))}</td>
                          <td data-mobile data-label="Atraso: 30 a 89 días">
                            {priceFormat.format(Number(debt.between30To89Days))}
                          </td>
                          <td data-desktop>{priceFormat.format(Number(debt.over90Days))}</td>
                          <td data-mobile data-label="Atraso: 90 a más días">
                            {priceFormat.format(Number(debt.over90Days))}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </DataTable>
              </Box>
            )}
            <Typography variant="h4" py={1}>
              Nuestro consejo:
            </Typography>
            {hasUnpaidCMFDebts ? (
              <Typography variant="body1">
                Tienes deudas atrasadas, lo que puede afectar tu evaluación crediticia. No te preocupes, esto le pasa a
                mucha gente. Nosotros te podemos ayudar a conseguir algún descuento en estas deudas para que puedas
                ponerte al dia 😉.
              </Typography>
            ) : (
              <>
                <Typography variant="body1">
                  No hemos detectado deudas atrasadas en la CMF. ¡Estás en el buen camino! ☺️ Asegúrate de seguir
                  pagando a tiempo. Es un buen momento para comenzar a ahorrar. Para eso, te recomendamos que revises la
                  sección &quot;Mi plan&quot;.
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <SecondaryBtn
                    onClick={() => {
                      navigate('/my-plan');
                    }}
                  >
                    Ver mi plan
                  </SecondaryBtn>
                </Box>
              </>
            )}
          </Box>
          <Box p={2} mt={4}>
            <MessageAlerta color="informativo" close-button={false}>
              <span slot="label">
                Esta información se obtuvo de la base de datos de la CMF.
                <br />
                Podrás ver una versión más actualizada el{' '}
                {getNextReportDate(selectedCMFReport.reportDate).toLocaleString('es-CL', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                })}
              </span>
            </MessageAlerta>
          </Box>
        </PaperContainer>
      )}
    </Box>
  );
};

export default DebtsReport;
