import {
  SendWhatsAppBulkTemplates,
  SendWhatsAppMessage,
  SendWhatsAppTemplate,
  SendWhatsAppTemplateToUserBody,
  ScheduleWhatsAppTemplateToUserBody,
  Message,
  WhatsAppTemplateResponse,
  WhatsAppUser,
  WhatsAppScheduledTemplateMessage,
} from '../types/whatsApp';
import baseApi from './baseApi';

const whatsAppApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getMessage: build.query({
      query: (messageId: number) => ({
        url: `/admin/whatsApp/${messageId}`,
        method: 'GET',
      }),
      transformResponse: (response: Message) => response,
    }),
    postMessage: build.mutation({
      query: (body: SendWhatsAppMessage) => ({
        url: '/admin/whatsApp/message',
        method: 'POST',
        body,
      }),
      transformResponse: (response: Message) => response,
    }),
    postTemplate: build.mutation({
      query: (body: SendWhatsAppTemplate) => ({
        url: '/admin/whatsApp/template',
        method: 'POST',
        body,
      }),
      transformResponse: (response: Message) => response,
    }),
    postTemplateToUser: build.mutation({
      query: ({ userId, template }: SendWhatsAppTemplateToUserBody) => ({
        url: `/admin/whatsApp/template/${userId}`,
        method: 'POST',
        body: template,
      }),
      transformResponse: (response: Message) => response,
    }),
    scheduleTemplateToUser: build.mutation({
      query: ({ userId, template, postAt }: ScheduleWhatsAppTemplateToUserBody) => ({
        url: '/admin/whatsAppScheduledTemplateMessages',
        method: 'POST',
        body: {
          userId,
          components: template.components,
          templateName: template.templateName,
          status: 'scheduled',
          postAt,
        },
      }),
      transformResponse: (response: Message) => response,
    }),
    postBulkTemplates: build.mutation({
      query: (body: SendWhatsAppBulkTemplates) => ({
        url: '/admin/whatsApp/bulk-template',
        method: 'POST',
        body,
      }),
      transformResponse: (response) => response,
    }),
    getConversation: build.mutation({
      query: (userId: number) => ({
        url: `/admin/whatsApp/conversation/${userId}`,
        method: 'GET',
      }),
      transformResponse: (response: Message[]) => response,
    }),
    getWhatsAppUsers: build.query({
      query: () => ({
        url: '/admin/whatsApp/users',
        method: 'GET',
      }),
      transformResponse: (response: WhatsAppUser[]) => response,
    }),
    getWhatsAppTemplates: build.query({
      query: () => ({
        url: '/admin/whatsApp/templates',
        method: 'GET',
      }),
      transformResponse: (response: WhatsAppTemplateResponse) => response,
    }),
    readConversation: build.mutation({
      query: (userId: number) => ({
        url: `/admin/whatsApp/read-conversation/${userId}`,
        method: 'POST',
      }),
    }),
    unreadConversation: build.mutation({
      query: (userId: number) => ({
        url: `/admin/whatsApp/unread-conversation/${userId}`,
        method: 'POST',
      }),
    }),
    getScheduledWAMessages: build.mutation({
      query: (userId: number) => ({
        url: `/admin/whatsApp/scheduledMessages/${userId}`,
        method: 'GET',
      }),
      transformResponse: (response: WhatsAppScheduledTemplateMessage[]) => response,
    }),
    deleteScheduledMessage: build.mutation({
      query: (scheduledMessageId: number) => ({
        url: `/admin/whatsAppScheduledTemplateMessages/${scheduledMessageId}`,
        method: 'DELETE',
      }),
      transformResponse: (response: WhatsAppScheduledTemplateMessage) => response,
    }),
    searchMessages: build.query({
      query: (query: string) => ({
        url: '/admin/whatsApp/search',
        method: 'GET',
        params: {
          query,
        },
      }),
      transformResponse: (response: WhatsAppUser[]) => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetMessageQuery,
  useGetConversationMutation,
  usePostMessageMutation,
  useGetWhatsAppUsersQuery,
  usePostTemplateMutation,
  usePostTemplateToUserMutation,
  useScheduleTemplateToUserMutation,
  useGetWhatsAppTemplatesQuery,
  usePostBulkTemplatesMutation,
  useReadConversationMutation,
  useUnreadConversationMutation,
  useGetScheduledWAMessagesMutation,
  useDeleteScheduledMessageMutation,
  useSearchMessagesQuery,
} = whatsAppApi;
