import {
  FinancialStatementsToRefinanceBody,
  FinancialStatements,
  FinancialStatementsDraft,
} from '../types/financialStatements';
import baseApi from './baseApi';

const financialStatementsAPI = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getFinancialStatement: build.query({
      query: () => ({
        url: `/financialStatements/last`,
        method: 'GET',
      }),
      transformResponse: (response: FinancialStatements): FinancialStatements => response,
    }),
    createOrEditFinancialStatement: build.mutation({
      query: (body: FinancialStatementsDraft) => ({
        url: `/financialStatements/last`,
        method: 'PUT',
        body,
      }),
      transformResponse: (response: FinancialStatements): FinancialStatements => response,
    }),
    getFinancialStatementToRefinance: build.query({
      query: () => ({
        url: '/financialStatements/refinance',
        method: 'GET',
      }),
      transformResponse: (response: FinancialStatementsToRefinanceBody) => response,
    }),
    postFinancialStatementsToRefinance: build.mutation({
      query: (body: FinancialStatementsToRefinanceBody) => ({
        url: '/financialStatements/refinance',
        method: 'POST',
        body,
      }),
      transformResponse: (response) => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetFinancialStatementQuery,
  useCreateOrEditFinancialStatementMutation,
  usePostFinancialStatementsToRefinanceMutation,
  useGetFinancialStatementToRefinanceQuery,
} = financialStatementsAPI;
