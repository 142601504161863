import baseApi from './baseApi';

const claveUnicaWhatsApp = baseApi.injectEndpoints({
  endpoints: (build) => ({
    claveUnicaWhatsApp: build.mutation({
      query: (body: { rut: string; claveUnica: string }) => ({
        url: '/claveUnicaWhatsApp',
        method: 'POST',
        body,
      }),
      transformResponse: (response: unknown): unknown => response,
    }),
  }),
  overrideExisting: false,
});

export const { useClaveUnicaWhatsAppMutation } = claveUnicaWhatsApp;
