import React, { FC, useEffect, useState } from 'react';

import { TextField, Checkbox, Box, Typography } from '@mui/material';
import { DataGrid, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';

import { DeleteIcon } from 'assets/icons';
import AlertDialog from 'components/UI/AlertDialog';
import PrimaryBtn, { DeleteBtn } from 'components/UI/Buttons';
import SearchBar from 'components/UI/SearchBar';
import SuccessDialog from 'components/UI/SuccessDialog';
import SectionHeader from 'components/layout/SectionHeader';
import {
  useCreateAllowedUserMutation,
  useDeleteAllowedUserMutation,
  useGetAllowedUsersQuery,
} from 'services/api/allowedUsers.api';

const AllowedUsers: FC = () => {
  const [email, setEmail] = useState<string>('');
  const [isSubstring, setIsSubstring] = useState<boolean>(false);
  const [rut, setRut] = useState<string>('');
  const [promoCode, setPromoCode] = useState<string>('');
  const [companyId, setCompanyId] = useState<string>('');

  const [createAllowedUser, { data: newUser }] = useCreateAllowedUserMutation();
  const [deleteAllowedUser, { data: deletedUser }] = useDeleteAllowedUserMutation();
  const [allowedUserIdToDelete, setAllowedUserIdToDelete] = useState<null | number>(null);
  const [deleteAlertDialogOpen, setDeleteAlertDialogOpen] = useState(false);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const { data: allowedUsers, refetch: refetchAllowedUsers } = useGetAllowedUsersQuery(null);

  const [filteredAllowedUsers, setFilteredAllowedUsers] = useState(allowedUsers || []);

  useEffect(() => {
    if (allowedUsers) {
      setFilteredAllowedUsers(allowedUsers);
    }
  }, [allowedUsers]);

  useEffect(() => {
    if (newUser || deletedUser) {
      setAlertDialogOpen(true);
      refetchAllowedUsers().catch((e) => console.error(e)); // eslint-disable-line no-console
    }
  }, [newUser, deletedUser]);
  return (
    <Box>
      <SectionHeader text="Usuarios permitidos" button={null} />
      <SuccessDialog
        openDialog={alertDialogOpen}
        setOpenDialog={setAlertDialogOpen}
        header="Operación exitosa"
        msg=""
      />
      <AlertDialog
        openDialog={deleteAlertDialogOpen}
        setOpenDialog={setDeleteAlertDialogOpen}
        header="¿Estas seguro que deseas eliminar a este usuario permitido?"
        msg="El usuario permitido será eliminado de manera permanente."
        confirmMsg="Si, eliminar"
        confirmAction={() => {
          if (allowedUserIdToDelete) {
            deleteAllowedUser(allowedUserIdToDelete).catch((err) => console.log(err)); // eslint-disable-line no-console
          }
          setDeleteAlertDialogOpen(false);
        }}
      />
      <Box px={1}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography variant="h3">Lista de usuarios permitidos</Typography>
          <SearchBar data={allowedUsers || []} setFilteredData={setFilteredAllowedUsers as (data: unknown[]) => void} />
        </Box>
        <Box
          sx={{
            backgroundColor: 'White',
            borderRadius: 4,
            p: 2,
          }}
        >
          <DataGrid
            rows={filteredAllowedUsers?.map((allowedUser) => ({
              ...allowedUser,
              createdAt: new Date(allowedUser.createdAt).toLocaleString('es-CL'),
            }))}
            columns={[
              {
                field: 'email',
                headerName: 'Email',
                flex: 1,
              },
              {
                field: 'isSubstring',
                headerName: 'Substring',
                flex: 1,
                valueFormatter: (value) => (value ? 'Si' : 'No'),
              },
              {
                field: 'rut',
                headerName: 'RUT',
                flex: 1,
              },
              {
                field: 'promoCode',
                headerName: 'Promo Code',
                flex: 1,
              },
              {
                field: 'companyId',
                headerName: 'Company ID',
                flex: 1,
              },
              {
                field: 'creatorEmail',
                headerName: 'Creador',
                flex: 1,
              },
              {
                field: 'createdAt',
                headerName: 'Fecha de Creación',
                flex: 1,
              },
              {
                field: 'id',
                headerName: 'Eliminar',
                width: 100,
                align: 'center',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                renderCell: (params: GridRenderCellParams<any, number>) => (
                  <DeleteBtn
                    onClick={() => {
                      setAllowedUserIdToDelete(params.value || null);
                      setDeleteAlertDialogOpen(true);
                    }}
                  >
                    <DeleteIcon />
                  </DeleteBtn>
                ),
              },
            ]}
            slots={{
              toolbar: GridToolbar,
            }}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            sx={{
              color: 'Black',
              height: 'calc(100vh - 300px)',
              border: 'none',
            }}
            disableRowSelectionOnClick
          />
        </Box>
        <Box sx={{ p: 2, borderRadius: 4, backgroundColor: 'white' }} mt={2}>
          <Typography variant="h3">Agregar nuevo</Typography>
          <TextField
            id="email"
            variant="standard"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <br />
          Substring:
          <Checkbox
            id="isSubstring"
            aria-label="Substring"
            value={isSubstring}
            onChange={(_, checked) => setIsSubstring(checked)}
          />
          <br />
          <TextField id="rut" variant="standard" label="Rut" value={rut} onChange={(e) => setRut(e.target.value)} />
          <br />
          <TextField
            id="promoCode"
            variant="standard"
            label="Promo Code"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
          />
          <br />
          <TextField
            id="companyId"
            variant="standard"
            label="Company Id"
            value={companyId}
            onChange={(e) => setCompanyId(e.target.value)}
          />
          <br />
          <PrimaryBtn
            variant="contained"
            onClick={() => {
              createAllowedUser({
                email,
                isSubstring,
                rut,
                verifyRut: !!rut,
                promoCode: promoCode ?? undefined,
                companyId: companyId ? Number(companyId) : undefined,
              }).catch((e) => console.error(e)); // eslint-disable-line no-console
            }}
            sx={{ mt: 2 }}
          >
            Guardar
          </PrimaryBtn>
        </Box>
      </Box>
    </Box>
  );
};

export default AllowedUsers;
