import React, { FC } from 'react';

import { Box, Container, Typography } from '@mui/material';

const RequestedBankOffer: FC = () => {
  return (
    <Box sx={{ margin: 0, padding: 0 }}>
      <div
        style={{
          backgroundImage: "url('./relif_background.webp')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: '100vh',
          width: '100vw',
          filter: 'blur(10px)',
          margin: 0,
          padding: 0,
        }}
      />
      <Container
        sx={{
          backgroundImage: 'url("/contact-background.svg")',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          borderRadius: '30px',
          padding: { lg: '20px 50px 50px', xs: '20px 10px' },
          position: 'absolute',
          mt: 3,
          top: '30%',
          left: '50%',
          transform: 'translate(-50%, -30%)',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            width: '100%',
          }}
        >
          <Typography variant="h4" sx={{ color: 'white', fontWeight: 'bold', mb: 2, fontSize: '22px' }}>
            Perfecto, para continuar con el proceso un ejecutivo del banco se pondrá en contacto contigo en las próximas
            horas.
          </Typography>
          <Typography variant="h5" sx={{ color: 'white', fontWeight: 'bold', mb: 2, fontSize: '20px' }}>
            Si tienes cualquier duda o quieres saber si te conviene, escríbenos a nuestro WhatsApp.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default RequestedBankOffer;
