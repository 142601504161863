import React, { FC } from 'react';

import { Grid, Typography } from '@mui/material';

import RoundedInput from 'components/UI/RoundedInput';
import priceFormat from 'services/format/priceFormat';

interface Props {
  rent: string;
  setRent: (fixedGrossIncome: string) => void;
}

const GetIncomeStep: FC<Props> = ({ rent, setRent }) => {
  const processIncomeInput = (stringValue: string) => {
    const value = Number(stringValue.replace(/\D+/g, ''));
    return priceFormat.format(value);
  };
  return (
    <Grid container>
      <Grid item xs={12} py={1}>
        <Typography variant="body2" textAlign="justify">
          Ingresa un valor aproximado de tu arriendo mensual. Déjalo en blanco si no tienes.
        </Typography>
      </Grid>
      <Grid item xs={12} py={1}>
        <RoundedInput
          variant="outlined"
          value={rent}
          fullWidth
          label="Arriendo Mensual"
          onChange={(e) => {
            setRent(processIncomeInput(e.target.value));
          }}
        />
      </Grid>
    </Grid>
  );
};

export default GetIncomeStep;
