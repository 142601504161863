export const getDV = (T: number): string => {
  let M = 0;
  let S = 1;
  for (let value = T; value > 0; value = Math.floor(value / 10)) {
    // eslint-disable-next-line no-plusplus
    S = (S + (value % 10) * (9 - (M++ % 6))) % 11;
  }
  return S ? (S - 1).toString() : 'K';
};

const validateRut = (rut: string): boolean => {
  const noDotsRut = rut.replace(/\./g, '');
  if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(noDotsRut)) {
    return false; // Doesn't match the rut format
  }
  const rutParts = noDotsRut.split('-');
  const rutDV = rutParts[1].toUpperCase();
  const rutNumber = parseInt(rutParts[0], 10);
  return rutDV === getDV(rutNumber);
};

export default validateRut;
