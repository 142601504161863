import React, { FC, ReactNode } from 'react';

import { Box } from '@mui/material';

import priceFormat from 'services/format/priceFormat';
import type { BudgetRowDraft } from 'services/types/budget';

import styles from '../../EditOrCreateBudget/EditOrCreateBudget.module.scss';
import BudgetSummary from '../../EditOrCreateBudget/components/BudgetSummary';
import SectionAccordion from '../../EditOrCreateBudget/components/SectionAccordion';
import { IncomeTable, ExpenseTable, ObjectiveTable } from './FormTable';

interface BudgetFormInterface {
  incomeRows: BudgetRowDraft[];
  homeRows: BudgetRowDraft[];
  familyRows: BudgetRowDraft[];
  childRows: BudgetRowDraft[];
  entertainmentRows: BudgetRowDraft[];
  financeRows: BudgetRowDraft[];
  goalRows: BudgetRowDraft[];
  actionButtons: ReactNode;
}

const BudgetForm: FC<BudgetFormInterface> = ({
  incomeRows,
  homeRows,
  familyRows,
  childRows,
  entertainmentRows,
  financeRows,
  goalRows,
  actionButtons,
}) => {
  const totalIncome = incomeRows.map((row) => Number(row.amount)).reduce((prev, next) => prev + next, 0);
  const totalHome = homeRows.map((row) => Number(row.amount)).reduce((prev, next) => prev + next, 0);
  const totalFamily = familyRows.map((row) => Number(row.amount)).reduce((prev, next) => prev + next, 0);
  const totalChild = childRows.map((row) => Number(row.amount)).reduce((prev, next) => prev + next, 0);
  const totalEntertain = entertainmentRows.map((row) => Number(row.amount)).reduce((prev, next) => prev + next, 0);
  const totalFinance = financeRows.map((row) => Number(row.amount)).reduce((prev, next) => prev + next, 0);

  const getRowsBadgeMessage = (rows: BudgetRowDraft[]): string => {
    const array: BudgetRowDraft[] = rows.filter((row) => {
      return Number(row.amount) > 0;
    });
    const total = rows.map((row) => Number(row.amount)).reduce((prev, next) => prev + next, 0);
    return `${priceFormat.format(total)} (${array.length})`;
  };

  const totalGoal =
    goalRows.length > 0 ? goalRows.map((row) => Number(row.amount)).reduce((prev, next) => prev + next) : 0;

  return (
    <div className={styles.MainContainer}>
      <Box
        sx={{ display: 'flex', justifyContent: { lg: 'space-between' }, flexDirection: { lg: 'row', xs: 'column' } }}
      >
        <Box
          sx={{
            width: {
              xs: '100%',
              lg: '60%',
              borderRadius: '20px',
            },
          }}
        >
          <SectionAccordion
            IncomeForm={<IncomeTable budgetRows={incomeRows} />}
            incomeBadge={getRowsBadgeMessage(incomeRows)}
            HomeForm={<ExpenseTable budgetRows={homeRows} />}
            homeBadge={getRowsBadgeMessage(homeRows)}
            FamilyForm={<ExpenseTable budgetRows={familyRows} />}
            familyBadge={getRowsBadgeMessage(familyRows)}
            ChildForm={<ExpenseTable budgetRows={childRows} />}
            childBadge={getRowsBadgeMessage(childRows)}
            EntertainmentForm={<ExpenseTable budgetRows={entertainmentRows} />}
            entertainmentBadge={getRowsBadgeMessage(entertainmentRows)}
            FinanceForm={<ExpenseTable budgetRows={financeRows} />}
            financeBadge={getRowsBadgeMessage(financeRows)}
            ObjectiveForm={<ObjectiveTable budgetRows={goalRows} />}
            objectiveBadge={getRowsBadgeMessage(goalRows)}
            actionButtons={actionButtons}
          />
        </Box>
        <Box width={{ lg: '38%', xs: '100%' }} mt={{ lg: 0, xs: 2 }}>
          <BudgetSummary
            totalIncome={totalIncome}
            totalHome={totalHome}
            totalEntertain={totalEntertain}
            totalFamily={totalFamily}
            totalFinance={totalFinance}
            totalChild={totalChild}
            totalGoal={totalGoal}
          />
        </Box>
      </Box>
    </div>
  );
};

export default BudgetForm;
