import React, { FC } from 'react';

import Typography from '@mui/material/Typography';

import CLALink from 'components/UI/CLALink';
import useIsCajaLosAndesTheme from 'hooks/useIsCajaLosAndesTheme';

import BodyTypography from './components/BodyTypography';
import NewHomeBreadCrumbs from './components/NewHomeBreadCrumbs';
import NewHomePaper from './components/NewHomePaper';

const SavingAccounts: FC = () => {
  const isCajaLosAndesTheme = useIsCajaLosAndesTheme();
  return (
    <NewHomePaper>
      <NewHomeBreadCrumbs title="Cuentas de ahorro" isHome={false} />
      <Typography my={2} fontSize="36px" lineHeight="44px" fontWeight="500">
        Cuentas de Ahorro para la Vivienda
      </Typography>
      <BodyTypography>
        Los subsidios piden que cuentes con un ahorro mínimo para postular. Si aun no tienes una cuenta de ahorro puedes
        crear una en {isCajaLosAndesTheme ? 'la ' : 'en las siguientes instituciones:'}
        {isCajaLosAndesTheme ? (
          <CLALink
            target="_blank"
            rel="noreferrer"
            href="https://mismetas.cajalosandes.cl/?_gl=1*1mbfdrz*_ga*MTIxMjM4NDQxNy4xNjM1ODgyNDMy*_ga_J99W05M2PC*MTcwMTQ0NTg1MS4xMDIuMC4xNzAxNDQ1ODUxLjYwLjAuMA"
          >
            Caja de los Andes
          </CLALink>
        ) : (
          <ul>
            <li>
              <CLALink
                target="_blank"
                rel="noreferrer"
                href="https://www.bancoestado.cl/content/bancoestado-public/cl/es/home/home/productos-/ahorro-e-inversiones-/cuentas-de-ahorro-/ahorro-vivienda.html#/"
              >
                Banco Estado
              </CLALink>
            </li>
            <li>
              <CLALink
                target="_blank"
                rel="noreferrer"
                href="https://mismetas.cajalosandes.cl/?_gl=1*1mbfdrz*_ga*MTIxMjM4NDQxNy4xNjM1ODgyNDMy*_ga_J99W05M2PC*MTcwMTQ0NTg1MS4xMDIuMC4xNzAxNDQ1ODUxLjYwLjAuMA"
              >
                Caja los Andes
              </CLALink>
            </li>
            <li>
              <CLALink target="_blank" rel="noreferrer" href="https://www.coopeuch.cl/personas/ahorro-vivienda.html">
                Coopeuch
              </CLALink>
            </li>
            <li>
              <CLALink
                target="_blank"
                rel="noreferrer"
                href="https://www.detacoop.cl/ahorro-para-postular-al-subsidio-habitacional/"
              >
                Detacoop
              </CLALink>
            </li>
          </ul>
        )}
      </BodyTypography>
    </NewHomePaper>
  );
};

export default SavingAccounts;
