import React, { FC } from 'react';

import Box from '@mui/material/Box';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import PrimaryBtn from 'components/UI/Buttons';
import { BigCheckWithText, BigCrossWithText, CheckWithText, CrossWithText } from 'components/common/IconsWithText';
import { Origin } from 'services/types/origin';

import styles from '../UniqueDebtFlow.module.scss';
import ModalWithHeader from './ModalWithHeader';

interface Props {
  onRedirectClick: () => void;
  origin: Origin;
}

const RedirectToDebtsModal: FC<Props> = (props: Props) => {
  const { onRedirectClick, origin } = props;

  return (
    <ModalWithHeader origin={origin} title="¿Qué haremos?">
      <Box className={styles.ContentBox}>
        <Box mt={2}>
          <BigCheckWithText text="Relif puede:" />
          <CheckWithText text="Negociar con instituciones financieras" />
          <CheckWithText text="Enviarte información con contenido necesario" />
          <CheckWithText text="Acceder a tu Boletín Comercial" />
          <CheckWithText text="Acceder a tu informe de deudas de la CMF" />
        </Box>
        <Box mt={2}>
          <BigCrossWithText text="Relif no puede:" />
          <CrossWithText text="Entregar información bancaria sin tu consentimiento" />
          <CrossWithText text="Prestar plata" />
          <CrossWithText text="Pedir créditos a tu nombre" />
          <CrossWithText text="Hacer cambios a tus créditos actuales" />
        </Box>
      </Box>
      <Box display="grid" justifyContent="center" alignItems="center" py={5}>
        <PrimaryBtn type="submit" onClick={onRedirectClick}>
          Continuar
        </PrimaryBtn>
      </Box>
    </ModalWithHeader>
  );
};

export default RedirectToDebtsModal;
