import React from 'react';

import { ResponsiveContainer, Cell, PieChart, Pie, Label } from 'recharts';

const RADIAN = Math.PI / 180;

const chartColors = ['#E7233B', '#FF8D3B', '#F9D00A', '#77D73E'];

const width = 300;
const height = 180;
const slices = chartColors.map((color) => ({ color, value: 25 }));

const sumValues = slices.map((cur) => cur.value).reduce((a, b) => a + b);

const pieProps = {
  startAngle: 180,
  endAngle: 0,
  cx: width / 2,
  cy: width / 2,
  isAnimationActive: false,
};

const Dot = ({ cx, cy, midAngle, outerRadius }: { cx: number; cy: number; midAngle: number; outerRadius: number }) => {
  // eslint-disable-line react/no-multi-comp
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const mx = cx + (outerRadius + width * 0.01) * cos;
  const my = cy + (outerRadius + width * 0.01) * sin;

  const [color1, color2, color3, color4] = chartColors;

  let color = '';
  if (midAngle <= 180 && midAngle > 135) {
    color = color1;
  } else if (midAngle <= 135 && midAngle > 90) {
    color = color2;
  } else if (midAngle <= 90 && midAngle > 45) {
    color = color3;
  } else {
    color = color4;
  }

  return (
    <g>
      <circle cx={mx} cy={my} r={width * 0.05} fill={color} stroke="none" />
      <circle cx={mx} cy={my} r={width * 0.03} fill="white" stroke="none" />
    </g>
  );
};

interface GaugeChartProps {
  realValue: number;
}

const GaugeChart = ({ realValue }: GaugeChartProps) => {
  const chartValue = 100 - realValue < 0 ? 0 : 100 - realValue;
  const arrowData = [{ value: chartValue }, { value: 0 }, { value: sumValues - chartValue }];

  const colorIndex = Math.floor(chartValue / 25);
  const labelColor = chartColors[colorIndex];

  return (
    <div style={{ width, height: 180 }}>
      <ResponsiveContainer>
        <PieChart width={width} height={height}>
          <Pie
            stroke="none"
            data={slices}
            innerRadius={(width / 2) * 0.7}
            outerRadius={(width / 2) * 0.8}
            {...pieProps}
            dataKey="value"
            cornerRadius={10}
            paddingAngle={5}
          >
            {slices.map((each, i) => (
              <Cell key={`cell-${each.value}`} fill={slices[i].color} stroke="none" />
            ))}
          </Pie>
          <Pie
            stroke="none"
            fill="none"
            activeIndex={1}
            activeShape={Dot}
            data={arrowData}
            innerRadius={(width / 2) * 0.74}
            outerRadius={(width / 2) * 0.74}
            {...pieProps}
            dataKey="value"
          >
            <Label
              value={`${realValue}%`}
              position="centerBottom"
              offset={-20}
              className="gauge-label"
              fontSize="50px"
              fontWeight="bold"
              fill={labelColor}
            />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default GaugeChart;
