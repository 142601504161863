import React, { useEffect } from 'react';

import { Cached } from '@mui/icons-material';
import { Box, Grid, IconButton, Typography } from '@mui/material';

import AlertDialog from 'components/UI/AlertDialog';
import SectionHeader from 'components/layout/SectionHeader';
import trpc from 'trpc';

import UsersTable from './UsersTable';

const Users = () => {
  const [openErrorAlert, setOpenErrorAlert] = React.useState(false);
  const activeUsersQuery = trpc.users.getActiveUsers.useQuery();

  const handleRefetchUserData = () => {
    // eslint-disable-next-line no-console
    activeUsersQuery.refetch().catch((e) => console.error(e));
  };

  useEffect(() => {
    if (activeUsersQuery.isError) {
      setOpenErrorAlert(true);
    }
  }, [activeUsersQuery.isError]);

  return (
    <Box>
      <SectionHeader text="Usuarios Activos" button={null} />
      <AlertDialog
        openDialog={openErrorAlert}
        setOpenDialog={setOpenErrorAlert}
        header="Error cargando usuarios"
        msg="No se pudieron cargar los usuarios"
        confirmMsg="Aceptar"
        confirmAction={() => setOpenErrorAlert(false)}
      />
      <Grid container>
        <Grid item xs={12} p={1}>
          <Box display="flex" alignItems="flex-start">
            <Typography variant="h3" mt={1}>
              Usuarios Activos (Última hora)
            </Typography>
            <IconButton onClick={() => handleRefetchUserData()} size="large">
              <Cached />
            </IconButton>
          </Box>
          <UsersTable users={activeUsersQuery.data || []} isLoading={activeUsersQuery.isLoading} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Users;
