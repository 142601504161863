import React, { FC, useEffect, useState } from 'react';

import PostAdd from '@mui/icons-material/PostAdd';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import PrimaryBtn from 'components/UI/Buttons';
import DrawerContent from 'components/UI/DrawerContent';
import DrawerStepper from 'components/UI/DrawerStepper';
import SideDrawer from 'components/UI/SideDrawer';
import { useCreateOrEditFinancialStatementMutation } from 'services/api/financialStatements.api';
import { FinancialStatements } from 'services/types/financialStatements';

import GetIncomeStep from './GetIncomeStep';
import GetRentStep from './GetRentStep';

const steps = ['Tus Ingresos', 'Tu Arriendo'];

interface Props {
  open: boolean;
  setModalOpen: (state: boolean) => void;
  financialStatementToEdit: null | FinancialStatements;
  setFinancialStatement: (state: undefined | FinancialStatements) => void;
}

const FinancialStatusModal: FC<Props> = ({ open, setModalOpen, financialStatementToEdit, setFinancialStatement }) => {
  const [createFinancialStatement, { data: newFinancialStatement, isError }] =
    useCreateOrEditFinancialStatementMutation();
  const [extraIncome, setExtraIncome] = useState('');
  const [averageNetIncome, setAverageNetIncome] = useState('');
  const [rent, setRent] = useState('');

  const [activeStep, setActiveStep] = React.useState(0);

  const verifyForm = () => {
    return averageNetIncome !== '';
  };

  const clearFormFields = () => {
    setExtraIncome('');
    setAverageNetIncome('');
    setRent('');
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSubmit = async () => {
    if (verifyForm()) {
      const date = new Date();
      date.setMonth(date.getMonth() - 1);
      const body = {
        month: date.getMonth() + 1,
        year: date.getFullYear(),
        averageNetIncome: Number(averageNetIncome.replace(/\D+/g, '')),
        extraIncome: Number(extraIncome.replace(/\D+/g, '')),
        rent: Number(rent.replace(/\D+/g, '')),
      };
      await createFinancialStatement(body);
    }
  };

  const checkNextDisabled = (step: number) => {
    if (step === 0) {
      return !verifyForm();
    }
    return false;
  };

  useEffect(() => {
    if (newFinancialStatement) {
      setActiveStep(0);
      handleClose();
      setFinancialStatement(newFinancialStatement);
    }
    if (isError) {
      clearFormFields();
      handleNext();
    }
  }, [newFinancialStatement, isError]);

  useEffect(() => {
    if (financialStatementToEdit) {
      setExtraIncome(financialStatementToEdit.extraIncome?.toString() || '');
      setAverageNetIncome(financialStatementToEdit.averageNetIncome?.toString() || '');
      setRent(financialStatementToEdit.rent?.toString() || '');
    }
  }, [financialStatementToEdit]);

  return (
    <Box display="grid" alignContent="center" textAlign="center">
      <SideDrawer
        title="Agregar Datos"
        open={open}
        onClose={handleClose}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      >
        <Box sx={{ width: '100%', height: '80%' }} component="form">
          <DrawerStepper activeStep={activeStep} steps={steps} />
          {activeStep === steps.length ? (
            <DrawerContent title="Error al cargar sus datos, por favor intenta de nuevo">
              <PrimaryBtn variant="contained" sx={{ my: 2 }} fullWidth onClick={() => setActiveStep(0)}>
                Volver
              </PrimaryBtn>
            </DrawerContent>
          ) : (
            <>
              {activeStep === 0 && (
                <DrawerContent title={steps[activeStep]}>
                  <GetIncomeStep
                    averageNetIncome={averageNetIncome}
                    setAverageNetIncome={setAverageNetIncome}
                    extraIncome={extraIncome}
                    setExtraIncome={setExtraIncome}
                    handleNext={handleNext}
                  />
                </DrawerContent>
              )}
              {activeStep === 1 && (
                <DrawerContent title={steps[activeStep]}>
                  <GetRentStep rent={rent} setRent={setRent} />
                </DrawerContent>
              )}
              <Grid container>
                {activeStep >= steps.length - 1 && (
                  <Grid item xs={12} px="20px">
                    <PrimaryBtn
                      variant="contained"
                      sx={{ my: 2 }}
                      fullWidth
                      onClick={() => {
                        handleSubmit().catch((e) => console.error(e)); // eslint-disable-line
                      }}
                      disabled={checkNextDisabled(activeStep)}
                      endIcon={<PostAdd />}
                    >
                      {financialStatementToEdit ? 'Editar' : 'Agregar'} Datos
                    </PrimaryBtn>
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </Box>
      </SideDrawer>
    </Box>
  );
};

export default FinancialStatusModal;
