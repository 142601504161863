import posthog, { Properties } from 'posthog-js';
import { SignInResponse } from 'services/types/user';

export const identifyPosthogUser = (user: SignInResponse) => {
  try {
    if (import.meta.env.NODE_ENV !== 'test') {
      posthog.identify(`${import.meta.env.NODE_ENV === 'development' ? 'dev_' : ''}${user.id}`, {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
        rut: user.rut,
        isTestUser: user.isTestUser,
        isAdmin: user.isAdmin,
        companyId: user.companyId,
      });
      if (user.companyId) {
        posthog.group(
          'company',
          `
      ${import.meta.env.NODE_ENV === 'development' ? 'dev_' : ''}${user.companyId}`,
          {
            companyId: user.companyId,
            companyName: user.company?.name,
          }
        );
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const posthogReset = () => {
  try {
    if (import.meta.env.NODE_ENV !== 'test') {
      posthog.reset();
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const capturePageView = () => {
  if (import.meta.env.NODE_ENV !== 'test') {
    posthog.capture('$pageview');
  }
};

export const captureUserEvent = (event: string, properties?: unknown) => {
  if (import.meta.env.NODE_ENV !== 'test') {
    posthog.capture(event, properties as Properties);
  }
};
