import React, { FC } from 'react';

import { Typography, Box } from '@mui/material';

import type { BankOffer } from '@relif/backend';
import Simulator from 'components/common/Simulator';
import { dateFormat } from 'services/format/dateFormat';

import OfferHeader from './OfferHeader';
import TermOptionCard from './TermOptionCard';

interface Props {
  bankOffer: BankOffer;
  setInterested: (id: number, term: number) => void;
  selectedTerm: number | null;
}

const RefinanceOffer: FC<Props> = ({ bankOffer, setInterested, selectedTerm }) => {
  const title = `Conseguimos una oferta de refinanciamiento con ${bankOffer.bank}.`;

  const options = bankOffer.options || bankOffer.terms || [];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: { xs: '100%', md: '80%', lg: '50%', xl: '40%' },
        }}
      >
        <OfferHeader
          title={title}
          amountTitle="Monto del refinanciamiento"
          amount={Number(bankOffer.paymentPlanAmount || bankOffer.cashPaymentAmount)}
          amountDescription={`${((bankOffer.interestRate || 0) * 100).toFixed(2)}% interés mensual`}
        />
        <Box mt={4} px={3} py={4} width="100%">
          <Typography
            variant="body1"
            sx={{
              width: '100%',
              fontSize: 18,
              fontWeight: 600,
              color: 'primary.main',
              textAlign: 'left',
            }}
          >
            Elige una opción de cuotas
          </Typography>
          {options.map((option) => (
            <TermOptionCard
              key={option.term}
              isSelected={selectedTerm === option.term}
              boldTitle={`${option.term} `}
              title="cuotas de"
              amount={option.monthlyPayment || 0}
              onClick={() => setInterested(bankOffer.id, option.term)}
            />
          ))}
          <Typography
            variant="body1"
            sx={{
              width: '100%',
              fontSize: 14,
              fontWeight: 400,
              color: 'primary.main',
              textAlign: 'left',
            }}
          >
            * Montos aproximados. Podrían variar al hablar con el banco.
          </Typography>
          <Simulator bankOfferUUID={bankOffer.uuid || ''} />

          <ul>
            <li>
              <Typography
                variant="body1"
                sx={{
                  width: '100%',
                  fontSize: 16,
                  fontWeight: 400,
                  color: 'primary.main',
                  textAlign: 'left',
                }}
              >
                Este monto puede ser mayor al total de tus deudas. Si lo deseas, puedes solicitar un monto menor.
              </Typography>
            </li>
            {bankOffer.description && (
              <li>
                <Typography
                  variant="body1"
                  sx={{
                    width: '100%',
                    fontSize: 16,
                    fontWeight: 400,
                    color: 'primary.main',
                    textAlign: 'left',
                  }}
                >
                  {bankOffer.description}
                </Typography>
              </li>
            )}
            {bankOffer.firstPaymentDate && (
              <li>
                <Typography
                  variant="body1"
                  sx={{
                    width: '100%',
                    fontSize: 16,
                    fontWeight: 400,
                    color: 'primary.main',
                    textAlign: 'left',
                  }}
                >
                  La fecha del primer pago sería el: {dateFormat(new Date(bankOffer.firstPaymentDate))}
                </Typography>
              </li>
            )}
          </ul>

          <Typography
            variant="body1"
            sx={{
              width: '100%',
              fontSize: 16,
              fontWeight: 400,
              color: 'black',
              textAlign: 'left',
            }}
          >
            ¿Tienes dudas? ¿No encontraste un plazo que te acomode? <br />
          </Typography>
          <Typography
            variant="body1"
            sx={{
              width: '100%',
              fontSize: 16,
              fontWeight: 600,
              color: 'info.main',
              textAlign: 'left',
              textDecoration: 'underline',
            }}
            onClick={() => {
              window.open('/book-meeting', '_blank');
            }}
          >
            Agenda aquí una asesoría con el equipo de Relif
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default RefinanceOffer;
