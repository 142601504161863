import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import { DataGrid, GridRowParams, GridToolbar } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';

import { User } from '@relif/backend';
import removeCSVInjection from 'utils/removeCSVInjection';

const USER_ATTRIBUTES = [
  {
    field: 'id',
    headerName: 'ID',
    width: 60,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 200,
  },
  {
    field: 'firstName',
    headerName: 'Nombre',
    width: 200,
  },
  {
    field: 'lastName',
    headerName: 'Apellido',
    width: 200,
  },
  {
    field: 'lastActive',
    headerName: 'Activo hace (minutos)',
    width: 200,
  },
  {
    field: 'rut',
    headerName: 'RUT',
    width: 150,
  },
  {
    field: 'phone',
    headerName: 'Teléfono',
    width: 150,
  },
  {
    field: 'company.name',
    headerName: 'Nombre Empresa',
    width: 200,
  },
];

interface Props {
  users: User[];
  isLoading: boolean;
}

const UsersTable: FC<Props> = ({ users, isLoading }) => {
  const navigate = useNavigate();
  // remove possible CSV injection
  const usersRows = users.map((user) => ({
    id: user.id,
    phone: user.phone,
    lastActive: Math.round(
      (((+new Date() - +new Date(user.userActions ? user.userActions[0].createdAt : 0)) % 86400000) % 3600000) / 60000
    ),
    email: removeCSVInjection(user.email || ''),
    firstName: removeCSVInjection(user.firstName || ''),
    lastName: removeCSVInjection(user.lastName || ''),
    rut: removeCSVInjection(user.rut || ''),
    company: user.company ? { name: removeCSVInjection(user.company.name || '') } : undefined,
  }));
  const navigateToUser = (userId: number) => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set('userId', userId.toString());
    navigate(`/admin/users?${queryParams.toString()}`);
  };

  return (
    <Box style={{ width: '100%' }}>
      <Box
        sx={{
          backgroundColor: 'White',
          borderRadius: 4,
          p: 2,
        }}
      >
        <DataGrid
          rows={usersRows}
          columns={USER_ATTRIBUTES}
          slots={{
            toolbar: GridToolbar,
          }}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          sx={{
            color: 'Black',
            height: 'calc(100vh - 380px)',
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
            },
            '& .MuiDataGrid-row.Mui-selected': {
              backgroundColor: '#e8f0fe',
            },
            border: 'none',
          }}
          onRowClick={(params: GridRowParams<{ id: number }>) => {
            navigateToUser(params.row.id);
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationMode="server"
          pagination
          loading={isLoading}
        />
      </Box>
    </Box>
  );
};

export default UsersTable;
