import { DebtInstitutionName, DebtInstitutionType, DebtType } from '../types/debt';
import { CamaraComercioDocumentType, FloidInstitutionName } from '../types/floid';

export const floidInstitutionsMapping = (
  emisor: FloidInstitutionName
): {
  type: DebtInstitutionType;
  name: DebtInstitutionName;
} => {
  switch (emisor) {
    case 'De Credito e Inversiones':
    case 'Banco Nova De Bci':
    case 'Bci':
      return {
        name: 'BCI',
        type: 'Banco',
      };
    case 'CMR Falabella':
      return {
        name: 'CMR',
        type: 'Casa Comercial',
      };
    case 'Falabella':
    case 'Banco Falabella':
      return {
        name: 'Banco Falabella',
        type: 'Banco',
      };
    case 'Santander - Chile':
    case 'Banco Santander':
    case 'Santander-Chile':
      return {
        name: 'Santander',
        type: 'Banco',
      };
    case 'Santander Consumer':
      return {
        name: 'Santander',
        type: 'Prestador Automotriz',
      };
    case 'CAT TC Mastercard':
    case 'CAT (ex CENCOSUD)':
      return {
        name: 'Cencosud',
        type: 'Casa Comercial',
      };
    case 'Del Estado':
    case 'Del Estado de Chile':
      return {
        name: 'Banco Estado',
        type: 'Banco',
      };
    case 'CCAF Los Heroes':
      return {
        name: 'Los Heroes',
        type: 'Caja de Compensación',
      };
    case 'CCAF La Araucana':
      return {
        name: 'La Araucana',
        type: 'Caja de Compensación',
      };
    case 'Ripley Car':
    case 'CAR - Ripley':
    case 'Banco Ripley':
    case 'Ripley':
      return {
        name: 'Ripley',
        type: 'Casa Comercial',
      };
    case 'CCAF. De los Andes':
      return {
        name: 'Los Andes',
        type: 'Caja de Compensación',
      };
    case 'Tricard S.A.':
    case 'Tricard. Tricot':
      return {
        name: 'Tricot',
        type: 'Casa Comercial',
      };
    case 'Inv y Tarjetas-Hites':
    case 'Hites Inversiones y Tarjetas S.A.':
      return {
        name: 'Hites',
        type: 'Casa Comercial',
      };
    case 'La Polar':
    case 'INVERSIONES LP':
      return {
        name: 'La Polar',
        type: 'Casa Comercial',
      };
    case 'De Chile':
    case 'Banco de Chile':
      return {
        name: 'Banco de Chile',
        type: 'Banco',
      };
    case 'Scotiabank Chile':
    case 'Scotiabank Sud Americano':
    case 'Scotiabank':
      return {
        name: 'Scotiabank',
        type: 'Banco',
      };
    case 'Bbva':
      return {
        name: 'BBVA',
        type: 'Banco',
      };
    case 'Itau Corpbanca':
    case 'Banco Itaú Chile':
    case 'Corpbanca':
      return {
        name: 'Itaú Corpbanca',
        type: 'Banco',
      };
    case 'Tarjeta ABCvisa':
    case 'COFISA (ABCDIN-ADRetail)':
      return {
        name: 'Abcdin',
        type: 'Casa Comercial',
      };
    case 'Forum Credito':
      return {
        name: 'Forum',
        type: 'Prestador Automotriz',
      };
    case 'Tarjeta Corona':
    case 'CORONA':
      return {
        name: 'Corona',
        type: 'Casa Comercial',
      };
    case 'Lautaro Rosas':
      return {
        name: 'Lautaro Rosas',
        type: 'Cooperativas de ahorro y crédito',
      };
    case 'Security':
      return {
        name: 'Security',
        type: 'Banco',
      };
    case 'Unicard S.A.':
      return {
        name: 'Unimarc',
        type: 'Supermercados y farmacias',
      };
    case 'Bice':
      return {
        name: 'Bice',
        type: 'Banco',
      };
    case 'Servicios Financieros S.A.':
    case 'PRESTO LIDER':
      return {
        name: 'Líder',
        type: 'Supermercados y farmacias',
      };
    case 'Coopeuch':
      return {
        name: 'Coopeuch',
        type: 'Cooperativas de ahorro y crédito',
      };
    case 'Consorcio':
      return {
        name: 'Consorcio',
        type: 'Banco',
      };
    case 'Solventa Tarjetas':
    case 'Solventa':
      return {
        name: 'Solventa',
        type: 'Casa Comercial',
      };
    case 'Farmacia Cruz Verde':
      return {
        name: 'Cruz Verde',
        type: 'Supermercados y farmacias',
      };
    case 'Eugenio Camus M.':
    case 'Maria A. Galan Bauerle':
    case 'Francisco Fuenzalida Rodriguez':
      return {
        name: emisor,
        type: 'Notaría',
      };
    case 'Tenpo Payments  S.A.':
      return {
        name: 'Tenpo',
        type: 'Banco',
      };
    default:
      return {
        name: emisor,
        type: 'Otro',
      };
  }
};

export const floidDocumentMapping = (document: CamaraComercioDocumentType): DebtType => {
  switch (document) {
    case 'LT': // 'LETRA'
    case 'PG': // 'PAGARE'
    case 'INFOCOM': // 'INFOCOM'
    case 'BO': // 'BOLETAS'
    case 'CT': // 'CONTRATO'
    case 'PI': // 'PAGARE IMPAGO'
    case 'FA': // 'FACTURA'
      return 'otro';
    case 'CH': // 'CHEQUE'
      return 'cheque - falta de fondos';
    case 'CC': // 'CREDITO CONSUMO',
    case 'CI': // 'CUOTA IMPAGA',
    case 'CM': // 'CUOTA MOROSA',
      return 'crédito de consumo';
    case 'DH': // 'DEUDORES HIPOTECARIOS'
      return 'crédito hipotecario';
    case 'TC':
      return 'tarjeta de crédito';
    default:
      return 'crédito de consumo';
  }
};

export const floidDebtCurrencyMapping = (currency: string): string => {
  switch (currency) {
    case 'UF':
      return 'UF';
    case '$':
    default:
      return 'CLP';
  }
};
