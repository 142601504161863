import React, { FC } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Team from './Team';

const BlueSeparator = () => (
  <svg
    width="30%"
    preserveAspectRatio="none"
    height="2"
    viewBox="0 0 180 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 1.00003L180 1.00005" stroke="#15183A" strokeWidth="2" />
  </svg>
);

const WhoWeAre: FC = () => {
  return (
    <Grid pt={24}>
      <Box px={2}>
        <Typography fontFamily="Roboto" fontWeight="700" fontSize="22px" lineHeight="30px" textAlign="center" pb={4}>
          ¿Quienes somos?
        </Typography>
        <Typography
          fontFamily="Roboto"
          fontWeight="800"
          fontSize={{ xs: '27px', sm: '45px' }}
          lineHeight={{ xs: '34px', sm: '50px' }}
          color="#2C5889"
          textAlign="center"
        >
          Relif es una empresa tecnológica que tiene
          <br />
          <span style={{ color: '#15183A' }}> el propósito de mejorar la salud financiera</span>
          <br />
          de las personas de Latam
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center" width="100%" py={10}>
        <BlueSeparator />
      </Box>

      <Box px={{ xs: 4, sm: 12 }} pb={8} textAlign={{ xs: 'justify', sm: 'left' }}>
        <Typography fontFamily="Jost" fontWeight="500" fontSize="22px" lineHeight="25px" pb={4} color="#15183A">
          Estamos convencidos de que nuestra tecnología, basada en Open Data e IA, puede generar soluciones concretas y
          accesibles para abordar el sobreendeudamiento y la falta de educación financiera.
        </Typography>
        <span style={{ paddingBottom: 4, color: '#66696D' }}>
          Con el tiempo, descubrimos que nuestra plataforma automatiza gran parte de los flujos entre las instituciones
          financieras y sus clientes. Esto nos llevó a darnos cuenta de que nuestro software también podría optimizar
          los procesos de venta en diversas industrias, dando origen a{' '}
          <a style={{ color: '#15183A' }} href="https://www.zeller.ai" target="_blank" rel="noreferrer">
            <u>Zeller by Relif</u>
          </a>
          .
          <div style={{ paddingTop: '32px' }}>
            <b>En resumen,</b> hoy en día contamos con un robot capaz no solo de ayudar a personas e instituciones
            financieras a refinanciar créditos, sino también de apoyar a las empresas en aumentar sus ventas y reducir
            sus costos
          </div>
        </span>
      </Box>

      <Box sx={{ background: 'linear-gradient(to left, #15183A, #638DB8)', mb: { xs: 4, sm: 8 } }}>
        <Typography
          fontFamily="Roboto"
          fontSize="22px"
          lineHeight="25px"
          fontWeight="500"
          textAlign="center"
          color="white"
          p={{ xs: 2, sm: 4 }}
        >
          Estamos construyendo el futuro, súmate!
        </Typography>
      </Box>

      <Team />
    </Grid>
  );
};

export default WhoWeAre;
