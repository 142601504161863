import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';

import { BankOfferRequestWithUser } from '@relif/backend';
import PrimaryBtn from 'components/UI/Buttons';
import PaperContainer from 'components/UI/PaperContainer';
import SearchBar from 'components/UI/SearchBar';
import { useAdminListBankOfferRequestsQuery } from 'services/api/admin-bankOfferRequests.api';

import './BankOfferRequestsTable.scss';

const BANK_OFFER_REQUEST_ATTRIBUTES = [
  {
    field: 'id',
    headerName: 'ID',
    width: 60,
  },
  {
    field: 'user.company.name',
    headerName: 'Empresa',
    width: 150,
    valueGetter: (_value: unknown, row: BankOfferRequestWithUser) =>
      (row.user?.company?.name ?? '') + (row.user?.origin ? `(${row.user?.origin})` : ''),
  },
  {
    field: 'userId',
    headerName: 'ID Usuario',
    width: 60,
  },
  {
    field: 'userRut',
    headerName: 'Rut Usuario',
    width: 120,
  },
  {
    field: 'bank',
    headerName: 'Banco',
    width: 170,
  },
  {
    field: 'status',
    headerName: 'Estado',
    valueGetter: (status: string) => {
      if (status === 'created') {
        return 'Creada';
      }
      if (status === 'sent_to_bank') {
        return 'Enviada al banco';
      }
      if (status === 'offer_sent') {
        return 'Oferta enviada';
      }
      if (status === 'user_showed_interest') {
        return 'Usuario mostró interés';
      }
      if (status === 'rejected_by_bank') {
        return 'Rechazada por el banco';
      }
      if (status === 'contact_info_sent_to_bank') {
        return 'Datos de contacto enviados al banco';
      }
      if (status === 'offer_rejected_by_user') {
        return 'Rechazada por el usuario';
      }
      if (status === 'offer_accepted') {
        return 'Oferta aceptada';
      }
      return '';
    },
    width: 200,
  },
  {
    field: 'offerRejectionReason',
    headerName: 'Razón de rechazo del usuario',
    width: 200,
  },
  {
    field: 'sentToBankAt',
    headerName: 'Fecha de envío al banco',
    width: 200,
    valueGetter: (value: string) => {
      if (!value) {
        return '';
      }
      return new Date(value).toLocaleString();
    },
  },
  {
    field: 'bankRepliedAt',
    headerName: 'Fecha de respuesta del banco',
    width: 200,
    valueGetter: (value: string) => {
      if (!value) {
        return '';
      }
      return new Date(value).toLocaleString();
    },
  },
  {
    field: 'userShowedInterestAt',
    headerName: 'Fecha de interés del usuario',
    width: 200,
    valueGetter: (value: string) => {
      if (!value) {
        return '';
      }
      return new Date(value).toLocaleString();
    },
  },
  {
    field: 'sentContactInfoAt',
    headerName: 'Fecha de envío de datos de contacto',
    width: 200,
    valueGetter: (value: string) => {
      if (!value) {
        return '';
      }
      return new Date(value).toLocaleString();
    },
  },
  {
    field: 'offerDecisionAt',
    headerName: 'Fecha de respuesta del usuario',
    width: 200,
    valueGetter: (value: string) => {
      if (!value) {
        return '';
      }
      return new Date(value).toLocaleString();
    },
  },
];

const BankOfferRequestsTable = () => {
  const { data: bankOfferRequests } = useAdminListBankOfferRequestsQuery(null);
  const [filteredBankOfferRequests, setFilteredBankOfferRequests] = useState<BankOfferRequestWithUser[]>(
    bankOfferRequests || []
  );
  const [currentFilter, setCurrentFilter] = useState<string>('');
  const query = new URLSearchParams(window.location.search);
  const [searchTerm, setSearchTerm] = useState<string>(query.get('searchTerm') || '');

  const navigateToUser = (userId: number | null) => {
    if (!userId) {
      return;
    }
    // open the user page in a new tab
    window.open(`/admin/users/?userId=${userId}`);
  };

  const bankOfferRequestsRows = filteredBankOfferRequests.map((bankOfferRequest) => ({
    ...bankOfferRequest,
    userId: bankOfferRequest.userId,
    userRut: bankOfferRequest.user?.rut,
  }));

  const today = new Date();

  const getRowClassName = (row: BankOfferRequestWithUser) => {
    if (currentFilter === 'sent_to_bank') {
      const sentToBankAt = new Date(row.sentToBankAt || '');
      const diff = today.getTime() - sentToBankAt.getTime();
      const days = diff / (1000 * 60 * 60 * 24);
      if (days >= 14) {
        return 'danger-row';
      }
      if (days >= 7) {
        return 'warning-row';
      }
    }
    if (currentFilter === 'offer_sent') {
      const bankRepliedAt = new Date(row.bankRepliedAt || '');
      const diff = today.getTime() - bankRepliedAt.getTime();
      const days = diff / (1000 * 60 * 60 * 24);
      if (days >= 14) {
        return 'danger-row';
      }
      if (days >= 7) {
        return 'warning-row';
      }
    }
    return '';
  };

  useEffect(() => {
    if (currentFilter === 'sent_to_bank') {
      const newFilteredRequests = bankOfferRequests
        ?.filter((request) => request.status === 'sent_to_bank')
        .sort((a, b) => {
          if (a.sentToBankAt && b.sentToBankAt) {
            return new Date(a.sentToBankAt).getTime() - new Date(b.sentToBankAt).getTime();
          }
          return 0;
        });
      setFilteredBankOfferRequests(newFilteredRequests || []);
    } else if (currentFilter === 'offer_sent') {
      const newFilteredRequests = bankOfferRequests
        ?.filter((request) => request.status === 'offer_sent')
        .sort((a, b) => {
          if (a.bankRepliedAt && b.bankRepliedAt) {
            return new Date(a.bankRepliedAt).getTime() - new Date(b.bankRepliedAt).getTime();
          }
          return 0;
        });
      setFilteredBankOfferRequests(newFilteredRequests || []);
    } else {
      setFilteredBankOfferRequests(bankOfferRequests || []);
    }
  }, [currentFilter]);

  useEffect(() => {
    setFilteredBankOfferRequests(bankOfferRequests || []);
  }, [bankOfferRequests]);

  useEffect(() => {
    // get the search term from the URL
    const params = new URLSearchParams(window.location.search);
    const newSearchTerm = params.get('searchTerm') || '';
    setSearchTerm(newSearchTerm);
  }, []);

  return (
    <PaperContainer fullWidth fullHeight>
      <SearchBar
        data={bankOfferRequests || []}
        setFilteredData={setFilteredBankOfferRequests as (data: unknown[]) => void}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      <Box py={2} display="flex" justifyContent="flex-start">
        <PrimaryBtn onClick={() => setCurrentFilter('sent_to_bank')}>Pendientes en banco</PrimaryBtn>
        <PrimaryBtn onClick={() => setCurrentFilter('offer_sent')} sx={{ ml: 2 }}>
          Pendientes con oferta
        </PrimaryBtn>
        <PrimaryBtn onClick={() => setCurrentFilter('')} sx={{ ml: 2 }}>
          Quitar filtros
        </PrimaryBtn>
      </Box>
      {currentFilter !== '' && (
        <>
          <Box
            sx={{
              backgroundColor: 'error.light',
              color: 'Black',
              padding: '8px 16px',
              borderRadius: '4px',
              marginBottom: '16px',
            }}
          >
            <span>
              <strong>Importante:</strong> Los siguientes usuarios llevan más de 14 días sin{' '}
              {currentFilter === 'sent_to_bank' ? 'una respuesta del banco' : 'responder a la oferta'}.
            </span>
          </Box>
          <Box
            sx={{
              backgroundColor: 'warning.light',
              color: 'Black',
              padding: '8px 16px',
              borderRadius: '4px',
              marginBottom: '16px',
            }}
          >
            <span>
              <strong>Importante:</strong> Los siguientes usuarios llevan más de 7 días sin{' '}
              {currentFilter === 'sent_to_bank' ? 'una respuesta del banco' : 'responder a la oferta'}.
            </span>
          </Box>
        </>
      )}
      <DataGrid
        rows={bankOfferRequestsRows as unknown as BankOfferRequestWithUser[]}
        columns={BANK_OFFER_REQUEST_ATTRIBUTES}
        slots={{
          toolbar: GridToolbar,
        }}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        sx={{
          color: 'Black',
          height: 'calc(100vh - 300px)',
          border: 'none',
        }}
        onRowClick={(params) => {
          navigateToUser((params.row as BankOfferRequestWithUser).userId);
        }}
        getRowClassName={(params) => {
          const parsedRow = params.row as unknown as BankOfferRequestWithUser;
          return getRowClassName(parsedRow);
        }}
      />
    </PaperContainer>
  );
};

export default BankOfferRequestsTable;
