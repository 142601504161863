import React, { FC } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Breadcrumbs, Breadcrumb } from '@dscla/ds-breadcrumbs/react';
import useCurrentRoute from 'hooks/useCurrentRoute';

interface BlogBreadCrumbsInterface {
  title: string;
  isHome: boolean;
}

const BlogBreadCrumbs: FC<BlogBreadCrumbsInterface> = ({ title, isHome }) => {
  const { path } = useCurrentRoute();
  const url = path.includes('/blog') ? '/blog' : '/education';
  return (
    <Breadcrumbs>
      <Breadcrumb href={url} label="Todos los artículos">
        Todos los artículos
      </Breadcrumb>
      {!isHome && <Breadcrumb>{title}</Breadcrumb>}
    </Breadcrumbs>
  );
};

export default BlogBreadCrumbs;
