import englishTranslation from './en';
import spanishTranslation from './es';
import { Translation } from './interface';
import portugueseTranslation from './pt';

const translations: { [key: string]: Translation } = {
  es: spanishTranslation,
  en: englishTranslation,
  pt: portugueseTranslation,
};

export default translations;
