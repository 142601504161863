import React, { FC } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import BodyTypography from './components/BodyTypography';
import NewHomeBreadCrumbs from './components/NewHomeBreadCrumbs';
import NewHomePaper from './components/NewHomePaper';

export const MortgagesContent: FC = () => (
  <BodyTypography>
    Un crédito hipotecario es un préstamo a mediano o largo plazo que se otorga para la compra, ampliación, reparación o
    construcción de bienes inmuebles como una vivienda, compra de sitios, oficinas o locales comerciales.
    <Box my={2}>
      La propiedad adquirida queda en garantía o &quot;hipotecada&quot; a favor del Banco para asegurar el cumplimiento
      del crédito y por eso este crédito lleva esa denominación.
    </Box>
    Los plazos a los cuales se otorgan estos créditos son de varios años, lo cual debe ser informado dentro de las
    características del crédito, junto a los otros costos y tasa de interés asociadas.
    <Box my={2}>
      El valor del dividendo no puede superar el 25% de tus ingresos propios o complementados. La renta complementada es
      la suma de tus ingresos con los de un tercero por ejemplo, tu cónyuge, conviviente civil, padre, etc.
    </Box>
  </BodyTypography>
);

const Mortgages: FC = () => (
  <NewHomePaper>
    <NewHomeBreadCrumbs title="Créditos Hipotecarios" isHome={false} />
    <Typography my={2} fontSize="36px" lineHeight="44px" fontWeight="500">
      Créditos Hipotecarios
    </Typography>
    <MortgagesContent />
  </NewHomePaper>
);
export default Mortgages;
