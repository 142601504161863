import React, { FC, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { EditIcon } from 'assets/icons';
import { EditBtn } from 'components/UI/Buttons';
import SearchBar from 'components/UI/SearchBar';
import { useGetCompaniesQuery } from 'services/api/companies.api';
import { Company } from 'services/types/companies';

interface Props {
  handleEditCompany: (company: Company) => void;
  refetchFlag: boolean;
}

const CompaniesTable: FC<Props> = ({ handleEditCompany, refetchFlag }) => {
  const { data, refetch } = useGetCompaniesQuery(null);
  const [filteredCompanies, setFilteredCompanies] = useState(data || []);

  useEffect(() => {
    refetch().catch((e) => console.error(e)); // eslint-disable-line no-console
  }, [refetchFlag]);

  useEffect(() => {
    if (data) {
      setFilteredCompanies(data);
    }
  }, [data]);

  return (
    <Box>
      <Box display="flex" justifyContent="flex-start" mb={2} alignItems="center">
        <Typography variant="h3">Lista de empresas</Typography>
        <SearchBar data={data || []} setFilteredData={setFilteredCompanies as (data: unknown[]) => void} />
      </Box>
      <TableContainer
        sx={{ height: 'calc(100vh - 300px)', overflow: 'auto', backgroundColor: 'white', borderRadius: 4, p: 2 }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Empresa</TableCell>
              <TableCell>Url</TableCell>
              <TableCell>Dia de Pago</TableCell>
              <TableCell>Url del Logo</TableCell>
              <TableCell>Software de RRHH</TableCell>
              <TableCell>Link RRHH</TableCell>
              <TableCell>Creado en</TableCell>
              <TableCell>Actualizado en</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCompanies?.map((company) => (
              <TableRow key={company.id}>
                <TableCell>{company.id}</TableCell>
                <TableCell>{company.name}</TableCell>
                <TableCell>{company.url}</TableCell>
                <TableCell
                  sx={{
                    maxWidth: '200px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {company.paymentDay}
                </TableCell>
                <TableCell
                  sx={{
                    maxWidth: '200px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {company.logoUrl}
                </TableCell>
                <TableCell
                  sx={{
                    maxWidth: '200px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {company.humanResourcesSoftwareName}
                </TableCell>
                <TableCell
                  sx={{
                    maxWidth: '200px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {company.humanResourcesSoftwareUrl}
                </TableCell>
                <TableCell>{new Date(company.createdAt).toLocaleString('es-CL')}</TableCell>
                <TableCell>{new Date(company.createdAt).toLocaleString('es-CL')}</TableCell>
                <TableCell>
                  <EditBtn onClick={() => handleEditCompany(company)}>
                    <EditIcon />{' '}
                  </EditBtn>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CompaniesTable;
