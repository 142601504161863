import React, { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Box, Grid, IconButton, Typography } from '@mui/material';

import { WhatsAppIcon } from 'assets/icons';
import { useGetConversationMutation } from 'services/api/whatsApp.api';
import { dateFormat } from 'services/format/dateFormat';

interface Props {
  userId: number;
}

const UserMessagesSummary: FC<Props> = ({ userId }) => {
  const [getConversation, { data: conversationData }] = useGetConversationMutation();

  useEffect(() => {
    if (userId) {
      getConversation(userId).catch((err) => console.error(err)); // eslint-disable-line no-console
    }
  }, [userId]);

  const specialStatuses = [
    'failed',
    'deleted',
    'warning',
    'functionSuggestion',
    'functionResponse',
    'suggestion',
    'neverSent',
  ];
  const validMessages = conversationData?.filter((message) => !specialStatuses.includes(message.status)) || [];
  let messagesToDisplay = [...validMessages];

  if (messagesToDisplay.length > 10) {
    // Display all the templates
    messagesToDisplay = messagesToDisplay.filter((message) => !!message.templateName);
    if (messagesToDisplay.length < 10) {
      // Display messages until we reach 10
      messagesToDisplay = messagesToDisplay.concat(
        validMessages.filter((message) => !message.templateName).slice(messagesToDisplay.length - 10)
      );
    }
    messagesToDisplay.sort((a, b) => a.id - b.id);
  }

  if (conversationData === undefined) {
    return <Typography variant="body1">Cargando resumen de mensajes del usuario...</Typography>;
  }

  return (
    <Box p={2} sx={{ backgroundColor: 'background.paper' }} textAlign={{ xs: 'left' }} borderRadius={4}>
      <Grid>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography variant="h3">Resumen de mensajes</Typography>
          <Typography variant="body1" pl={1}>
            ({messagesToDisplay.length}/{validMessages.length})
          </Typography>
          <IconButton sx={{ color: 'text.secondary' }} aria-label="chat">
            <Link to={`/admin/chat?userId=${userId}`} style={{ color: 'inherit' }}>
              <WhatsAppIcon />
            </Link>
          </IconButton>
        </Box>
        {conversationData.length === 0 && <Typography variant="body1">Este usuario no tiene mensajes</Typography>}
        {messagesToDisplay?.map((message) => (
          <Box display="flex" flexDirection="row" key={message.id}>
            <Typography flex={1} sx={{ fontWeight: 'bold' }}>
              {message.role}:
            </Typography>
            {message.templateName ? (
              <Typography flex={10} sx={{ textDecoration: 'underline' }}>
                Template: {message.templateName}
              </Typography>
            ) : (
              <Typography flex={10}>{message.fileName ?? message.text}</Typography>
            )}
            <Typography flex={1} sx={{ color: 'grey', fontFamily: 'monospace' }}>
              ({dateFormat(message.createdAt)})
            </Typography>
          </Box>
        ))}
      </Grid>
    </Box>
  );
};

export default UserMessagesSummary;
