/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState, useRef, FC } from 'react';

import MuiAlert, { AlertProps, AlertColor } from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// @ts-ignore
import { Button as CajaButton } from '@dscla/ds-button/react';
// @ts-ignore
import { MessageAlerta } from '@dscla/ds-message-alerta/react';
import { AddIcon } from 'assets/icons';
import PrimaryBtn from 'components/UI/Buttons';
import PaperContainer from 'components/UI/PaperContainer';
import StyledTableCell from 'components/UI/StyledTableCell';
import useIsCajaLosAndesTheme from 'hooks/useIsCajaLosAndesTheme';
import InitialPaper from 'pages/Debts/components/InitialPaper';
import FinancialStatusModal from 'pages/FinancialHealth/EditFinancialStatusModal';
import { useGetFinancialStatementQuery } from 'services/api/financialStatements.api';
import { useGetGoalsMutation, useGetGoalTemplatesQuery } from 'services/api/goals.api';
import { Goal } from 'services/types/goals';

import AddGoalModal from './AddGoalModal';
import GoalCard from './GoalCard';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface AlertInterface {
  severity: AlertColor;
  text: string;
}

interface Props {
  handleGoalsRefetch: () => void;
}

const Goals: FC<Props> = ({ handleGoalsRefetch }) => {
  const [goals, setGoals] = useState<Goal[]>([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [openNewObjective, setOpenNewObjective] = useState(false);
  const [alert, setAlert] = useState<AlertInterface>({
    severity: 'success',
    text: '',
  });
  const [pendingDebtsAlertOpen, setPendingDebtsAlertOpen] = useState(false);
  const [noIncomeAlertOpen, setNoIncomeAlertOpen] = useState(false);
  const [editFinancialStatusModalOpen, setEditFinancialStatusModalOpen] = useState(false);
  const [getGoals, { data, isLoading }] = useGetGoalsMutation();
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  const { data: financialStatementData } = useGetFinancialStatementQuery(null);
  const {
    data: goalTemplatesData,
    isLoading: isLoadingGoalTemplates,
    refetch: refetchGoalTemplates,
  } = useGetGoalTemplatesQuery(null);
  const isCajaTheme = useIsCajaLosAndesTheme();

  useEffect(() => {
    const getGoalsData = async () => {
      await getGoals(null);
    };
    // eslint-disable-next-line no-void
    void getGoalsData();
  }, []);

  useEffect(() => {
    if (data) {
      setGoals(data);
    }
  }, [data]);

  const addGoal = (newGoal: Goal) => {
    setGoals([...goals, newGoal]);
    handleGoalsRefetch();
  };

  const handleDeleteGoal = (id: number) => {
    setGoals((prevGoals) => prevGoals.filter((goal) => goal.id !== id));
    handleGoalsRefetch();
  };
  const handleAlert = (severity: AlertColor, text: string) => {
    setOpenAlert(true);
    setAlert({ severity, text });
  };

  useEffect(() => {
    if (goalTemplatesData) {
      const hasPendingDebts = goalTemplatesData.templates.some((template) => {
        return template.isUnpaidDebt;
      });
      setPendingDebtsAlertOpen(hasPendingDebts);
      const hasNoIncome = !goalTemplatesData.lastIncome;
      setNoIncomeAlertOpen(hasNoIncome);
    }
  }, [goalTemplatesData]);

  const prevOpenNewObjective = useRef(openNewObjective);

  // refetch goal templates when goals modal is closed
  useEffect(() => {
    if (prevOpenNewObjective.current === true && openNewObjective === false) {
      refetchGoalTemplates().catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
    }
    prevOpenNewObjective.current = openNewObjective;
  }, [openNewObjective]);

  const prevOpenEditFinancialStatusModal = useRef(editFinancialStatusModalOpen);

  // refetch goal templates when edit financial status modal is closed
  useEffect(() => {
    if (prevOpenEditFinancialStatusModal.current === true && editFinancialStatusModalOpen === false) {
      refetchGoalTemplates().catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
    }
    prevOpenEditFinancialStatusModal.current = editFinancialStatusModalOpen;
  }, [editFinancialStatusModalOpen]);

  const pendingDebtsAlert = isCajaTheme ? (
    pendingDebtsAlertOpen && (
      <MessageAlerta color="advertencia">
        <span slot="label">Tienes deudas en mora, crea un objetivo para pagarlas.</span>
      </MessageAlerta>
    )
  ) : (
    <Alert
      severity="warning"
      sx={{ width: '100%', backgroundColor: '#FF8D3B33', mb: 2, color: '#CF5C09' }}
      onClose={() => {
        setPendingDebtsAlertOpen(false);
      }}
    >
      Tienes deudas en mora,{' '}
      <Button
        onClick={() => setOpenNewObjective(true)}
        sx={{
          color: '#CF5C09',
          fontWeight: 'bold',
          textTransform: 'none',
          fontSize: '16px',
          textDecoration: 'underline',
        }}
      >
        crea un objetivo
      </Button>
      para pagarlas.
    </Alert>
  );

  const noIncomeAlert = isCajaTheme ? (
    noIncomeAlertOpen && (
      <MessageAlerta color="advertencia">
        <span slot="label">
          No tienes ingresos registrados, ingresa esos datos para poder ayudarte con objetivos personalizados.
        </span>
        <div slot="footer">
          <CajaButton onClick={() => setEditFinancialStatusModalOpen(true)} variant="secundario">
            Ingresar datos
          </CajaButton>
        </div>
      </MessageAlerta>
    )
  ) : (
    <Alert
      severity="warning"
      sx={{ width: '100%', backgroundColor: '#FF8D3B33', mb: 2, color: '#CF5C09' }}
      onClose={() => {
        setNoIncomeAlertOpen(false);
      }}
    >
      No tienes ingresos registrados,{' '}
      <Button
        onClick={() => setEditFinancialStatusModalOpen(true)}
        sx={{
          color: '#CF5C09',
          fontWeight: 'bold',
          textTransform: 'none',
          fontSize: '16px',
          textDecoration: 'underline',
        }}
      >
        ingresa esos datos
      </Button>
      para poder ayudarte con objetivos personalizados.
    </Alert>
  );

  if (!data || goals.length === 0 || !goalTemplatesData) {
    return (
      <>
        <InitialPaper
          button={
            isCajaTheme ? (
              <CajaButton
                onClick={() => setOpenNewObjective(true)}
                behaviour="fluido"
                disabled={isLoadingGoalTemplates}
              >
                <span slot="start">
                  <AddIcon />
                </span>
                Agregar Objetivo
              </CajaButton>
            ) : (
              <PrimaryBtn
                startIcon={<AddIcon />}
                onClick={() => setOpenNewObjective(true)}
                disabled={isLoadingGoalTemplates}
              >
                Agregar Objetivo
              </PrimaryBtn>
            )
          }
          width={2000}
          height={pendingDebtsAlertOpen || noIncomeAlertOpen ? 500 : 400}
          title="Aún no has creado un objetivo"
          isLoading={isLoading}
          paragraph="Crea un objetivo para empezar a ahorrar de manera planificada."
        >
          {pendingDebtsAlertOpen && pendingDebtsAlert}
          {noIncomeAlertOpen && noIncomeAlert}
        </InitialPaper>
        {goalTemplatesData && (
          <>
            <AddGoalModal
              addGoal={addGoal}
              handleAlert={handleAlert}
              open={openNewObjective}
              setOpen={setOpenNewObjective}
              templatesData={goalTemplatesData}
              prevGoal={null}
              handleUpdateGoal={() => {}}
            />
            <FinancialStatusModal
              open={editFinancialStatusModalOpen}
              setModalOpen={setEditFinancialStatusModalOpen}
              financialStatementToEdit={financialStatementData || null}
              setFinancialStatement={() => {}}
            />
          </>
        )}
      </>
    );
  }
  return (
    <PaperContainer
      fullWidth
      fullHeight
      title="Mis objetivos"
      actionBtn={
        isCajaTheme ? (
          <CajaButton onClick={() => setOpenNewObjective(true)} behaviour="fluido" disabled={isLoadingGoalTemplates}>
            <span slot="start">
              <AddIcon />
            </span>
            Agregar Objetivo
          </CajaButton>
        ) : (
          <PrimaryBtn
            startIcon={<AddIcon />}
            onClick={() => setOpenNewObjective(true)}
            disabled={isLoadingGoalTemplates}
          >
            Agregar Objetivo
          </PrimaryBtn>
        )
      }
    >
      <Snackbar open={openAlert} autoHideDuration={4000} onClose={() => setOpenAlert(false)}>
        <Alert onClose={() => setOpenAlert(false)} severity={alert.severity} sx={{ width: '100%' }}>
          {alert.text}
        </Alert>
      </Snackbar>

      {pendingDebtsAlertOpen && pendingDebtsAlert}
      {noIncomeAlertOpen && noIncomeAlert}
      <TableContainer sx={{ mb: 3 }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Nombre</StyledTableCell>
              <StyledTableCell align="left">Monto</StyledTableCell>
              <StyledTableCell align="left">Plazo (meses)</StyledTableCell>
              <StyledTableCell align="left">Ahorro mensual</StyledTableCell>
              <StyledTableCell align="left" />
            </TableRow>
          </TableHead>
          <TableBody>
            {goals?.map((goal) => (
              <GoalCard
                goal={goal}
                key={`Goal ${goal.id}`}
                filterGoal={handleDeleteGoal}
                handleAlert={handleAlert}
                templatesData={goalTemplatesData}
                handleGoalsRefetch={handleGoalsRefetch}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {goalTemplatesData && (
        <AddGoalModal
          addGoal={addGoal}
          handleAlert={handleAlert}
          open={openNewObjective}
          setOpen={setOpenNewObjective}
          templatesData={goalTemplatesData}
          prevGoal={null}
          handleUpdateGoal={() => {}}
        />
      )}
      <FinancialStatusModal
        open={editFinancialStatusModalOpen}
        setModalOpen={setEditFinancialStatusModalOpen}
        financialStatementToEdit={financialStatementData || null}
        setFinancialStatement={() => {}}
      />
    </PaperContainer>
  );
};

export default Goals;
