import React, { FC, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';

import { Box, Checkbox, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';

import AlertDialog from 'components/UI/AlertDialog';
import PrimaryBtn, { BlackBtn } from 'components/UI/Buttons';
import LoadingOverlay from 'components/UI/LoadingOverlay';
import { useCreateCompanyMutation, useEditCompanyMutation, useGetCompaniesQuery } from 'services/api/companies.api';
import { Company, CompanyDraft } from 'services/types/companies';

interface Props {
  company: Company | null;
  handleBack: () => void;
}

const EditCompany: FC<Props> = ({ company, handleBack }) => {
  const [editedCompany, setEditedCompany] = useState<CompanyDraft>(
    company
      ? {
          name: company.name,
          paymentDay: company.paymentDay,
          url: company.url === null ? undefined : company.url,
          logoUrl: company.logoUrl === null ? undefined : company.logoUrl,
          humanResourcesSoftwareName:
            company.humanResourcesSoftwareName === null ? undefined : company.humanResourcesSoftwareName,
          humanResourcesSoftwareUrl:
            company.humanResourcesSoftwareUrl === null ? undefined : company.humanResourcesSoftwareUrl,
          humanResourcesSoftwareTokenName:
            company.humanResourcesSoftwareTokenName === null ? undefined : company.humanResourcesSoftwareTokenName,
          disableSavingsFeature: !!company.disableSavingsFeature,
          disableStraightToDebtsFeature: !!company.disableStraightToDebtsFeature,
          userCountLimit: company.userCountLimit,
          rut: company.rut,
          masterCompanyId: company.masterCompanyId,
        }
      : { name: '', paymentDay: 1 }
  );
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);

  const { data: companies } = useGetCompaniesQuery(null);
  const [editCompany, { isLoading: editLoading, isSuccess: editSuccess, isError: editError }] =
    useEditCompanyMutation();
  const [createCompany, { isLoading: createLoading, isSuccess: createSuccess, isError: createError }] =
    useCreateCompanyMutation();

  const handleSave = () => {
    if (company) {
      editCompany({ company: editedCompany, id: company.id }).catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
    } else {
      createCompany(editedCompany).catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
    }
  };

  useEffect(() => {
    if (editSuccess || createSuccess) {
      handleBack();
    }
  }, [editSuccess, createSuccess]);

  useEffect(() => {
    if (editError || createError) {
      setErrorDialogOpen(true);
    }
  }, [editError, createError]);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <LoadingOverlay visible={editLoading || createLoading} />
      <AlertDialog
        openDialog={errorDialogOpen}
        setOpenDialog={setErrorDialogOpen}
        header="Error al editar empresa"
        confirmMsg="aceptar"
        msg="Ha ocurrido un error al editar la empresa, por favor intente nuevamente"
        confirmAction={() => setErrorDialogOpen(false)}
      />
      <Grid container width="100%">
        <Grid item xs={12}>
          <Typography variant="h6">{company ? 'Editar' : 'Nueva'} empresa</Typography>
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          <TextField
            value={editedCompany.name}
            onChange={(e) => {
              setEditedCompany({ ...editedCompany, name: e.target.value });
            }}
            fullWidth
            label="Nombre"
            required
          />
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          <NumericFormat
            value={editedCompany.paymentDay}
            valueIsNumericString
            decimalScale={0}
            isAllowed={(values) => {
              const { floatValue } = values;
              return floatValue !== undefined && floatValue <= 28 && floatValue >= 1;
            }}
            onValueChange={(value) => {
              setEditedCompany({ ...editedCompany, paymentDay: value.floatValue || 1 });
            }}
            fullWidth
            label="Dia de pago, debe ser entre 1 y 28 (Si es entre 29-31 usar 1)"
            customInput={TextField}
            required
          />
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          <TextField
            value={editedCompany.url}
            onChange={(e) => {
              setEditedCompany({ ...editedCompany, url: e.target.value });
            }}
            fullWidth
            label="Url del mail de la empresa, por ejemplo: @relif.cl"
          />
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          <TextField
            value={editedCompany.logoUrl}
            onChange={(e) => {
              setEditedCompany({ ...editedCompany, logoUrl: e.target.value });
            }}
            fullWidth
            label="Url del logo de la empresa"
          />
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          <Select
            value={editedCompany.humanResourcesSoftwareName || ''}
            onChange={(e) => {
              setEditedCompany({ ...editedCompany, humanResourcesSoftwareName: e.target.value });
            }}
            fullWidth
            label="Software de RRHH"
          >
            <option value="buk">Buk</option>
          </Select>
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          <TextField
            value={editedCompany.humanResourcesSoftwareUrl}
            onChange={(e) => {
              setEditedCompany({ ...editedCompany, humanResourcesSoftwareUrl: e.target.value });
            }}
            fullWidth
            label="Url del software de RRHH"
          />
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          <TextField
            value={editedCompany.humanResourcesSoftwareTokenName}
            onChange={(e) => {
              setEditedCompany({ ...editedCompany, humanResourcesSoftwareTokenName: e.target.value });
            }}
            fullWidth
            label="Nombre del token del software de RRHH"
          />
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          <TextField
            value={editedCompany.rut}
            onChange={(e) => {
              setEditedCompany({ ...editedCompany, rut: e.target.value });
            }}
            fullWidth
            label="Rut de la empresa"
          />
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          <Typography variant="body1">Desactivar funcionalidad de ahorro</Typography>
          <Checkbox
            checked={!!editedCompany.disableSavingsFeature}
            onChange={(e) => setEditedCompany({ ...editedCompany, disableSavingsFeature: e.target.checked })}
          />
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          <TextField
            value={editedCompany.userCountLimit || 0}
            onChange={(e) => {
              setEditedCompany({ ...editedCompany, userCountLimit: parseInt(e.target.value, 10) });
            }}
            fullWidth
            label="Limite de usuarios"
            type="number"
          />
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          <Typography variant="body1">Desactivar entrar directo a deudas</Typography>
          <Checkbox
            checked={!!editedCompany.disableStraightToDebtsFeature}
            onChange={(e) => setEditedCompany({ ...editedCompany, disableStraightToDebtsFeature: e.target.checked })}
          />
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          <Typography variant="body1">Empresa principal</Typography>

          <Select
            value={editedCompany.masterCompanyId || 0}
            onChange={(e) => {
              if (e.target.value === 'null') {
                setEditedCompany({ ...editedCompany, masterCompanyId: null });
                return;
              }
              setEditedCompany({ ...editedCompany, masterCompanyId: parseInt(String(e.target.value), 10) });
            }}
            fullWidth
            label="Empresa principal"
          >
            {companies?.map((c) => (
              <MenuItem key={c.id} value={c.id}>
                {c.name}
              </MenuItem>
            ))}
            <MenuItem value="null">Ninguna</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <BlackBtn onClick={handleBack} sx={{ mx: 1 }}>
          Volver
        </BlackBtn>
        <PrimaryBtn onClick={handleSave} disabled={editedCompany.name === ''} sx={{ mx: 1 }}>
          Guardar
        </PrimaryBtn>
      </Box>
    </Box>
  );
};

export default EditCompany;
