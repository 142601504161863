import { DateFilter, NPSResponse, NoActionResponse } from 'services/types/surveys';

import baseApi from './baseApi';

const whatsAppApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getNPSResults: build.mutation({
      query: (body: DateFilter) => ({
        url: '/admin/whatsAppFlowInstance/nps',
        method: 'POST',
        body,
      }),
      transformResponse: (response: NPSResponse) => response,
    }),
    getNoActionResults: build.mutation({
      query: (body: DateFilter) => ({
        url: '/admin/whatsAppFlowInstance/no-action',
        method: 'POST',
        body,
      }),
      transformResponse: (response: NoActionResponse) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useGetNPSResultsMutation, useGetNoActionResultsMutation } = whatsAppApi;
