import { BlogPage, BlogPartialPage } from '../types/notion';
import baseApi from './baseApi';

const notionApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getDatabasePages: build.query({
      query: () => ({
        url: '/blogs/partial',
        method: 'GET',
      }),
      transformResponse: (response: BlogPartialPage[]): BlogPartialPage[] => response,
    }),
    getPage: build.query({
      query: (slug: string) => ({
        url: `/blogs/${slug}`,
        method: 'GET',
      }),
      transformResponse: (response: BlogPage): BlogPage => response,
    }),
    updateBlog: build.mutation({
      query: () => ({
        url: '/admin/blogs/update',
        method: 'PUT',
        body: {},
      }),
      transformResponse: (response: unknown): unknown => response,
    }),
  }),
});

export const { useGetDatabasePagesQuery, useGetPageQuery, useUpdateBlogMutation } = notionApi;
