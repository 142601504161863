import { AllowedUserDraft, AllowedUser } from '../types/allowedUsers';
import baseApi from './baseApi';

const allowedUserAPI = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllowedUsers: build.query({
      query: () => ({
        url: '/allowedUsers',
        method: 'GET',
      }),
      transformResponse: (response: AllowedUser[]): AllowedUser[] => response,
    }),
    createAllowedUser: build.mutation({
      query: (body: AllowedUserDraft) => ({
        url: '/allowedUsers',
        method: 'POST',
        body,
      }),
      transformResponse: (response: AllowedUser): AllowedUser => response,
    }),
    deleteAllowedUser: build.mutation({
      query: (id: number) => ({
        url: `/allowedUsers/${id}`,
        method: 'DELETE',
      }),
      transformResponse: (response: AllowedUser): AllowedUser => response,
    }),
  }),
  overrideExisting: false,
});

export const { useGetAllowedUsersQuery, useCreateAllowedUserMutation, useDeleteAllowedUserMutation } = allowedUserAPI;
