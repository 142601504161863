import React, { useState } from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import { PrimaryBtn, BlackBtn, CloseButton } from './Buttons';

interface BannerInterface {
  title: string;
  description: string;
  buttonText: string | null;
  onConfirmationClick: () => void;
  show: boolean;
  setShow: (show: boolean) => void;
}

const Banner: React.FC<BannerInterface> = ({ title, description, buttonText, onConfirmationClick, show, setShow }) => {
  const [openBannerConfirmation, setOpenBannerConfirmation] = useState(false);

  const handleConfirm = () => {
    onConfirmationClick();
    setShow(false);
    setOpenBannerConfirmation(false);
  };

  if (show) {
    return (
      <>
        <Dialog open={openBannerConfirmation} onClose={() => setOpenBannerConfirmation(false)}>
          <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ whiteSpace: 'pre-line' }}>{description}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <PrimaryBtn onClick={handleConfirm} autoFocus color="error">
              Confirmar Inscripción
            </PrimaryBtn>
          </DialogActions>
        </Dialog>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: 'info.main',
            paddingY: '10px',
            height: '100px',
            borderRadius: '10px',
            m: 1,
            mt: { xs: '81px', md: '81px', lg: 1 },
            color: 'white',
          }}
        >
          <div />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box px={1} pt={1}>
              <InfoOutlinedIcon fontSize="large" color="inherit" />
            </Box>
            <Typography
              fontSize={{ xs: '19px', md: '24px' }}
              fontWeight={600}
              color="white"
              width="100%"
              textAlign="left"
            >
              {title}
            </Typography>
            {buttonText && (
              <BlackBtn sx={{ backgroundColor: 'white', mr: 1 }} onClick={() => setOpenBannerConfirmation(true)}>
                {buttonText}
              </BlackBtn>
            )}
          </Box>
          <CloseButton sx={{ mr: 1, color: 'white' }} onClick={() => setShow(false)} />
        </Box>
      </>
    );
  }
  return null;
};

export default Banner;
