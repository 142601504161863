import { FloidCMFResponse, floidInstitutionsMapping } from '@relif/floid-helpers';
import { DebtToCategorize } from 'services/types/debt';

const parseCMFDirectDebt = (CMFResponse: FloidCMFResponse): DebtToCategorize[] => {
  return CMFResponse.data.directDebt.map((debt) => {
    const { name, type } = floidInstitutionsMapping(debt.institution);
    return {
      institutionName: name,
      institutionType: type,
      debtType: debt.creditType === 'Vivienda' ? 'crédito hipotecario' : 'crédito de consumo',
      totalAmount: Number(debt.total),
      totalMonthlyPayment: 0,
      source: `Floid-CMF`,
    };
  });
};

export default parseCMFDirectDebt;
