import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Grid, Select, TextField, Typography, Checkbox } from '@mui/material';

import { BankOfferRequestAttributes, BankOfferRequestStatus, BankOfferRequestVia } from '@relif/backend';
import PrimaryBtn from 'components/UI/Buttons';
import LoadingOverlay from 'components/UI/LoadingOverlay';
import PaperContainer from 'components/UI/PaperContainer';
import SectionHeader from 'components/layout/SectionHeader';
import {
  useAdminGetBankOfferRequestQuery,
  useAdminUpdateBankOfferRequestMutation,
} from 'services/api/admin-bankOfferRequests.api';

interface DateInputProps {
  value: BankOfferRequestAttributes['sentToBankAt'];
  handleChange: (value: BankOfferRequestAttributes['sentToBankAt']) => void;
}

const DateInput = ({ value, handleChange }: DateInputProps) => {
  const originalValue = useRef(value).current;
  const [dateValue, setDateValue] = useState<Date | null>(value ? new Date(value) : null);
  const handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setDateValue(null);
      handleChange(null);
    } else {
      setDateValue(originalValue ? new Date(originalValue) : new Date());
      handleChange(originalValue ? new Date(originalValue) : new Date());
    }
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = new Date(e.target.value);
    setDateValue(newDate);
    handleChange(newDate);
  };
  return (
    <Box display="flex" alignItems="center">
      <Box display="flex" flexDirection="column">
        <Typography variant="h5" textAlign="center">
          No definida
        </Typography>
        <Checkbox checked={dateValue === null} onChange={handleCheckBoxChange} />
      </Box>
      <TextField
        disabled={dateValue === null}
        type="date"
        value={(dateValue ?? new Date()).toISOString().split('T')[0]}
        onChange={onChange}
        fullWidth
      />
    </Box>
  );
};

const BankOfferRequest = () => {
  const [requestDraft, setRequestDraft] = useState<BankOfferRequestAttributes | null>(null);
  const { id } = useParams<{ id: string }>();

  const {
    data: request,
    refetch: refetchRequest,
    isFetching: isRequestLoading,
  } = useAdminGetBankOfferRequestQuery(Number(id));
  const [updateRequest, { data: updatedRequest, isLoading: isRequestUpdating }] =
    useAdminUpdateBankOfferRequestMutation();

  const navigate = useNavigate();
  if (!id) {
    navigate('/bank-offer-requests');
  }

  const handleSave = () => {
    if (requestDraft) {
      updateRequest(requestDraft).catch((e) => console.log(e)); // eslint-disable-line no-console
    }
  };

  useEffect(() => {
    if (request) {
      setRequestDraft(request);
    }
  }, [request]);

  useEffect(() => {
    if (updatedRequest) {
      refetchRequest().catch((e) => console.log(e)); // eslint-disable-line no-console
    }
  }, [updatedRequest]);

  return (
    <Box>
      <SectionHeader
        text="Solicitud de refinanciamiento"
        button={<PrimaryBtn onClick={() => navigate(-1)}>Volver</PrimaryBtn>}
      />
      <LoadingOverlay visible={isRequestLoading || isRequestUpdating} />
      {requestDraft && (
        <PaperContainer fullWidth>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h6">Id</Typography>
              <TextField value={requestDraft.id} disabled fullWidth />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">Banco</Typography>
              <TextField value={requestDraft.bank} disabled fullWidth />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">Agente</Typography>
              <TextField
                value={requestDraft.bankAgent}
                fullWidth
                onChange={(e) => setRequestDraft({ ...requestDraft, bankAgent: e.target.value })}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">Vía de envío</Typography>
              <Select
                native
                value={requestDraft.sentVia}
                onChange={(e) => setRequestDraft({ ...requestDraft, sentVia: e.target.value as BankOfferRequestVia })}
                fullWidth
              >
                <option value="email">Email</option>
                <option value="sftp">SFTP</option>
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">Estado</Typography>
              <Select
                native
                value={requestDraft.status}
                onChange={(e) => setRequestDraft({ ...requestDraft, status: e.target.value as BankOfferRequestStatus })}
                fullWidth
              >
                <option value="created">Creada</option>
                <option value="sent_to_bank">Enviada al banco</option>
                <option value="rejected_by_bank">Rechazada por el banco</option>
                <option value="offer_sent">Oferta enviada</option>
                <option value="user_showed_interest">Usuario mostró interés</option>
                <option value="contact_info_sent_to_bank">Datos de contacto enviados al banco</option>
                <option value="offer_accepted">Oferta aceptada</option>
                <option value="offer_rejected_by_user">Oferta rechazada por el usuario</option>
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">Fecha de envío al banco</Typography>
              <DateInput
                value={requestDraft.sentToBankAt}
                handleChange={(date) => setRequestDraft({ ...requestDraft, sentToBankAt: date })}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">Fecha de respuesta del banco</Typography>
              <DateInput
                value={requestDraft.bankRepliedAt}
                handleChange={(date) => setRequestDraft({ ...requestDraft, bankRepliedAt: date })}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">Fecha de interés del usuario</Typography>
              <DateInput
                value={requestDraft.userShowedInterestAt}
                handleChange={(date) => setRequestDraft({ ...requestDraft, userShowedInterestAt: date })}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">Fecha de envío de datos de contacto</Typography>
              <DateInput
                value={requestDraft.sentContactInfoAt}
                handleChange={(date) => setRequestDraft({ ...requestDraft, sentContactInfoAt: date })}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">Fecha de resolución</Typography>
              <DateInput
                value={requestDraft.offerDecisionAt}
                handleChange={(date) => setRequestDraft({ ...requestDraft, offerDecisionAt: date })}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">Razón de rechazo del banco</Typography>
              <TextField
                value={requestDraft.bankRejectionReason}
                onChange={(e) => setRequestDraft({ ...requestDraft, bankRejectionReason: e.target.value })}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">Razón de rechazo del usuario</Typography>
              <TextField
                value={requestDraft.offerRejectionReason}
                onChange={(e) => setRequestDraft({ ...requestDraft, offerRejectionReason: e.target.value })}
                fullWidth
              />
            </Grid>
          </Grid>
          <Box mt={2}>
            <PrimaryBtn onClick={handleSave}>Guardar</PrimaryBtn>
          </Box>
        </PaperContainer>
      )}
    </Box>
  );
};

export default BankOfferRequest;
