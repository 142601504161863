import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Grid, Menu, MenuItem, Typography } from '@mui/material';

import { ListIcon, RefreshIcon } from 'assets/icons';
import PrimaryBtn, { EditBtn } from 'components/UI/Buttons';
import PaperContainer from 'components/UI/PaperContainer';
import SectionHeader from 'components/layout/SectionHeader';
import AIAssistant from 'pages/WelcomePanel/components/AIAssistant';
import { useGetCompanyMetricsSummaryQuery } from 'services/api/companymetrics.api';
import { useGetNPSResultsMutation } from 'services/api/surveys.api';
import { useGetWhatsAppUsersQuery } from 'services/api/whatsApp.api';
import { User } from 'services/types/user';

const MENU_ITEMS = [
  {
    label: 'Ver lista de usuarios',
    path: '/admin/users',
  },
  {
    label: 'Ver lista de deudas',
    path: '/admin/debts',
  },
  {
    label: 'Ver lista de usuarios permitidos',
    path: '/admin/allowed-users',
  },
  {
    label: 'Ver constantes',
    path: '/admin/constants',
  },
  {
    label: 'Ver empresas',
    path: '/admin/companies',
  },
  {
    label: 'Administrador de OpenAI',
    path: '/admin/openAI',
  },
  {
    label: 'Métricas',
    path: '/admin/metrics',
  },
  {
    label: 'Chat de WhatsApp',
    path: '/admin/chat',
  },
  {
    label: 'Ver Calculadoras',
    path: '/admin/roi-calculators',
  },
  {
    label: 'Ver Resultados de Encuestas',
    path: '/admin/survey-results',
  },
  {
    label: 'Debt Lab',
    path: '/admin/debt-lab',
  },
  {
    label: 'Subir ofertas de bancos',
    path: '/admin/bank-offers',
  },
  {
    label: 'Usuarios activos',
    path: '/admin/active-users',
  },
  {
    label: 'Solicitudes de ofertas',
    path: '/admin/bank-offer-requests',
  },
  {
    label: 'Analizador de Base de Datos',
    path: '/admin/db-reader',
  },
];

const Admin = () => {
  useState<User | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { data: metricsSummary } = useGetCompanyMetricsSummaryQuery(null);
  const { data: whatsAppUsers, refetch: getWhatsAppUsers } = useGetWhatsAppUsersQuery(null);
  const [getNPS, { data: NPSResults }] = useGetNPSResultsMutation();

  useEffect(() => {
    getNPS({}).catch((e) => console.error(e)); // eslint-disable-line no-console
  }, []);

  const usersWithUnreadMessages = whatsAppUsers?.filter(
    (user) => (user.isLastMessageFromUser && user.lastMessageStatus !== 'read') || user.read === false
  );

  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box height="100%">
      <SectionHeader
        text="Hola administrador!"
        button={
          <PrimaryBtn
            id="basic-button"
            aria-controls="menu"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            startIcon={<ListIcon />}
          >
            Menú
          </PrimaryBtn>
        }
      />
      <Box
        sx={{
          maxHeight: '80vh',
          overflow: 'auto',
        }}
      >
        <Menu
          id="menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {MENU_ITEMS.map((item) => (
            <MenuItem
              key={item.path}
              onClick={() => {
                navigate(item.path);
                handleClose();
              }}
            >
              {item.label}
            </MenuItem>
          ))}
        </Menu>
        <Grid container>
          <Grid item xs={12} p={1}>
            <PaperContainer title="Asistente virtual 🤖" fullWidth>
              <AIAssistant />
            </PaperContainer>
          </Grid>
          {metricsSummary && (
            <>
              <Grid item xs={12} md={4} p={1}>
                <PaperContainer title="Usuarios Activos" fullWidth fullHeight>
                  <Typography variant="h1" mb={1} sx={{ width: '100%' }} color="info.main" textAlign="center">
                    {metricsSummary.activeUsers || 0}
                  </Typography>
                  <Typography variant="h3" mb={1} sx={{ width: '100%' }}>
                    {metricsSummary.registeredUsers} usuario(s) registrado(s) en el último día
                  </Typography>
                </PaperContainer>
              </Grid>
              <Grid item xs={12} md={4} p={1}>
                <PaperContainer title="Solicitudes de refinanciamiento" fullWidth fullHeight>
                  <Typography variant="h1" mb={1} sx={{ width: '100%' }} color="info.main" textAlign="center">
                    {Number(metricsSummary.normalizeRequests) || 0 + Number(metricsSummary.refinanceRequests) || 0}
                  </Typography>
                </PaperContainer>
              </Grid>
              <Grid item xs={12} md={4} p={1}>
                <PaperContainer title="NPS Score últimos 30 dias" fullWidth fullHeight>
                  <Typography variant="h1" mb={1} sx={{ width: '100%' }} color="info.main" textAlign="center">
                    {NPSResults?.NPS?.toFixed(0)}
                  </Typography>
                </PaperContainer>
              </Grid>
            </>
          )}
          {usersWithUnreadMessages && (
            <Grid item xs={12} p={1}>
              <PaperContainer
                title="Mensajes sin leer"
                fullWidth
                actionBtn={
                  <EditBtn
                    onClick={() => {
                      getWhatsAppUsers().catch((err) => {
                        // eslint-disable-next-line no-console
                        console.error(err);
                      });
                    }}
                  >
                    <RefreshIcon />
                  </EditBtn>
                }
              >
                {usersWithUnreadMessages.map((user) => (
                  <Box display="flex" key={user.id} alignItems="center" mb={1}>
                    <Typography variant="h5">
                      {user.firstName} {user.lastName} ({user.id}):
                    </Typography>
                    <Typography variant="body1" ml={1}>
                      {user.lastMessageText}
                    </Typography>
                    <Button
                      variant="text"
                      color="info"
                      onClick={() => {
                        navigate(`/admin/chat?userId=${user.id}`);
                      }}
                    >
                      Ver chat
                    </Button>
                  </Box>
                ))}
              </PaperContainer>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default Admin;
