import { SlackReminder } from 'services/types/slack';

import baseApi from './baseApi';

const SlackAPI = baseApi.injectEndpoints({
  endpoints: (build) => ({
    scheduleMessage: build.mutation({
      query: (body: { text: string; postAt: Date; userId: number }) => ({
        url: '/admin/slackReminders/scheduleMessage',
        method: 'POST',
        body,
      }),
      transformResponse: (response: { message?: { text?: string }; post_at: number } | string) => response,
    }),
    getScheduledMessages: build.mutation({
      query: (userId: number) => ({
        url: `/admin/slackReminders/getScheduledMessages/${userId}`,
        method: 'GET',
      }),
      transformResponse: (response: SlackReminder[]) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useScheduleMessageMutation, useGetScheduledMessagesMutation } = SlackAPI;
