const numberFormat = (value?: number) => {
  const numberFormatter = new Intl.NumberFormat('de-DE');

  if (value) {
    return numberFormatter.format(value);
  }
  return numberFormatter.format(0);
};

export default numberFormat;
