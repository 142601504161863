import React, { FC } from 'react';

import Box from '@mui/material/Box';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Stepper, Step } from '@dscla/ds-stepper/react';
import SideDrawer from 'components/UI/SideDrawer';
import { Debt as DebtInterface } from 'services/types/debt';

import ManualDebtFormSteps from './ManualDebtFormSteps';

const steps = ['Tipo de institución', 'Nombre de institución', 'Tipo de producto', 'Detalles de deuda'];

interface Props {
  debtToEdit: DebtInterface | null;
  setDebtToEdit: (debt: DebtInterface | null) => void;
  isFormOpen: boolean;
  setFormOpen: (state: boolean) => void;
  adminMode?: boolean;
}

const DrawerDebtForm: FC<Props> = ({ debtToEdit, isFormOpen, setFormOpen, setDebtToEdit, adminMode }) => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleClose = () => {
    setDebtToEdit(null);
    setFormOpen(false);
    setActiveStep(0);
  };

  return (
    <SideDrawer
      title={debtToEdit ? 'Editar Deuda' : 'Agregar Deuda'}
      open={isFormOpen}
      onClose={handleClose}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
    >
      <Box sx={{ overflowY: 'auto', p: 1 }}>
        {activeStep !== -1 && (
          <Stepper size="small">
            {steps.map((label, index) => (
              <Step slot="step" size="small" key={label} text={(index + 1).toString()} success={activeStep > index} />
            ))}
          </Stepper>
        )}
        <ManualDebtFormSteps
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          debtToEdit={debtToEdit}
          setFormOpen={setFormOpen}
          setDebtToEdit={setDebtToEdit}
          adminMode={adminMode || false}
          onSave={handleClose}
        />
      </Box>
    </SideDrawer>
  );
};

DrawerDebtForm.defaultProps = {
  adminMode: false,
};

export default DrawerDebtForm;
