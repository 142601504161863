import React, { FC } from 'react';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const Features: FC = () => {
  return (
    <Grid py={12} px={10} sx={{ backgroundColor: '#F1F6FC' }}>
      <Box justifyContent="center" display="grid" pb={{ xs: 4, md: 10 }}>
        <Typography
          fontFamily="Roboto"
          fontWeight={800}
          fontSize={{ xs: '35px', md: '45px' }}
          lineHeight={{ xs: '38px', md: '50px' }}
          textAlign={{
            xs: 'center',
            md: 'left',
          }}
        >
          Con Relif verás un impacto real
          <br />
          <span style={{ color: '#2C5889' }}>en tu salud financiera</span>
        </Typography>
      </Box>

      <Grid container spacing={{ xs: 4, md: 8 }}>
        <Grid item xs={12} md={4}>
          <Stack alignItems="center" textAlign="center" spacing={2}>
            <AccountBalanceIcon
              sx={{
                color: '#2C5889',
                backgroundColor: '#9FC6EA',
                borderRadius: '100%',
                padding: '10px',
                width: { xs: '70px', md: '80px' },
                height: { xs: '70px', md: '80px' },
              }}
            />
            <Typography color="#2C5889" fontFamily="Roboto" fontWeight="700" fontSize="22px" lineHeight="30px">
              Te asesoramos
            </Typography>
            <Typography fontFamily="Jost" fontWeight="400" fontSize="20px" lineHeight="25px">
              Miguelina revisó su reporte comercial gratis y encontró un descuento de 70% en su deuda morosa.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack alignItems="center" textAlign="center" spacing={2}>
            <CreditScoreIcon
              sx={{
                color: '#2C5889',
                backgroundColor: '#9FC6EA',
                borderRadius: '100%',
                padding: '10px',
                width: '80px',
                height: '80px',
              }}
            />
            <Typography color="#2C5889" fontFamily="Roboto" fontWeight="700" fontSize="22px" lineHeight="30px">
              Mejoramos tus créditos
            </Typography>
            <Typography fontFamily="Jost" fontWeight="400" fontSize="20px" lineHeight="25px">
              Luis bajó la cuota de su deuda de $200.000 a $62.000 sin aumentar el monto total a pagar.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack alignItems="center" textAlign="center" spacing={2}>
            <AttachMoneyIcon
              sx={{
                color: '#2C5889',
                backgroundColor: '#9FC6EA',
                borderRadius: '100%',
                padding: '10px',
                width: '80px',
                height: '80px',
              }}
            />
            <Typography color="#2C5889" fontFamily="Roboto" fontWeight="700" fontSize="22px" lineHeight="30px">
              Te ayudamos a entender tu situación
            </Typography>
            <Typography fontFamily="Jost" fontWeight="400" fontSize="20px" lineHeight="25px">
              Pablo hizo su presupuesto, llega tranquilo a fin de mes y ahorra para la educación de su hijo.
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Features;
