import { FC } from 'react';

import PriceCheck from '@mui/icons-material/PriceCheck';
import Redeem from '@mui/icons-material/Redeem';

import { ArticleIcon, DebtsIcon, SavingsIcon, BlogIcon } from 'assets/icons';

export interface ImageProps {
  title: string;
  largeImage: string;
  smallImage: string;
}

export interface ServiceProps {
  services: {
    icon: string;
    name: string;
    text: string;
  }[];
}

export interface TeamProps {
  members: {
    translatorKey: string;
    img: string;
    name: string;
    job: string;
    jobSummary?: string;
    prevJob: { place: string; title: string }[];
    icons: string[];
  }[];
}

export interface ContactProps {
  address: string;
  phone: string;
  email: string;
  facebook: string;
  twitter: string;
  youtube: string;
  investor1: string;
  investor2: string;
}

export interface FeatureProps {
  features: {
    icon: FC;
    title: string;
  }[];
}

export const FeaturesData: FeatureProps = {
  features: [
    {
      icon: PriceCheck,
      title: 'Descuentos para salir de DICOM',
    },
    {
      icon: DebtsIcon,
      title: 'Paga menos en tus créditos',
    },
    {
      icon: SavingsIcon,
      title: 'Ordena tus finanzas y ahorra',
    },
    {
      icon: ArticleIcon,
      title: 'Revisa tu boletín comercial',
    },
    {
      icon: BlogIcon,
      title: 'Educación financiera',
    },
    {
      icon: Redeem,
      title: 'Gratis para colaboradores',
    },
  ],
};

export const TeamData: TeamProps = {
  members: [
    {
      translatorKey: 'jorge',
      img: '/landing_page/img/team/jorge-azulclaro.webp',
      name: 'Jorge de la Carrera',
      job: 'Co-fundador & CTO',
      prevJob: [
        { place: 'Meta', title: 'Software Engineer' },
        { place: 'PUC', title: 'Ingeniero Civil Computación' },
      ],
      icons: ['/landing_page/img/team/meta.webp', '/landing_page/img/team/puc.webp'],
    },
    {
      translatorKey: 'benja',
      img: '/landing_page/img/team/benja-azulclaro.webp',
      name: 'Benjamín Bustos',
      job: 'Co-fundador & CEO',
      prevJob: [
        {
          place: 'London Business School',
          title: 'MBA',
        },
        { place: 'Credicorp Capital', title: 'Investment Banking Associate' },
      ],
      icons: ['/landing_page/img/team/London.webp', '/landing_page/img/team/credi.webp'],
    },
    {
      translatorKey: 'carlos',
      img: '/landing_page/img/team/carlos-azulclaro.webp',
      name: 'Carlos Stevenson',
      job: 'Co-fundador & COO',
      prevJob: [
        {
          place: 'London Business School',
          title: 'MBA',
        },
        { place: 'Banco Bice', title: 'Wealth Management Advisor' },
      ],
      icons: ['/landing_page/img/team/London.webp', '/landing_page/img/team/bice.webp'],
    },
    {
      translatorKey: 'juan',
      img: '/landing_page/img/team/juan-azulclaro.webp',
      name: 'Juan Cortés',
      job: 'Developer',
      prevJob: [
        { place: 'PUC', title: 'Ingeniero Industrial TI' },
        { place: 'Forcast', title: 'Full-Stack Developer' },
      ],
      icons: ['/landing_page/img/team/puc.webp', '/landing_page/img/team/forcast.webp'],
    },
    {
      translatorKey: 'tomas',
      img: '/landing_page/img/team/tomas-azulclaro.webp',
      name: 'Tomás García',
      job: 'Developer',
      prevJob: [
        { place: 'PUC', title: 'Ingeniero Civil Computación' },
        { place: 'Celcom', title: 'Software Engineer' },
      ],
      icons: ['/landing_page/img/team/puc.webp', '/landing_page/img/team/celcom.webp'],
    },
    {
      translatorKey: 'trinidad',
      img: '/landing_page/img/team/trinidad-azulclaro.webp',
      name: 'Trinidad Olivos',
      job: 'Key Account Manager',
      prevJob: [
        { place: 'Universidad de los Andes', title: 'Administradora de servicios' },
        { place: 'Promedon', title: 'Gestión de personas' },
      ],
      icons: ['/landing_page/img/team/uandes.webp', '/landing_page/img/team/promedon.webp'],
    },
    {
      translatorKey: 'maria_jose',
      img: '/landing_page/img/team/maria-jose-azulclaro.webp',
      name: 'María José Castro',
      job: 'Chief Commercial Officer (CCO)',
      prevJob: [
        { place: 'PUC', title: 'Ingeniera Comercial' },
        { place: 'BUK', title: 'Sales Lead' },
      ],
      icons: ['/landing_page/img/team/puc.webp', '/landing_page/img/team/buk.webp'],
    },
    {
      translatorKey: 'harold',
      img: '/landing_page/img/team/harold-azulclaro.webp',
      name: 'Harold Müller',
      job: 'Developer',
      prevJob: [
        { place: 'PUC', title: 'Ingeniero Industrial TI' },
        { place: 'MACH', title: 'Software Engineer' },
      ],
      icons: ['/landing_page/img/team/puc.webp', '/landing_page/img/team/mach.webp'],
    },
  ],
};
export const ContactData: ContactProps = {
  address: 'Av. Vitacura 4380, piso 2, Vitacura, Santiago',
  phone: '+56 9 9 2268245',
  email: 'contacto@relif.cl',
  facebook: '',
  twitter: '',
  youtube: '',
  investor1: 'landing_page/img/corfo.webp',
  investor2: '/landing_page/img/team/London.webp',
};
