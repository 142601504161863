import React, { useState } from 'react';

import { Box } from '@mui/material';

import PrimaryBtn from 'components/UI/Buttons';
import SectionHeader from 'components/layout/SectionHeader';
import { Company } from 'services/types/companies';

import CompaniesTable from './CompaniesTable';
import EditCompany from './EditCompany';

const Companies = () => {
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const [companiesRefetchFlag, setCompaniesRefetchFlag] = useState(false);
  const [openEditCompany, setOpenEditCompany] = useState(false);

  const handleEditCompany = (company: Company) => {
    setSelectedCompany(company);
    setOpenEditCompany(true);
  };

  const handleNewCompany = () => {
    setSelectedCompany(null);
    setOpenEditCompany(true);
  };

  const handleBack = () => {
    setOpenEditCompany(false);
    setCompaniesRefetchFlag(!companiesRefetchFlag);
  };

  return (
    <Box>
      <SectionHeader text="Empresas" button={<PrimaryBtn onClick={handleNewCompany}>Nueva empresa</PrimaryBtn>} />
      <Box p={1}>
        {openEditCompany ? (
          <EditCompany company={selectedCompany} handleBack={handleBack} />
        ) : (
          <CompaniesTable handleEditCompany={handleEditCompany} refetchFlag={companiesRefetchFlag} />
        )}
      </Box>
    </Box>
  );
};

export default Companies;
