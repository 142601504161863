import React from 'react';

import { TableHead, TableRow, TableCell } from '@mui/material';

const HEADERS = [
  {
    name: 'Acciones',
  },
  {
    name: 'Errores',
  },
  { name: 'Alertas' },
  {
    name: 'Id usuario',
    officialName: 'userId',
  },
  {
    name: 'Rut Usuario',
    officialName: 'rut',
  },
  { name: 'Es cliente del Banco', officialName: 'isBankClient' },
  {
    name: 'Monto original',
    officialName: 'originalAmount',
  },
  {
    name: 'Monto plan de pago',
    officialName: 'paymentPlanAmount',
  },
  {
    name: 'Monto pago contado',
    officialName: 'cashPaymentAmount',
  },
  {
    name: 'Cuotas',
    officialName: 'terms',
  },
  {
    name: 'Máximo de cuotas',
    officialName: 'maxTerm',
  },
  {
    name: 'Tasa de interés',
    officialName: 'interestRate',
  },
  {
    name: 'Instrucciones',
    officialName: 'instructions',
  },
  {
    name: 'Descripción',
    officialName: 'description',
  },
  {
    name: 'Límite de pago',
    officialName: 'limit',
  },
  {
    name: 'Primer pago',
    officialName: 'firstPaymentDate',
  },
];

const BankOfferHeaders = () => {
  return (
    <TableHead>
      <TableRow>
        {HEADERS.map((header) => (
          <TableCell key={header.name}>
            {header.name}
            {header.officialName && (
              <span style={{ fontSize: 14 }}>
                <br /> {header.officialName}
              </span>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default BankOfferHeaders;
