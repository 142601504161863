import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { StyledEngineProvider } from '@mui/material';

import 'assets/styles/index.scss';
import { Properties } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import { store, persistor } from './redux/store';

const options = {
  api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
  sanitize_properties: (properties: Properties) => {
    return {
      ...properties,
      filterOutEvent: import.meta.env.VITE_PUBLIC_POSTHOG_FILTER_OUT_EVENTS,
      // use the drop events plugin in PostHog to drop these events
    };
  },
};

ReactDOM.render(
  <React.StrictMode>
    <PostHogProvider apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY} options={options}>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
          <StyledEngineProvider injectFirst>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </StyledEngineProvider>
        </PersistGate>
      </Provider>
    </PostHogProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
