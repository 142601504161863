import chartColorPalette from 'assets/styles/chartColorPalette';

export const UserMetrics = [
  {
    name: 'Usuarios registrados (diarios)',
    dataKey: 'registeredUsers',
    stroke: chartColorPalette[0],
  },
  {
    name: 'Usuarios registrados (acumulado)',
    dataKey: 'accRegisteredUsers',
    stroke: chartColorPalette[0],
  },
  {
    name: 'Usuarios activos (total)',
    dataKey: 'activeUsers',
    stroke: chartColorPalette[1],
  },
  {
    name: 'Visitantes (diarios)',
    dataKey: 'visitors',
    stroke: chartColorPalette[2],
  },
  {
    name: 'Visitantes (acumulado)',
    dataKey: 'accVisitors',
    stroke: chartColorPalette[2],
  },
];

export const RefinanceMetrics = [
  {
    name: 'Solicitudes de refinanciamiento (diarias)',
    dataKey: 'refinanceRequests',
    stroke: chartColorPalette[3],
  },
  {
    name: 'Solicitudes de refinanciamiento (acumulado)',
    dataKey: 'accRefinanceRequests',
    stroke: chartColorPalette[3],
  },
  {
    name: 'Solicitudes de normalización (diarias)',
    dataKey: 'normalizeRequests',
    stroke: chartColorPalette[4],
  },
  {
    name: 'Solicitudes de normalización (acumulado)',
    dataKey: 'accNormalizeRequests',
    stroke: chartColorPalette[4],
  },
  {
    name: 'Usuarios morosos (total)',
    dataKey: 'defaulterUsers',
    stroke: chartColorPalette[5],
  },
];

export const FinancialHealthMetrics = [
  {
    name: 'Veces renta (promedio)',
    dataKey: 'averageVR',
    stroke: chartColorPalette[6],
  },
  {
    name: 'Carga financiera (promedio)',
    dataKey: 'averageRCR',
    stroke: chartColorPalette[7],
  },
];

export const ChurnMetrics = [
  {
    name: 'Usuarios perdidos (últimos 60 días)',
    dataKey: 'last60DaysChurnAmount',
    stroke: chartColorPalette[8],
  },
  {
    name: 'Usuarios perdidos (últimos 180 días)',
    dataKey: 'last180DaysChurnAmount',
    stroke: chartColorPalette[9],
  },
  {
    name: 'Usuarios perdidos (últimos 365 días)',
    dataKey: 'last365DaysChurnAmount',
    stroke: chartColorPalette[10],
  },
];

export const AllMetrics = [...UserMetrics, ...RefinanceMetrics, ...FinancialHealthMetrics, ...ChurnMetrics];
