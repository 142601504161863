import { RefinanceDebtsBody, DebtDraft, Debt, RefinanceDebtsResponse, AdminDebt } from '../types/debt';
import baseApi from './baseApi';

const debtApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    listDebts: build.query({
      query: () => ({
        url: '/debts',
        method: 'GET',
      }),
      transformResponse: (response: Debt[]) => response,
    }),
    adminListDebts: build.query({
      query: () => ({
        url: '/admin/debts',
        method: 'GET',
      }),
      transformResponse: (response: AdminDebt[]) => response,
    }),
    createDebt: build.mutation({
      query: (body: DebtDraft) => ({
        url: '/debts',
        method: 'POST',
        body,
      }),
      transformResponse: (response) => response,
    }),
    editDebt: build.mutation({
      query: (props: { body: DebtDraft; id: number }) => ({
        url: `/debts/${props.id}`,
        method: 'PATCH',
        body: props.body,
      }),
      transformResponse: (response) => response,
    }),
    refinanceDebts: build.mutation({
      query: (body: RefinanceDebtsBody) => ({
        url: '/debts/refinance',
        method: 'POST',
        body,
      }),
      transformResponse: (response: RefinanceDebtsResponse) => response,
    }),
    deleteDebt: build.mutation({
      query: (id: number) => ({
        url: `/debts/${id}`,
        method: 'DELETE',
      }),
      transformResponse: (): void => {},
    }),
    adminUpdateDebt: build.mutation({
      query: (props: { body: DebtDraft; id: number }) => ({
        url: `/admin/debts/${props.id}`,
        method: 'PATCH',
        body: props.body,
      }),
      transformResponse: (response) => response,
    }),
    adminDeleteDebt: build.mutation({
      query: (id: number) => ({
        url: `/admin/debts/${id}`,
        method: 'DELETE',
      }),
      transformResponse: (): void => {},
    }),
    deleteOldCMFDebts: build.mutation({
      query: () => ({
        url: '/debts/delete_old_cmf_debts',
        method: 'POST',
      }),
      transformResponse: (response: number) => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  useListDebtsQuery,
  useLazyListDebtsQuery,
  useAdminListDebtsQuery,
  useCreateDebtMutation,
  useRefinanceDebtsMutation,
  useDeleteDebtMutation,
  useEditDebtMutation,
  useAdminUpdateDebtMutation,
  useAdminDeleteDebtMutation,
  useDeleteOldCMFDebtsMutation,
} = debtApi;

export default debtApi;
