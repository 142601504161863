import React, { FC, useEffect, useState } from 'react';
import { useAutosave } from 'react-autosave';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { BlackBtn } from 'components/UI/Buttons';
import { useUpdateActionPlanMutation } from 'services/api/admin-users.api';
import { useGetUserInformationQuery } from 'services/api/user.api';
import { User } from 'services/types/user';

const currentDate = new Date();

interface Props {
  userId: number;
  userData: User | null;
  isLoadingUserData: boolean;
}
const ActionPlan: FC<Props> = ({ userId, userData, isLoadingUserData }) => {
  const [actionPlan, setActionPlan] = useState('');
  const [updateActionPlan, { reset: resetUpdatePlan, isLoading: isLoadingUpdatePlan }] =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    useUpdateActionPlanMutation();
  const [lastSavedBy, setLastSavedBy] = useState('');
  const [isOnFocus, setIsOnFocus] = useState(false);
  const { data: currentUser } = useGetUserInformationQuery(null);

  const handleSave = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    updateActionPlan({ id: userId, actionPlan })
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        resetUpdatePlan();
        const date = new Date();
        setLastSavedBy(`Guardado por última vez a las ${date.toLocaleTimeString()}`);
      })
      .catch(() => {});
  };

  const saveOnFocus = () => {
    if (!isOnFocus || actionPlan === '' || isLoadingUpdatePlan) return;
    handleSave();
  };

  const addTemplate = () => {
    const nextThreeMonths = [0, 1, 2].map((month) => {
      const date = new Date();
      date.setMonth(date.getMonth() + month);
      return date.toLocaleDateString('es-ES', {
        month: 'long',
      });
    });
    const ActionPlanTemplate = `
    1. ¿Cuáles son mis problemas actuales? \n
    \n
    2. ¿Qué es lo que quiero solucionar? \n
    \n
    3. ¿Qué puedo hacer para lograrlo? \n
    ${nextThreeMonths[0]}: \n
    ${nextThreeMonths[1]}: \n
    ${nextThreeMonths[2]}: \n
    ______________________________________________________\n
    Actualizado el ${currentDate.toLocaleDateString()} por ${currentUser?.firstName ?? 'Relif'} ${
      currentUser?.lastName ?? ''
    }
    
    `;
    setActionPlan(actionPlan + ActionPlanTemplate);
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  useAutosave({
    data: actionPlan,
    onSave: saveOnFocus,
    interval: 2000,
  });

  useEffect(() => {
    if (!userData) return;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    setActionPlan(userData.actionPlan || '');
    setLastSavedBy('');
  }, [userData]);

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h3">Plan de acción</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <BlackBtn onClick={addTemplate} sx={{ width: 150, height: 40, alignSelf: 'flex-end', mr: 1 }}>
            Plantilla
          </BlackBtn>
          <Typography variant="body1" lineHeight={2} mr={1}>
            {lastSavedBy}
          </Typography>
          <BlackBtn
            onClick={handleSave}
            sx={{ width: 150, height: 40, alignSelf: 'flex-end' }}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            disabled={isLoadingUpdatePlan}
          >
            Guardar
          </BlackBtn>
        </Box>
      </Box>
      {isLoadingUserData ? (
        <CircularProgress />
      ) : (
        <TextField
          multiline
          rows={10}
          fullWidth
          value={actionPlan}
          onChange={(e) => setActionPlan(e.target.value)}
          onFocus={() => setIsOnFocus(true)}
          onBlur={() => setIsOnFocus(false)}
        />
      )}
    </Box>
  );
};

export default ActionPlan;
