import React, { FC } from 'react';

import { SvgIconTypeMap, Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import * as icons from 'assets/icons';

interface IconsInterface {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [key: string]: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
}

// cast icons to IconsInterface
const iconsList: IconsInterface = icons;

const { HomeIcon } = iconsList;

const Icons: FC = () => {
  return (
    <Box p={2}>
      <Typography variant="h4" gutterBottom component="div">
        Icons
      </Typography>
      <Typography variant="h5" gutterBottom>
        To use an icon, import it from <code>assets/icons</code> and use it as a component.
      </Typography>
      <Typography variant="h5" gutterBottom>
        For example:
      </Typography>
      <Typography variant="body1" gutterBottom>
        <code>{`import {HomeIcon} from 'assets/icons';`}</code>
      </Typography>
      <Typography variant="body1" gutterBottom>
        <code>{`<HomeIcon />`}</code>
      </Typography>
      <Typography variant="h5" gutterBottom component="div">
        It will look like this:
      </Typography>
      <HomeIcon />
      <Typography variant="h5" gutterBottom component="div">
        As any other MUI component, you can style it with the sx prop.
      </Typography>
      <Typography variant="body1" gutterBottom>
        <code>{`<HomeIcon sx={{ fontSize: 80 }} />`}</code>
      </Typography>
      <Typography variant="h5" gutterBottom component="div">
        It will look like this:
      </Typography>
      <HomeIcon sx={{ fontSize: 80 }} />
      <Typography variant="h5" gutterBottom component="div">
        You can also use the color prop to change the color of the icon.
      </Typography>
      <Typography variant="body1" gutterBottom>
        <code>{`<HomeIcon color="info" />`}</code>
      </Typography>
      <HomeIcon color="info" />
      <Typography variant="body1" gutterBottom>
        <code>{`<HomeIcon color="secondary" />`}</code>
      </Typography>
      <HomeIcon color="secondary" />
      <Typography variant="body1" gutterBottom>
        <code>{`<HomeIcon color="error" />`}</code>
      </Typography>
      <HomeIcon color="error" />
      <Typography variant="body1" gutterBottom>
        <code>{`<HomeIcon color="info" />`}</code>
      </Typography>
      <HomeIcon color="info" />
      <Typography variant="body1" gutterBottom>
        <code>{`<HomeIcon color="success" />`}</code>
      </Typography>
      <HomeIcon color="success" />
      <Typography variant="body1" gutterBottom>
        <code>{`<HomeIcon color="warning" />`}</code>
      </Typography>
      <HomeIcon color="warning" />

      <Typography variant="h5" gutterBottom component="div">
        Here is a list of all the icons:
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Icon</TableCell>
            <TableCell>Icon Name</TableCell>
            <TableCell>Snippet</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(iconsList).map((icon) => {
            const Icon = iconsList[icon];
            const snippet = `<${icon} />`;
            return (
              <TableRow key={icon}>
                <TableCell>
                  <Icon />
                </TableCell>
                <TableCell>{icon}</TableCell>
                <TableCell>{snippet}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

export default Icons;
