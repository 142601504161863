import { BudgetDraft, Budget } from '../types/budget';
import baseApi from './baseApi';

const budgetApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getBudgets: build.mutation({
      query: () => ({
        url: '/budgets',
        method: 'GET',
      }),
      transformResponse: (response: Budget[]): Budget[] => response,
    }),
    getBudgetsV2: build.query({
      query: () => ({
        url: '/budgets',
        method: 'GET',
      }),
      transformResponse: (response: Budget[]): Budget[] => response,
    }),
    getBudget: build.query({
      query: (id: number) => ({
        url: `/budgets/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: Budget): Budget => response,
    }),
    createBudget: build.mutation({
      query: (body: BudgetDraft) => ({
        url: '/budgets',
        method: 'POST',
        body,
      }),
      transformResponse: (response: Budget): Budget => response,
    }),
    editBudget: build.mutation({
      query: (props: { body: BudgetDraft; id: number }) => ({
        url: `/budgets/${props.id}`,
        method: 'PUT',
        body: props.body,
      }),
      transformResponse: (response: Budget): Budget => response,
    }),
    deleteBudget: build.mutation({
      query: (id: number) => ({
        url: `/budgets/${id}`,
        method: 'DELETE',
      }),
      transformResponse: (response: Budget): Budget => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetBudgetsMutation,
  useGetBudgetsV2Query,
  useGetBudgetQuery,
  useCreateBudgetMutation,
  useEditBudgetMutation,
  useDeleteBudgetMutation,
} = budgetApi;
