import React, { FC } from 'react';

import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';

import PrimaryBtn from 'components/UI/Buttons';
import RoundedInput from 'components/UI/RoundedInput';
import { GoalTemplate } from 'services/types/goals';
import getIcon from 'utils/goalUtils';

interface Props {
  selectedTemplate: GoalTemplate;
  setSelectedTemplate: React.Dispatch<React.SetStateAction<GoalTemplate | null>>;
  handleNext: () => void;
}

const GoalNameStep: FC<Props> = ({ selectedTemplate, setSelectedTemplate, handleNext }) => {
  return (
    <Grid container rowSpacing={2} columnSpacing={1}>
      <Grid item xs={12}>
        <RoundedInput
          id="selectedTemplate.name"
          variant="outlined"
          sx={{ width: '100%' }}
          label="Nombre"
          value={selectedTemplate.name}
          onChange={(e) => setSelectedTemplate({ ...selectedTemplate, name: e.target.value })}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Avatar
                  sx={{
                    width: 35,
                    height: 35,
                    backgroundColor: 'backgroundGrey.main',
                    color: '#51526B',
                  }}
                >
                  {getIcon(selectedTemplate.name)}
                </Avatar>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <PrimaryBtn
          sx={{ width: '100%', mt: 2 }}
          onClick={() => handleNext()}
          disabled={selectedTemplate.name.length === 0}
        >
          Continuar
        </PrimaryBtn>
      </Grid>
    </Grid>
  );
};

export default GoalNameStep;
