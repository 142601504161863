import { GoalTemplatesResponse, GoalDraft, Goal, GoalPUT } from '../types/goals';
import baseApi from './baseApi';

const goalAPI = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getGoals: build.mutation({
      query: () => ({
        url: '/goals',
        method: 'GET',
      }),
      transformResponse: (response: Goal[]): Goal[] => response,
    }),
    getGoal: build.query({
      query: (id: number) => ({
        url: `/goals/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: Goal): Goal => response,
    }),
    createGoal: build.mutation({
      query: (body: GoalDraft) => ({
        url: '/goals',
        method: 'POST',
        body,
      }),
      transformResponse: (response: Goal): Goal => response,
    }),
    editGoal: build.mutation({
      query: ({ body, id }: GoalPUT) => ({
        url: `/goals/${id}`,
        method: 'PUT',
        body,
      }),
      transformResponse: (response: Goal): Goal => response,
    }),
    deleteGoal: build.mutation({
      query: (id: number) => ({
        url: `/goals/${id}`,
        method: 'DELETE',
      }),
      transformResponse: (response: Goal): Goal => response,
    }),
    getGoalTemplates: build.query({
      query: () => ({
        url: '/goals/templates',
        method: 'GET',
      }),
      transformResponse: (response: GoalTemplatesResponse): GoalTemplatesResponse => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetGoalsMutation,
  useGetGoalQuery,
  useCreateGoalMutation,
  useEditGoalMutation,
  useDeleteGoalMutation,
  useGetGoalTemplatesQuery,
} = goalAPI;
