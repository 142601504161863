import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Grid, Typography } from '@mui/material';

import PrimaryBtn from 'components/UI/Buttons';
import PaperContainer from 'components/UI/PaperContainer';
import SectionHeader from 'components/layout/SectionHeader';
import { useGetCompaniesQuery } from 'services/api/companies.api';
import {
  useGetCompanyMetricsByMonthMutation,
  useGetCompanyMetricsMutation,
  useGetOnlineCompanyMetricsMutation,
} from 'services/api/companymetrics.api';

import DebtMetricsSummary from './DebtMetricsSummary';
import MetricsByMonth from './MetricsByMonth';
import MetricsChart from './MetricsChart';
import MetricsSummary from './MetricsSummary';
import OnlineMetricsSummary from './OnlineMetricsSummary';
import UserMetricsSummary from './UserMetricsSummary';
import { AllMetrics, UserMetrics, RefinanceMetrics, FinancialHealthMetrics, ChurnMetrics } from './metrics';

const currentDate = new Date();

const MetricsDashboard: FC = () => {
  const [startDate, setStartDate] = useState<Date>(new Date(currentDate.getFullYear(), currentDate.getMonth() - 3, 1));
  const [endDate, setEndDate] = useState<Date>(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0));
  const [companyIds, setCompanyIds] = useState<number[]>([]);
  const [hidden, isHidden] = useState<{ [key: string]: boolean }>({});

  AllMetrics.forEach((metric) => {
    if (hidden[metric.dataKey] === undefined) {
      isHidden({ ...hidden, [metric.dataKey]: false });
    }
  });

  const handleHide = (key: string) => {
    if (hidden[key] !== undefined) {
      isHidden({ ...hidden, [key]: !hidden[key] });
    }
  };

  const { data: companies } = useGetCompaniesQuery(null, { refetchOnMountOrArgChange: true });
  const [getMetrics, { data: metricsData }] = useGetCompanyMetricsMutation();
  const [getOnlineMetrics, { data: onlineMetricsData }] = useGetOnlineCompanyMetricsMutation();
  const [getMetricsByMonth, { data: metricsByMonthData }] = useGetCompanyMetricsByMonthMutation();

  const navigate = useNavigate();

  const handleAddCompany = (id: number) => {
    if (companyIds.includes(id)) {
      setCompanyIds(companyIds.filter((companyId) => companyId !== id));
    } else {
      setCompanyIds([...companyIds, id]);
    }
  };

  const getAllMetrics = () => {
    getMetrics({
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      companyIds,
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
    });
    getOnlineMetrics({
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      companyIds,
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
    });
    getMetricsByMonth({
      companyIds,
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
    });
  };

  return (
    <>
      <SectionHeader text="Métricas" button={<PrimaryBtn onClick={() => navigate('/admin')}>Volver</PrimaryBtn>} />

      <PaperContainer>
        <Typography variant="h5">Empresas</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            mt: 2,
          }}
        >
          {companies?.map((company) => (
            <Box
              key={company.id}
              sx={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  mr: 2,
                  mb: 2,
                  color: companyIds.includes(company.id) ? '#000' : '#ccc',
                }}
                onClick={() => handleAddCompany(company.id)}
              >
                <Box
                  sx={{
                    width: 20,
                    height: 20,
                    borderRadius: '50%',
                    backgroundColor: companyIds.includes(company.id) ? '#000' : '#ccc',
                    mr: 1,
                  }}
                />
                <Typography variant="body1">{company.name}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
        <Typography variant="h5">Fecha de inicio</Typography>
        <input
          type="date"
          value={startDate.toISOString().split('T')[0]}
          onChange={(e) => setStartDate(new Date(e.target.value))}
        />
        <Typography variant="h5">Fecha de fin</Typography>
        <input
          type="date"
          value={endDate.toISOString().split('T')[0]}
          onChange={(e) => setEndDate(new Date(e.target.value))}
        />
        <Box sx={{ mt: 2 }}>
          <PrimaryBtn onClick={getAllMetrics}>Obtener métricas</PrimaryBtn>
        </Box>
      </PaperContainer>

      {metricsByMonthData && <MetricsByMonth metricsByMonthData={metricsByMonthData} />}

      {onlineMetricsData && <OnlineMetricsSummary onlineMetricsData={onlineMetricsData} />}

      <Grid container>
        {metricsData && (
          <>
            <Grid item xs={12} md={6} p={2}>
              <MetricsSummary metricsData={metricsData.timeMetrics} />
            </Grid>
            <Grid item xs={12} md={6} p={2}>
              <UserMetricsSummary userMetrics={metricsData.userMetrics} />
            </Grid>
            <Grid item xs={12} p={2}>
              <DebtMetricsSummary debtMetrics={metricsData.debtMetrics} />
            </Grid>
            <Grid item xs={12} md={6} p={2}>
              <MetricsChart
                title='Métricas de "Usuarios"'
                metricsData={metricsData.timeMetrics}
                metrics={UserMetrics}
                hide={hidden}
                handleHide={handleHide}
              />
            </Grid>
            <Grid item xs={12} md={6} p={2}>
              <MetricsChart
                title='Métricas de "Refinanciamiento"'
                metricsData={metricsData.timeMetrics}
                metrics={RefinanceMetrics}
                hide={hidden}
                handleHide={handleHide}
              />
            </Grid>
            <Grid item xs={12} md={6} p={2}>
              <MetricsChart
                title='Métricas de "Salud Financiera"'
                metricsData={metricsData.timeMetrics}
                metrics={FinancialHealthMetrics}
                hide={hidden}
                handleHide={handleHide}
              />
            </Grid>
            <Grid item xs={12} md={6} p={2}>
              <MetricsChart
                title='Métricas de "Abandono"'
                metricsData={metricsData.timeMetrics}
                metrics={ChurnMetrics}
                hide={hidden}
                handleHide={handleHide}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default MetricsDashboard;
