import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

interface Props {
  title: string;
  navigateTo: string;
}

const CardWithArrow: FC<Props> = ({ title, navigateTo }) => {
  const navigate = useNavigate();
  return (
    <Card
      raised
      sx={{
        width: '300px',
        backgroundColor: 'info.light',
        borderRadius: '20px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        mx: 3,
        height: '100px',
      }}
      onClick={() => navigate(navigateTo)}
    >
      <Typography variant="body1" fontWeight={650} m={2} textAlign="start" fontSize="24px">
        {title}
      </Typography>
      <Avatar
        sx={{
          borderRadius: '10px',
          bgcolor: 'newBlue.A700',
          width: '24px',
          height: '24px',
          mx: 2,
        }}
      >
        <KeyboardArrowRightIcon />
      </Avatar>
    </Card>
  );
};

export default CardWithArrow;
