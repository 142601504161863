import baseApi from './baseApi';

interface IFirmaYaApi {
  isValidated: boolean;
}

const firmaYaAPI = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getIsUserValidated: build.query({
      query: () => ({
        url: '/firmaYa',
        method: 'GET',
      }),
      transformResponse: (response: IFirmaYaApi): IFirmaYaApi => response,
    }),
    signExpediente: build.mutation({
      query: (body: { rut: string; documentNumber: string }) => ({
        url: '/firmaYa/sign',
        method: 'POST',
        body,
      }),
      transformResponse: (response: { message: string }) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useGetIsUserValidatedQuery, useLazyGetIsUserValidatedQuery, useSignExpedienteMutation } = firmaYaAPI;
