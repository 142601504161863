import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import CelebrationIcon from 'assets/images/celebration_icon.svg';
import priceFormat from 'services/format/priceFormat';

interface Props {
  title: string;
  amountTitle: string;
  amount: number;
  amountDescription: string;
}

const OfferHeader: FC<Props> = ({ title, amountTitle, amount, amountDescription }) => {
  return (
    <Box
      id="header"
      sx={{
        backgroundColor: 'primary.main',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        py: 4,
        px: 3,
      }}
    >
      <img src={CelebrationIcon} alt="celebration icon" width="58px" height="58px" />
      <Typography
        variant="h1"
        sx={{
          fontSize: 24,
          width: '100%',
          mt: 3,
          fontWeight: 600,
          color: 'white',
          textAlign: 'center',
        }}
      >
        ¡Felicidades!
      </Typography>
      <Typography
        variant="body1"
        sx={{
          width: '100%',
          mt: '15px',
          fontSize: 18,
          fontWeight: 500,
          color: 'white',
          textAlign: 'center',
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="h1"
        sx={{
          fontSize: 24,
          width: '100%',
          mt: 3,
          fontWeight: 600,
          color: 'white',
          textAlign: 'center',
        }}
      >
        {amountTitle}
      </Typography>
      <Box
        sx={{
          backgroundColor: 'secondary.main',
          borderRadius: '20px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 4,
          height: '100px',
          mt: 1.5,
          mb: '-75px',
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: 35,
            width: '100%',
            fontWeight: 700,
            color: 'primary.main',
            textAlign: 'center',
          }}
        >
          {priceFormat.format(amount)}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: 18,
            width: '100%',
            mt: 1,
            fontWeight: 600,
            color: 'primary.main',
            textAlign: 'center',
          }}
        >
          {amountDescription}
        </Typography>
      </Box>
    </Box>
  );
};

export default OfferHeader;
