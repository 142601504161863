import { LinkProps } from '@mui/material/Link';
import { createTheme } from '@mui/material/styles';

import LinkBehavior from '../LinkBehaviour';

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#15183A',
      contrastText: '#fff',
    },
    secondary: {
      main: '#F9D00A',
      light: '#FFF6CB',
      contrastText: '#000',
    },
    // TODO remove this colors or use better name
    newBlue: {
      A100: '#6293C8',
      A400: '#2A578B',
      A700: '#47BCD8', // The same as info.main
    },
    success: {
      main: '#53B721', // green
      light: '#E2F4D9', // 'rgba(114, 210, 66, 0.2)',
    },
    error: {
      main: '#E7233B', // red
      light: '#FAD4D7', // 'rgba(231, 35, 59, 0.2)',
    },
    warning: {
      main: '#CF5C09', // orange
      light: '#FEE6D7', // 'rgba(255, 141, 59, 0.2)',
    },
    info: {
      main: '#47BCD8', // light blue
      light: '#C2EBF4', // 'rgba(64, 188, 218, 0.2)',
    },
    backgroundGrey: {
      main: '#F9FAFD', // To use for background color
    },
  },
  typography: {
    fontFamily: "'Jost','Roboto','sans-serif'",
    h2: {
      fontSize: '22px',
      fontWeight: 500,
      color: '#15183A',
      lineHeight: 1.2,
    },
    h3: {
      fontSize: '20px',
      fontWeight: 700,
      color: '#202020',
      lineHeight: '32px',
    },
    h4: {
      fontSize: '19px',
      fontWeight: 400,
      color: '#202020',
      lineHeight: '30px',
    },
    h5: {
      fontSize: '17px',
      fontWeight: 400,
      color: '#A6A7A8',
      lineHeight: '24px',
    },
    body1: {
      fontSize: '18px',
      color: '#202020',
      fontWeight: 400,
      lineHeight: '26px',
    },
    body2: {
      fontSize: '16px',
      color: '#47BCD8',
      fontWeight: 600,
      lineHeight: '23px',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1300,
      xl: 1800,
    },
  },
  components: {
    // Name of the component
    MuiPaper: {
      defaultProps: {
        // The props to change the default for.
        elevation: 0, // No elevation for paper!
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: 'green',
          color: 'white',
        },
        standardError: {
          backgroundColor: 'red',
          color: 'white',
        },
        standardWarning: {
          backgroundColor: 'rgb(255, 244, 229)',
          color: 'rgb(102, 60, 0)',
          '& .MuiAlert-icon': {
            color: '#ff9800',
          },
          marginBottom: 2,
        },
        standardInfo: {
          backgroundColor: 'grey',
          color: 'black',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
  },
});

export default defaultTheme;
