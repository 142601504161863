import React, { FC, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';

import { Box, Grid, TextField, Typography } from '@mui/material';

import AlertDialog from 'components/UI/AlertDialog';
import PrimaryBtn, { BlackBtn } from 'components/UI/Buttons';
import LoadingOverlay from 'components/UI/LoadingOverlay';
import { useCreateRoiCalculatorMutation, useEditRoiCalculatorMutation } from 'services/api/roiCalculators.api';
import { RoiCalculator, RoiCalculatorDraft } from 'services/types/roiCalculators';

const NumericInput = (
  property: keyof RoiCalculatorDraft,
  label: string,
  isFloat: boolean,
  editedCalculator: RoiCalculatorDraft,
  setEditedCalculator: (value: RoiCalculatorDraft) => void,
  required = true
) => (
  <NumericFormat
    value={editedCalculator[property]} // eslint-disable-line react/destructuring-assignment
    valueIsNumericString
    decimalScale={isFloat ? 3 : 0}
    isAllowed={(values) => {
      const { floatValue } = values;
      if (isFloat) {
        return floatValue !== undefined && floatValue > 0 && floatValue <= 1;
      }
      return floatValue !== undefined && floatValue >= 1;
    }}
    onValueChange={(value) => {
      setEditedCalculator({ ...editedCalculator, [property]: value.floatValue || (isFloat ? 0.1 : 1) });
    }}
    fullWidth
    label={label}
    customInput={TextField}
    required={required}
  />
);

interface Props {
  calculator: RoiCalculator | null;
  handleBack: () => void;
}

const EditCalculator: FC<Props> = ({ calculator, handleBack }) => {
  const [editedCalculator, setEditedCalculator] = useState<RoiCalculatorDraft>(
    calculator
      ? {
          email: calculator.email,
          employeeCount: calculator.employeeCount,
          averageSalary: calculator.averageSalary,
          absenteeismRate: calculator.absenteeismRate,
          rotationRate: calculator.rotationRate,
          expectedActiveUsersRate: calculator.expectedActiveUsersRate,
          minimumActiveUsersChargeRate: calculator.minimumActiveUsersChargeRate,
          segment1pricePerUser: calculator.segment1pricePerUser,
          segment2pricePerUser: calculator.segment2pricePerUser,
          segment3pricePerUser: calculator.segment3pricePerUser,
          segment4pricePerUser: calculator.segment4pricePerUser,
          segment1startEmployees: calculator.segment1startEmployees,
          segment2startEmployees: calculator.segment2startEmployees,
          segment3startEmployees: calculator.segment3startEmployees,
          segment4startEmployees: calculator.segment4startEmployees,
        }
      : {
          email: '',
          employeeCount: 1000,
          averageSalary: 700000,
          absenteeismRate: 0.05,
          rotationRate: 0.1,
          expectedActiveUsersRate: 0.2,
          minimumActiveUsersChargeRate: 0.15,
          segment1pricePerUser: 0.06,
          segment2pricePerUser: 0.05,
          segment3pricePerUser: 0.035,
          segment4pricePerUser: 0.01,
          segment1startEmployees: 0,
          segment2startEmployees: 300,
          segment3startEmployees: 1000,
          segment4startEmployees: 10000,
        }
  );
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);

  const [createCalculator, { isLoading: createLoading, isSuccess: createSuccess, isError: createError }] =
    useCreateRoiCalculatorMutation();
  const [editCalculator, { isLoading: editLoading, isSuccess: editSuccess, isError: editError }] =
    useEditRoiCalculatorMutation();

  const handleSave = () => {
    if (calculator) {
      editCalculator({ calculator: editedCalculator, id: calculator.id }).catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
    } else {
      createCalculator(editedCalculator).catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
    }
  };

  useEffect(() => {
    if (editSuccess || createSuccess) {
      handleBack();
    }
  }, [editSuccess, createSuccess]);

  useEffect(() => {
    if (editError || createError) {
      setErrorDialogOpen(true);
    }
  }, [editError, createError]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <LoadingOverlay visible={editLoading || createLoading} />
      <AlertDialog
        openDialog={errorDialogOpen}
        setOpenDialog={setErrorDialogOpen}
        header="Error al editar la calculadora"
        confirmMsg="aceptar"
        msg="Ha ocurrido un error al editar la calculadora, por favor intente nuevamente"
        confirmAction={() => setErrorDialogOpen(false)}
      />
      <Grid container width="100%">
        <Grid item xs={12}>
          <Typography variant="h6">{calculator ? 'Editar' : 'Nueva'} calculadora</Typography>
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          <TextField
            value={editedCalculator.email}
            onChange={(e) => {
              setEditedCalculator({ ...editedCalculator, email: e.target.value });
            }}
            fullWidth
            label="Email"
            required
          />
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          {NumericInput('employeeCount', 'Cantidad de empleados', false, editedCalculator, setEditedCalculator)}
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          {NumericInput('averageSalary', 'Salario promedio', false, editedCalculator, setEditedCalculator)}
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          {NumericInput('absenteeismRate', 'Ratio de absentismo', true, editedCalculator, setEditedCalculator)}
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          {NumericInput('rotationRate', 'Ratio de rotación', true, editedCalculator, setEditedCalculator)}
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          {NumericInput(
            'expectedActiveUsersRate',
            'Usuarios activos esperados',
            true,
            editedCalculator,
            setEditedCalculator
          )}
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          {NumericInput(
            'minimumActiveUsersChargeRate',
            'Usuarios mínimos activos para cobrar',
            true,
            editedCalculator,
            setEditedCalculator
          )}
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          {NumericInput('flatFee', 'Tarifa plana', true, editedCalculator, setEditedCalculator, false)}
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          {NumericInput(
            'segment1pricePerUser',
            'Precio por usuario segmento 1',
            false,
            editedCalculator,
            setEditedCalculator
          )}
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          {NumericInput(
            'segment2pricePerUser',
            'Precio por usuario segmento 2',
            false,
            editedCalculator,
            setEditedCalculator
          )}
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          {NumericInput(
            'segment3pricePerUser',
            'Precio por usuario segmento 3',
            false,
            editedCalculator,
            setEditedCalculator
          )}
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          {NumericInput(
            'segment4pricePerUser',
            'Precio por usuario segmento 4',
            false,
            editedCalculator,
            setEditedCalculator
          )}
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          {NumericInput(
            'segment1startEmployees',
            'Cantidad usuarios segmento 1',
            false,
            editedCalculator,
            setEditedCalculator
          )}
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          {NumericInput(
            'segment2startEmployees',
            'Cantidad usuarios segmento 2',
            false,
            editedCalculator,
            setEditedCalculator
          )}
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          {NumericInput(
            'segment3startEmployees',
            'Cantidad usuarios segmento 3',
            false,
            editedCalculator,
            setEditedCalculator
          )}
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          {NumericInput(
            'segment4startEmployees',
            'Cantidad usuarios segmento 4',
            false,
            editedCalculator,
            setEditedCalculator
          )}
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <BlackBtn onClick={handleBack} sx={{ mx: 1 }}>
          Volver
        </BlackBtn>
        <PrimaryBtn onClick={handleSave} disabled={editedCalculator.email === ''} sx={{ mx: 1 }}>
          Guardar
        </PrimaryBtn>
      </Box>
    </Box>
  );
};

export default EditCalculator;
