import * as React from 'react';

import { Box, IconButton, Stack, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { CloseIcon } from 'assets/icons';
import CelebrationIcon from 'assets/images/celebration_icon.svg';

interface SuccessDialogInterface {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  header: string;
  msg: string;
  extraMsg?: string;
}

const SuccessDialog: React.FC<SuccessDialogInterface> = ({
  openDialog: open,
  setOpenDialog: setOpen,
  header,
  msg,
  extraMsg,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{ '& .MuiDialog-paper': { borderRadius: '25px' } }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Stack spacing={2} display="inline-grid" padding="20px">
        <Box display="flex" justifyContent="flex-end">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box justifySelf="center">
          <img src={CelebrationIcon} alt="celebration icon" width="58px" height="58px" />
        </Box>
        <DialogTitle id="alert-dialog-title">
          <Typography
            variant="h4"
            fontWeight={600}
            fontSize="18px"
            justifySelf="center"
            textAlign="center"
            sx={{ whiteSpace: 'pre-line' }}
          >
            {header}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography
              variant="body1"
              fontSize="16px"
              justifySelf="center"
              textAlign="justify"
              sx={{ whiteSpace: 'pre-line' }}
            >
              {msg}
            </Typography>
            <Typography
              variant="body1"
              fontSize="16px"
              justifySelf="center"
              textAlign="justify"
              fontWeight={600}
              sx={{ whiteSpace: 'pre-line' }}
            >
              {extraMsg}
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Stack>
    </Dialog>
  );
};

SuccessDialog.defaultProps = {
  extraMsg: undefined,
};

export default SuccessDialog;
