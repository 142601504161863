import React, { FC } from 'react';

import { FormControl, Grid, InputLabel, Select, MenuItem, styled } from '@mui/material';

const StyledSelect = styled(Select)(() => ({
  border: 'none',
  borderRadius: '10px',
  '& .MuiSelect-select': {
    backgroundColor: 'backgroundGrey.main',
    borderRadius: '10px',
    border: '1px solid #EDF1FA',
    padding: '20px',
  },
  boxShadow: 'none',
  '& .MuiSelect-icon': {
    borderRadius: '5px',
    backgroundColor: 'white',
    color: '#000000',
  },
  fontWeight: 'bold',
}));
interface Props {
  selectedTerm: number;
  setSelectedTerm: (term: number) => void;
  availableTerms: number[];
}

const SimulationOptions: FC<Props> = ({ selectedTerm, setSelectedTerm, availableTerms }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="term-select-label">Plazo (meses)</InputLabel>
          <StyledSelect
            id="term-select"
            value={selectedTerm}
            label="Plazo (meses)"
            variant="filled"
            disableUnderline
            onChange={(e) => {
              setSelectedTerm(e.target.value as number);
            }}
          >
            {availableTerms.map((term) => (
              <MenuItem
                key={term}
                onClick={() => {
                  setSelectedTerm(term);
                }}
                value={term}
              >
                {term}
              </MenuItem>
            ))}
          </StyledSelect>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default SimulationOptions;
