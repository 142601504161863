import React from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import PrimaryBtn from 'components/UI/Buttons';

const FinancialHealth = () => {
  const navigate = useNavigate();

  return (
    <Grid container py={{ xs: 7, sm: 14 }} px={{ xs: 2, sm: 9, md: 18 }}>
      <Grid item xs={12} sm={6} textAlign={{ xs: 'center', sm: 'left' }} pb={4}>
        <Typography fontFamily="Roboto" fontWeight={700} fontSize="22px" lineHeight="30px" color="#2C5889">
          En Relif
        </Typography>
        <Typography
          fontFamily="Roboto"
          fontWeight={800}
          fontSize={{ xs: '35px', sm: '45px' }}
          lineHeight={{ xs: '38px', sm: '50px' }}
          color="#15183A"
        >
          Nos importa
          <br />
          <span style={{ color: '#2C5889' }}>
            la salud financiera <br />
            de cada persona
          </span>
        </Typography>
        <PrimaryBtn
          variant="outlined"
          sx={{
            display: { xs: 'none', sm: 'block' },
            mt: 4,
            color: 'black',
            backgroundColor: '#F9D00A',
            borderColor: '#F9D00A',
            '&:hover': {
              backgroundColor: '#DCB705',
              borderColor: '#DCB705',
              color: 'black',
            },
          }}
          onClick={() => navigate('/')}
        >
          Contáctanos
        </PrimaryBtn>
      </Grid>
      <Grid item xs={12} sm={6} justifyContent="center" display="ruby">
        <Box display={{ xs: 'none', md: 'block' }}>
          <img alt="" src="/landing_page/img/velocimeter.png" width="100%" />
        </Box>
        <Box display={{ xs: 'block', md: 'none' }}>
          <img alt="" src="/landing_page/img/mobileSummary.png" width="100%" />
          <img alt="" src="/landing_page/img/mobileVelocimeter.png" width="100%" />
        </Box>
        <PrimaryBtn
          variant="outlined"
          sx={{
            display: { xs: 'block', sm: 'none' },
            width: '80%',
            color: 'black',
            backgroundColor: '#F9D00A',
            borderColor: '#F9D00A',
            '&:hover': {
              backgroundColor: '#DCB705',
              borderColor: '#DCB705',
              color: 'black',
            },
          }}
          onClick={() => navigate('/')}
        >
          Contáctanos
        </PrimaryBtn>
      </Grid>
    </Grid>
  );
};

export default FinancialHealth;
