import React from 'react';

import Storefront from '@mui/icons-material/Storefront';

import { findCMFInstitutionsLogo } from 'assets/data/institutionTypeList';
import { InstitutionIcon } from 'assets/icons';
import { Debt as DebtInterface, DebtToCategorize } from 'services/types/debt';

const showDebtIcon = (debt: DebtInterface | DebtToCategorize) => {
  if (findCMFInstitutionsLogo(debt.institutionName || '') !== '') {
    return (
      <img
        src={findCMFInstitutionsLogo(debt.institutionName || '')}
        style={{
          borderRadius: '25px',
          width: '35px',
          height: '35px',
        }}
        alt="company logo"
      />
    );
  }
  if (debt.institutionType === 'Banco') {
    return (
      <InstitutionIcon
        sx={{
          width: '29px',
          height: '29px',
        }}
      />
    );
  }
  return (
    <Storefront
      sx={{
        width: '29px',
        height: '29px',
      }}
    />
  );
};

export default showDebtIcon;
