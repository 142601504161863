/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC } from 'react';

import Add from '@mui/icons-material/Add';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// @ts-ignore
import { Button } from '@dscla/ds-button/react';
// @ts-ignore
import { DataTable } from '@dscla/ds-data-table/react';
import { CajaStatusButton, PrimaryBtnWithIcon } from 'components/UI/Buttons';
import PaperContainer from 'components/UI/PaperContainer';
import DeleteButton from 'components/common/DeleteButton';
import useIsCajaLosAndesTheme from 'hooks/useIsCajaLosAndesTheme';
import { useAdminDeleteDebtMutation, useDeleteDebtMutation } from 'services/api/debt.api';
import priceFormat, { UFFormat } from 'services/format/priceFormat';
import { Debt as DebtInterface } from 'services/types/debt';
import { getDebtStatus, getMonthlyPayment, isImplicitTermType } from 'utils/debtHelpers';
import showDebtIcon from 'utils/showDebtIcon';

interface DebtsTableInterface {
  currentDebts: DebtInterface[] | undefined;
  openEditDebtModal: (debt: DebtInterface) => void;
  getData: () => void;
  handleOpenDebtTracesDrawer: (debt: DebtInterface) => void;
  handleAddDebts?: () => void;
  adminMode?: boolean;
}

const DebtsTable: FC<DebtsTableInterface> = ({
  currentDebts,
  openEditDebtModal,
  getData,
  handleOpenDebtTracesDrawer,
  handleAddDebts,
  adminMode = false,
}) => {
  const [deleteDebt, { isLoading: isLoadingDelete }] = useDeleteDebtMutation();
  const [adminDeleteDebt, { isLoading: isLoadingAdminDelete }] = useAdminDeleteDebtMutation();
  const deleteDebtFunction = async (objectId: number) => {
    if (adminMode) {
      await adminDeleteDebt(objectId);
    } else {
      await deleteDebt(objectId);
    }
    getData();
  };
  const isCajaTheme = useIsCajaLosAndesTheme();

  const unpaidDebts = currentDebts?.filter((debt) => (debt.monthsBehind || 0) > 0) || [];
  const onDueDebts = currentDebts?.filter((debt) => (debt.monthsBehind || 0) === 0) || [];

  const getEditButton = (debt: DebtInterface, behaviour: 'fijo' | 'fluido') => {
    return (
      <Button onClick={() => openEditDebtModal(debt)} behaviour={behaviour} variant="texto">
        Editar
      </Button>
    );
  };

  const showDebtsRows = (onDue: boolean) => {
    const debtsToShow = onDue ? onDueDebts : unpaidDebts;
    if (debtsToShow?.length) {
      return debtsToShow
        ?.sort((a, b) => {
          return a.institutionName?.localeCompare(b.institutionName || '') || 0;
        })
        .map((debt) => {
          // find trace with last created date
          const lastTrace = debt.debtTraces?.slice().sort((a, b) => {
            if (a.createdAt > b.createdAt) {
              return -1;
            }
            if (a.createdAt < b.createdAt) {
              return 1;
            }
            return 0;
          })[0];
          const debtStatus = lastTrace?.status || debt.status;
          const status = getDebtStatus(debtStatus || '', onDue, isCajaTheme);
          const showMonthlyPayment = !isImplicitTermType(debt) || adminMode;
          return (
            <tr key={debt.id}>
              <td data-first="" data-mobile-width="100%" data-mobile>
                <Box display="flex" justifyContent="space-between" alignContent="center">
                  <Box>
                    {debt.institutionName}
                    <br />
                    {debt.debtType && debt.debtType.charAt(0).toUpperCase() + debt.debtType.slice(1)}
                  </Box>
                  <Box>
                    <Avatar
                      sx={{
                        bgcolor: '#EDF1FA',
                        color: 'primary.main',
                        width: '30px',
                        height: '30px',
                      }}
                    >
                      {showDebtIcon(debt)}
                    </Avatar>
                  </Box>
                </Box>
              </td>
              <td data-desktop>
                <Avatar
                  sx={{
                    bgcolor: '#EDF1FA',
                    color: 'primary.main',
                    width: '30px',
                    height: '30px',
                  }}
                >
                  {showDebtIcon(debt)}
                </Avatar>
              </td>
              <td data-label={debt.institutionName} data-desktop>
                {debt.debtType && debt.debtType.charAt(0).toUpperCase() + debt.debtType.slice(1)}
              </td>

              {adminMode && <td>{debt.source}</td>}

              <td align="right" data-desktop>
                {debt.currency === 'UF' ? UFFormat(Number(debt.amount)) : priceFormat.format(Number(debt.amount))}
                {onDue && (
                  <Typography variant="body1" fontSize={16}>
                    {showMonthlyPayment ? `Cuota: ${getMonthlyPayment(debt)}` : ''}
                  </Typography>
                )}
                {!onDue && (
                  <Typography variant="body1" fontSize={16}>
                    {`${Number(debt.monthsBehind)} ${
                      Number(debt.monthsBehind) > 1 ? 'meses de atraso' : 'mes de atraso'
                    }`}
                  </Typography>
                )}
              </td>
              <td data-mobile data-label="Total" data-mobile-width="50%">
                {debt.currency === 'UF' ? UFFormat(Number(debt.amount)) : priceFormat.format(Number(debt.amount))}
              </td>

              {onDue && (
                <td data-mobile data-label="Cuota" data-mobile-width="50%">
                  {showMonthlyPayment ? getMonthlyPayment(debt) : '-'}
                </td>
              )}
              {!onDue && (
                <td
                  aria-label="Atraso"
                  data-mobile
                  data-label={`${Number(debt.monthsBehind)} ${
                    Number(debt.monthsBehind) > 1 ? 'meses de atraso' : 'mes de atraso'
                  }`}
                  data-mobile-width="50%"
                />
              )}
              <td data-mobile data-mobile-width="100%">
                {/* ver que hacer con esto */}
                <CajaStatusButton
                  text={status.text}
                  value={status.value}
                  onClick={() => handleOpenDebtTracesDrawer(debt)}
                  behaviour="fluido"
                />
              </td>
              <td align="center" data-desktop>
                <CajaStatusButton
                  text={status.text}
                  value={status.value}
                  onClick={() => handleOpenDebtTracesDrawer(debt)}
                  behaviour="adaptable"
                />
              </td>
              {(onDue || adminMode) && (
                <td data-desktop>
                  <Stack spacing={1}>
                    {getEditButton(debt, 'fijo')}
                    {((onDue && debt.source === 'Manual') || adminMode) && (
                      <DeleteButton
                        isMenuIcon={false}
                        objectId={debt.id}
                        isLoading={isLoadingDelete || isLoadingAdminDelete}
                        deleteFunction={deleteDebtFunction}
                        confirmationText="¿Estás seguro de eliminar esta deuda?"
                        onErrorAction={() => {}}
                        onSuccessAction={() => {}}
                        compact
                        isCajaTheme
                        behaviour="fijo"
                      />
                    )}
                  </Stack>
                </td>
              )}
              {(onDue || adminMode) && (
                <td data-mobile data-mobile-width="100%">
                  <Box display="flex" justifyContent="space-between">
                    {getEditButton(debt, 'fluido')}
                    {((onDue && debt.source === 'Manual') || adminMode) && (
                      <DeleteButton
                        isMenuIcon={false}
                        objectId={debt.id}
                        isLoading={isLoadingDelete || isLoadingAdminDelete}
                        deleteFunction={deleteDebtFunction}
                        confirmationText="¿Estás seguro de eliminar esta deuda?"
                        onErrorAction={() => {}}
                        onSuccessAction={() => {}}
                        compact
                        isCajaTheme
                        behaviour="fluido"
                      />
                    )}
                  </Box>
                </td>
              )}
            </tr>
          );
        });
    }
    return null;
  };

  const onDueDebtsTotalRow = (debts: DebtInterface[]) => {
    const totalMonthlyPayment = debts.reduce((acc, debt) => {
      if (isImplicitTermType(debt)) {
        return acc;
      }
      return acc + Number(debt.monthlyPayment);
    }, 0);
    const totalAmount = debts.reduce((acc, debt) => acc + Number(debt.amount), 0);

    return (
      <>
        <tr>
          <td data-desktop aria-label="empty" />
          <td data-desktop>Monto Total</td>
          <td data-desktop align="right">
            {priceFormat.format(totalAmount)}
          </td>
          <td data-desktop aria-label="empty" />
          <td data-desktop aria-label="empty" />
          <td data-mobile data-first="" data-mobile-width="100%">
            Total
          </td>
          <td data-mobile data-label="Monto Total">
            {priceFormat.format(totalAmount)}
          </td>
          <td data-mobile data-label="Cuota Total">
            {priceFormat.format(totalMonthlyPayment)}
          </td>
        </tr>
        <tr>
          <td data-desktop aria-label="empty" />
          <td data-desktop>Cuota Total</td>
          <td data-desktop align="right">
            {priceFormat.format(totalMonthlyPayment)}
          </td>
          <td data-desktop aria-label="empty" />
          <td data-desktop aria-label="empty" />
        </tr>
      </>
    );
  };

  const onDebtDebtsTotalRow = (debts: DebtInterface[]) => {
    const totalAmount = debts.reduce((acc, debt) => acc + Number(debt.amount), 0);
    return (
      <tr>
        <td data-desktop aria-label="empty" />
        <td data-desktop>Monto Total</td>
        <td data-desktop align="right">
          {priceFormat.format(totalAmount)}
        </td>
        <td data-mobile data-first="" data-mobile-width="100%">
          Monto Total
        </td>
        <td data-mobile data-label="Total">
          {priceFormat.format(totalAmount)}
        </td>
        <td data-desktop aria-label="empty" />
      </tr>
    );
  };

  if (isLoadingDelete || isLoadingAdminDelete) {
    return <CircularProgress sx={{ m: 'auto' }} />;
  }
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {currentDebts && currentDebts.length === 0 && (
        <PaperContainer
          title="No encontramos deudas en la CMF ni la Cámara de Comercio 🤔"
          fullWidth
          actionBtn={
            handleAddDebts && <PrimaryBtnWithIcon onClick={handleAddDebts} icon={<Add />} text="Agregar deuda" />
          }
        >
          <Typography variant="body1" textAlign="center">
            Nosotros nos encargaremos de actualizar esta información una vez al mes.
            <br />
            Cualquier duda o consulta, escríbenos a nuestro WhatsApp o chat
          </Typography>
        </PaperContainer>
      )}
      {(onDueDebts?.length || 0) > 0 ? (
        <PaperContainer
          title="Deudas vigentes"
          fullWidth
          actionBtn={
            handleAddDebts && <PrimaryBtnWithIcon onClick={handleAddDebts} icon={<Add />} text="Agregar deuda" />
          }
        >
          <DataTable aria-label="simple table" variant="card">
            <table slot="card">
              <thead>
                <tr>
                  <td>Tipo</td>
                  <td>Institución</td>
                  {adminMode ? <td>Fuente</td> : null}
                  <td align="right">Saldo vigente</td>
                  <td align="center">Estado</td>
                  <td align="center">Acciones</td>
                </tr>
              </thead>
              <tbody>
                {showDebtsRows(true)}
                {onDueDebtsTotalRow(onDueDebts || [])}
              </tbody>
            </table>
          </DataTable>
        </PaperContainer>
      ) : null}

      {(unpaidDebts?.length || 0) > 0 && (
        <PaperContainer title="Deudas atrasadas" fullWidth>
          <DataTable variant="card">
            <table slot="card">
              <thead>
                <tr>
                  <td>Tipo</td>
                  <td>Institución</td>
                  {adminMode ? <td>Fuente</td> : null}
                  <td align="right">Total adeudado</td>
                  <td align="center">Estado</td>
                  {adminMode && <td>Acciones</td>}
                </tr>
              </thead>
              <tbody>
                {showDebtsRows(false)}
                {onDebtDebtsTotalRow(unpaidDebts || [])}
              </tbody>
            </table>
          </DataTable>
        </PaperContainer>
      )}
    </Box>
  );
};

DebtsTable.defaultProps = {
  adminMode: false,
  handleAddDebts: undefined,
};

export default DebtsTable;
