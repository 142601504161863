import React, { FC, useState, ReactNode, useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';

import {
  Box,
  Grid,
  Container,
  Typography,
  FormControlLabel,
  Checkbox,
  Link,
  TextField,
  Tooltip,
  CircularProgress,
} from '@mui/material';

import useQuery from 'hooks/useQuery';
import { format, validate } from 'rut.js';
import { useSignMandateMutation } from 'services/api/signatures.api';
import { captureUserEvent } from 'utils/posthogUtils';
import validateEmail from 'utils/validations/validateEmail';
import validateRut from 'utils/validations/validateRut';

interface Props {
  header?: ReactNode;
  title?: string;
  description?: string;
}

const loadingMessages = [
  'Estamos procesando tus datos, esto puede tomar unos segundos...',
  'No recargues la página, esto puede tomar unos segundos...',
  'Sólo unos segundos más y estaremos listos...',
];

const SignMandate: FC<Props> = ({ header, title, description }: Props) => {
  const query = useQuery();
  const [signMandateMutation, { isLoading }] = useSignMandateMutation();
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(query.get('success') === 'true');
  const [messageIndex, setMessageIndex] = useState(0);
  const [email, setEmail] = useState<string>(query.get('email') || '');
  const [firstName, setFirstName] = useState<string>(query.get('firstName') || '');
  const [lastName, setLastName] = useState<string>(query.get('lastName') || '');
  const queryRut = query.get('rut');
  const [rut, setRut] = useState<string>(queryRut ? format(queryRut) : '');
  const [documentNumber, setDocumentNumber] = useState<string>(query.get('documentNumber') || '');
  const validateDocumentNumber = (value: string) => value.length === 11;
  const [phone, setPhone] = useState<string>(query.get('phone') || '569');
  const validatePhone = (value: string) => value.startsWith('569') && value.length === 11;
  const validateAllInputs = () =>
    isLoading ||
    firstName.length === 0 ||
    lastName.length === 0 ||
    !validateEmail(email) ||
    !validate(rut) ||
    !validatePhone(phone) ||
    !validateDocumentNumber(documentNumber);

  useEffect(() => {
    const interval = setInterval(() => {
      setMessageIndex((messageIndex + 1) % loadingMessages.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [messageIndex, setMessageIndex]);

  const rutFormatter = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setRut(format(event.target.value));
    }
    if (!event.target.value && rut === '-') {
      setRut('');
    }
  };
  const documentNumberFormatter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatter = new Intl.NumberFormat('es-CL');
    const value = Number(e.target.value.replace(/\D+/g, '').slice(0, 9));
    if (value) {
      setDocumentNumber(formatter.format(value));
    }
  };
  const emptyFormFields = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhone('569');
    setRut('');
    setDocumentNumber('');
  };
  const signMandate = () => {
    setMessageIndex(0);
    signMandateMutation({
      email,
      firstName,
      lastName,
      rut,
      documentNumber,
      phone,
    })
      .then((response) => {
        if ('error' in response && 'status' in response.error) {
          const errorMsg = response.error.data as { message: string };
          captureUserEvent('failed_sign_mandate', {
            email,
            firstName,
            lastName,
            rut,
            phone,
            errorMsg,
          });
          setIsError(true);
          setErrorMessage(errorMsg.message);
          throw new Error('Api error');
        } else {
          captureUserEvent('successful_sign_mandate', {
            email,
            firstName,
            lastName,
            rut,
            phone,
          });
          setIsSuccess(true);
          emptyFormFields();
          window.history.replaceState({}, document.title, `${window.location.href}?success=true`);
        }
      })
      .catch((error) => console.error(error)); // eslint-disable-line no-console
  };
  const getHelperText = () => {
    if (firstName.length === 0 || lastName.length === 0) {
      return 'Ingrese su nombre y apellido';
    }
    if (!validateEmail(email)) {
      return 'Ingrese un email válido';
    }
    if (!validatePhone(phone)) {
      return 'Ingrese un teléfono válido';
    }
    if (!validate(rut)) {
      return 'Ingrese un rut válido';
    }
    if (!validateDocumentNumber(documentNumber)) {
      return 'Ingrese un número de documento válido';
    }
    return '';
  };
  return (
    <Box sx={{ margin: 0, padding: 0 }}>
      <div
        style={{
          backgroundImage: "url('./relif_background.webp')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: '100vh',
          width: '100vw',
          filter: 'blur(10px)',
          margin: 0,
          padding: 0,
        }}
      />
      <Container
        sx={{
          backgroundImage: 'url("/contact-background.svg")',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          borderRadius: '30px',
          padding: { lg: '20px 50px 50px', xs: '20px 10px' },
          position: 'absolute',
          mt: 3,
          top: '10%',
          left: '50%',
          transform: 'translate(-50%, -10%)',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            width: '100%',
          }}
        >
          {header}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              mr: 2,
              ml: 2,
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: { lg: '40px', xs: '25px' },
                width: '100%',
                mb: '15px',
                textTransform: 'uppercase',
                fontFamily: 'Roboto',
                fontWeight: 800,
                color: 'white',
                textAlign: 'center',
              }}
            >
              {title}
            </Typography>
            {!isLoading && !isSuccess && !isError && (
              <Typography
                variant="h4"
                sx={{
                  fontSize: { lg: 20, xs: 16 },
                  mb: 3,
                  fontWeight: 500,
                  color: 'white',
                  marginBottom: 3,
                  textAlign: 'center',
                  lineHeight: '28px',
                }}
              >
                {description}
              </Typography>
            )}
            {isSuccess && (
              <Typography
                variant="h4"
                sx={{
                  fontSize: { lg: 20, xs: 16 },
                  mb: 3,
                  fontWeight: 500,
                  color: 'white',
                  marginBottom: 3,
                  textAlign: 'center',
                  lineHeight: '28px',
                }}
              >
                Ahora, Relif se pone a trabajar. Nosotros negociamos con todas tus instituciones financieras para
                buscarte las mejores ofertas de pago. Cuando consigamos algo, te contactaremos por WhatsApp para que
                estés atento!
              </Typography>
            )}
          </Box>
          {(isLoading || isError) && !isSuccess && (
            <Box display="grid" alignItems="center" justifyItems="center">
              {isLoading && <CircularProgress color="secondary" />}
              <Typography
                variant="h3"
                sx={{ fontSize: 25, mb: 3, fontWeight: 500, color: 'white', textAlign: 'center' }}
              >
                {isLoading && loadingMessages[messageIndex]}
                {isError && (errorMessage || 'Error al validar tus datos. Intente nuevamente.')}
              </Typography>
              {isError && (
                <button type="button" className="sign-mandate-button" onClick={() => setIsError(false)}>
                  Volver a intentar
                </button>
              )}
            </Box>
          )}
          {!isLoading && !isError && !isSuccess && (
            <Box className="contact-form-container" flexDirection="column" width={{ xs: '100%', lg: '70%' }}>
              <form className="contact-form" style={{ flexDirection: 'column' }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="firstName"
                      value={firstName}
                      required
                      fullWidth
                      label="Nombre"
                      variant="filled"
                      sx={{ bgcolor: '#fff', borderRadius: '10px' }}
                      InputLabelProps={{
                        style: {
                          color: 'grey',
                        },
                      }}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="lastName"
                      value={lastName}
                      required
                      fullWidth
                      label="Apellido"
                      variant="filled"
                      sx={{ bgcolor: '#fff', borderRadius: '10px' }}
                      InputLabelProps={{
                        style: {
                          color: 'grey',
                        },
                      }}
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="email"
                      value={email}
                      required
                      autoFocus
                      autoComplete="email"
                      type="email"
                      fullWidth
                      label="Email"
                      variant="filled"
                      sx={{ bgcolor: '#fff', borderRadius: '10px' }}
                      InputLabelProps={{
                        style: {
                          color: 'grey',
                        },
                      }}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      error={email.length !== 0 && !validateEmail(email)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <PhoneInput
                      country="cl"
                      placeholder="Teléfono"
                      value={phone}
                      onChange={(value) => {
                        setPhone(value);
                      }}
                      inputStyle={{
                        width: '100%',
                        height: '55px',
                        font: 'inherit',
                        paddingLeft: '48px',
                        borderRadius: '3px',
                        color: 'black',
                      }}
                      isValid={() => {
                        if (phone.length === 3 || validatePhone(phone)) {
                          return true;
                        }
                        if (!phone.startsWith('569')) {
                          return 'Número debe comenzar con +56 9';
                        }
                        return 'Largo de número incorrecto';
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="rut"
                      value={rut}
                      autoComplete="rut"
                      required
                      fullWidth
                      label="Rut"
                      variant="filled"
                      sx={{ bgcolor: '#fff', borderRadius: '10px' }}
                      InputLabelProps={{
                        style: {
                          color: 'grey',
                        },
                      }}
                      onChange={rutFormatter}
                      error={rut.length !== 0 && !validateRut(rut)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="documentNumber"
                      value={documentNumber}
                      required
                      fullWidth
                      label="Número de documento de carnet de identidad"
                      variant="filled"
                      sx={{ bgcolor: '#fff', borderRadius: '10px' }}
                      InputLabelProps={{
                        style: {
                          color: 'grey',
                        },
                      }}
                      onChange={documentNumberFormatter}
                      error={documentNumber.length !== 0 && !validateDocumentNumber(documentNumber)}
                    />
                  </Grid>
                </Grid>
                <FormControlLabel
                  control={<Checkbox checked />}
                  label={
                    <Typography fontSize={18} color="white">
                      Acepto{' '}
                      <Link
                        rel="noreferrer"
                        target="_blank"
                        href="https://storage.googleapis.com/relif-public-bucket/Poder%20Especial.pdf"
                      >
                        Poder
                      </Link>{' '}
                      y{' '}
                      <Link
                        rel="noreferrer"
                        target="_blank"
                        href="https://storage.googleapis.com/relif-public-bucket/terms_and_conditions.pdf"
                      >
                        Términos & Condiciones
                      </Link>
                    </Typography>
                  }
                />
                <Tooltip title={getHelperText()} placement="bottom" arrow>
                  <span>
                    <button
                      type="button"
                      className="sign-mandate-button"
                      onClick={(e) => {
                        e.preventDefault();
                        signMandate();
                      }}
                      disabled={validateAllInputs()}
                    >
                      Enviar
                    </button>
                  </span>
                </Tooltip>
              </form>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

SignMandate.defaultProps = {
  header: (
    <Box flexDirection="row" alignItems="flex-end" display="flex" mt={1} mb={2}>
      <img alt="Relif" src="/relif_logo_white.webp" height="100px" />
    </Box>
  ),
  title: 'Mejora tus deudas',
  description:
    'Necesitamos algunos datos para poder ayudarte. Nos darás permiso para ir a negociar tus deudas con tus acreedores. Intentaremos conseguirte descuentos en tus deudas morosas y conseguir mejores condiciones en tus créditos al día. Tú tomarás la decisión final.',
};

export default SignMandate;
