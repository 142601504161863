import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Avatar, Box, CircularProgress, Grid, Tooltip, Typography } from '@mui/material';

import { HelpIcon } from 'assets/icons';
import PrimaryBtn from 'components/UI/Buttons';
import PaperContainer from 'components/UI/PaperContainer';
import SectionHeader from 'components/layout/SectionHeader';
import priceFormat from 'services/format/priceFormat';
import trpc from 'trpc';

const Metrics = () => {
  const navigate = useNavigate();
  const metricsQuery = trpc.HRMetrics.metrics.useQuery();

  const downloadUsersAsCSV = async () => {
    if (!metricsQuery.data?.usersByCompany) return;
    let csvContent = 'data:text/csv;charset=utf-8,';
    const headers = ['Empresa', 'RUT', 'Fecha de registro', 'Nombre', 'Apellido'];
    csvContent += `${headers.join(',')}\n`;
    Object.entries(metricsQuery.data.usersByCompany).forEach(([companyName, users]) => {
      users.forEach((user) => {
        csvContent += `${companyName},${user.rut},${new Date(user.registeredAt).toLocaleDateString()},${
          user.firstName
        },${user.lastName}\n`;
      });
    });
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'usuarios.csv');
    document.body.appendChild(link);
    link.click();
  };

  const helpTooltip = (text: string) => (
    <Tooltip
      arrow
      title={
        <Typography color="white" fontSize="20px">
          {text}
        </Typography>
      }
      placement="bottom"
    >
      <Avatar
        sx={{
          borderRadius: '50%',
          bgcolor: 'info.main',
          width: '24px',
          height: '24px',
        }}
      >
        <HelpIcon />
      </Avatar>
    </Tooltip>
  );

  return (
    <Box>
      <SectionHeader
        text="Métricas"
        button={<PrimaryBtn onClick={() => navigate('/hr/allowed-users')}>Usuarios Permitidos</PrimaryBtn>}
      />
      <Grid container>
        <Grid item xs={12} md={4} p={1}>
          <PaperContainer
            title="Usuarios Registrados"
            actionBtn={helpTooltip('Todos los usuarios registrados hasta el momento')}
            fullWidth
            fullHeight
          >
            <Typography variant="h1" sx={{ width: '100%' }} color="info.main" textAlign="center">
              {metricsQuery.data ? metricsQuery.data.totalUsers : <CircularProgress />}
            </Typography>
          </PaperContainer>
        </Grid>
        <Grid item xs={12} md={4} p={1}>
          <PaperContainer title="Porcentaje de usuarios que piden ayuda con sus deudas" fullWidth fullHeight>
            <Typography variant="h1" sx={{ width: '100%' }} color="info.main" textAlign="center">
              {metricsQuery.data ? (
                `${(metricsQuery.data.usersThatAskForHelpRate * 100).toFixed(0)}%`
              ) : (
                <CircularProgress />
              )}
            </Typography>
          </PaperContainer>
        </Grid>
        <Grid item xs={12} md={4} p={1}>
          <PaperContainer title="Usuarios asesorados por videollmada" fullWidth fullHeight>
            <Typography variant="h1" sx={{ width: '100%' }} color="info.main" textAlign="center">
              {metricsQuery.data ? metricsQuery.data.hubspotUsers : <CircularProgress />}
            </Typography>
          </PaperContainer>
        </Grid>
        <Grid item xs={12} md={4} p={1}>
          <PaperContainer title="Usuarios asesorados por WhatsApp" fullWidth fullHeight>
            <Typography variant="h1" sx={{ width: '100%' }} color="info.main" textAlign="center">
              {metricsQuery.data ? metricsQuery.data.usersThatSentWA : <CircularProgress />}
            </Typography>
          </PaperContainer>
        </Grid>
        <Grid item xs={12} md={4} p={1}>
          <PaperContainer
            title="Usuarios con soluciones concretas encontradas"
            actionBtn={helpTooltip('La cantidad de usuario a los que les hemos encontrado ofertas')}
            fullWidth
            fullHeight
          >
            <Box display="flex" justifyContent="center" alignItems="baseline">
              <Typography variant="h1" color="info.main" textAlign="center">
                {metricsQuery.data ? metricsQuery.data.usersWithOffers : <CircularProgress />}
              </Typography>
              <Typography variant="h3" color="info.light" textAlign="center" pl={3}>
                {metricsQuery.data && `(${(metricsQuery.data.usersWithSolutionsRate * 100).toFixed(0)}%)`}
              </Typography>
            </Box>
          </PaperContainer>
        </Grid>
        <Grid item xs={12} md={4} p={1}>
          <PaperContainer
            title="Porcentaje de usuarios reportados en Dicom"
            actionBtn={helpTooltip(
              'El porcentaje de usuarios que firmaron los términos y condiciones y que están en Dicom'
            )}
            fullWidth
            fullHeight
          >
            <Typography variant="h1" sx={{ width: '100%' }} color="info.main" textAlign="center">
              {metricsQuery.data ? `${(metricsQuery.data.defaultedUsersRate * 100).toFixed(0)}%` : <CircularProgress />}
            </Typography>
          </PaperContainer>
        </Grid>
        <Grid item xs={12} md={4} p={1}>
          <PaperContainer
            title="Porcentaje de usuarios con deudas al dia"
            actionBtn={helpTooltip(
              'El porcentaje de usuarios que firmaron los términos y condiciones y que tienen NO tienen deudas morosas'
            )}
            fullWidth
            fullHeight
          >
            <Typography variant="h1" sx={{ width: '100%' }} color="info.main" textAlign="center">
              {metricsQuery.data ? (
                `${((1 - metricsQuery.data.defaultedUsersRate) * 100).toFixed(0)}%`
              ) : (
                <CircularProgress />
              )}
            </Typography>
          </PaperContainer>
        </Grid>
        <Grid item xs={12} md={4} p={1}>
          <PaperContainer title="Ofertas de normalización" fullWidth fullHeight>
            <Box display="flex" justifyContent="center" alignItems="baseline">
              <Typography variant="h1" color="info.main" textAlign="center">
                {metricsQuery.data ? metricsQuery.data.normalizationOffers : <CircularProgress />}
              </Typography>
              <Typography variant="h3" color="info.light" textAlign="center" pl={3}>
                {metricsQuery.data && `(${(metricsQuery.data.normalizationOffersRate * 100).toFixed(0)}%)`}
              </Typography>
            </Box>
          </PaperContainer>
        </Grid>
        <Grid item xs={12} md={4} p={1}>
          <PaperContainer title="Ofertas de refinanciamiento" fullWidth fullHeight>
            <Box display="flex" justifyContent="center" alignItems="baseline">
              <Typography variant="h1" color="info.main" textAlign="center">
                {metricsQuery.data ? metricsQuery.data.refinanceOffers : <CircularProgress />}
              </Typography>
              <Typography variant="h3" color="info.light" textAlign="center" pl={3}>
                {metricsQuery.data && `(${(metricsQuery.data.refinanceOffersRate * 100).toFixed(0)}%)`}
              </Typography>
            </Box>
          </PaperContainer>
        </Grid>
        <Grid item xs={12} md={4} p={1}>
          <PaperContainer title="Monto total de ofertas" fullWidth fullHeight>
            <Box display="flex" justifyContent="center" alignItems="baseline">
              <Typography variant="h1" color="info.main" textAlign="center" fontSize="3rem">
                {metricsQuery.data ? priceFormat.format(metricsQuery.data.totalOffersAmount) : <CircularProgress />}
              </Typography>
            </Box>
          </PaperContainer>
        </Grid>
        {metricsQuery.data?.usersByCompany && Object.keys(metricsQuery.data.usersByCompany).length > 0 && (
          <Grid item xs={12} p={1}>
            <PaperContainer title="Usuarios por empresa" fullWidth fullHeight>
              <PrimaryBtn
                onClick={() => {
                  downloadUsersAsCSV().catch((e) => console.error(e)); // eslint-disable-line no-console
                }}
              >
                Descargar
              </PrimaryBtn>
              <table>
                <thead>
                  <tr>
                    <th>Empresa</th>
                    <th>RUT</th>
                    <th>Nombre</th>
                    <th>Apellido</th>
                    <th>Fecha de registro</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(metricsQuery.data.usersByCompany).map(([companyName, users]) => (
                    <React.Fragment key={companyName}>
                      <tr>
                        <td colSpan={3}>{companyName}</td>
                      </tr>
                      {users.map((user) => (
                        <tr key={user.rut}>
                          <td>{companyName}</td>
                          <td>{user.rut}</td>
                          <td>{user.firstName}</td>
                          <td>{user.lastName}</td>
                          <td>{new Date(user.registeredAt).toLocaleDateString()}</td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </PaperContainer>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Metrics;
