import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Button } from '@dscla/ds-button/react';
import { MoreIcon } from 'assets/icons';
import { PrimaryBtnWithIcon } from 'components/UI/Buttons';
import CajaTooltip from 'components/UI/CajaTooltip';
import PaperContainer from 'components/UI/PaperContainer';
import { UserRefinanceStatus } from 'services/types/user';

interface Props {
  refinanceStatus: UserRefinanceStatus | null;
  setOpenRefinanceStatusDrawer: (open: boolean) => void;
  showRefreshBtn: boolean;
  handleRefreshDebts: () => void;
}

const RefinanceStatusBox: FC<Props> = ({
  refinanceStatus,
  setOpenRefinanceStatusDrawer,
  showRefreshBtn,
  handleRefreshDebts,
}) => {
  const navigate = useNavigate();
  const { type } = refinanceStatus || {};
  const getRefinanceTypeDescription = () => {
    switch (type) {
      case 'normalize':
        return 'normalización de deudas atrasadas';
      case 'refinance':
        return 'refinanciamiento de deudas al día';
      default:
        return '';
    }
  };

  if (!refinanceStatus) {
    return null;
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <PaperContainer
        title="Estado"
        fullWidth
        actionBtn={
          <PrimaryBtnWithIcon
            icon={<MoreIcon />}
            text="Ver detalle"
            onClick={() => setOpenRefinanceStatusDrawer(true)}
          />
        }
      >
        <Typography
          variant="body1"
          sx={{
            textAlign: 'left',
            mb: 1,
            lineHeight: '1.5',
          }}
        >
          Te encuentras en proceso de <b>{getRefinanceTypeDescription()}</b>
        </Typography>
        {/* This buttons are here because they don't fit in the section header in small screens */}
        <Box sx={{ display: { xs: 'flex', sm: 'none' }, justifyContent: 'space-between' }}>
          {showRefreshBtn && (
            <CajaTooltip title="Aprieta este botón para actualizar tus deudas con la clave única" arrow>
              <Box paddingRight={1}>
                <Button onClick={handleRefreshDebts}>Actualizar deudas</Button>
              </Box>
            </CajaTooltip>
          )}
          <Button onClick={() => navigate('/debt/debts-report')}>Ver boletín comercial</Button>
        </Box>
      </PaperContainer>
    </Box>
  );
};

export default RefinanceStatusBox;
