import React, { FC, useEffect, useState } from 'react';

import { Box, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import {
  useGetWhatsAppTemplatesQuery,
  usePostBulkTemplatesMutation,
  usePostTemplateMutation,
} from 'services/api/whatsApp.api';
import { SendWhatsAppTemplate, WhatsAppTemplateResponse } from 'services/types/whatsApp';

declare type Parameter = { text: string; type: 'text' };
declare type Parameters = { HEADER: Parameter[]; BODY: Parameter[]; FOOTER: Parameter[]; BUTTON: Parameter[] };
const getEmptyParameters = (): Parameters => ({
  HEADER: [],
  BODY: [],
  FOOTER: [],
  BUTTON: [],
});

interface Props {
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
  selectedPhone: string | undefined;
}

const TemplateForm: FC<Props> = ({ openModal, setOpenModal, selectedPhone }) => {
  const { data: templates } = useGetWhatsAppTemplatesQuery(null);
  const [messageToSend, setMessageToSend] = useState({
    HEADER: '',
    BODY: '',
    FOOTER: '',
    BUTTONS: [
      { text: '', url: '' },
      { text: '', url: '' },
    ],
  });
  const [selectedTemplate, setSelectedTemplate] = useState<WhatsAppTemplateResponse['data'][number] | null>(null);
  const [usersPhones, setUsersPhones] = useState<string>('');
  const [postTemplate] = usePostTemplateMutation();
  const [postBulkTemplates] = usePostBulkTemplatesMutation();
  const [parameters, setParameters] = useState<Parameters>(getEmptyParameters());

  const emptyParameters = () => {
    setParameters(getEmptyParameters());
    setSelectedTemplate(null);
    setMessageToSend({
      HEADER: '',
      BODY: '',
      FOOTER: '',
      BUTTONS: [
        { text: '', url: '' },
        { text: '', url: '' },
      ],
    });
    setUsersPhones('');
  };

  useEffect(() => {
    // build Form
    if (selectedTemplate) {
      const tempParameters: Parameters = getEmptyParameters();
      selectedTemplate?.components.forEach((component) => {
        if (component.type !== 'BUTTONS') {
          const count = (component.text?.match(/{{[1-9]}}/g) || []).length;
          for (let i = 0; i < count; i += 1) {
            tempParameters[component.type].push({ text: '', type: 'text' });
          }
        } else if (component.buttons) {
          // There can only be 2 buttons but only one with a url
          let url;
          if (component.buttons.length === 2) {
            url = component.buttons[0].url || component.buttons[1].url || '';
          } else {
            url = component.buttons[0].url || '';
          }
          const count = (url.match(/{{[1-9]}}/g) || []).length;
          for (let i = 0; i < count; i += 1) {
            tempParameters.BUTTON.push({ text: '', type: 'text' });
          }
        }
      });
      setParameters(tempParameters);
    }
  }, [selectedTemplate]);

  const getTemplateMessage = () => {
    const tempMessageToSend = {
      HEADER: '',
      BODY: '',
      FOOTER: '',
      BUTTONS: [
        { text: '', url: '' },
        { text: '', url: '' },
      ],
    };
    selectedTemplate?.components.forEach((component) => {
      if (component.text && component.type !== 'BUTTONS') {
        const count = (component.text.match(/{{[1-9]}}/g) || []).length;
        let newText = component.text;
        for (let i = 0; i < count; i += 1) {
          const re = new RegExp(`{{[${i + 1}]}}`, 'g');
          newText = newText.replace(re, parameters[component.type][i].text);
        }
        tempMessageToSend[component.type] = newText;
      } else if (component.buttons) {
        // There can only be 2 buttons but only one with a url
        let buttonWithUrl;
        if (component.buttons[0].url) {
          buttonWithUrl = 0;
        } else if (component.buttons.length === 2 && component.buttons[1].url) {
          buttonWithUrl = 1;
        }
        if (buttonWithUrl !== undefined) {
          let url = component.buttons[buttonWithUrl].url || '';
          const count = (url.match(/{{[1-9]}}/g) || []).length;
          for (let i = 0; i < count; i += 1) {
            const re = new RegExp(`{{[${i + 1}]}}`, 'g');
            url = url.replace(re, parameters.BUTTON[i].text);
          }
          tempMessageToSend.BUTTONS[buttonWithUrl].url = url;
        }
        component.buttons.forEach((button, index) => {
          tempMessageToSend.BUTTONS[index].text = button.text;
        });
      }
    });
    setMessageToSend(tempMessageToSend);
  };

  const getTemplateMessageWithoutParameters = () => {
    const tempMessageToSend = {
      HEADER: '',
      BODY: '',
      FOOTER: '',
      BUTTONS: [
        { text: '', url: '' },
        { text: '', url: '' },
        { text: '', url: '' },
      ],
    };
    selectedTemplate?.components.forEach((component) => {
      if (component.text && component.type !== 'BUTTONS') {
        tempMessageToSend[component.type] = component.text;
      } else if (component.buttons) {
        component.buttons.forEach((button, index) => {
          tempMessageToSend.BUTTONS[index].text = button.text;
          tempMessageToSend.BUTTONS[index].url = button.url || '';
        });
      }
    });
    setMessageToSend(tempMessageToSend);
  };

  const getMessageToSend = () => {
    let message = '';
    Object.keys(messageToSend).forEach((key) => {
      if (key === 'BUTTONS') {
        if (messageToSend.BUTTONS.some((button) => button.text !== '')) {
          message += '\nBotones: ';
          messageToSend.BUTTONS.filter((b) => b.text !== '').forEach((button) => {
            message += `(${button.text}) `;
            if (button.url !== '') {
              message += button.url;
            }
            message += '\n';
          });
        }
      } else if (messageToSend[key as keyof Omit<Parameters, 'BUTTON'>] !== '') {
        message += `${messageToSend[key as keyof Omit<Parameters, 'BUTTON'>]}\n`;
      }
    });
    return message;
  };

  useEffect(() => {
    if (
      parameters.HEADER.length > 0 ||
      parameters.BODY.length > 0 ||
      parameters.FOOTER.length > 0 ||
      parameters.BUTTON.length > 0
    ) {
      getTemplateMessage();
    } else {
      getTemplateMessageWithoutParameters();
    }
  }, [parameters]);

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>, type: keyof Parameters, index: number) => {
    const data = [...parameters[type]];
    data[index].text = event.target.value;
    setParameters({ ...parameters, [type]: data });
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    let components: SendWhatsAppTemplate['components'] = [];
    Object.keys(parameters).forEach((key) => {
      if (parameters[key as keyof Parameters].length > 0 && components) {
        components.push({
          type: key.toLowerCase() as 'header' | 'body' | 'footer' | 'button',
          parameters: parameters[key as keyof Parameters],
          sub_type: key === 'BUTTON' ? ('url' as const) : undefined,
          index: key === 'BUTTON' ? 0 : undefined,
        });
      }
    });
    if (components.length === 0) {
      components = undefined;
    }
    if (openModal && usersPhones && selectedTemplate) {
      postBulkTemplates({
        toPhoneNumbers: usersPhones.split(','),
        template: {
          text: getMessageToSend(),
          languageCode: selectedTemplate.language,
          templateName: selectedTemplate.name,
          components,
        },
      }).catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
    } else if (selectedPhone && selectedTemplate) {
      postTemplate({
        toUserPhone: selectedPhone,
        text: getMessageToSend(),
        languageCode: selectedTemplate.language,
        templateName: selectedTemplate.name,
        components,
      }).catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
    }
    setOpenModal(false);
    emptyParameters();
  };

  return (
    <>
      {openModal && (
        <TextField
          id="outlined-basic-email"
          label="Lista de números separados por coma"
          fullWidth
          sx={{ marginBottom: 2 }}
          value={usersPhones}
          onChange={(e) => setUsersPhones(e.target.value)}
        />
      )}
      <FormControl fullWidth>
        <InputLabel id="select-template">Nombre de la plantilla</InputLabel>
        <Select
          labelId="select-template"
          id="select-template"
          value={selectedTemplate?.name || ''}
          label="Nombre de la plantilla"
          onChange={(e) => {
            const targetTemplate = templates?.data.find((template) => template.name === e.target.value);
            if (targetTemplate) {
              setSelectedTemplate(targetTemplate);
            }
          }}
        >
          {templates?.data.map((template) => (
            <MenuItem key={template.id} value={template.name}>
              {template.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <form onSubmit={handleFormSubmit}>
        <Stack spacing={1} width="100%" justifyContent="center">
          {Object.keys(parameters).map((key) => {
            if (parameters[key as keyof Parameters].length > 0) {
              return (
                <Box key={key}>
                  <Typography>Parámetros {key}</Typography>
                  {parameters[key as keyof Parameters].map((form, index) => (
                    <div key={`${key}-${index.toString()}`}>
                      <input
                        name={`text-${index.toString()}`}
                        onChange={(event) => handleFormChange(event, key as keyof Parameters, index)}
                        value={form.text}
                      />
                    </div>
                  ))}
                </Box>
              );
            }
            return null;
          })}
          {selectedTemplate && (
            <Button type="submit" variant="contained">
              Enviar Plantilla
            </Button>
          )}
        </Stack>
      </form>
      {messageToSend && (
        <Stack spacing={2} width="100%">
          <Typography variant="h2" width="100%">
            Mensaje a enviar:
          </Typography>
          {messageToSend.HEADER && (
            <Typography variant="h3" sx={{ whiteSpace: 'pre-line' }} width="100%">
              {messageToSend.HEADER}
            </Typography>
          )}
          {messageToSend.BODY && (
            <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
              {messageToSend.BODY}
            </Typography>
          )}
          {messageToSend.FOOTER && (
            <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }} width="100%">
              {messageToSend.FOOTER}
            </Typography>
          )}
          {messageToSend.BUTTONS.some((b) => b.text !== '') && (
            <Box display="flex" alignItems="center">
              <Typography variant="body1" pr={1}>
                Botones:
              </Typography>
              {messageToSend.BUTTONS.map(
                (button) =>
                  button.text && (
                    <Typography
                      key={button.text}
                      variant="body2"
                      sx={{ whiteSpace: 'pre-line', border: '2px solid', borderRadius: '20px', px: 2, mr: 2 }}
                    >
                      {button.text} {button.url && <a href={button.url}>({button.url})</a>}
                    </Typography>
                  )
              )}
            </Box>
          )}
        </Stack>
      )}
    </>
  );
};

export default TemplateForm;
