import * as React from 'react';

import Report from '@mui/icons-material/Report';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Button as CajaButton } from '@dscla/ds-button/react';
import useIsCajaLosAndesTheme from 'hooks/useIsCajaLosAndesTheme';

interface AlertDialogInterface {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  header: string;
  confirmMsg?: string;
  msg: string;
  confirmAction?: (() => void) | (() => Promise<void>);
  cancelMsg?: string;
}

const AlertDialog: React.FC<AlertDialogInterface> = ({
  openDialog: open,
  setOpenDialog: setOpen,
  header,
  msg,
  confirmMsg,
  confirmAction,
  cancelMsg,
}) => {
  const isCajaTheme = useIsCajaLosAndesTheme();
  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    // if confirmAction is void just execute it
    // if confirmAction is Promise<void> execute it and catch the error
    if (typeof confirmAction === 'function') {
      // cast to void to avoid TS error
      const voidConfirmAction = confirmAction as () => void;
      voidConfirmAction();
    } else if (confirmAction !== undefined) {
      const promiseConfirmAction = confirmAction as () => Promise<void>;
      promiseConfirmAction().catch((err) => console.error(err)); // eslint-disable-line no-console
    }
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ display: 'flex', alignItems: 'center' }}>
        <Report color="error" sx={{ mr: 1 }} /> {header}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={{ whiteSpace: 'pre-line' }}>
          {msg}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {isCajaTheme ? (
          <>
            <CajaButton onClick={handleClose} variant="secundario">
              {cancelMsg && cancelMsg !== '' ? cancelMsg : 'Cancelar'}
            </CajaButton>
            {confirmMsg !== undefined && handleConfirm !== undefined && (
              <CajaButton onClick={handleConfirm} color="rojo">
                {confirmMsg}
              </CajaButton>
            )}
          </>
        ) : (
          <>
            <Button onClick={handleClose}>{cancelMsg && cancelMsg !== '' ? cancelMsg : 'Cancelar'}</Button>
            {confirmMsg !== undefined && handleConfirm !== undefined && (
              <Button onClick={handleConfirm} autoFocus color="error">
                {confirmMsg}
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

AlertDialog.defaultProps = {
  cancelMsg: undefined,
  confirmAction: undefined,
  confirmMsg: undefined,
};

export default AlertDialog;
