/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, ReactNode, FC, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

// @ts-ignore
import { Button } from '@dscla/ds-button/react';
// @ts-ignore
import { Tag } from '@dscla/ds-tag/react';
import {
  ExpandMoreIcon,
  HomeIcon,
  ProfileIcon,
  EntertainmentIcon,
  InstitutionIcon,
  GoalIcon,
  ChildIcon,
  IncomeIcon,
  HelpIcon,
} from 'assets/icons';
import CajaTooltip from 'components/UI/CajaTooltip';
import PaperContainer from 'components/UI/PaperContainer';
import useIsCajaLosAndesTheme from 'hooks/useIsCajaLosAndesTheme';
import priceFormat from 'services/format/priceFormat';

import { CompactPercentBar } from './PercentBar';

export const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={1} {...props} />)(
  ({ theme }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
    borderLeft: 0,
    borderRight: 0,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '& .MuiAccordionDetails-root': {
      paddingTop: 0,
    },
  })
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '65px',
  '& .MuiAccordionSummary-content': {
    height: '100%',
    alignItems: 'center',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    backgroundColor: '#EDF1FA',
    borderRadius: '10px',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  [theme.breakpoints.down('lg')]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '3px',
    paddingRight: '3px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
}));

export const Summary = (props: {
  children: ReactNode;
  text: string;
  tooltip?: string | null;
  color?: 'primary' | 'success' | 'error' | 'warning';
  totalAmount?: number;
  badge: string | number;
  panelId: number;
  percentage: null | number;
}) => {
  const { children, text, panelId, badge, percentage, color, totalAmount, tooltip } = props;
  const textColor = color ? color.concat('.main') : 'info.main';
  const bgColor = color ? color.concat('.light') : 'info.light';

  return (
    <AccordionSummary aria-controls={`panel${panelId}d-content`} id={`panel${panelId}d-header`}>
      <Box width={{ xs: '13%', lg: '7%' }} pr={6}>
        {children}
      </Box>

      <Box width={{ lg: '42%' }} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <CajaTooltip title={tooltip || ''} arrow>
          <Typography fontSize={{ xs: '16px', lg: '18px' }} fontWeight={600} color={textColor}>
            {text} <HelpIcon sx={{ fontSize: '18px', display: tooltip === null ? 'none' : 'inline-flex' }} />
          </Typography>
        </CajaTooltip>
        {totalAmount ? (
          <Typography fontSize={{ xs: '16px', lg: '18px' }} fontWeight={600} color={textColor}>
            {priceFormat.format(Number(totalAmount))}
          </Typography>
        ) : null}
      </Box>

      {percentage ? (
        <Box width={{ lg: '25%' }} ml="100px" display={{ xs: 'none', lg: 'block' }}>
          <CompactPercentBar value={percentage} title="del ingreso total" barColor={textColor} bgColor={bgColor} />
        </Box>
      ) : null}

      <Box ml="auto" pr={1}>
        {textColor.includes('warning') ? (
          <Tag label={badge} subVariant="error" />
        ) : (
          <Tag label={badge} subVariant="success" />
        )}
      </Box>
    </AccordionSummary>
  );
};

Summary.defaultProps = {
  color: 'info',
  totalAmount: null,
  tooltip: null,
};

interface SectionAccordionInterface {
  IncomeForm: ReactElement;
  incomeBadge: string;
  HomeForm: ReactElement;
  homeBadge: string;
  FamilyForm: ReactElement;
  familyBadge: string;
  ChildForm: ReactElement;
  childBadge: string;
  EntertainmentForm: ReactElement;
  entertainmentBadge: string;
  FinanceForm: ReactElement;
  financeBadge: string;
  ObjectiveForm: ReactElement;
  objectiveBadge: string;
  actionButtons?: ReactNode;
}
const SectionAccordion: FC<SectionAccordionInterface> = ({
  IncomeForm,
  incomeBadge,
  HomeForm,
  homeBadge,
  FamilyForm,
  familyBadge,
  ChildForm,
  childBadge,
  FinanceForm,
  financeBadge,
  EntertainmentForm,
  entertainmentBadge,
  ObjectiveForm,
  objectiveBadge,
  actionButtons,
}) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState<string[]>(['panel6']);
  const isCajaTheme = useIsCajaLosAndesTheme();

  const handleChange = (panel: string) => () => {
    setExpanded((prevExpanded) => {
      if (prevExpanded.indexOf(panel) === -1) {
        return [...prevExpanded, panel];
      }
      return prevExpanded.filter((x) => x !== panel);
    });
  };
  return (
    <PaperContainer fullWidth title="Categorías" actionBtn={actionButtons}>
      <Accordion expanded={expanded.indexOf('panel1') !== -1} onChange={handleChange('panel1')}>
        <Summary text="Ingresos" badge={incomeBadge} panelId={1} percentage={null} color="success">
          <Avatar
            sx={{
              width: { xs: '25px', lg: '35px' },
              height: { xs: '25px', lg: '35px' },
              backgroundColor: 'success.main',
            }}
          >
            <IncomeIcon sx={{ width: '70%', height: '70%' }} />
          </Avatar>
        </Summary>
        <AccordionDetails>{IncomeForm}</AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded.indexOf('panel2') !== -1} onChange={handleChange('panel2')}>
        <Summary
          text="Gastos de hogar"
          badge={homeBadge}
          panelId={2}
          percentage={null}
          color="warning"
          tooltip="Todos esos gastos para mantener tu casa funcionando. En general el que más se puede disminuir es el de supermercado, busca opciones al por mayor y eliminar alimentos evitables (bebidas gaseosas, papas fritas, chocolates, que son caros y poco saludables)"
        >
          <Avatar
            sx={{
              width: { xs: '25px', lg: '35px' },
              height: { xs: '25px', lg: '35px' },
              backgroundColor: 'warning.main',
            }}
          >
            <HomeIcon sx={{ width: '70%', height: '70%' }} />
          </Avatar>
        </Summary>
        <AccordionDetails>{HomeForm}</AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded.indexOf('panel3') !== -1} onChange={handleChange('panel3')}>
        <Summary
          text="Gastos personales"
          badge={familyBadge}
          panelId={3}
          percentage={null}
          color="warning"
          tooltip="Lo que gastas en moverte, estar sano y cualquier cosa que necesites durante el mes solo para ti"
        >
          <Avatar
            sx={{
              width: { xs: '25px', lg: '35px' },
              height: { xs: '25px', lg: '35px' },
              backgroundColor: 'warning.main',
            }}
          >
            <ProfileIcon sx={{ width: '70%', height: '70%' }} />
          </Avatar>
        </Summary>
        <AccordionDetails>{FamilyForm}</AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded.indexOf('panel7') !== -1} onChange={handleChange('panel7')}>
        <Summary
          text="Gastos de hijos"
          badge={childBadge}
          panelId={7}
          percentage={null}
          color="warning"
          tooltip="Todo lo que gastamos en nuestros niños (y no tan niños a veces)"
        >
          <Avatar
            sx={{
              width: { xs: '25px', lg: '35px' },
              height: { xs: '25px', lg: '35px' },
              backgroundColor: 'warning.main',
            }}
          >
            <ChildIcon sx={{ width: '70%', height: '70%' }} />
          </Avatar>
        </Summary>
        <AccordionDetails>{ChildForm}</AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded.indexOf('panel4') !== -1} onChange={handleChange('panel4')}>
        <Summary
          text="Gastos de entretención"
          badge={entertainmentBadge}
          panelId={4}
          percentage={null}
          color="warning"
          tooltip="Lo mejor del mes. Nuestra música, series, comida favorita, hobbies, todo! Ojo que este es el primero que hay que bajar en época de vacas flacas"
        >
          <Avatar
            sx={{
              width: { xs: '25px', lg: '35px' },
              height: { xs: '25px', lg: '35px' },
              backgroundColor: 'warning.main',
            }}
          >
            <EntertainmentIcon sx={{ width: '70%', height: '70%' }} />
          </Avatar>
        </Summary>
        <AccordionDetails>{EntertainmentForm}</AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded.indexOf('panel5') !== -1} onChange={handleChange('panel5')}>
        <Summary
          text="Gastos Financieros"
          badge={financeBadge}
          panelId={5}
          percentage={null}
          color="warning"
          tooltip="Los más lateros, son tus créditos y seguros (¿No tienes seguro? quizás deberías buscar uno)"
        >
          <Avatar
            sx={{
              width: { xs: '25px', lg: '35px' },
              height: { xs: '25px', lg: '35px' },
              backgroundColor: 'warning.main',
            }}
          >
            <InstitutionIcon sx={{ width: '70%', height: '70%' }} />
          </Avatar>
        </Summary>
        <AccordionDetails>
          <>
            {FinanceForm}
            <Alert
              sx={{ mt: 2 }}
              action={<Button onClick={() => navigate('/debt')}>Mejorar Deudas</Button>}
              severity="warning"
            >
              Aquí puedes refinanciar tus deudas morosas y al día
            </Alert>
          </>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded.indexOf('panel6') !== -1} onChange={handleChange('panel6')}>
        <Summary
          text="Objetivos"
          badge={objectiveBadge}
          panelId={6}
          percentage={null}
          tooltip="Esto viene de tu plan, es importante verlos como un gasto más, para así poder ahorrar siempre"
          color={isCajaTheme ? 'primary' : undefined}
        >
          <Avatar
            sx={{
              width: { xs: '25px', lg: '35px' },
              height: { xs: '25px', lg: '35px' },
              backgroundColor: `${isCajaTheme ? 'primary.main' : 'info.main'}`,
            }}
          >
            <GoalIcon sx={{ width: '70%', height: '70%' }} />
          </Avatar>
        </Summary>
        <AccordionDetails>{ObjectiveForm}</AccordionDetails>
      </Accordion>
    </PaperContainer>
  );
};

SectionAccordion.defaultProps = {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  actionButtons: <></>,
};

export default SectionAccordion;
