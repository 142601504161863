export type Hosts = {
  userId: number;
  hubSpotOwnerId: string;
  meetLink: string;
  name: string;
  description: string;
  img: string;
};

export const HostsData: Hosts[] = [
  {
    userId: 1,
    hubSpotOwnerId: '289864962',
    meetLink: 'https://meetings.hubspot.com/jorge-de-la-carrera',
    name: 'Jorge',
    description:
      'Fanático del fútbol, padel y tecnología. Vivió en EEUU y volvió a Chile a reducir el sobreendeudamiento',
    img: '/landing_page/img/team/jorge-azulclaro.webp',
  },
  {
    userId: 2,
    hubSpotOwnerId: '289181558',
    meetLink: 'https://meetings.hubspot.com/carlos622',
    name: 'Carlos',
    description:
      'Trabajó en el Banco Bice, tiene una certificación de asesorías financieras y hace refinanciamientos de deudas todos los días',
    img: '/landing_page/img/team/carlos-azulclaro.webp',
  },
  {
    userId: 4,
    hubSpotOwnerId: '289864972',
    meetLink: 'https://meetings.hubspot.com/benjamin-bustos',
    name: 'Benjamín',
    description: 'Trabajó en Finanzas corporativas, tiene un MBA en Londres y lidera un equipo de asesores financieros',
    img: '/landing_page/img/team/benja-azulclaro.webp',
  },
  {
    userId: 49,
    hubSpotOwnerId: '295532337',
    meetLink: 'https://meetings.hubspot.com/tomas140',
    name: 'Tomás',
    description: 'Fanático de la música y videojuegos, desarrolla productos tecnológicos para asesores financieros',
    img: '/landing_page/img/team/tomas-azulclaro.webp',
  },
  {
    userId: 34,
    hubSpotOwnerId: '295536487',
    meetLink: 'https://meetings.hubspot.com/juan352',
    name: 'Juan',
    description: 'Es fanático de la UC y ha escuchado todos los podcasts de finanzas personales',
    img: '/landing_page/img/team/juan-azulclaro.webp',
  },
  {
    userId: 1721,
    hubSpotOwnerId: '647038466',
    meetLink: 'https://meetings.hubspot.com/trinidad-olivos',
    name: 'Trinidad',
    description: '',
    img: '/landing_page/img/team/trinidad-azulclaro.webp',
  },
];
