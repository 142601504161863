import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';

import { dateFormat } from 'services/format/dateFormat';
import { User } from 'services/types/user';
import removeCSVInjection from 'utils/removeCSVInjection';

const USER_ATTRIBUTES = [
  {
    field: 'id',
    headerName: 'ID',
    width: 60,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 300,
  },
  {
    field: 'firstName',
    headerName: 'Nombre',
    width: 200,
  },
  {
    field: 'lastName',
    headerName: 'Apellido',
    width: 200,
  },
  {
    field: 'rut',
    headerName: 'RUT',
    width: 150,
  },
  {
    field: 'phone',
    headerName: 'Teléfono',
    width: 150,
  },
  {
    field: 'company.name',
    headerName: 'Nombre Empresa',
    width: 200,
    valueGetter: (_value: unknown, row: User) => (row.company?.name ?? '') + (row.origin ? `(${row.origin})` : ''),
  },
  {
    field: 'createdAtFormatted',
    headerName: 'Fecha ingreso',
    width: 120,
  },
];

interface PaginationModel {
  pageSize: number;
  pageIndex: number;
}
interface Props {
  users: User[];
  paginationModel: PaginationModel;
  setPaginationModel: (paginationModel: PaginationModel) => void;
  rowCount: number | undefined;
  isLoading: boolean;
}

interface DataGridState {
  selectedUserId: number | null;
}

const UsersTable: FC<Props> = ({ users, paginationModel, setPaginationModel, rowCount, isLoading }) => {
  const [state, setState] = React.useState<DataGridState>(() => {
    const queryParams = new URLSearchParams(window.location.search);

    const selectedUserId = parseInt(queryParams.get('selectedUserId') || '0', 10);
    if (selectedUserId) {
      return {
        selectedUserId,
      };
    }
    return {
      selectedUserId: null,
    };
  });
  const [currentRowCount, setCurrentRowCount] = React.useState<number>(0);
  const navigate = useNavigate();
  // remove possible CSV injection
  const usersRows = users.map((user) => ({
    ...user,
    email: removeCSVInjection(user.email || ''),
    firstName: removeCSVInjection(user.firstName || ''),
    lastName: removeCSVInjection(user.lastName || ''),
    rut: removeCSVInjection(user.rut || ''),
    company: user.company ? { ...user.company, name: removeCSVInjection(user.company.name || '') } : undefined,
    createdAtFormatted: dateFormat(new Date(user.createdAt)),
  }));
  const saveState = (newState: DataGridState) => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set('selectedUserId', newState.selectedUserId?.toString() || '');
    navigate(`?${queryParams.toString()}`);
    setState(newState);
  };
  const navigateToUser = (userId: number) => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set('userId', userId.toString());
    navigate(`/admin/users?${queryParams.toString()}`);
  };
  // set current row count, only when the table is loaded
  React.useEffect(() => {
    if (!isLoading && rowCount !== undefined) {
      setCurrentRowCount(rowCount || 0);
    }
  }, [isLoading, rowCount]);

  return (
    <Box style={{ width: '100%' }} my={2}>
      <Box
        sx={{
          backgroundColor: 'White',
          borderRadius: 4,
          p: 2,
        }}
      >
        <DataGrid
          rows={usersRows || []}
          columns={USER_ATTRIBUTES}
          slots={{
            toolbar: GridToolbar,
          }}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          sx={{
            color: 'Black',
            height: 'calc(100vh - 380px)',
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
            },
            '& .MuiDataGrid-row.Mui-selected': {
              backgroundColor: '#e8f0fe',
            },
            border: 'none',
          }}
          onRowClick={(params) => {
            saveState({ ...state, selectedUserId: (params.row as User).id });
            navigateToUser((params.row as User).id);
          }}
          rowSelectionModel={[state.selectedUserId || 0]}
          paginationModel={{ page: paginationModel.pageIndex || 0, pageSize: paginationModel.pageSize || 10 }}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationMode="server"
          pagination
          rowCount={currentRowCount}
          onPaginationModelChange={(newPaginationModel) =>
            setPaginationModel({
              ...newPaginationModel,
              pageIndex: newPaginationModel.page,
            })
          }
          loading={isLoading}
        />
      </Box>
    </Box>
  );
};

export default UsersTable;
