import React, { FC } from 'react';

import { Input, Typography } from '@mui/material';

import numberFormat from 'services/format/numberFormat';
import priceFormat from 'services/format/priceFormat';

const UFFormat = (value: number) => {
  return `UF ${numberFormat(value)}`;
};

interface MoneyInputInterface {
  label: string;
  value: number;
  setValue: (value: number) => void;
  maxValue?: number;
  error?: boolean;
  disabled?: boolean;
  currency?: string;
}

const MoneyInput: FC<MoneyInputInterface> = ({ label, value, setValue, maxValue, error, disabled, currency }) => {
  const setAmount = (newValue: string) => {
    const number = Number(newValue.replace(/\D+/g, ''));
    if (Number.isNaN(number) || number <= 0) {
      setValue(0);
    } else if (maxValue && number > maxValue) {
      setValue(maxValue);
    } else {
      setValue(number);
    }
  };

  return (
    <div>
      {label && (
        <Typography variant="body1" fontSize="16px" fontWeight={500} pb="10px" textAlign="left">
          {label}
        </Typography>
      )}
      <Input
        type="text"
        id={label}
        value={currency === 'CLP' ? priceFormat.format(value) : UFFormat(value)}
        onInput={(e: React.ChangeEvent<HTMLInputElement>) => setAmount(e.target.value)}
        disabled={disabled}
        error={error}
        fullWidth
      />
    </div>
  );
};

MoneyInput.defaultProps = {
  error: false,
  maxValue: undefined,
  disabled: false,
  currency: 'CLP',
};

export default MoneyInput;
