import React, { FC, useState } from 'react';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import PrimaryBtn, { BlackBtn } from 'components/UI/Buttons';
import { useEditUserMutation } from 'services/api/admin-users.api';
import { useGetCompaniesQuery } from 'services/api/companies.api';
import { EditUserBody, User as UserInterface } from 'services/types/user';

interface Props {
  user: UserInterface;
  handleBack: () => void;
}

const EditUser: FC<Props> = ({ user, handleBack }) => {
  const [editedUser, setEditedUser] = useState<UserInterface>(user);
  const [editUser] = useEditUserMutation();
  const { data: companies } = useGetCompaniesQuery(null);

  const handleSave = () => {
    const userBody: EditUserBody = {
      firstName: editedUser.firstName || '',
      lastName: editedUser.lastName || '',
      email: editedUser.email || '',
      phone: editedUser.phone || '',
      rut: editedUser.rut || '',
      isBlocked: !!editedUser.isBlocked,
      isTestUser: !!editedUser.isTestUser,
      companyId: editedUser.companyId || 1,
      isHRAdmin: !!editedUser.isHRAdmin,
      notificationsDisabled: editedUser.notificationsDisabled,
      bankOfferNotificationsDisabled: editedUser.bankOfferNotificationsDisabled,
    };
    editUser({ user: userBody, id: user.id })
      .then(() => handleBack())
      .catch((err) => console.log(err)); // eslint-disable-line no-console
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Grid container width="100%">
        <Grid item xs={12}>
          <Typography variant="h6">Editar usuario</Typography>
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          <TextField
            value={editedUser.firstName}
            onChange={(e) => setEditedUser({ ...editedUser, firstName: e.target.value })}
            fullWidth
            label="Nombre"
          />
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          <TextField
            value={editedUser.lastName}
            onChange={(e) => setEditedUser({ ...editedUser, lastName: e.target.value })}
            fullWidth
            label="Apellido"
          />
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          <TextField
            value={editedUser.email}
            onChange={(e) => setEditedUser({ ...editedUser, email: e.target.value })}
            fullWidth
            label="Email"
          />
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          <TextField
            value={editedUser.phone || ''}
            onChange={(e) => setEditedUser({ ...editedUser, phone: e.target.value })}
            fullWidth
            label="Teléfono"
          />
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          <TextField
            value={editedUser.rut}
            onChange={(e) => setEditedUser({ ...editedUser, rut: e.target.value })}
            fullWidth
            label="RUT"
          />
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          <FormControl fullWidth>
            <InputLabel id="company-label">Empresa</InputLabel>
            <Select
              labelId="company-label"
              label="Empresa"
              value={editedUser.companyId}
              onChange={(e) => setEditedUser({ ...editedUser, companyId: Number(e.target.value) })}
            >
              {companies?.map((company) => (
                <MenuItem key={company.id} value={company.id}>
                  {company.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          <Typography variant="body1">Bloqueado</Typography>
          <Checkbox
            checked={!!editedUser.isBlocked}
            onChange={(e) => setEditedUser({ ...editedUser, isBlocked: e.target.checked })}
          />
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          <Typography variant="body1">Test User</Typography>
          <Checkbox
            checked={!!editedUser.isTestUser}
            onChange={(e) => setEditedUser({ ...editedUser, isTestUser: e.target.checked })}
          />
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          <Typography variant="body1">Es admin de RRHH?</Typography>
          <Checkbox
            checked={!!editedUser.isHRAdmin}
            onChange={(e) => setEditedUser({ ...editedUser, isHRAdmin: e.target.checked })}
          />
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          <Typography variant="body1">Notificaciones desactivadas?</Typography>
          <Checkbox
            checked={editedUser.notificationsDisabled}
            onChange={(e) => setEditedUser({ ...editedUser, notificationsDisabled: e.target.checked })}
          />
        </Grid>
        <Grid item xs={12} md={6} p={1}>
          <Typography variant="body1">Notificaciones de ofertas bancarias desactivadas?</Typography>
          <Checkbox
            checked={editedUser.bankOfferNotificationsDisabled}
            onChange={(e) => setEditedUser({ ...editedUser, bankOfferNotificationsDisabled: e.target.checked })}
          />
        </Grid>
      </Grid>
      <Box sx={{ height: '1rem' }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <BlackBtn onClick={handleBack}>Volver</BlackBtn>
        <PrimaryBtn onClick={handleSave}>Guardar</PrimaryBtn>
      </Box>
    </Box>
  );
};

export default EditUser;
