import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Grid, Typography } from '@mui/material';

import PrimaryBtn from 'components/UI/Buttons';
import PaperContainer from 'components/UI/PaperContainer';
import SectionHeader from 'components/layout/SectionHeader';
import { useGetNPSResultsMutation, useGetNoActionResultsMutation } from 'services/api/surveys.api';

const currentDate = new Date();

const SurveyResults: FC = () => {
  const [getNPS, { data: NPSResults }] = useGetNPSResultsMutation();
  const [getNoAction, { data: noActionResults }] = useGetNoActionResultsMutation();
  const [startDate, setStartDate] = useState<Date>(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDay() - 30)
  );
  const [endDate, setEndDate] = useState<Date>(new Date());
  const navigate = useNavigate();

  useEffect(() => {
    getNPS({ startDate, endDate }).catch((e) => console.error(e)); // eslint-disable-line no-console
    getNoAction({ startDate, endDate }).catch((e) => console.error(e)); // eslint-disable-line no-console
  }, [startDate, endDate]);

  return (
    <Box height="100%">
      <SectionHeader
        text="Resultados de Encuestas"
        button={<PrimaryBtn onClick={() => navigate('/admin')}>Volver</PrimaryBtn>}
      />
      <Box>
        <Typography variant="h5">Fecha de inicio</Typography>
        <input
          type="date"
          value={startDate.toISOString().split('T')[0]}
          onChange={(e) => setStartDate(new Date(e.target.value))}
        />
        <Typography variant="h5">Fecha de fin</Typography>
        <input
          type="date"
          value={endDate.toISOString().split('T')[0]}
          onChange={(e) => setEndDate(new Date(e.target.value))}
        />
      </Box>

      {NPSResults && NPSResults.total > 0 ? (
        <>
          <Typography variant="h2" color="info.main" mt={4}>
            Resultados NPS:
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <PaperContainer fullWidth fullHeight>
                <Typography variant="h2" color="info.main">
                  Score NPS: {NPSResults?.NPS.toFixed(0)}
                </Typography>
                <Typography variant="h2" mt={1}>
                  <Box>Total Respuestas: {NPSResults?.total}</Box>
                  <Box>Promotores: {NPSResults?.promoters}</Box>
                  <Box>Detractores: {NPSResults?.detractors}</Box>
                  Pasivos: {NPSResults && NPSResults.total - NPSResults.detractors - NPSResults.promoters}
                </Typography>
              </PaperContainer>
            </Grid>
            <Grid item xs={12} md={4}>
              <PaperContainer title="Secciones Favoritas" fullWidth fullHeight>
                <Typography variant="h2">
                  {NPSResults &&
                    Object.keys(NPSResults.favouriteFeatures).map((feature) => (
                      <Box key={feature}>
                        {feature}: {NPSResults.favouriteFeatures[feature]}
                      </Box>
                    ))}
                </Typography>
              </PaperContainer>
            </Grid>
            <Grid item xs={12} md={4}>
              <PaperContainer title="Features Pedidas" fullWidth fullHeight>
                <Typography variant="h2">
                  {NPSResults &&
                    Object.keys(NPSResults.missingFeatures).map((feature) => (
                      <Box key={feature}>
                        {feature}: {NPSResults.missingFeatures[feature]}
                      </Box>
                    ))}
                </Typography>
              </PaperContainer>
            </Grid>
            <Grid item xs={12} md={4}>
              <PaperContainer title="Temas de Webinar" fullWidth fullHeight>
                <Typography variant="h2">
                  {NPSResults &&
                    Object.keys(NPSResults.webinarTopics).map((topic) => (
                      <Box key={topic}>
                        {topic}: {NPSResults.webinarTopics[topic]}
                      </Box>
                    ))}
                </Typography>
              </PaperContainer>
            </Grid>
          </Grid>
        </>
      ) : (
        <Typography variant="h2" color="info.main" mt={4}>
          No hay usuarios que hayan contestado la encuesta NPS en la fecha seleccionada.
        </Typography>
      )}

      {noActionResults && Object.keys(noActionResults.reasons).length > 0 ? (
        <>
          <Typography variant="h2" color="info.main" mt={4}>
            Usuarios que no contestaron:
          </Typography>
          <Grid container spacing={2}>
            <Grid item>
              <PaperContainer title="Motivos" fullWidth fullHeight>
                <Typography variant="h2">
                  {Object.keys(noActionResults.reasons).map((reason) => (
                    <Box key={reason}>
                      {reason}: {noActionResults.reasons[reason]}
                    </Box>
                  ))}
                  <Box mt={3}>Total: {Object.keys(noActionResults.reasons).length}</Box>
                </Typography>
              </PaperContainer>
            </Grid>
          </Grid>
        </>
      ) : (
        <Typography variant="h2" color="info.main" mt={4}>
          No hay usuarios sin acciones que hayan contestado la encuesta en la fecha seleccionada.
        </Typography>
      )}
    </Box>
  );
};

export default SurveyResults;
