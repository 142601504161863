import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { BlackBtn } from 'components/UI/Buttons';
import PaperContainer from 'components/UI/PaperContainer';
import { dateFormat } from 'services/format/dateFormat';
import { User } from 'services/types/user';

interface Props {
  bankOfferRequests: User['bankOfferRequests'];
}

const UserBankOfferRequests: FC<Props> = ({ bankOfferRequests }) => {
  const navigate = useNavigate();
  const navigateToBankOfferRequest = (id: number) => {
    navigate(`/admin/bank-offer-requests/${id}`);
  };
  return (
    <PaperContainer fullWidth title="Solicitudes de refinanciamiento">
      {!bankOfferRequests || bankOfferRequests.length === 0 ? (
        <p>No hay solicitudes de refinanciamiento</p>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Banco</TableCell>
              <TableCell>Agente</TableCell>
              <TableCell>Vía de envío</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Fecha de envío a banco</TableCell>
              <TableCell>Fecha de respuesta del banco</TableCell>
              <TableCell>Fecha de envío de datos de contacto</TableCell>
              <TableCell>Fecha de resolución</TableCell>
              <TableCell>Razón de rechazo del banco</TableCell>
              <TableCell>Razón de rechazo del usuario</TableCell>
              <TableCell>Ver detalle</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bankOfferRequests.map((bankOfferRequest) => (
              <TableRow key={bankOfferRequest.id}>
                <TableCell>{bankOfferRequest.id}</TableCell>
                <TableCell>{bankOfferRequest.bank}</TableCell>
                <TableCell>{bankOfferRequest.bankAgent}</TableCell>
                <TableCell>{bankOfferRequest.sentVia}</TableCell>
                <TableCell>{bankOfferRequest.status}</TableCell>
                <TableCell>{dateFormat(bankOfferRequest.sentToBankAt)}</TableCell>
                <TableCell>{dateFormat(bankOfferRequest.bankRepliedAt)}</TableCell>
                <TableCell>{dateFormat(bankOfferRequest.sentContactInfoAt)}</TableCell>
                <TableCell>{dateFormat(bankOfferRequest.offerDecisionAt)}</TableCell>
                <TableCell>{bankOfferRequest.bankRejectionReason}</TableCell>
                <TableCell>{bankOfferRequest.offerRejectionReason}</TableCell>
                <TableCell>
                  <BlackBtn
                    onClick={() => navigateToBankOfferRequest(bankOfferRequest.id as number)}
                    variant="contained"
                    size="small"
                  >
                    Ver detalle
                  </BlackBtn>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </PaperContainer>
  );
};

export default UserBankOfferRequests;
