import React from 'react';

import { Box, Typography } from '@mui/material';

import { AddIcon, EditIcon, DeleteIcon } from 'assets/icons';
import PrimaryBtn, {
  SecondaryBtn,
  EditBtn,
  DeleteBtn,
  EditBtnLarge,
  DeleteBtnLarge,
  CancelBtn,
  BlackBtn,
} from 'components/UI/Buttons';
import PaperContainer from 'components/UI/PaperContainer';

const ColorBox = ({ color, textColor = 'white' }: { color: string; textColor?: string }) => (
  <Box
    sx={{
      bgcolor: color,
      height: '170px',
      width: '170px',
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
      color: textColor,
    }}
  >
    {color}
  </Box>
);

ColorBox.defaultProps = {
  textColor: 'white',
};

const ThemeDemo = () => {
  return (
    <Box mt={3}>
      <Typography variant="h4" color="initial">
        Botones
      </Typography>
      <PrimaryBtn>PrimaryBtn</PrimaryBtn>
      <SecondaryBtn startIcon={<AddIcon />} sx={{ ml: 2 }}>
        SecondaryBtn
      </SecondaryBtn>
      <EditBtn sx={{ ml: 2 }}>
        <EditIcon />
      </EditBtn>
      <EditBtnLarge sx={{ ml: 2 }}>
        <EditIcon />
      </EditBtnLarge>
      <DeleteBtn sx={{ ml: 2 }}>
        <DeleteIcon />
      </DeleteBtn>
      <DeleteBtnLarge sx={{ ml: 2 }}>
        <DeleteIcon />
      </DeleteBtnLarge>
      <CancelBtn sx={{ ml: 2 }}>
        <DeleteIcon />
      </CancelBtn>
      <BlackBtn sx={{ ml: 2 }}>
        <DeleteIcon />
      </BlackBtn>
      <Typography variant="h4" color="initial">
        Paleta Principal
      </Typography>
      <Box
        sx={{
          width: '70%',
          display: 'flex',
          justifyContent: 'start',
          flexWrap: 'wrap',
        }}
      >
        <ColorBox color="secondary.main" textColor="black" />
        <ColorBox color="secondary.contrastText" />
        <ColorBox color="primary.contrastText" textColor="black" />
      </Box>
      <Typography variant="h4" color="initial">
        Paleta Complementaria
      </Typography>
      <Box
        sx={{
          width: '70%',
          display: 'flex',
          justifyContent: 'start',
          flexWrap: 'wrap',
        }}
      >
        <ColorBox color="primary.main" />
        <ColorBox color="primary.light" textColor="black" />
        <ColorBox color="secondary.light" textColor="black" />
      </Box>
      <Typography variant="h4" color="initial">
        Paleta Secundaria
      </Typography>
      <Box
        sx={{
          width: '80%',
          display: 'flex',
          justifyContent: 'start',
          flexWrap: 'wrap',
        }}
      >
        <ColorBox color="newBlue.A100" />
        <ColorBox color="newBlue.A400" />
        <ColorBox color="newBlue.A100" />
      </Box>
      <Typography variant="h4" color="initial">
        Otras Paletas
      </Typography>
      <Box
        sx={{
          width: '70%',
          display: 'flex',
          justifyContent: 'start',
          flexWrap: 'wrap',
        }}
      >
        <ColorBox color="success.main" />
        <ColorBox color="success.light" textColor="black" />
        <ColorBox color="error.main" />
        <ColorBox color="error.light" textColor="black" />
        <ColorBox color="warning.main" />
        <ColorBox color="warning.light" textColor="black" />
        <ColorBox color="info.main" textColor="black" />
        <ColorBox color="info.light" textColor="black" />
      </Box>
      <Typography variant="h4" color="initial">
        Textos
      </Typography>
      <Typography variant="h2">h2.Titulos Principales de una seccion</Typography>
      <Typography variant="h3">h3.Titulos secundarios de un paper</Typography>
      <Typography variant="h4">h4.Contenidos Importantes</Typography>
      <Typography variant="h5">h5.Subtitulos secundarios de un paper</Typography>
      <Typography variant="body1">body1.contenido principales</Typography>
      <Typography variant="body2">body2.contenido principales</Typography>
      <Typography variant="h4" color="initial">
        Contenedores
      </Typography>
      <PaperContainer
        title="PaperContainer"
        actionBtn={
          <EditBtn>
            <EditIcon />
          </EditBtn>
        }
      >
        <Typography variant="body1">Contenido</Typography>
      </PaperContainer>
    </Box>
  );
};

export default ThemeDemo;
