import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TextField, Box, Typography } from '@mui/material';
import { DataGrid, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';

import { DeleteIcon } from 'assets/icons';
import AlertDialog from 'components/UI/AlertDialog';
import PrimaryBtn, { DeleteBtn } from 'components/UI/Buttons';
import SearchBar from 'components/UI/SearchBar';
import SuccessDialog from 'components/UI/SuccessDialog';
import SectionHeader from 'components/layout/SectionHeader';
import { format } from 'rut.js';
import {
  useCreateAllowedUserHRAdminMutation,
  useDeleteAllowedUserHRAdminMutation,
  useGetAllowedUsersHRAdminQuery,
  useUploadFileMutation,
} from 'services/api/HRAdmin.api';
import validateEmail from 'utils/validations/validateEmail';
import validateRut from 'utils/validations/validateRut';

const AllowedUsers: FC = () => {
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState(false);
  const [rut, setRut] = useState<string>('');
  const [rutError, setRutError] = useState(false);

  const [emailsFile, setEmailsFile] = useState<File | null>(null);
  const [rutsFile, setRutsFile] = useState<File | null>(null);
  const [fileAlertDialogOpen, setFileAlertDialogOpen] = useState(false);
  const [fileAlertDialogMessage, setFileAlertDialogMessage] = useState('');

  const [createAllowedUser, { data: newUser }] = useCreateAllowedUserHRAdminMutation();
  const [createdAlertDialogOpen, setCreatedAlertDialogOpen] = useState(false);
  const [createdUserMessage, setCreatedUserMessage] = useState('');
  const [deleteAllowedUser, { data: deletedUser }] = useDeleteAllowedUserHRAdminMutation();
  const [userIdToDelete, setUserIdToDelete] = useState<null | number>(null);
  const [deleteAlertDialogOpen, setDeleteAlertDialogOpen] = useState(false);
  const { data: allowedUsers, refetch: refetchAllowedUsers } = useGetAllowedUsersHRAdminQuery(null);
  const [uploadFile, { error: uploadFileError, data: createdAllowedUsers }] = useUploadFileMutation();

  const [filteredAllowedUsers, setFilteredAllowedUsers] = useState(allowedUsers || []);

  const navigate = useNavigate();

  useEffect(() => {
    if (allowedUsers) {
      setFilteredAllowedUsers(allowedUsers);
    }
  }, [allowedUsers]);

  useEffect(() => {
    if (newUser) {
      setCreatedUserMessage(`Usuario: ${newUser.email || newUser.rut} agregado exitosamente`);
      setCreatedAlertDialogOpen(true);
      refetchAllowedUsers().catch((e) => console.error(e)); // eslint-disable-line no-console
    }
  }, [newUser]);

  useEffect(() => {
    if (deletedUser) {
      refetchAllowedUsers().catch((e) => console.error(e)); // eslint-disable-line no-console
    }
  }, [deletedUser]);

  useEffect(() => {
    if (createdAllowedUsers) {
      if (createdAllowedUsers.notValidRows.length > 0) {
        let failMessage = `No se pudieron procesar ${createdAllowedUsers.notValidRows.length} filas. Por favor revisa que los datos sean correctos, si el problema persiste háblanos a nuestro chat.\n`;
        if (createdAllowedUsers.notValidRows.length < 15) {
          failMessage = `Las siguientes filas que no se pudieron procesar:\n- ${createdAllowedUsers.notValidRows.join(
            '\n- '
          )}\n`;
        }
        setFileAlertDialogMessage(failMessage);
        setFileAlertDialogOpen(true);
      } else if (createdAllowedUsers.createdRows.length > 0) {
        let message = `Se crearon exitosamente ${createdAllowedUsers.createdRows.length} usuarios.\n`;
        if (createdAllowedUsers.createdRows.length < 15) {
          message = `Usuarios creados exitosamente:\n- ${createdAllowedUsers.createdRows.join('\n- ')}\n`;
        }
        setCreatedUserMessage(message);
        setCreatedAlertDialogOpen(true);
      }
      refetchAllowedUsers().catch((e) => console.error(e)); // eslint-disable-line no-console
    }
  }, [createdAllowedUsers]);

  useEffect(() => {
    if (uploadFileError) {
      if ('data' in uploadFileError) {
        const data = uploadFileError.data as { error: string } | string;
        if (typeof data === 'string') {
          setFileAlertDialogMessage(data);
        } else {
          setFileAlertDialogMessage(data.error);
        }
      } else {
        setFileAlertDialogMessage('Hubo un error subiendo tu archivo, por favor intentalo de nuevo.');
      }
      setFileAlertDialogOpen(true);
    }
  }, [uploadFileError]);

  const handleSubmit = (fileType: 'emails' | 'ruts') => (event: React.FormEvent) => {
    event.preventDefault();
    const formData = new FormData();
    if (emailsFile && fileType === 'emails') {
      formData.append('file', emailsFile);
      formData.append('fileType', fileType);
      uploadFile(formData).catch((e) => console.error(e)); // eslint-disable-line no-console
    } else if (rutsFile && fileType === 'ruts') {
      formData.append('file', rutsFile);
      formData.append('fileType', fileType);
      uploadFile(formData).catch((e) => console.error(e)); // eslint-disable-line no-console
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    if (!event.target.value) {
      setEmailError(false);
    } else {
      setEmailError(!validateEmail(event.target.value));
    }
  };
  const handleRutChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRut(format(event.target.value));
    if (!event.target.value) {
      setRutError(false);
    } else {
      setRutError(!validateRut(format(event.target.value)));
    }
  };

  return (
    <Box>
      <SectionHeader
        text="Usuarios permitidos"
        button={
          <>
            <SearchBar
              data={allowedUsers || []}
              setFilteredData={setFilteredAllowedUsers as (data: unknown[]) => void}
            />
            <PrimaryBtn onClick={() => navigate('/hr/metrics')}>Métricas</PrimaryBtn>
          </>
        }
      />
      <SuccessDialog
        openDialog={createdAlertDialogOpen}
        setOpenDialog={setCreatedAlertDialogOpen}
        header={newUser ? '¡Usuario agregado correctamente!' : '¡Usuarios agregados correctamente!'}
        msg={createdUserMessage}
      />
      <AlertDialog
        openDialog={deleteAlertDialogOpen}
        setOpenDialog={setDeleteAlertDialogOpen}
        header="¿Estas seguro que deseas eliminar al usuario?"
        msg="El usuario será eliminado de manera permanente."
        confirmMsg="Si, eliminar"
        confirmAction={() => {
          if (userIdToDelete) {
            deleteAllowedUser(userIdToDelete).catch((err) => console.log(err)); // eslint-disable-line no-console
          }
          setDeleteAlertDialogOpen(false);
        }}
      />
      <AlertDialog
        openDialog={fileAlertDialogOpen}
        setOpenDialog={setFileAlertDialogOpen}
        header="Hubo un error agregando usuarios"
        confirmMsg="Entiendo"
        msg={fileAlertDialogMessage}
        confirmAction={() => setFileAlertDialogOpen(false)}
      />
      <Box>
        <Box
          sx={{
            backgroundColor: 'White',
            borderRadius: 4,
            p: 2,
          }}
        >
          <DataGrid
            rows={filteredAllowedUsers?.map((allowedUser) => ({
              ...allowedUser,
              createdAt: new Date(allowedUser.createdAt).toLocaleString('es-CL'),
            }))}
            columns={[
              {
                field: 'email',
                headerName: 'Email',
                flex: 1,
              },
              {
                field: 'rut',
                headerName: 'RUT',
                flex: 1,
              },
              {
                field: 'creatorEmail',
                headerName: 'Creador',
                flex: 1,
              },
              {
                field: 'createdAt',
                headerName: 'Fecha de Creación',
                flex: 1,
              },
              {
                field: 'id',
                headerName: 'Eliminar',
                width: 100,
                align: 'center',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                renderCell: (params: GridRenderCellParams<any, number>) => (
                  <DeleteBtn
                    onClick={() => {
                      setUserIdToDelete(params.value || null);
                      setDeleteAlertDialogOpen(true);
                    }}
                  >
                    <DeleteIcon />
                  </DeleteBtn>
                ),
              },
            ]}
            slots={{
              toolbar: GridToolbar,
            }}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            sx={{
              color: 'Black',
              height: 'calc(100vh - 300px)',
              border: 'none',
            }}
            disableRowSelectionOnClick
          />
        </Box>

        <Box sx={{ p: 2, borderRadius: 4, backgroundColor: 'white' }} mt={2}>
          <Typography variant="h3">Agregar nuevos usuarios permitidos</Typography>
          <Typography variant="body1">
            Tienes dos opciones, puedes cargar un archivo de excel o agregar manualmente.
          </Typography>
          <Typography variant="h3" pt={3}>
            Subir archivo
          </Typography>
          <Typography variant="body1" pb={1}>
            Tiene que ser un archivo excel (.xls o .xlsx) que contenga solamente una columna con emails o ruts para
            agregar.
          </Typography>

          <Box display="flex">
            <Box pr={15}>
              <form onSubmit={handleSubmit('emails')}>
                <Box mt={3}>
                  <label htmlFor="file">
                    <input
                      id="file"
                      type="file"
                      accept=".xls,.xlsx"
                      onChange={(e) => {
                        if (e.target.files && e.target.files.length === 1) {
                          setEmailsFile(e.target.files[0]);
                        }
                      }}
                    />
                  </label>
                </Box>
                <PrimaryBtn type="submit" variant="contained" disabled={emailsFile === null} sx={{ mt: 2 }}>
                  Subir Archivo con Emails
                </PrimaryBtn>
              </form>
            </Box>
            <Box px={2}>
              <form onSubmit={handleSubmit('ruts')}>
                <Box mt={3}>
                  <label htmlFor="file">
                    <input
                      id="file"
                      type="file"
                      accept=".xls,.xlsx"
                      onChange={(e) => {
                        if (e.target.files && e.target.files.length === 1) {
                          setRutsFile(e.target.files[0]);
                        }
                      }}
                    />
                  </label>
                </Box>
                <PrimaryBtn type="submit" variant="contained" disabled={rutsFile === null} sx={{ mt: 2 }}>
                  Subir Archivo con Ruts
                </PrimaryBtn>
              </form>
            </Box>
          </Box>
          <Typography variant="h3" pt={3}>
            Agregar manualmente
          </Typography>
          <Typography variant="body1" pb={2}>
            Puedes agregar por rut o por correo electrónico.
          </Typography>
          <Box display="flex">
            <Box py={3}>
              <TextField
                id="email"
                fullWidth
                label="Email"
                value={email}
                sx={{ bgcolor: '#fff' }}
                onChange={handleEmailChange}
                error={emailError}
                helperText={emailError ? 'Correo electrónico inválido' : ''}
              />
              <PrimaryBtn
                variant="contained"
                sx={{ mt: 2 }}
                onClick={() => {
                  createAllowedUser({
                    email,
                  }).catch((e) => console.error(e)); // eslint-disable-line no-console
                }}
                disabled={emailError || email === ''}
              >
                Agregar Email
              </PrimaryBtn>
            </Box>
            <Box p={3}>
              <TextField
                id="rut"
                fullWidth
                label="RUT"
                value={rut}
                sx={{ bgcolor: '#fff' }}
                onChange={handleRutChange}
                error={rutError}
                helperText={rutError ? 'Rut inválido' : ''}
              />
              <PrimaryBtn
                variant="contained"
                sx={{ mt: 2 }}
                onClick={() => {
                  createAllowedUser({
                    rut,
                  }).catch((e) => console.error(e)); // eslint-disable-line no-console
                }}
                disabled={rutError || rut === ''}
              >
                Agregar Rut
              </PrimaryBtn>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AllowedUsers;
