import React, { FC } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Breadcrumbs, Breadcrumb } from '@dscla/ds-breadcrumbs/react';

interface BlogBreadCrumbsInterface {
  title: string;
  isHome: boolean;
}

const NewHomeBreadCrumbs: FC<BlogBreadCrumbsInterface> = ({ title, isHome }) => (
  <Breadcrumbs>
    <Breadcrumb href="/new-home" label="Volver a Inicio">
      Inicio
    </Breadcrumb>

    {!isHome ? <Breadcrumb>{title}</Breadcrumb> : null}
  </Breadcrumbs>
);

export default NewHomeBreadCrumbs;
