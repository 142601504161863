/* eslint-disable @typescript-eslint/ban-ts-comment */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { SecondaryBtn } from 'components/UI/Buttons';
import LoadingOverlay from 'components/UI/LoadingOverlay';
import useQuery from 'hooks/useQuery';
import Error from 'pages/Error/ErrorAlert';
import { setAccessToken } from 'redux/slices/accessToken.slice';
import { usePasswordResetMutation, useSignInUserMutation, useGetUserQuery } from 'services/api/user.api';
import getReCaptchaToken from 'utils/getReCaptchaToken';
import { identifyPosthogUser } from 'utils/posthogUtils';
import validatePassword, { getPasswordErrorMessage } from 'utils/validations/validatePassword';

import styles from './Password.Reset.module.scss';

const PasswordReset: FC = () => {
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordConfirmError, setPasswordConfirmError] = useState(false);

  const [passwordReset, { isLoading, isError, error }] = usePasswordResetMutation();
  const [signIn, { data: signInData, isSuccess, isLoading: signInLoading }] = useSignInUserMutation();

  const { data: user, refetch } = useGetUserQuery(null);

  useEffect(() => {
    refetch().catch((e) => {
      console.error(e); // eslint-disable-line no-console
    });
  }, []);

  const query = useQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const passwordVerify = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    if (!event.target.value) {
      setPasswordError(false);
    } else {
      setPasswordError(!validatePassword(event.target.value, user?.rut || '', user?.email || ''));
    }
  };

  const passwordConfirmVerify = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordConfirm(event.target.value);
    if (!event.target.value) {
      setPasswordConfirmError(false);
    } else {
      setPasswordConfirmError(password !== event.target.value);
    }
  };

  const verifyForm = () => {
    return password && passwordConfirm && !passwordError && !passwordConfirmError;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (verifyForm()) {
      passwordReset({
        password,
        email: query.get('userEmail') || '',
        token: query.get('token') || '',
      })
        .then(async () => {
          const recaptchaToken = await getReCaptchaToken();
          await signIn({
            recaptchaToken,
            email: query.get('userEmail') || '',
            password,
            rut: user?.rut || '',
          });
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    if (isSuccess && signInData) {
      identifyPosthogUser(signInData);
      dispatch(setAccessToken({ accessToken: signInData.accessToken }));
      navigate('/');
    }
  }, [signInData]);

  return (
    <>
      <LoadingOverlay visible={isLoading || signInLoading} />
      {/* @ts-ignore */}
      <Error message={error?.data} isOpen={isError && error?.status !== 403} />
      <Grid className={styles.MainGridContainer} container component="main">
        {/* <Grid className={styles.SecondaryGrid} item xs={false} sm={4} xl={7} /> sm={8} xl={5} */}

        <Grid item xs={12} component={Paper} elevation={6} square className={styles.Container}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: { xs: '100%', lg: '450px' },
              alignItems: 'center',
              mx: 'auto',
              bgcolor: 'backgroundGrey.main',
              mt: '60px',
              pt: 4,
              borderRadius: '20px',
              border: '1px solid hsla(210,18%,87%,1)',
              boxShadow: '5px 5px 10px 5px',
            }}
          >
            <img alt="Relif" src="/logo.webp" height="50" />
            <Typography variant="h4" sx={{ mt: 3 }}>
              Hola {query.get('userEmail')}
            </Typography>
            <Typography variant="h4" sx={{ mt: 3 }}>
              Elige tu contraseña
            </Typography>
            <Box
              sx={{
                p: 2,
                mt: 2,
                width: { xs: '88%', lg: '420px' },
              }}
              component="form"
              onSubmit={handleSubmit}
            >
              <TextField
                id="password"
                name="password"
                autoComplete="new-password"
                required
                fullWidth
                label="Nueva contraseña"
                type="password"
                onChange={passwordVerify}
                error={passwordError}
                helperText={passwordError && getPasswordErrorMessage(password, user?.rut || '', user?.email || '')}
                sx={{ bgcolor: '#fff', my: 1 }}
              />
              <TextField
                id="passwordConfirm"
                name="passwordConfirm"
                required
                fullWidth
                label="Confirmar contraseña"
                type="password"
                onChange={passwordConfirmVerify}
                error={passwordConfirmError}
                helperText={passwordConfirmError && 'Las contraseñas no coinciden'}
                sx={{ bgcolor: '#fff', my: 1 }}
              />
              <SecondaryBtn
                sx={{ mt: 2, py: 1, mb: 1 }}
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                disabled={!verifyForm()}
              >
                Guardar contraseña
              </SecondaryBtn>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default PasswordReset;
