import React, { FC } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const customVerticalStepper = (steps: { title: string }[]) => {
  return (
    <Box display="grid" px={2}>
      {steps.map((step, index) => (
        <Box display="flex" alignItems="center" key={step.title}>
          <Box
            style={{
              height: '60px',
              width: '60px',
              borderRadius: '50%',
              backgroundColor: '#CDE1F4',
              textAlign: 'center',
            }}
          >
            <Typography fontFamily="Roboto" fontWeight="900" fontSize="32px" lineHeight="60px" color="#2C5889">
              0{index + 1}
            </Typography>
          </Box>
          <Box py={4} pl={2}>
            <Typography fontFamily="Jost" fontWeight="400" fontSize="22px" lineHeight="25px">
              {step.title}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const How: FC = () => {
  return (
    <Grid container py={{ xs: 4, md: 12 }} spacing={{ xs: 3, md: 8 }}>
      <Grid item xs={12} md={6} display="flex">
        <Box
          sx={{
            backgroundImage: 'url("/landing_page/img/featuresBackground.png")',
            backgroundRepeat: 'round',
            backgroundSize: 'cover',
            borderRadius: '0 150px 150px 0',
            width: '100%',
            display: 'flex',
            justifyContent: { xs: 'left', md: 'right' },
            px: '100px',
            alignItems: 'center',
            py: 4,
          }}
        >
          <Typography
            fontFamily="Roboto"
            fontWeight="800"
            fontSize="45px"
            lineHeight="50px"
            color={{ xs: '#15183A', md: 'white' }}
          >
            Esto en <br />
            3 simples <br />
            pasos
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        {customVerticalStepper([
          { title: 'Regístrate y completa tus datos en 2 minutos' },
          {
            title: 'Nosotros analizamos tu información',
          },
          {
            title: 'Te entregamos las mejores soluciones',
          },
        ])}
      </Grid>
    </Grid>
  );
};

export default How;
