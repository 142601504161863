/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { Dispatch, FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

// @ts-ignore
import { Button as CajaButton } from '@dscla/ds-button/react';
// @ts-ignore
import { DataTable } from '@dscla/ds-data-table/react';
// @ts-ignore
import { Input } from '@dscla/ds-input/react';
// @ts-ignore
import { Select, SelectOption } from '@dscla/ds-select/react';
import { AddIcon, HelpIcon } from 'assets/icons';
import PrimaryBtn, { PrimaryBtnWithIcon } from 'components/UI/Buttons';
import CajaTooltip from 'components/UI/CajaTooltip';
import MoneyInput from 'components/UI/MoneyInput';
import useIsCajaLosAndesTheme from 'hooks/useIsCajaLosAndesTheme';
import { Debt } from 'services/types/debt';

import type { BudgetRowDraft } from '../../../services/types/budget';
import { INCOME_CATEGORIES } from '../constants';

interface BasicTableInterface {
  budgetRows: BudgetRowDraft[];
  setBudgetRowAmount: (
    amount: number | string,
    index: number,
    rows: BudgetRowDraft[],
    setRows: Dispatch<BudgetRowDraft[]>
  ) => void;
  removeRow: (index: number, rows: BudgetRowDraft[], setRows: Dispatch<BudgetRowDraft[]>) => void;
  setBudgetRows: Dispatch<BudgetRowDraft[]>;
}

interface FormTableInterface extends BasicTableInterface {
  setBudgetRowStringAttribute: (
    budgetRowName: string,
    index: number,
    attribute: 'name' | 'category' | 'subcategory',
    rows: BudgetRowDraft[],
    setRows: Dispatch<BudgetRowDraft[]>
  ) => void;
  addRow: (rows: BudgetRowDraft[], setRows: Dispatch<BudgetRowDraft[]>, isExpense: boolean, category: string) => void;
}

interface ExpenseTableInterface extends FormTableInterface {
  subcategories: string[];
  debtsData?: Debt[];
  formName: string;
}

const IncomeFormTable: FC<FormTableInterface> = ({
  budgetRows,
  setBudgetRowAmount,
  setBudgetRowStringAttribute,
  removeRow,
  addRow,
  setBudgetRows,
}) => {
  const [expandRows, setExpandRows] = useState([false, false]);
  const getHeight = (index: number, length: number) => {
    if (expandRows[0] && index === length - 1) {
      return '250px';
    }
    if (expandRows[1] && index === length - 2) {
      return '150px';
    }
    return 'auto';
  };
  const handleExpandRows = (index: number, length: number) => {
    if (index === length - 1) {
      setExpandRows([!expandRows[0], false]);
    }
    if (index === length - 2) {
      setExpandRows([false, !expandRows[1]]);
    }
  };
  return (
    <>
      <DataTable variant="card">
        <table aria-label="Tabla Ingreso" slot="card">
          <thead>
            <tr>
              <td>Nombre</td>
              <td>Categoría</td>
              <td>Monto ($)</td>
              <td align="center">Acción</td>
            </tr>
          </thead>
          <tbody>
            {budgetRows.map((row, index) => (
              <tr key={`${index + 1}`} style={{ height: getHeight(index, budgetRows.length) }}>
                <td data-first="" data-mobile-width="100%">
                  <Input
                    type="text"
                    value={row.name}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setBudgetRowStringAttribute(e.target.value, index, 'name', budgetRows, setBudgetRows)
                    }
                  />
                </td>
                <td data-mobile data-mobile-width="50%" style={{ overflow: 'visible' }}>
                  <Select
                    label="Categoría"
                    value={row.category}
                    onClickOption={(e: { detail: string }) =>
                      setBudgetRowStringAttribute(e.detail, index, 'category', budgetRows, setBudgetRows)
                    }
                  >
                    {INCOME_CATEGORIES.map((categoryName) => (
                      <SelectOption key={categoryName} value={categoryName} selected={categoryName === row.category}>
                        {categoryName}
                      </SelectOption>
                    ))}
                  </Select>
                </td>
                <td data-desktop width="200px">
                  <Box display="block" height="100%">
                    <Select
                      label=""
                      value={row.category}
                      onClickOption={(e: { detail: string }) =>
                        setBudgetRowStringAttribute(e.detail, index, 'category', budgetRows, setBudgetRows)
                      }
                      onClick={() => index >= budgetRows.length - 2 && handleExpandRows(index, budgetRows.length)}
                    >
                      {INCOME_CATEGORIES.map((categoryName) => (
                        <SelectOption key={categoryName} value={categoryName} selected={categoryName === row.category}>
                          {categoryName}
                        </SelectOption>
                      ))}
                    </Select>
                  </Box>
                </td>
                <td data-mobile data-mobile-width="50%">
                  <MoneyInput
                    label="Monto ($)"
                    value={Number(row.amount)}
                    setValue={(value) => setBudgetRowAmount(value, index, budgetRows, setBudgetRows)}
                  />
                </td>
                <td data-desktop>
                  <MoneyInput
                    label=""
                    value={Number(row.amount)}
                    setValue={(value) => setBudgetRowAmount(value, index, budgetRows, setBudgetRows)}
                  />
                </td>
                <td data-mobile-width="100%">
                  <CajaButton onClick={() => removeRow(index, budgetRows, setBudgetRows)}>Eliminar</CajaButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </DataTable>
      <Box pt={1}>
        <PrimaryBtnWithIcon
          text="Agregar ingreso"
          onClick={() => addRow(budgetRows, setBudgetRows, false, 'Ingreso')}
          icon={<AddIcon />}
        />
      </Box>
    </>
  );
};

const IncomeTable = React.memo(IncomeFormTable);

const ExpenseFormTable: FC<ExpenseTableInterface> = ({
  budgetRows,
  setBudgetRowAmount,
  setBudgetRowStringAttribute,
  removeRow,
  addRow,
  setBudgetRows,
  formName,
  subcategories,
  debtsData,
}) => {
  const navigate = useNavigate();
  const isCajaTheme = useIsCajaLosAndesTheme();
  const explainText = () => {
    if (formName === 'Hogar') {
      return 'En gris está indicado el gasto promedio chileno de la respectiva categoría tomando en cuenta una familia de 3 personas, puedes tomarlo como referencia.';
    }
    if (formName === 'Entretención') {
      return 'En gris está indicado el gasto promedio chileno, en caso de salidas es el promedio de una salida de dicha categoría, en el caso de suscripciones es el precio de plan estándar, puedes tomarlo como referencia.';
    }
    return 'En gris está indicado el gasto promedio chileno de la respectiva categoría, puedes tomarlo como referencia.';
  };

  const [expandRows, setExpandRows] = useState([false, false, false]);
  const getHeight = (index: number, length: number) => {
    if (expandRows[0] && index === length - 1) {
      return '350px';
    }
    if (expandRows[1] && index === length - 2) {
      return '250px';
    }
    if (expandRows[2] && index === length - 3) {
      return '150px';
    }
    return 'auto';
  };
  const handleExpandRows = (index: number, length: number) => {
    if (index === length - 1) {
      setExpandRows([!expandRows[0], false, false]);
    }
    if (index === length - 2) {
      setExpandRows([false, !expandRows[1], false]);
    }
    if (index === length - 3) {
      setExpandRows([false, false, !expandRows[2]]);
    }
  };
  return (
    <>
      {formName === 'Financiero' && (!debtsData || debtsData.length === 0) && (
        <Alert
          action={
            isCajaTheme ? (
              <CajaButton onClick={() => navigate('/debt')}>Agregar deuda</CajaButton>
            ) : (
              <PrimaryBtn size="small" onClick={() => navigate('/debt')}>
                Agregar deuda
              </PrimaryBtn>
            )
          }
          severity="warning"
        >
          No has agregado ninguna deuda
        </Alert>
      )}
      <DataTable variant="card">
        <table slot="card" aria-label="Tabla Ingreso">
          <thead>
            <tr>
              <td>Nombre</td>
              <td>Subcategoría</td>
              <td>
                Monto ($)
                <CajaTooltip title={explainText()} placement="top">
                  <IconButton aria-label="Ayuda para el monto" size="small" sx={{ ml: 2, pt: '2.5px' }}>
                    <HelpIcon sx={{ color: 'white !important' }} fontSize="inherit" />
                  </IconButton>
                </CajaTooltip>
              </td>
              <td align="center">Acción</td>
            </tr>
          </thead>
          <tbody>
            {budgetRows
              .filter((row) => row.isExpense)
              .map((row, index) => (
                <tr key={`${index + 1}`} style={{ height: getHeight(index, budgetRows.length) }}>
                  <td data-first="" data-mobile-width="100%">
                    <Input
                      type="text"
                      value={row.name}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setBudgetRowStringAttribute(e.target.value, index, 'name', budgetRows, setBudgetRows)
                      }
                    />
                  </td>
                  <td data-mobile data-mobile-width="50%" style={{ overflow: 'visible' }}>
                    <Select
                      label="Categoria"
                      value={row.subcategory}
                      onClickOption={(e: { detail: string }) =>
                        setBudgetRowStringAttribute(e.detail, index, 'subcategory', budgetRows, setBudgetRows)
                      }
                    >
                      {subcategories.map((categoryName) => (
                        <SelectOption
                          key={categoryName}
                          value={categoryName}
                          selected={categoryName === row.subcategory}
                        >
                          {categoryName}
                        </SelectOption>
                      ))}
                    </Select>
                  </td>
                  <td data-desktop width="200px">
                    <Box display="block" height="100%">
                      <Select
                        label=""
                        value={row.subcategory}
                        onClickOption={(e: { detail: string }) =>
                          setBudgetRowStringAttribute(e.detail, index, 'subcategory', budgetRows, setBudgetRows)
                        }
                        onClick={() => index >= budgetRows.length - 3 && handleExpandRows(index, budgetRows.length)}
                      >
                        {subcategories.map((categoryName) => (
                          <SelectOption
                            key={categoryName}
                            value={categoryName}
                            selected={categoryName === row.subcategory}
                          >
                            {categoryName}
                          </SelectOption>
                        ))}
                      </Select>
                    </Box>
                  </td>
                  <td data-mobile data-mobile-width="50%">
                    <MoneyInput
                      label="Monto ($)"
                      value={Number(row.amount)}
                      setValue={(value) => setBudgetRowAmount(value, index, budgetRows, setBudgetRows)}
                    />
                  </td>
                  <td data-desktop>
                    <MoneyInput
                      label=""
                      value={Number(row.amount)}
                      setValue={(value) => setBudgetRowAmount(value, index, budgetRows, setBudgetRows)}
                    />
                  </td>
                  <td data-mobile-width="100%">
                    <CajaButton onClick={() => removeRow(index, budgetRows, setBudgetRows)}>Eliminar</CajaButton>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </DataTable>
      <PrimaryBtnWithIcon
        text="Agregar gasto"
        onClick={() => addRow(budgetRows, setBudgetRows, true, formName)}
        icon={<AddIcon />}
      />
    </>
  );
};
ExpenseFormTable.defaultProps = {
  debtsData: undefined,
};
const ExpenseTable = React.memo(ExpenseFormTable);

export { IncomeTable, ExpenseTable };
