import React, { useEffect, useState } from 'react';

import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import {
  PreProcessedOffersResponse,
  type BankOfferAttributes,
  type BankOfferSheet,
  type OfferDraft,
  type OfferToUpdate,
} from '@relif/backend';
import AlertDialog from 'components/UI/AlertDialog';
import { BlackBtn } from 'components/UI/Buttons';
import PaperContainer from 'components/UI/PaperContainer';
import SuccessDialog from 'components/UI/SuccessDialog';
import SectionHeader from 'components/layout/SectionHeader';
import { useBulkRejectRequestsMutation } from 'services/api/admin-bankOfferRequests.api';
import {
  useBulkCreateMutation,
  useBulkUpdateMutation,
  useGetBankOffersSheetListQuery,
  usePreProcessFileMutation,
} from 'services/api/admin-bankOffers.api';

import BankOfferHeaders from './BankOfferHeaders';
import BankOfferRow from './BankOfferRow';

const AdminBankOffers = () => {
  const { data: bankOffersSheetList } = useGetBankOffersSheetListQuery(null);
  const [preProcessFile, { data: preProcessedData }] = usePreProcessFileMutation();
  const [bulkCreateOffers, { data: createResponse, error: createError }] = useBulkCreateMutation();
  const [bulkUpdateOffers, { data: updateResponse, error: updateError }] = useBulkUpdateMutation();
  const [bulkRejectRequests, { data: rejectResponse, error: rejectError }] = useBulkRejectRequestsMutation();

  const [selectedSheet, setSelectedSheet] = useState<BankOfferSheet | null>(null);
  const [showSheetInstructions, setShowSheetInstructions] = useState<boolean>(false);
  const [offersFile, setOffersFile] = useState<File | null>(null);
  const [noOfferUsers, setNoOfferUsers] = useState<PreProcessedOffersResponse['noOfferUsers']>([]);
  const [offersToUpdate, setOffersToUpdate] = useState<OfferToUpdate[]>([]);
  const [offersToCreate, setOffersToCreate] = useState<OfferDraft[]>([]);
  const [sendRejectionNotifications, setSendRejectionNotifications] = useState<boolean>(true);
  const [sendCreationNotifications, setSendCreationNotifications] = useState<boolean>(true);
  const [sendUpdateNotifications, setSendUpdateNotifications] = useState<boolean>(true);
  const [isOpenSuccessDialog, setIsOpenSuccessDialog] = useState<boolean>(false);
  const [isOpenErrorDialog, setIsOpenErrorDialog] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleSubmitFile = () => {
    const formData = new FormData();
    if (offersFile) {
      formData.append('file', offersFile);
      formData.append('bankName', selectedSheet?.bankName || '');
      formData.append('refinanceType', selectedSheet?.refinanceType || '');
      preProcessFile(formData).catch((err) => {
        console.error(err); // eslint-disable-line no-console
      });
    }
  };

  const handleOfferToUpdateChange = (index: number, changedOffer: OfferToUpdate) => {
    const newOffersToUpdate = [...offersToUpdate];
    newOffersToUpdate[index] = changedOffer;
    setOffersToUpdate(newOffersToUpdate);
  };

  const handleOfferToCreateChange = (index: number, changedOffer: OfferDraft) => {
    const newOffersToCreate = [...offersToCreate];
    newOffersToCreate[index] = changedOffer;
    setOffersToCreate(newOffersToCreate);
  };

  const handleCreateOffers = () => {
    bulkCreateOffers({ bankOffers: offersToCreate, notifyUsers: sendCreationNotifications }).catch((err) => {
      console.error(err); // eslint-disable-line no-console
    });
  };

  const handleUpdateOffers = () => {
    bulkUpdateOffers({ bankOffers: offersToUpdate, notifyUsers: sendUpdateNotifications }).catch((err) => {
      console.error(err); // eslint-disable-line no-console
    });
  };

  const handleRejectRequests = () => {
    const userIds = noOfferUsers.map((user) => user.id);
    bulkRejectRequests({
      userIds,
      bankName: selectedSheet?.bankName || '',
      sendMessages: sendRejectionNotifications,
    }).catch((err) => {
      console.error(err); // eslint-disable-line no-console
    });
  };

  const getBankOfferErrors = (offer: OfferDraft) => {
    const errors: string[] = [];
    const {
      rut,
      paymentPlanAmount,
      cashPaymentAmount,
      interestRate,
      instructions,
      terms,
      refinanceType,
      isBankClient,
    } = offer;
    if (!(rut.length > 9)) {
      errors.push('Rut es requerido');
    }
    if (isBankClient) {
      return errors;
    }
    if (refinanceType === 'refinance' && !interestRate) {
      errors.push('Tasa de interés es requerida');
    }
    if (!paymentPlanAmount && !cashPaymentAmount) {
      errors.push('Monto plan de pago o monto pago contado es requerido');
    }
    if (!instructions) {
      errors.push('Instrucciones son requeridas');
    }
    if (paymentPlanAmount) {
      if (!terms) {
        errors.push('Cuotas son requeridas');
      } else if (!terms.length) {
        errors.push('Se requiere al menos una cuota');
      } else {
        terms.forEach((term) => {
          if (!term.term) {
            errors.push('Se requiere un número de cuota (term)');
          }
          if (!term.monthlyPayment) {
            errors.push('Se requiere un monto de cuota (monthlyPayment)');
          }
        });
      }
    }
    return errors;
  };

  const getBankOfferWarnings = (offer: OfferDraft) => {
    const { userId } = offer;
    const warnings: string[] = [];
    if (!userId) {
      warnings.push('Se recomienda asignar un id de usuario. Chequear que el rut sea correcto');
    }
    return warnings;
  };

  const getHasErrors = (offers: OfferDraft[] | OfferToUpdate[]) => {
    const hasErrors = offers.some((offer) => {
      const errors = getBankOfferErrors(offer);
      return errors.length > 0;
    });
    return hasErrors;
  };

  const removeOfferToUpdate = (offer: OfferDraft | OfferToUpdate) => {
    const newOffers = offersToUpdate.filter((o) => o.source !== offer.source);
    setOffersToUpdate(newOffers);
    const newNoOfferUsers = [...noOfferUsers, { rut: offer.rut, id: offer.userId || 0 }];
    setNoOfferUsers(newNoOfferUsers);
  };

  const removeOfferToCreate = (offer: OfferDraft | OfferToUpdate) => {
    const newOffers = offersToCreate.filter((o) => o.source !== offer.source);
    setOffersToCreate(newOffers);
    const newNoOfferUsers = [...noOfferUsers, { rut: offer.rut, id: offer.userId || 0 }];
    setNoOfferUsers(newNoOfferUsers);
  };

  const addOfferToCreateWithRut = (user: PreProcessedOffersResponse['noOfferUsers'][0]) => {
    const newOffer: BankOfferAttributes = {
      rut: user.rut,
      userId: user.id || null,
      refinanceType: selectedSheet?.refinanceType || null,
      bank: selectedSheet?.bankName || '',
      originalAmount: 0,
      paymentPlanAmount: 0,
      cashPaymentAmount: 0,
      terms: [],
      interestRate: 0,
      maxTerm: 0,
      instructions: '',
      description: '',
      userReaction: '',
      source: '',
      whatsAppMessageCount: 0,
      isAccepted: false,
      acceptanceStatus: '',
      limit: new Date(),
      firstPaymentDate: new Date(),
      isBankClient: false,
      freeAvailabilityAmount: 0,
    };
    const newOffers = [...offersToCreate, newOffer];
    setOffersToCreate(newOffers);
    const newNoOfferRuts = noOfferUsers.filter((u) => u.rut !== user.rut);
    setNoOfferUsers(newNoOfferRuts);
  };

  useEffect(() => {
    if (preProcessedData?.noOfferUsers) {
      setNoOfferUsers(preProcessedData.noOfferUsers);
    }
    if (preProcessedData?.offersToUpdate) {
      setOffersToUpdate(preProcessedData.offersToUpdate);
    }
    if (preProcessedData?.offersToCreate) {
      setOffersToCreate(preProcessedData.offersToCreate);
    }
  }, [preProcessedData]);

  useEffect(() => {
    if (updateResponse) {
      setIsOpenSuccessDialog(true);
      const message = `
        Ofertas actualizadas: ${JSON.stringify(updateResponse.updatedOffers)}
        Ofertas por notificar: ${updateResponse.updatedOffersToNotify
          .map((offer) => `(offerId: ${offer.id}, userId: ${offer.userId})`)
          .join(', ')}
        Alertas: ${JSON.stringify(updateResponse.updatedOffersWhatsAppWarnings)}
      `;
      setSuccessMessage(message);
    }
  }, [updateResponse]);

  useEffect(() => {
    if (createResponse) {
      setIsOpenSuccessDialog(true);
      setSuccessMessage(`Created offers: ${JSON.stringify(createResponse)}`);
    }
  }, [createResponse]);

  useEffect(() => {
    if (rejectResponse) {
      setIsOpenSuccessDialog(true);
      setSuccessMessage(`Rejected requests: ${JSON.stringify(rejectResponse)}`);
    }
  }, [rejectResponse]);

  useEffect(() => {
    if (updateError) {
      setIsOpenErrorDialog(true);
      setErrorMessage(`Error updating offers: ${JSON.stringify(updateError)}`);
    }
  }, [updateError]);

  useEffect(() => {
    if (createError) {
      setIsOpenErrorDialog(true);
      setErrorMessage(`Error creating offers: ${JSON.stringify(createError)}`);
    }
  }, [createError]);

  useEffect(() => {
    if (rejectError) {
      setIsOpenErrorDialog(true);
      setErrorMessage(`Error rejecting requests: ${JSON.stringify(rejectError)}`);
    }
  }, [rejectError]);

  return (
    <div>
      <SuccessDialog
        openDialog={isOpenSuccessDialog}
        setOpenDialog={setIsOpenSuccessDialog}
        header="Operación exitosa"
        msg={successMessage}
      />
      <AlertDialog
        openDialog={isOpenErrorDialog}
        setOpenDialog={setIsOpenErrorDialog}
        header="Error"
        msg={errorMessage}
        confirmMsg="Ok"
        confirmAction={() => setIsOpenErrorDialog(false)}
      />
      <SectionHeader text="Ofertas bancarias" button={null} />
      <Typography variant="body1">
        Para subir ofertas bancarias, primero selecciona una plantilla de ofertas bancarias. Luego, sube un archivo
        Excel con las ofertas a subir.
      </Typography>
      <select
        onChange={(e) => {
          const newSheet = bankOffersSheetList?.find((sheet) => {
            return `${sheet.refinanceType} - ${sheet.bankName}` === e.target.value;
          });
          setSelectedSheet(newSheet || null);
        }}
      >
        {bankOffersSheetList?.map((sheet) => (
          <option
            key={`sheet-${sheet.refinanceType}-${sheet.bankName}`}
            value={`${sheet.refinanceType} - ${sheet.bankName}`}
          >
            {sheet.refinanceType} - {sheet.bankName}
          </option>
        ))}
      </select>
      {selectedSheet ? (
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1">
            <b>Tipo de refinanciamiento:</b> {selectedSheet.refinanceType}
          </Typography>
          <Typography variant="body1">
            <b>Institución:</b> {selectedSheet.bankName}
          </Typography>
          <Typography
            onClick={() => setShowSheetInstructions(!showSheetInstructions)}
            sx={{ mt: 2, cursor: 'pointer', textTransform: 'uppercase', textDecoration: 'underline' }}
            variant="body1"
          >
            {showSheetInstructions ? 'Esconder' : 'Mostrar'} instrucciones
          </Typography>
          {showSheetInstructions && (
            <Box>
              <Typography variant="body1">
                <b>Columnas:</b>
              </Typography>
              {selectedSheet.columns.map((column) => (
                <div key={column.name}>
                  <Typography variant="body1">
                    <b>{column.name}</b> ({column.description}): {column.instructions}
                  </Typography>
                </div>
              ))}
            </Box>
          )}
          <Typography variant="body1" sx={{ mt: 2 }}>
            <b>Formato de archivo:</b> Excel
          </Typography>
          <input
            type="file"
            onChange={(e) => {
              if (e.target.files) {
                setOffersFile(e.target.files[0]);
              }
            }}
            accept=".xlsx, .txt"
            placeholder="Archivo de ofertas bancarias"
          />
          <BlackBtn
            type="button"
            onClick={() => {
              if (offersFile) {
                handleSubmitFile();
              }
            }}
            disabled={!offersFile}
          >
            Pre procesar archivo
          </BlackBtn>
        </Box>
      ) : null}
      {preProcessedData?.noOfferUsers ? (
        <div>
          <Typography variant="body1" sx={{ mt: 2 }}>
            <b>Usuarios sin ofertas:</b>
          </Typography>
          <label htmlFor="sendRejectionNotifications">
            Enviar WhatsApps de rechazo
            <input
              id="sendRejectionNotifications"
              type="checkbox"
              checked={sendRejectionNotifications}
              onChange={(e) => setSendRejectionNotifications(e.target.checked)}
            />
          </label>
          <br />
          <BlackBtn sx={{ my: 2 }} onClick={handleRejectRequests}>
            Rechazar solicitudes
          </BlackBtn>
          <PaperContainer sx={{ overflowX: 'auto' }} fullWidth>
            <Table sx={{ minWidth: 300 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Crear oferta</TableCell>
                  <TableCell>userId</TableCell>
                  <TableCell>Rut</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {noOfferUsers.map((user) => (
                  <TableRow key={user.rut}>
                    <TableCell>
                      <BlackBtn onClick={() => addOfferToCreateWithRut(user)}>Crear oferta</BlackBtn>
                    </TableCell>
                    <TableCell>{user.id}</TableCell>
                    <TableCell>{user.rut}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </PaperContainer>
        </div>
      ) : null}
      {preProcessedData?.offersToUpdate ? (
        <div>
          <Typography variant="body1" sx={{ mt: 2 }}>
            <b>Ofertas a actualizar:</b>
          </Typography>
          <label htmlFor="sendUpdateNotifications">
            Enviar WhatsApps de actualización
            <input
              id="sendUpdateNotifications"
              type="checkbox"
              checked={sendUpdateNotifications}
              onChange={(e) => setSendUpdateNotifications(e.target.checked)}
            />
          </label>
          <br />
          <BlackBtn sx={{ my: 2 }} onClick={handleUpdateOffers} disabled={getHasErrors(offersToUpdate)}>
            Update offers
          </BlackBtn>
          <PaperContainer sx={{ overflowX: 'auto' }} fullWidth>
            <Table sx={{ minWidth: 3000 }} aria-label="simple table">
              <BankOfferHeaders />
              <TableBody>
                {offersToUpdate.map((offer, index) => (
                  <BankOfferRow
                    key={offer.source}
                    offer={offer}
                    handleOfferChange={(changedOffer) => {
                      handleOfferToUpdateChange(index, changedOffer as OfferToUpdate);
                    }}
                    getOfferErrors={getBankOfferErrors}
                    getOfferWarnings={getBankOfferWarnings}
                    removeOffer={removeOfferToUpdate}
                  />
                ))}
              </TableBody>
            </Table>
          </PaperContainer>
        </div>
      ) : null}
      {preProcessedData?.offersToCreate ? (
        <div>
          <Typography variant="body1" sx={{ mt: 2 }}>
            <b>Ofertas a crear:</b>
          </Typography>
          <label htmlFor="sendCreationNotifications">
            Enviar WhatsApps de creación
            <input
              id="sendCreationNotifications"
              type="checkbox"
              checked={sendCreationNotifications}
              onChange={(e) => setSendCreationNotifications(e.target.checked)}
            />
          </label>
          <br />
          <BlackBtn sx={{ my: 2 }} onClick={handleCreateOffers} disabled={getHasErrors(offersToCreate)}>
            Crear ofertas
          </BlackBtn>

          <PaperContainer sx={{ overflowX: 'auto' }} fullWidth>
            <Table sx={{ minWidth: 3000 }} aria-label="simple table">
              <BankOfferHeaders />
              <TableBody>
                {offersToCreate.map((offer, index) => (
                  <BankOfferRow
                    key={offer.source}
                    offer={offer}
                    handleOfferChange={(changedOffer) => {
                      handleOfferToCreateChange(index, changedOffer);
                    }}
                    getOfferErrors={getBankOfferErrors}
                    getOfferWarnings={getBankOfferWarnings}
                    removeOffer={removeOfferToCreate}
                  />
                ))}
              </TableBody>
            </Table>
          </PaperContainer>
        </div>
      ) : null}
    </div>
  );
};

export default AdminBankOffers;
