import React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

// eslint-disable-next-line react/display-name
const LinkBehavior = React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>(
  (props, ref) => {
    const { href, ...other } = props;
    // Map href (MUI) -> to (react-router). More info here https://mui.com/material-ui/guides/routing/#link
    return <RouterLink ref={ref} to={href} {...other} />;
  }
);

export default LinkBehavior;
