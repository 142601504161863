import React, { FC, useEffect, useState } from 'react';

import { Box, CircularProgress, Grid, Typography } from '@mui/material';

import { useGetDebtsComparisonMutation } from 'services/api/bankOffer.api';

import CurrentStatusCard from './CurrentStatusCard';
import RelifAdviseCard from './RelifAdviseCard';
import SimulatedStatusCard from './SimulatedStatusCard';
import SimulationOptions from './SimulationOptions';

const RCRThreshold = 30; // RCR percentage that is considered high for a person

interface Props {
  bankOfferUUID: string;
}

const Simulator: FC<Props> = ({ bankOfferUUID }) => {
  const gracePeriod = 0;
  const selectedInstitution = 'Banco';

  const [getDebtsComparison, { data, isLoading }] = useGetDebtsComparisonMutation();
  const date = new Date();
  date.setMonth(date.getMonth() - 1);

  const [selectedTerm, setSelectedTerm] = useState<number | null>(null);

  const selectedSimulation = data?.creditsByGracePeriod[gracePeriod][selectedInstitution].find(
    (item) => item.term === selectedTerm
  );

  const getMonthlyDifference = () => {
    if (data) {
      return selectedSimulation?.monthlyPayment ? selectedSimulation.monthlyPayment - data.totalMonthlyPayment : 0;
    }
    return 0;
  };

  const getTotalDifference = () => {
    if (data) {
      return selectedSimulation?.monthlyPayment
        ? selectedSimulation.monthlyPayment * Number(selectedSimulation.pendingPayments) - data.totalPayments
        : 0;
    }
    return 0;
  };

  const getPaymentsDifference = () => {
    if (data) {
      return selectedSimulation?.pendingPayments
        ? Number(selectedSimulation.pendingPayments) - data.maxPendingPayments
        : 0;
    }
    return 0;
  };

  const getSuggestionMessage = () => {
    const monthlyDifference = getMonthlyDifference();
    const totalDifference = getTotalDifference();
    if (monthlyDifference > 0 && totalDifference > 0) {
      return 'Esta opción no te conviene. Pagarías más al mes y más en total 😔.';
    }
    if (monthlyDifference > 0 && totalDifference <= 0) {
      return 'Esta es una buena opción, si es que tienes la capacidad de pagar más al mes y quieres pagar menos en intereses 🤓.';
    }
    if (monthlyDifference <= 0 && totalDifference > 0) {
      return 'Esta opción es buena si es que estás complicado en pagar tus cuotas actuales. Pero, ten en cuenta que, a largo plazo, pagarías más en intereses 🤔.';
    }
    if (monthlyDifference <= 0 && totalDifference <= 0) {
      return 'Es una buena opción, ya que pagarías menos todos los meses y menos en intereses totales 😎.';
    }
    return '';
  };

  const currentSalary = data?.currentSalary || 0;
  const currentDebtsPayments = data?.currentDebtsPayments || 0;
  const selectedDebtsPayments = data?.totalMonthlyPayment || 0;
  const newDebtsPayments = currentDebtsPayments - selectedDebtsPayments + (selectedSimulation?.monthlyPayment || 0);
  const currentRCR = data?.currentRCR || 0;
  const newRCR = currentSalary > 0 ? (newDebtsPayments / currentSalary) * 100 : 0;

  const handleGetSimulations = () => {
    getDebtsComparison(bankOfferUUID).catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
    });
  };

  useEffect(() => {
    handleGetSimulations();
  }, []);

  useEffect(() => {
    if (data) {
      const firstTerm = data?.creditsByGracePeriod[gracePeriod][selectedInstitution][0].term;
      setSelectedTerm(Number(firstTerm));
    }
  }, [data]);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!data?.creditsByGracePeriod || !selectedTerm) {
    return null;
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Grid
        container
        sx={{
          mb: 2,
        }}
      >
        <Grid
          item
          xs={12}
          lg={10}
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              width: '100%',
              fontSize: 18,
              fontWeight: 600,
              color: 'primary.main',
              textAlign: 'left',
            }}
          >
            Comparador
          </Typography>
          <Typography
            variant="body1"
            sx={{
              width: '100%',
              fontSize: 16,
              fontWeight: 400,
              color: 'primary.main',
              textAlign: 'left',
            }}
          >
            Revisa como quedaría tu situación en comparación a lo que pagas actualmente en deudas
          </Typography>
        </Grid>
      </Grid>

      <Box>
        <SimulationOptions
          selectedTerm={selectedTerm}
          setSelectedTerm={setSelectedTerm}
          availableTerms={
            data?.creditsByGracePeriod[gracePeriod][selectedInstitution].map((item) => Number(item.term)) || []
          }
        />
        <Grid container>
          <Grid item xs={12} py={1}>
            <CurrentStatusCard
              currentRCR={currentRCR}
              chosenMonthlyPayment={data?.implicitTermMonthlyPayment || 0}
              maxPendingPayments={data?.maxPendingPayments || 0}
              debtsMonthlyTotal={data?.totalMonthlyPayment || 0}
              debtsTotal={data?.totalPayments || 0}
            />
          </Grid>
          <Grid item xs={12} py={1}>
            {selectedSimulation && (
              <SimulatedStatusCard
                newRCR={newRCR}
                selectedSimulation={selectedSimulation}
                paymentsDifference={getPaymentsDifference()}
                monthlyDifference={getMonthlyDifference()}
                totalDifference={getTotalDifference()}
              />
            )}
          </Grid>

          <Grid item xs={12} py={1}>
            <RelifAdviseCard
              newRCR={newRCR}
              currentRCR={currentRCR}
              RCRThreshold={RCRThreshold}
              getSuggestionMessage={getSuggestionMessage}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Simulator;
