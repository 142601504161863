import React, { FC, useEffect } from 'react';

import { Box, Grid, IconButton, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

import { ExpandLessIcon, ExpandMoreIcon } from 'assets/icons';
import PrimaryBtn from 'components/UI/Buttons';
import PaperContainer from 'components/UI/PaperContainer';
import RoundedInput from 'components/UI/RoundedInput';
import { FilterUsersBody, NumberFilter } from 'services/types/user';

interface BooleanFilterProps {
  name: string;
  value: boolean | undefined;
  onChange: (value: boolean | undefined) => void;
}

const BooleanFilter: FC<BooleanFilterProps> = ({ name, value, onChange }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
      <Typography variant="body1" mr={1}>
        {name}
      </Typography>
      <ToggleButtonGroup value="true" exclusive>
        <ToggleButton
          value="all"
          selected={value === undefined}
          onClick={() => {
            onChange(undefined);
          }}
        >
          Any
        </ToggleButton>
        <ToggleButton
          value="true"
          selected={value === true}
          onClick={() => {
            onChange(true);
          }}
        >
          Si
        </ToggleButton>
        <ToggleButton
          value="false"
          selected={value === false}
          onClick={() => {
            onChange(false);
          }}
        >
          No
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

interface StringFilterProps {
  name: string;
  value: string | undefined;
  onChange: (value: string | undefined) => void;
}

const StringFilter: FC<StringFilterProps> = ({ name, value, onChange }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
      <Typography variant="body1" mr={1}>
        {name}
      </Typography>
      <RoundedInput
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        placeholder="Any"
        sx={{ width: '100%', maxWidth: '300px' }}
      />
    </Box>
  );
};

interface NumberFilterProps {
  name: string;
  filter: NumberFilter | undefined;
  onChange: (value: NumberFilter | undefined) => void;
}

const NumberFilterInput: FC<NumberFilterProps> = ({ name, filter, onChange }) => {
  const value = filter?.value;
  const operator = filter?.operator;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
      <Typography variant="body1" mr={1}>
        {name}
      </Typography>
      <ToggleButtonGroup value="true" exclusive sx={{ mr: 2 }}>
        <ToggleButton
          value="all"
          selected={operator === undefined}
          onClick={() => {
            onChange(undefined);
          }}
        >
          Any
        </ToggleButton>
        <ToggleButton
          value="true"
          selected={operator === 'gt'}
          onClick={() => {
            onChange({ operator: 'gt', value });
          }}
        >
          &gt;
        </ToggleButton>
        <ToggleButton
          value="false"
          selected={operator === 'lt'}
          onClick={() => {
            onChange({ operator: 'lt', value });
          }}
        >
          &lt;
        </ToggleButton>
      </ToggleButtonGroup>
      <RoundedInput
        value={value}
        onChange={(e) => {
          onChange({ operator, value: parseInt(e.target.value, 10) });
        }}
        placeholder="Any"
        sx={{ width: '100%', maxWidth: '300px' }}
      />
    </Box>
  );
};

interface ToggleFilterProps {
  name: string;
  value: boolean | undefined;
  onChange: (value: boolean | undefined) => void;
}

const ToggleFilter: FC<ToggleFilterProps> = ({ name, value, onChange }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
      <Typography variant="body1" mr={1}>
        {name}
      </Typography>
      <ToggleButtonGroup value="true" exclusive>
        <ToggleButton
          value="true"
          selected={value === true}
          onClick={() => {
            onChange(true);
          }}
        >
          Si
        </ToggleButton>
        <ToggleButton
          value="false"
          selected={value === false}
          onClick={() => {
            onChange(false);
          }}
        >
          No
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

interface Props {
  filterUsersBody: FilterUsersBody;
  setFilterUsersBody: React.Dispatch<React.SetStateAction<FilterUsersBody>>;
  refetchUsers: () => void;
}

const Filters: FC<Props> = ({ filterUsersBody, setFilterUsersBody, refetchUsers }) => {
  const [showFilters, setShowFilters] = React.useState<boolean>(false);
  const [includeDebtFilter, setIncludeDebtFilter] = React.useState<boolean>(filterUsersBody.debtFilter !== undefined);
  const [includeFinancialStatementFilter, setIncludeFinancialStatementFilter] = React.useState<boolean>(
    filterUsersBody.financialStatementFilter !== undefined
  );

  useEffect(() => {
    if (!includeDebtFilter) {
      setFilterUsersBody((prev) => ({
        ...prev,
        debtFilter: undefined,
      }));
    }
  }, [includeDebtFilter]);

  useEffect(() => {
    if (!includeFinancialStatementFilter) {
      setFilterUsersBody((prev) => ({
        ...prev,
        financialStatementFilter: undefined,
      }));
    }
  }, [includeFinancialStatementFilter]);

  return (
    <PaperContainer
      title="Filtros"
      sx={{ display: 'flex', flexDirection: 'column', minHeight: '50px' }}
      actionBtn={
        <IconButton
          onClick={() => {
            setShowFilters((prev) => !prev);
          }}
        >
          {showFilters ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      }
    >
      <Grid container spacing={2} sx={{ display: showFilters ? 'flex' : 'none' }}>
        <Grid item xs={6} lg={6}>
          <BooleanFilter
            name="Test user"
            value={filterUsersBody.isTestUser}
            onChange={(value) => {
              setFilterUsersBody((prev) => ({ ...prev, isTestUser: value }));
            }}
          />
        </Grid>

        <Grid item xs={6} lg={6}>
          <BooleanFilter
            name="Blocked"
            value={filterUsersBody.isBlocked}
            onChange={(value) => {
              setFilterUsersBody((prev) => ({ ...prev, isBlocked: value }));
            }}
          />
        </Grid>

        <Grid item xs={6} lg={6}>
          <BooleanFilter
            name="Admin"
            value={filterUsersBody.isAdmin}
            onChange={(value) => {
              setFilterUsersBody((prev) => ({ ...prev, isAdmin: value }));
            }}
          />
        </Grid>

        <Grid item xs={6} lg={12}>
          <StringFilter
            name="Nombre Empresa"
            value={filterUsersBody.companyFilter?.name}
            onChange={(value) => {
              if (!value) {
                setFilterUsersBody((prev) => ({
                  ...prev,
                  companyFilter: {
                    ...prev.companyFilter,
                    name: '',
                  },
                }));
                return;
              }
              setFilterUsersBody((prev) => ({
                ...prev,
                companyFilter: { ...prev.companyFilter, name: value },
              }));
            }}
          />
        </Grid>

        <Grid item xs={6} lg={12}>
          <ToggleFilter
            name="Incluir filtros de deuda"
            value={includeDebtFilter}
            onChange={(value) => {
              setIncludeDebtFilter(value ?? false);
            }}
          />
        </Grid>
        {includeDebtFilter && (
          <>
            <Grid item xs={6} lg={12}>
              <NumberFilterInput
                name="Monto de deuda"
                filter={filterUsersBody.debtFilter?.amount}
                onChange={(value) => {
                  setFilterUsersBody((prev) => ({
                    ...prev,
                    debtFilter: { ...prev.debtFilter, amount: value },
                  }));
                }}
              />
            </Grid>
            <Grid item xs={6} lg={12}>
              <NumberFilterInput
                name="Meses de atraso"
                filter={filterUsersBody.debtFilter?.monthsBehind}
                onChange={(value) => {
                  setFilterUsersBody((prev) => ({
                    ...prev,
                    debtFilter: { ...prev.debtFilter, monthsBehind: value },
                  }));
                }}
              />
            </Grid>
            <Grid item xs={6} lg={12}>
              <StringFilter
                name="Institución"
                value={filterUsersBody.debtFilter?.institutionName}
                onChange={(value) => {
                  setFilterUsersBody((prev) => ({
                    ...prev,
                    debtFilter: { ...prev.debtFilter, institutionName: value },
                  }));
                }}
              />
            </Grid>
          </>
        )}
        <Grid item xs={6} lg={12}>
          <ToggleFilter
            name="Incluir filtros de estados financieros"
            value={includeFinancialStatementFilter}
            onChange={(value) => {
              setIncludeFinancialStatementFilter(value ?? false);
            }}
          />
        </Grid>
        {includeFinancialStatementFilter && (
          <Grid item xs={6} lg={12}>
            <NumberFilterInput
              name="Ingresa mensual promedio"
              filter={filterUsersBody.financialStatementFilter?.averageNetIncome}
              onChange={(value) => {
                if (!value) {
                  setFilterUsersBody((prev) => ({
                    ...prev,
                    financialStatementFilter: undefined,
                  }));
                  return;
                }
                setFilterUsersBody((prev) => ({
                  ...prev,
                  financialStatementFilter: { ...prev.financialStatementFilter, averageNetIncome: value },
                }));
              }}
            />
          </Grid>
        )}
        <Grid item xs={6} lg={12} display={{ xs: 'none', lg: 'flex' }} justifyContent="flex-end">
          <PrimaryBtn
            onClick={() => {
              refetchUsers();
            }}
          >
            Aplicar filtros
          </PrimaryBtn>
        </Grid>
      </Grid>
    </PaperContainer>
  );
};

export default Filters;
