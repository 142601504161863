import React, { FC, useState, useEffect } from 'react';

import { Box, Typography } from '@mui/material';

import { CartesianGrid, Line, LineChart, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';
import { dateFormat } from 'services/format/dateFormat';
import { CompanyMetrics, CompanyInvestmentMetric } from 'services/types/companyMetrics';

import { AllMetrics } from './metrics';

const getTooltipDate = (payload: Payload<number, string>[]) => {
  if (payload && payload.length) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/unbound-method, @typescript-eslint/no-unsafe-member-access
    return dateFormat(payload[0].payload.day);
  }
  return '';
};

const CustomTooltip: FC<TooltipProps<number, string>> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          backgroundColor: '#fff',
          borderRadius: 2,
          p: 3,
        }}
      >
        <Typography variant="body1">
          <strong>Fecha: </strong>
          {getTooltipDate(payload)}
        </Typography>
        {payload.map((entry) => (
          <Typography key={entry.name} variant="body1">
            <strong>{AllMetrics.find((metric) => metric.dataKey === entry.name)?.name}: </strong>
            {entry.value}
          </Typography>
        ))}
      </Box>
    );
  }
  return null;
};
interface MetricsChartProps {
  title: string;
  metricsData: CompanyMetrics[] | CompanyInvestmentMetric[];
  metrics: {
    name: string;
    dataKey: string;
    stroke: string;
  }[];
  hide: { [key: string]: boolean };
  handleHide: (key: string) => void;
}

const MetricsChart: FC<MetricsChartProps> = ({ title, metricsData, metrics, hide, handleHide }) => {
  // find the width of the parent element
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const el = document.getElementById(`chart-${title}`);
    if (el) {
      setWidth(el.offsetWidth * 0.9);
    }
  }, [title]);
  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        borderRadius: 2,
        p: 3,
        my: 3,
      }}
      id={`chart-${title}`}
    >
      <Typography variant="h5">{title}</Typography>
      <LineChart width={width} height={350} data={metricsData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="day" interval={0} angle={-45} tickMargin={50} height={110} tick={{ fontSize: 12 }} />
        <YAxis />
        {metrics.map((metric) => (
          <Line
            key={metric.dataKey}
            type="monotone"
            dataKey={metric.dataKey}
            stroke={metric.stroke}
            activeDot={{ r: 8 }}
            dot={false}
            hide={hide[metric.dataKey]}
          />
        ))}
        <Tooltip content={<CustomTooltip />} />
      </LineChart>
      <Box>
        {metrics.map((metric) => (
          <Box
            key={metric.dataKey}
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              mr: 2,
              mb: 2,
              color: hide[metric.dataKey] ? '#ccc' : '#000',
            }}
            onClick={() => handleHide(metric.dataKey)}
          >
            <Box
              sx={{
                width: 20,
                height: 20,
                borderRadius: '50%',
                backgroundColor: hide[metric.dataKey] ? '#ccc' : metric.stroke,
                mr: 1,
              }}
            />
            <Typography variant="body1">{metric.name}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default MetricsChart;
