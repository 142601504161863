import { ScraperDebts } from 'services/types/scraperRequest';

import baseApi from './baseApi';

const scraperRequestsAPI = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getParsedScraperDebts: build.query({
      query: () => '/scraperRequests/parsedDebts',
      transformResponse: (response: ScraperDebts) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useGetParsedScraperDebtsQuery } = scraperRequestsAPI;

export default scraperRequestsAPI;
