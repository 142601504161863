import React, { FC } from 'react';

import { SvgIconComponent } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';

export function AddDigit(num: number) {
  return `$${num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`;
}
interface BudgetSummaryExpenseItemProps {
  Icon: SvgIconComponent;
  text: string;
  amount: number;
}
const BudgetSummaryExpenseItem: FC<BudgetSummaryExpenseItemProps> = ({ Icon, text, amount }) => {
  return (
    <Grid
      container
      rowSpacing={0}
      columnSpacing={0}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      marginLeft={0}
    >
      <Grid item xs={2}>
        <Icon sx={{ fontSize: { xs: '32px', xl: '38px' }, color: 'warning.main' }} />
      </Grid>
      <Grid item xs={5}>
        <Typography fontSize="18px" fontWeight={700}>
          {text}
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography fontSize="18px" fontWeight={700} sx={{ pl: 2 }}>
          {AddDigit(amount)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default BudgetSummaryExpenseItem;
