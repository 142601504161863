import React from 'react';

import Link, { LinkProps, linkClasses } from '@mui/material/Link';
import { styled } from '@mui/material/styles';

const CLALink = styled(({ ref, ...props }: LinkProps) => <Link {...props} underline="hover" />)(({ theme }) => ({
  [`& .${linkClasses.root}`]: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  [`&:hover`]: {
    color: '#00648F',
    backgroundColor: theme.palette.backgroundGrey.main,
  },
  [`&:visited`]: {
    color: '#00648F',
  },
  [`&:active`]: {
    color: theme.palette.info.main,
  },
}));

export default CLALink;
