import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import AlertDialog from 'components/UI/AlertDialog';
import { BlackBtn, SecondaryBtn } from 'components/UI/Buttons';
import LoadingOverlay from 'components/UI/LoadingOverlay';
import RoundedInput from 'components/UI/RoundedInput';
import { BigCheckWithText, BigCrossWithText, CheckWithText, CrossWithText } from 'components/common/IconsWithText';
import Error from 'pages/Error/ErrorAlert';
import { setAccessToken } from 'redux/slices/accessToken.slice';
import { format } from 'rut.js';
import { useSignExpedienteMutation } from 'services/api/firmaYa.api';
import { useGetUserInformationQuery, useInformationAdditionalMutation } from 'services/api/user.api';
import { captureUserEvent, posthogReset } from 'utils/posthogUtils';
import validateEmail from 'utils/validations/validateEmail';

import styles from '../WelcomePanel.module.scss';

interface Props {
  open: boolean;
  setAddInfoModalOpen: (state: boolean) => void;
  addEmail: boolean;
  onSuccessSubmit: () => void;
}

const AddInformationModal: FC<Props> = (props: Props) => {
  const { data: currentUser } = useGetUserInformationQuery(null);
  const { open, setAddInfoModalOpen, addEmail, onSuccessSubmit } = props;

  const [rut, setRut] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [documentNumber, setDocumentNumber] = useState('');
  const [acceptedTerms, setAcceptedTerms] = useState(true);

  const [msgError, setMsgError] = useState('');
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [openAuthErrorDialog, setOpenAuthErrorDialog] = useState(false);
  const [
    addInformation,
    {
      isSuccess: addInformationIsSuccess,
      isLoading: addInformationIsLoading,
      isError: addInformationIsError,
      error: addInformationError,
    },
  ] = useInformationAdditionalMutation();
  const [signExpediente, { isSuccess: signExpedienteIsSuccess, isLoading: signExpedienteIsLoading }] =
    useSignExpedienteMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const emailVerify = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    if (!event.target.value) {
      setEmailError(false);
    } else {
      setEmailError(!validateEmail(event.target.value));
    }
  };

  const documentNumberFormatter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatter = new Intl.NumberFormat('es-CL');
    const value = Number(e.target.value.replace(/\D+/g, '').slice(0, 9));
    setDocumentNumber(formatter.format(value));
  };

  const getError = () => {
    const queryError = addInformationError as { data: string | object; status: number };
    const errorMessage = typeof queryError.data === 'string' ? queryError.data : 'Error desconocido';
    return <Error message={errorMessage} isOpen={addInformationIsError && queryError.status !== 403} />;
  };

  useEffect(() => {
    if (currentUser) {
      setRut(currentUser.rut || '');
      setEmail(currentUser.email || '');
    }
  }, [currentUser]);

  const handleSignMandate = async () => {
    const response = await signExpediente({
      rut,
      documentNumber,
    });
    if ('error' in response && 'status' in response.error) {
      const errorMsg = response.error.data as { message: string };
      captureUserEvent('failed_sign_mandate', {
        errorMsg,
        rut,
        email: currentUser?.email,
        firstName: currentUser?.firstName,
        lastName: currentUser?.lastName,
        phone: currentUser?.phone,
      });
      if (response.error.status === 502) {
        setOpenErrorDialog(true);
        setMsgError(errorMsg.message);
      }
      if (errorMsg.message.includes('no está vigente')) {
        setOpenAuthErrorDialog(true);
      }
    } else {
      captureUserEvent('successful_sign_mandate', {
        rut,
        email: currentUser?.email,
        firstName: currentUser?.firstName,
        lastName: currentUser?.lastName,
        phone: currentUser?.phone,
      });
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await addInformation({
      email,
      rut,
      isAddEmail: addEmail,
      phone: currentUser?.phone || '',
    });
  };

  const validate = () => {
    if (addEmail) {
      return email && !emailError && documentNumber.length >= 11 && acceptedTerms;
    }
    return rut.length >= 9 && documentNumber.length >= 11 && acceptedTerms;
  };

  useEffect(() => {
    if (addInformationIsSuccess) {
      handleSignMandate().catch((e) => console.error(e)); // eslint-disable-line no-console
    }
  }, [addInformationIsSuccess]);

  useEffect(() => {
    if (addInformationIsSuccess && signExpedienteIsSuccess) {
      onSuccessSubmit();
      setAddInfoModalOpen(false);
    }
  }, [addInformationIsSuccess, signExpedienteIsSuccess]);

  const mandateAndTC = () => (
    <>
      <RoundedInput
        id="documentNumber"
        name="documentNumber"
        value={documentNumber}
        required
        fullWidth
        label="Número de documento de su cédula"
        sx={{ bgcolor: '#fff', my: 1 }}
        onChange={documentNumberFormatter}
      />
      <FormControlLabel
        sx={{ px: 2 }}
        control={<Checkbox checked={acceptedTerms} />}
        onChange={(_, checked) => {
          setAcceptedTerms(checked);
        }}
        label={
          <Typography variant="body1" fontSize={20}>
            Acepto{' '}
            <Link
              rel="noreferrer"
              target="_blank"
              href="https://storage.googleapis.com/relif-public-bucket/Poder%20Especial.pdf"
            >
              Poder
            </Link>{' '}
            y{' '}
            <Link
              rel="noreferrer"
              target="_blank"
              href="https://storage.googleapis.com/relif-public-bucket/terms_and_conditions.pdf"
            >
              Términos & Condiciones
            </Link>
          </Typography>
        }
      />
    </>
  );

  return (
    <Modal
      open={open}
      slotProps={{
        backdrop: {
          style: {
            backgroundImage: "url('./relif_welcome_page.webp')",
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            height: '100vh',
            width: '100vw',
            // blur and make it darker
            filter: 'blur(8px) brightness(0.8)',
            margin: 0,
            padding: 0,
          },
        },
      }}
    >
      <Box className={styles.AddInfoModal}>
        <Box>
          <LoadingOverlay visible={signExpedienteIsLoading || addInformationIsLoading} />
          {addInformationIsError && getError()}
          <AlertDialog
            openDialog={openErrorDialog}
            setOpenDialog={setOpenErrorDialog}
            header="Hubo un error, si el problema persiste contáctanos a través del chat"
            msg={msgError}
            confirmMsg="Cerrar"
            confirmAction={() => setOpenErrorDialog(false)}
            cancelMsg=""
          />
          <AlertDialog
            openDialog={openAuthErrorDialog}
            setOpenDialog={setOpenAuthErrorDialog}
            header="Error al autentificarte"
            msg="Tu RUT no coincide con el número de documento que has ingresado"
            confirmMsg="Cerrar"
            confirmAction={() => setOpenAuthErrorDialog(false)}
            cancelMsg=""
          />
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h2" pt={0} textAlign="left" width="100%" py={2} fontSize={30}>
              Bienvenid@
            </Typography>
            <BlackBtn
              sx={{
                height: 30,
                fontSize: 10,
                width: 120,
                p: 0,
              }}
              onClick={() => {
                posthogReset();
                dispatch(setAccessToken({ accessToken: null }));
                navigate('/welcome');
              }}
            >
              Cerrar sesión
            </BlackBtn>
          </Box>
          <Box
            component="form"
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
              handleSubmit(e).catch((err) => {
                // eslint-disable-next-line no-console
                console.error(err);
              });
            }}
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            mt={2}
          >
            <Box className={styles.ContentBox}>
              <Typography variant="body1" fontSize={20} width="100%" textAlign="left" py={1}>
                Para empezar, completa tus datos y revisa nuestros términos & condiciones.
              </Typography>

              {!addEmail && (
                <RoundedInput
                  id="rut"
                  name="rut"
                  autoComplete="rut"
                  value={rut}
                  required
                  fullWidth
                  label="Rut"
                  onChange={(e) => setRut(format(e.target.value))}
                  sx={{ bgcolor: '#fff', my: 1 }}
                />
              )}
              {addEmail && (
                <RoundedInput
                  id="email"
                  name="email"
                  autoComplete="email"
                  fullWidth
                  label="Email"
                  onChange={emailVerify}
                  error={emailError}
                  helperText={emailError && 'Debe ser un correo'}
                  sx={{ bgcolor: '#fff', my: 1 }}
                />
              )}
              {mandateAndTC()}
              <Box mt={2}>
                <BigCheckWithText text="¿Qué podemos hacer?" />
                <CheckWithText text="Negociar con instituciones financieras" />
                <CheckWithText text="Solicitar tus liquidaciones de sueldo a tu empleador" />
                <BigCrossWithText text="¿Qué no podemos hacer?" />
                <CrossWithText text="Tomar créditos a tu nombre" />
                <CrossWithText text="Hacer cambios a tus créditos actuales" />
              </Box>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center" mt={1}>
              <SecondaryBtn
                className={styles.SubmitButton}
                type="submit"
                variant="contained"
                sx={{
                  height: 40,
                  width: 300,
                }}
                disabled={!validate()}
              >
                Aceptar
              </SecondaryBtn>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddInformationModal;
