import React from 'react';

import DirectionsCar from '@mui/icons-material/DirectionsCar';
import Elderly from '@mui/icons-material/Elderly';
import FireExtinguisher from '@mui/icons-material/FireExtinguisher';
import Flight from '@mui/icons-material/Flight';
import ShoppingCart from '@mui/icons-material/ShoppingCart';

import { HomeIcon, GoalIcon, DebtsIcon } from 'assets/icons';

const getIcon = (name: string) => {
  if (
    name.toLowerCase().includes('auto') ||
    name.toLowerCase().includes('vehículo') ||
    name.toLowerCase().includes('vehiculo')
  ) {
    return <DirectionsCar sx={{ width: '70%', height: '70%' }} />;
  }

  if (name.toLowerCase().includes('casa') || name.toLowerCase().includes('depa')) {
    return <HomeIcon sx={{ width: '70%', height: '70%' }} />;
  }

  if (name.toLowerCase().includes('compra')) {
    return <ShoppingCart sx={{ width: '70%', height: '70%' }} />;
  }

  if (name.toLowerCase().includes('vacaci') || name.toLowerCase().includes('viaje')) {
    return <Flight sx={{ width: '70%', height: '70%' }} />;
  }

  if (name.toLowerCase().includes('jubila')) {
    return <Elderly sx={{ width: '70%', height: '70%' }} />;
  }
  if (
    name.toLowerCase().includes('pago') ||
    name.toLowerCase().includes('deuda') ||
    name.toLowerCase().includes('crédito')
  ) {
    return <DebtsIcon sx={{ width: '70%', height: '70%' }} />;
  }
  if (name.toLowerCase().includes('emergencia')) {
    return <FireExtinguisher sx={{ width: '70%', height: '70%' }} />;
  }
  return <GoalIcon sx={{ width: '70%', height: '70%' }} />;
};

export default getIcon;
