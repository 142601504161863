import { OpenAIMessage, OpenAIFunction } from '../types/whatsApp';
import baseApi from './baseApi';

const playgroundApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    generateRequest: build.mutation({
      query: (body: { conversation: OpenAIMessage[]; functions: OpenAIFunction[] }) => ({
        url: 'admin/playground/request',
        method: 'POST',
        body,
      }),
      transformResponse: (response: OpenAIMessage): OpenAIMessage => response,
    }),
  }),
  overrideExisting: false,
});

// eslint-disable-next-line import/prefer-default-export
export const { useGenerateRequestMutation } = playgroundApi;
