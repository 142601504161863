import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme, padding }) => ({
  fontSize: '14px',
  padding: padding === 'none' ? 0 : '10px',
  fontWeight: 400,
  [theme.breakpoints.up('xl')]: {
    fontSize: '16px',
    padding: padding === 'none' ? 0 : '12px',
  },
  [`&.${tableCellClasses.head}`]: {
    color: '#A6A7A8',
  },
}));

export default StyledTableCell;
