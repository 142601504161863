import { RoiCalculatorDraft, RoiCalculator } from '../types/roiCalculators';
import baseApi from './baseApi';

const roiCalculatorsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getRoiCalculator: build.mutation({
      query: (email: string) => ({
        url: `/roiCalculators/${email}`,
        method: 'GET',
      }),
      transformResponse: (response: RoiCalculator): RoiCalculator => response,
    }),
    createRoiCalculator: build.mutation({
      query: (body: RoiCalculatorDraft) => ({
        url: 'admin/roiCalculators',
        method: 'POST',
        body,
      }),
      transformResponse: (response: RoiCalculator): RoiCalculator => response,
    }),
    getAllRoiCalculators: build.query({
      query: () => ({
        url: 'admin/roiCalculators',
        method: 'GET',
      }),
      transformResponse: (response: RoiCalculator[]): RoiCalculator[] => response,
    }),
    editRoiCalculator: build.mutation({
      query: (props: { id: number; calculator: Partial<RoiCalculatorDraft> }) => ({
        url: `admin/roiCalculators/${props.id}`,
        method: 'PUT',
        body: props.calculator,
      }),
      transformResponse: (response: RoiCalculator): RoiCalculator => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetRoiCalculatorMutation,
  useCreateRoiCalculatorMutation,
  useGetAllRoiCalculatorsQuery,
  useEditRoiCalculatorMutation,
} = roiCalculatorsApi;
