/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, useEffect, useState } from 'react';

import Phone from '@mui/icons-material/Phone';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// @ts-ignore
import { Button } from '@dscla/ds-button/react';
// @ts-ignore
import { Input } from '@dscla/ds-input/react';
// @ts-ignore
import { MessageAlerta } from '@dscla/ds-message-alerta/react';
import PrimaryBtn from 'components/UI/Buttons';
import CloseableAlert from 'components/UI/CloseableAlert';
import LoadingOverlay from 'components/UI/LoadingOverlay';
import SectionHeader from 'components/layout/SectionHeader';
import useIsCajaLosAndesTheme from 'hooks/useIsCajaLosAndesTheme';
import { useChangeInformationMutation, useChangePasswordMutation, useGetUserQuery } from 'services/api/user.api';
import validatePassword, { getPasswordErrorMessage } from 'utils/validations/validatePassword';

const Profile: FC = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordConfirmError, setPasswordConfirmError] = useState(false);
  const [changePassword, { isError, isLoading, isSuccess }] = useChangePasswordMutation();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [errorInfo, setErrorInfo] = useState('');
  const [changeInformation, { isError: isErrorInfo, isLoading: isLoadingInfo, isSuccess: isSuccessInfo }] =
    useChangeInformationMutation();
  const validatePhone = (value: string) =>
    (value.startsWith('569') && value.length === 11) || (value.startsWith('+56 (9)') && value.length === 17);
  const isCajaTheme = useIsCajaLosAndesTheme();

  const { data: user, refetch } = useGetUserQuery(null);

  useEffect(() => {
    refetch().catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e);
    });
  }, []);

  useEffect(() => {
    if (user) {
      setLastName(user.lastName);
      setFirstName(user.firstName);
      setPhone(user.phone || '');
    }
  }, [user]);

  const passwordVerify = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
    if (!event.target.value) {
      setPasswordError(false);
    } else {
      setPasswordError(!validatePassword(event.target.value, user?.rut || '', user?.email || ''));
    }
  };

  const verifyPasswordForm = () => {
    const values = !!currentPassword && !!newPassword && !!passwordConfirm;
    const errors = passwordError || passwordConfirmError;
    return values && !errors;
  };

  const handlePasswordSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (newPassword === passwordConfirm && verifyPasswordForm()) {
      changePassword({
        currentPassword,
        newPassword,
      }).catch((err: Error) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
    }
  };

  const passwordConfirmVerify = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordConfirm(event.target.value);
    if (!event.target.value) {
      setPasswordConfirmError(false);
    } else {
      setPasswordConfirmError(newPassword !== event.target.value);
    }
  };

  const handleInformationSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    type ErrorSchema = {
      data: string;
    };
    if (validatePhone(phone)) {
      changeInformation({
        firstName,
        lastName,
        phone,
      })
        .unwrap()
        .catch((err: ErrorSchema) => {
          setErrorInfo(err.data);
        });
    }
  };

  const getPasswordInputVariant = () => {
    if (!newPassword) {
      return '';
    }
    return passwordError ? 'error' : 'success';
  };
  const getConfirmPasswordInputVariant = () => {
    if (!passwordConfirm) {
      return '';
    }
    return passwordConfirmError ? 'error' : 'success';
  };

  return (
    <Box sx={{ px: { xs: 2, xl: 0 } }}>
      <SectionHeader text="Perfil" button={null} />
      <Typography variant="h6" pb="25px">
        Actualización de información
      </Typography>
      <Box component="form" onSubmit={handleInformationSubmit}>
        <LoadingOverlay visible={isLoadingInfo} />
        {isErrorInfo && <CloseableAlert msg={errorInfo} severity="error" />}
        {isSuccessInfo && (
          <MessageAlerta color="exito">
            <span slot="label">Información actualizada correctamente</span>
          </MessageAlerta>
        )}
        <Grid container spacing={2} pb={2}>
          <Grid item xs={12} sm={6}>
            <Input
              id="firstName"
              name="firstName"
              autoComplete="given-name"
              label="Nombre"
              type="text"
              value={firstName}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              id="lastName"
              name="lastName"
              autoComplete="family-name"
              fullWidth
              label="Apellido"
              type="text"
              value={lastName}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              type="tel"
              label="Celular"
              value={phone}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => setPhone(e.target.value)}
            >
              <div slot="icon">
                <Phone />
              </div>
            </Input>
          </Grid>
        </Grid>
        {isCajaTheme ? (
          <Button type="submit" disabled={!validatePhone(phone)}>
            Actualizar información
          </Button>
        ) : (
          <PrimaryBtn type="submit" fullWidth variant="contained" disabled={!validatePhone(phone)}>
            Actualizar información
          </PrimaryBtn>
        )}
      </Box>
      <Typography variant="h6" pt={4} pb={1}>
        Cambio de contraseña
      </Typography>
      <Box component="form" onSubmit={handlePasswordSubmit}>
        <LoadingOverlay visible={isLoading} />
        {isError && <CloseableAlert msg="Error actualizando la contraseña" severity="error" />}
        {isSuccess && <CloseableAlert msg="Contraseña actualizada correctamente" severity="success" />}
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Grid container spacing={2} pb={2}>
            <Grid item xs={12} sm={6}>
              <Input
                id="password"
                name="password"
                autoComplete="new-password"
                label="Contraseña nueva"
                type="password"
                variant={getPasswordInputVariant()}
                value={newPassword}
                errorMessage={passwordError && getPasswordErrorMessage(newPassword, user?.rut || '', user?.email || '')}
                onInput={passwordVerify}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                id="passwordConfirm"
                name="passwordConfirm"
                label="Confirmar contraseña"
                type="password"
                variant={getConfirmPasswordInputVariant()}
                value={passwordConfirm}
                errorMessage={passwordConfirmError && 'Las contraseñas no coinciden'}
                onInput={passwordConfirmVerify}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                id="currentPassword"
                name="currentPassword"
                autoComplete="current-password"
                label="Contraseña actual"
                type="password"
                value={currentPassword}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => setCurrentPassword(e.target.value)}
              />
            </Grid>
          </Grid>
          {isCajaTheme ? (
            <Button type="submit" disabled={!verifyPasswordForm()}>
              Cambiar contraseña
            </Button>
          ) : (
            <PrimaryBtn type="submit" fullWidth variant="contained" disabled={!verifyPasswordForm()}>
              Cambiar contraseña
            </PrimaryBtn>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Profile;
