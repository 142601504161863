import { DebtsSimulationsResponse } from '../types/debt';
import baseApi from './baseApi';

const bankOfferApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getDebtsComparison: build.mutation({
      query: (bankOfferUUID: string) => ({
        url: `/bankOffers/${bankOfferUUID}/comparison`,
        method: 'GET',
      }),
      transformResponse: (response: DebtsSimulationsResponse | null) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useGetDebtsComparisonMutation } = bankOfferApi;

export default bankOfferApi;
