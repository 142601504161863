import React, { FC } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'contexts/translationContext';
import useIsMobile from 'hooks/useIsMobile';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import '../App.scss';

export const companyLogos = [
  { src: '/landing_page/img/companies/cchc.webp', alt: 'CCHC' },
  { src: '/landing_page/img/companies/concha-y-toro.webp', alt: 'Concha y Toro' },
  { src: '/landing_page/img/companies/desco.webp', alt: 'Desco' },

  { src: '/landing_page/img/companies/kaufmann.webp', alt: 'Kaufmann' },
  { src: '/landing_page/img/companies/enaex.webp', alt: 'Enaex' },

  { src: '/landing_page/img/companies/paula-benitez.webp', alt: 'Paula Benitez' },
  { src: '/landing_page/img/companies/desa.webp', alt: 'Desa' },

  { src: '/landing_page/img/companies/maria-manjar.webp', alt: 'Maria Manjar' },

  { src: '/landing_page/img/companies/iansa.webp', alt: 'Iansa' },
  { src: '/landing_page/img/companies/camanchaca.webp', alt: 'Camanchaca' },
];

const Companies: FC = () => {
  const { translation } = useTranslation();
  const companyTranslation = translation.landingPage.company;

  const { isMobile } = useIsMobile();
  return (
    <Box
      sx={{
        backgroundColor: '#F1F6FC',
        py: 8,
      }}
    >
      <Box width="100%">
        <Typography
          fontFamily="Roboto"
          fontWeight={700}
          fontSize="22px"
          lineHeight="30px"
          color="#2C5889"
          textAlign="center"
        >
          {companyTranslation.title2}
        </Typography>
        <Swiper
          className="sample-slider"
          modules={[Autoplay]}
          loop
          autoplay={{
            delay: 0,
            disableOnInteraction: false, // restart autoplay when hover is removed
            reverseDirection: false, // reverse the autoplay direction
          }}
          slidesPerView={isMobile ? 2 : 5}
          speed={5000}
        >
          {companyLogos.map((companyLogo) => (
            <SwiperSlide key={companyLogo.alt} style={{ height: '200px' }}>
              <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
                <img src={companyLogo.src} alt={companyLogo.alt} width="150px" />
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  );
};

export default Companies;
