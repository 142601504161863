import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, CircularProgress, Grid, TextField, Typography } from '@mui/material';

import { EditIcon } from 'assets/icons';
import AlertDialog from 'components/UI/AlertDialog';
import PrimaryBtn, { EditBtn } from 'components/UI/Buttons';
import SearchBar from 'components/UI/SearchBar';
import SectionHeader from 'components/layout/SectionHeader';
import useQuery from 'hooks/useQuery';
import DebtTracesDrawer from 'pages/Debts/components/DebtTracesDrawer';
import DebtsTable from 'pages/Debts/components/DebtsTable';
import DrawerDebtForm from 'pages/Debts/components/ManualDebt/DrawerDebtForm';
import { setAccessToken } from 'redux/slices/accessToken.slice';
import {
  useGetUserMandateMutation,
  useGetUsersMutation,
  useLogInAsUserMutation,
  useAdminGetUserMutation,
  useGetPayrollInfoMutation,
  useGetPayrollFileMutation,
  useResetTestUserMutation,
} from 'services/api/admin-users.api';
import { BukSettlementResponse } from 'services/types/buk';
import { Debt } from 'services/types/debt';
import { FilterUsersBody, User } from 'services/types/user';

import ActionPlan from './ActionPlan';
import AdminNotes from './AdminNotes';
import EditUser from './EditUser';
import Filters from './Filters';
import ReminderDrawer from './ReminderDrawer';
import UserBankOfferRequests from './UserBankOfferRequests';
import UserDetailsCard from './UserDetailsCard';
import UserMessagesSummary from './UserMessagesSummary';
import UsersTable from './UsersTable';

const Users = () => {
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [selectedDebt, setSelectedDebt] = useState<Debt | null>(null);
  const [debtsRefetchFlag, setDebtsRefetchFlag] = useState(false);
  const [openEditDebtModal, setOpenEditDebtModal] = useState(false);
  const [openEditUserView, setOpenEditUserView] = useState(false);
  const [openDebtTracesDrawer, setOpenDebtTracesDrawer] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = React.useState(false);
  const [password, setPassword] = React.useState('');

  const query = useQuery();
  const queryFilterModel = query.get('filterModel');
  const [filterUsersBody, setFilterUsersBody] = useState<FilterUsersBody>(
    queryFilterModel
      ? (JSON.parse(queryFilterModel) as FilterUsersBody)
      : {
          page: 0,
          pageSize: 50,
          search: '',
          companyFilter: {
            name: '',
          },
        }
  );

  const paginationModel = {
    pageIndex: filterUsersBody.page,
    pageSize: filterUsersBody.pageSize,
  };

  const setPaginationModel = ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }) => {
    setFilterUsersBody((prev) => ({ ...prev, page: pageIndex, pageSize }));
  };

  const [getUsers, { data: usersData, isLoading: isGetUsersLoading }] = useGetUsersMutation();
  const [getUserMandate, { data: userMandate, isError }] = useGetUserMandateMutation();
  const [logInAsUser, { data: loggedInUser, isError: isErrorLoginUser, isLoading: isLoadingLoginUser }] =
    useLogInAsUserMutation();
  const [getUserData, { data: userData, isLoading: isLoadingUserData }] = useAdminGetUserMutation();
  const [getPayrollInfo, { data: payrollInfo, reset: resetPayrollInfo }] = useGetPayrollInfoMutation();
  const [getPayrollFile, { data: payrollFile, reset: resetPayrollFile }] = useGetPayrollFileMutation();
  const [resetTestUser] = useResetTestUserMutation();

  // make a copy and sort
  const sortedPayrolls = (payrollInfo as BukSettlementResponse)
    ? (payrollInfo as BukSettlementResponse).data?.slice().sort((a, b) => {
        const aDate = new Date(Number(a.year), Number(a.month) - 1);
        const bDate = new Date(Number(b.year), Number(b.month) - 1);
        return bDate.getTime() - aDate.getTime();
      })
    : [];

  const userId = query.get('userId');
  const pageSize = query.get('pageSize');
  const pageIndex = query.get('pageIndex');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleGetUserMandate = () => {
    const selectedUserId = Number(selectedUser?.id);
    if (!selectedUserId) return;
    getUserMandate(selectedUserId).catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
    });
  };

  const handleLogInAsUser = () => {
    if (!selectedUser) return;
    logInAsUser({ userId: selectedUser.id, password }).catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
    });
  };

  const handleRefetchUserData = () => {
    if (!selectedUser) return;
    getUserData(selectedUser.id).catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
    });
  };

  const handleBackFromEditUser = () => {
    setOpenEditUserView(false);
    handleRefetchUserData();
  };

  const handleResetTestUser = () => {
    if (!selectedUser) return;
    resetTestUser(selectedUser.id)
      .then(() => {
        handleRefetchUserData();
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };

  const handleOpenEditDebtModal = (debt: Debt) => {
    setSelectedDebt(debt);
    setOpenEditDebtModal(true);
  };

  const handleOpenDebtTracesDrawer = (debt: Debt) => {
    setSelectedDebt(debt);
    setOpenDebtTracesDrawer(true);
  };

  const handleGetPayrollFile = (year: number, month: number) => {
    if (!selectedUser || !selectedUser.rut || !selectedUser.company?.humanResourcesSoftwareUrl) return;
    getPayrollFile({ userId: selectedUser.id, year, month }).catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
    });
  };

  const handleGetUsers = () => {
    if (userId) return;
    getUsers(filterUsersBody).catch((err) => {
      console.log(err); // eslint-disable-line no-console
    });
  };

  const setSearchTerm = (term: string) => {
    setFilterUsersBody((prev) => ({ ...prev, search: term }));
  };

  const downloadDataAsJson = (data: unknown, fileName: string) => {
    const fileData = JSON.stringify(data, null, 2);
    const blob = new Blob([fileData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = fileName;
    link.href = url;
    link.click();
  };

  const navigateBack = () => {
    setSelectedUser(null);
    const prevPath = query.get('prevPath');
    if (prevPath) {
      navigate(prevPath);
    } else {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.delete('userId');
      navigate(`/admin/users?${queryParams.toString()}`);
    }
  };

  useEffect(() => {
    if (userMandate) {
      const { url } = userMandate;
      if (url) {
        window.open(url, '_blank')?.focus();
      } else {
        setOpenErrorAlert(true);
      }
    }
  }, [userMandate]);

  useEffect(() => {
    if (pageSize && pageIndex) {
      setFilterUsersBody((prev) => ({ ...prev, pageSize: Number(pageSize), page: Number(pageIndex) }));
    }
  }, [pageSize, pageIndex]);

  useEffect(() => {
    if (filterUsersBody.page >= 0 && filterUsersBody.pageSize >= 0) {
      handleGetUsers();
    }
  }, [filterUsersBody.page, filterUsersBody.pageSize]);

  useEffect(() => {
    if (isError) {
      setOpenErrorAlert(true);
    }
  }, [isError]);

  useEffect(() => {
    if (loggedInUser) {
      setPassword('');
      dispatch(setAccessToken({ accessToken: loggedInUser.accessToken, loggedInAsOtherUser: true }));
    }
  }, [loggedInUser]);

  useEffect(() => {
    if (selectedUser?.id) {
      handleRefetchUserData();
    }
  }, [selectedUser?.id]);

  useEffect(() => {
    handleGetUsers();
  }, []);

  useEffect(() => {
    if (userId) {
      getUserData(parseInt(userId, 10)).catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
    } else {
      setSelectedUser(null);
    }
  }, [userId]);

  useEffect(() => {
    if (userData) {
      setSelectedUser(userData);
    }
  }, [userData]);

  useEffect(() => {
    if (selectedUser && selectedUser.rut && selectedUser.company?.humanResourcesSoftwareUrl) {
      getPayrollInfo(selectedUser.id).catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
    } else {
      resetPayrollInfo();
      resetPayrollFile();
    }
  }, [selectedUser?.id]);

  useEffect(() => {
    if (payrollFile) {
      const { url } = payrollFile;
      if (url) {
        // open file in new tab
        window.open(url, '_blank')?.focus();
      }
    }
  }, [payrollFile]);

  useEffect(() => {
    if (userData && selectedUser) {
      getUserData(selectedUser.id).catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
    }
  }, [debtsRefetchFlag]);

  // refetch debt data when debt drawer is closed
  useEffect(() => {
    if (!openEditDebtModal) {
      setDebtsRefetchFlag((prev) => !prev);
    }
  }, [openEditDebtModal]);

  useEffect(() => {
    query.delete('filterModel');
    // save filters in query params
    if (filterUsersBody) {
      query.set('filterModel', JSON.stringify(filterUsersBody));
      navigate(`?${query.toString()}`);
    }
  }, [filterUsersBody]);

  return (
    <Box>
      <SectionHeader text="Usuarios" button={null} />
      <AlertDialog
        openDialog={openErrorAlert}
        setOpenDialog={setOpenErrorAlert}
        header="Error descargando el poder"
        msg="El poder no existe o no se pudo descargar"
        confirmMsg="Aceptar"
        confirmAction={() => setOpenErrorAlert(false)}
      />
      {openEditDebtModal && (
        <DrawerDebtForm
          debtToEdit={selectedDebt}
          setDebtToEdit={setSelectedDebt}
          isFormOpen={openEditDebtModal}
          setFormOpen={setOpenEditDebtModal}
          adminMode
        />
      )}
      {openDebtTracesDrawer && (
        <DebtTracesDrawer
          isOpen={openDebtTracesDrawer}
          onClose={() => {
            setOpenDebtTracesDrawer(false);
          }}
          debt={selectedDebt}
          handleRefinanceDebts={() => {}}
          handleEditDebt={() => {}}
        />
      )}
      {openEditUserView && userData ? (
        <EditUser user={userData} handleBack={handleBackFromEditUser} />
      ) : (
        <Grid container>
          <Grid item xs={12} p={1}>
            {!selectedUser && (
              <>
                <Box display="flex" alignItems="flex-start">
                  <Typography variant="h3" mt={1}>
                    Lista de usuarios
                  </Typography>
                  <SearchBar
                    data={usersData?.rows || []}
                    searchTerm={filterUsersBody.search}
                    setSearchTerm={setSearchTerm}
                  />
                  <PrimaryBtn
                    onClick={() => {
                      handleGetUsers();
                    }}
                    sx={{ height: '50px', ml: 2 }}
                    disabled={isGetUsersLoading}
                  >
                    Buscar
                  </PrimaryBtn>
                </Box>
                <Filters
                  filterUsersBody={filterUsersBody}
                  setFilterUsersBody={setFilterUsersBody}
                  refetchUsers={handleGetUsers}
                />
                <UsersTable
                  users={usersData?.rows || []}
                  paginationModel={paginationModel}
                  setPaginationModel={setPaginationModel}
                  rowCount={usersData?.totalItems || 0}
                  isLoading={isGetUsersLoading}
                />
              </>
            )}
            {selectedUser && (
              <Typography variant="h3" mt={1}>
                Detalle de usuario
              </Typography>
            )}
          </Grid>
          {selectedUser && userData && (
            <>
              <Grid item xs={12} p={1}>
                <Box display="flex" justifyContent="flex-start" alignItems="center">
                  <PrimaryBtn
                    onClick={() => {
                      navigateBack();
                    }}
                  >
                    Volver
                  </PrimaryBtn>
                  {selectedUser && <ReminderDrawer userId={selectedUser.id} />}
                  <EditBtn
                    onClick={() => {
                      setOpenEditUserView(true);
                    }}
                    sx={{ height: '50px', width: '50px', ml: 2 }}
                  >
                    <EditIcon />
                  </EditBtn>
                </Box>
              </Grid>
              <Grid item xs={12} md={8} p={1}>
                <UserDetailsCard user={userData} />
              </Grid>
              <Grid item xs={12} md={4} p={1}>
                <Box
                  sx={{
                    backgroundColor: 'white',
                    p: 2,
                    borderRadius: 4,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  height="100%"
                >
                  <Typography variant="h4" mb={2}>
                    Documentos
                  </Typography>
                  <Typography
                    variant="body1"
                    mb={1}
                    sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={handleGetUserMandate}
                  >
                    Poder
                  </Typography>
                  <Typography
                    variant="body1"
                    mb={1}
                    sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={() => {
                      navigate(`/admin/users/debts-report?userId=${selectedUser.id}`);
                    }}
                  >
                    Boletín comercial
                  </Typography>
                  <Typography
                    variant="body1"
                    mb={1}
                    sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={() => {
                      downloadDataAsJson(userData, `user-${selectedUser.id}`);
                    }}
                  >
                    JSON usuario
                  </Typography>
                  {payrollInfo && (
                    <Typography
                      variant="body1"
                      mb={1}
                      sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                      // button to download payroll info json
                      onClick={() => {
                        downloadDataAsJson(payrollInfo, `payroll-info-${selectedUser.id}`);
                      }}
                    >
                      JSON liquidaciones
                    </Typography>
                  )}
                  {sortedPayrolls?.map((payroll) => (
                    <Typography
                      key={`${Number(payroll.month)}-${Number(payroll.year)}`}
                      variant="body1"
                      mb={1}
                      sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                      // button to download payroll file
                      onClick={() => {
                        handleGetPayrollFile(Number(payroll.year), Number(payroll.month));
                      }}
                    >
                      PDF liquidación {payroll.month}/{payroll.year}
                    </Typography>
                  ))}
                  {isLoadingLoginUser ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <Typography variant="caption" sx={{ m: 1 }}>
                        Ingresa tu contraseña para ingresar como usuario con id {selectedUser?.id}
                      </Typography>
                      <TextField
                        sx={{ m: 1 }}
                        label="Contraseña"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        disabled={isLoadingLoginUser}
                      />
                    </>
                  )}
                  <PrimaryBtn
                    sx={{ m: 1 }}
                    disabled={!selectedUser || !password || isLoadingLoginUser}
                    onClick={() => {
                      handleLogInAsUser();
                    }}
                  >
                    Login como usuario
                  </PrimaryBtn>
                  {isErrorLoginUser && (
                    <Typography variant="body2" sx={{ m: 1 }}>
                      Error al ingresar como usuario
                    </Typography>
                  )}
                  {selectedUser?.isTestUser && (
                    <PrimaryBtn
                      sx={{ m: 1 }}
                      onClick={() => {
                        handleResetTestUser();
                      }}
                    >
                      Resetear usuario
                    </PrimaryBtn>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} p={1}>
                {userData.debts && (
                  <DebtsTable
                    currentDebts={userData.debts}
                    openEditDebtModal={handleOpenEditDebtModal}
                    handleOpenDebtTracesDrawer={handleOpenDebtTracesDrawer}
                    getData={handleRefetchUserData}
                    adminMode
                  />
                )}
              </Grid>
              <Grid item xs={12} p={1}>
                <UserMessagesSummary userId={selectedUser.id} />
              </Grid>
              <Grid item xs={12} p={1}>
                <UserBankOfferRequests bankOfferRequests={userData.bankOfferRequests || []} />
              </Grid>

              <Grid item xs={12} md={6} p={1}>
                <Box sx={{ backgroundColor: 'white', p: 2, borderRadius: 4 }} height="100%">
                  <ActionPlan userId={selectedUser.id} userData={userData} isLoadingUserData={isLoadingUserData} />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} p={1}>
                <Box sx={{ backgroundColor: 'white', p: 2, borderRadius: 4 }} height="100%">
                  <AdminNotes userId={selectedUser.id} userData={userData} isLoadingUserData={isLoadingUserData} />
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </Box>
  );
};

export default Users;
