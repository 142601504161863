/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// @ts-ignore
import { Input } from '@dscla/ds-input/react';
// @ts-ignore
import { Select, SelectOption } from '@dscla/ds-select/react';
import CajaTooltip from 'components/UI/CajaTooltip';
import MoneyInput from 'components/UI/MoneyInput';
import { DebtDraft } from 'services/types/debt';

const EnableAmountSources = ['Manual', 'buk'];
interface Props {
  debtFamilyType: string | undefined;
  debtDraft: DebtDraft;
  setDebtDraft: (debtDraft: DebtDraft) => void;
  debtSource?: string;
  adminMode?: boolean;
}

const NewDebtStepFour: FC<Props> = ({ debtFamilyType, debtDraft, setDebtDraft, debtSource, adminMode }) => {
  return (
    <Grid container columnSpacing={1}>
      <Grid item xs={12} mb={1}>
        <Select
          type="select"
          label="Moneda"
          value={debtDraft.currency}
          placeHolder="CLP"
          onClickOption={(e: { detail: string }) => {
            setDebtDraft({ ...debtDraft, currency: e.detail });
          }}
        >
          {['CLP', 'UF'].map((currency) => (
            <SelectOption value={currency} key={currency}>
              <Typography variant="body2" color="primary">
                {currency}
              </Typography>
            </SelectOption>
          ))}
        </Select>
      </Grid>
      <CajaTooltip title="Monto de dinero que te queda por pagar de la deuda">
        <Grid item xs={12} py={1}>
          <MoneyInput
            label={`Saldo vigente en ${debtDraft.currency}`}
            value={debtDraft.amount}
            setValue={(value: number) => setDebtDraft({ ...debtDraft, amount: value })}
            disabled={!adminMode && !EnableAmountSources.includes(debtSource || '')}
            currency={debtDraft.currency}
          />
        </Grid>
      </CajaTooltip>
      {debtFamilyType !== 'revolving' && (
        <>
          <CajaTooltip
            title={
              debtFamilyType === 'revolving'
                ? 'Monto mínimo que debes pagar mensualmente'
                : 'Monto de dinero que pagas mensualmente'
            }
          >
            <Grid item xs={12} py={1}>
              <MoneyInput
                label={debtFamilyType === 'revolving' ? 'Mínimo a pagar mensualmente' : 'Monto mensual'}
                value={debtDraft.monthlyPayment}
                setValue={(value: number) => setDebtDraft({ ...debtDraft, monthlyPayment: value })}
                currency={debtDraft.currency}
              />
            </Grid>
          </CajaTooltip>
          <CajaTooltip title="N° de cuotas cuando iniciaste el crédito">
            <Grid item xs={12} lg={6} py={1}>
              <Input
                id="term"
                name="term"
                type="number"
                label="N° de cuotas iniciales"
                value={debtDraft.term.toString()}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setDebtDraft({ ...debtDraft, term: Math.trunc(Number(e.target.value)) })
                }
                min={0}
              />
            </Grid>
          </CajaTooltip>
          <CajaTooltip title="N° de cuotas que te quedan por pagar del crédito">
            <Grid item xs={12} lg={6} py={1}>
              <Input
                id="pendingPayments"
                name="pendingPayments"
                type="number"
                label="N° de cuotas por pagar"
                value={debtDraft.pendingPayments.toString()}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setDebtDraft({ ...debtDraft, pendingPayments: Math.trunc(Number(e.target.value)) })
                }
                min={0}
              />
            </Grid>
          </CajaTooltip>
        </>
      )}
    </Grid>
  );
};

NewDebtStepFour.defaultProps = {
  debtSource: 'Manual',
  adminMode: false,
};

export default NewDebtStepFour;
