import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { CompanyMetrics } from 'services/types/companyMetrics';

interface MetricsSummaryProps {
  metricsData: CompanyMetrics[];
}

const MetricsSummary: FC<MetricsSummaryProps> = ({ metricsData }) => {
  const lastMetric =
    metricsData.length > 0
      ? metricsData[metricsData.length - 1]
      : {
          accRegisteredUsers: 0,
          accVisitors: 0,
          accRefinanceRequests: 0,
          accNormalizeRequests: 0,
        };
  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        borderRadius: 2,
        p: 3,
      }}
    >
      <Typography variant="h5">Resumen del periodo</Typography>
      <Typography variant="body1">
        <strong>Usuarios registrados: </strong>
        {lastMetric.accRegisteredUsers}
      </Typography>
      <Typography variant="body1">
        <strong>Visitas: </strong>
        {lastMetric.accVisitors}
      </Typography>
      <Typography variant="body1">
        <strong>Solicitudes de refinanciamiento: </strong>
        {lastMetric.accRefinanceRequests}
      </Typography>
      <Typography variant="body1">
        <strong>Solicitudes de normalización: </strong>
        {lastMetric.accNormalizeRequests}
      </Typography>
    </Box>
  );
};

export default MetricsSummary;
