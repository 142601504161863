/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, useEffect } from 'react';

import { Checkbox, FormGroup, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';

import PrimaryBtn from 'components/UI/Buttons';
import CajaTooltip from 'components/UI/CajaTooltip';
import MoneyInput from 'components/UI/MoneyInput';
import useDebtFlow from 'hooks/useDebtFlow';
import { useUpdateUserDebtProfileMutation } from 'services/api/user.api';

const GOALS = [
  { value: 'improve_debts', label: 'Mejorar mis deudas' },
  { value: 'save_money', label: 'Ahorrar dinero' },
  { value: 'financial_advice', label: 'Asesoría financiera' },
  { value: 'understand_my_finances', label: 'Entender mis finanzas' },
];

const CREDIT_CARD_PROFILES = [
  { value: 'pays_minimum' as const, label: 'Pago el mínimo' },
  { value: 'pays_more_than_minimum' as const, label: 'Pago más del mínimo' },
  { value: 'pays_full' as const, label: 'Pago el total' },
  { value: 'no_credit_card' as const, label: 'No tengo tarjetas de crédito' },
];
interface Props {
  onSave: () => void;
}

const UserDebtProfiling: FC<Props> = ({ onSave }) => {
  const debtFlow = useDebtFlow();
  const { userDebtProfile: debtProfile } = debtFlow;
  const [creditCardProfile, setCreditCardProfile] = React.useState(debtProfile?.creditCardProfile);
  const [residencyStatus, setResidencyStatus] = React.useState(debtProfile?.residencyStatus || '');
  const [relifGoals, setRelifGoals] = React.useState(debtProfile?.relifGoals || ['improve_debts']);
  const [averageIncome, setAverageIncome] = React.useState(debtProfile?.averageIncome || 0);
  const [totalDebtPayments, setTotalDebtPayments] = React.useState(debtProfile?.totalDebtPayments || 0);

  const shouldAskForResidencyStatus = !debtProfile || debtProfile.nationality?.toLowerCase() !== 'chilena';

  const [updateDebtProfile, { data: updatedDebtProfile }] = useUpdateUserDebtProfileMutation();

  const validateDebtProfile = () => {
    if (averageIncome === 0) {
      return false;
    }
    if (shouldAskForResidencyStatus) {
      if (residencyStatus === '') {
        return false;
      }
    }
    return true;
  };

  const getErrorMessage = () => {
    if (!creditCardProfile) {
      return 'Debes ingresar tu perfil de tarjetas de crédito';
    }
    if (averageIncome === 0) {
      return 'Debes ingresar tu ingreso promedio mensual';
    }
    if (shouldAskForResidencyStatus) {
      if (residencyStatus === '') {
        return 'Debes ingresar tu situación de residencia';
      }
    }
    return '';
  };

  const handleSave = () => {
    if (validateDebtProfile()) {
      const newDebtProfile = {
        creditCardProfile: creditCardProfile || 'no_credit_card',
        residencyStatus,
        averageIncome,
        relifGoals,
        totalDebtPayments,
      };
      updateDebtProfile(newDebtProfile).catch((error) => {
        console.log(error); // eslint-disable-line no-console
      });
    }
  };

  useEffect(() => {
    if (debtProfile) {
      setCreditCardProfile(debtProfile.creditCardProfile);
      setResidencyStatus(debtProfile.residencyStatus || '');
      setAverageIncome(debtProfile.averageIncome);
    }
  }, [debtProfile]);

  useEffect(() => {
    if (updatedDebtProfile) {
      onSave();
    }
  }, [updatedDebtProfile]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '600px',
      }}
    >
      <Typography variant="h3" fontSize="25px" pb="12px" textAlign="center">
        Perfil financiero
      </Typography>
      <Box mb={{ xs: '20px' }}>
        <Box py={{ xs: '20px' }}>
          <MoneyInput
            label="¿Cuál es tu ingreso promedio mensual?"
            value={averageIncome}
            setValue={(value: number) => setAverageIncome(value)}
          />
        </Box>
        <Box py={{ xs: '20px' }}>
          <MoneyInput
            label="¿Cuánto pagas aproximadamente al mes por tus deudas?"
            value={totalDebtPayments}
            setValue={(value: number) => setTotalDebtPayments(value)}
          />
        </Box>
        <Box py={{ xs: '20px' }}>
          <FormGroup>
            <Typography pb="10px" fontWeight={500} fontSize="16px" lineHeight="20px" textAlign="left">
              ¿Cuál es tu objetivo en Relif?
            </Typography>
            <Grid container>
              {GOALS.map((goal) => (
                <Grid item xs={12} sm={12} key={goal.value}>
                  <Box display="flex" alignItems="center" pb={1}>
                    <Checkbox
                      checked={relifGoals.includes(goal.value)}
                      onChange={() => {
                        if (relifGoals.includes(goal.value)) {
                          setRelifGoals(relifGoals.filter((g) => g !== goal.value));
                        } else {
                          setRelifGoals([...relifGoals, goal.value]);
                        }
                      }}
                      name={goal.value}
                      color="primary"
                      id={goal.value}
                    />
                    <Typography variant="body1" fontSize="16px" textAlign="left">
                      {goal.label}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </FormGroup>
        </Box>
        <Box py={{ xs: '20px' }}>
          <Typography pb="10px" fontWeight={500} fontSize="16px" lineHeight="20px" textAlign="left">
            ¿Cuanto pagas de tus tarjetas de crédito al mes?
          </Typography>
          <Grid container>
            {CREDIT_CARD_PROFILES.map((profile) => (
              <Grid item xs={12} sm={12} key={profile.value}>
                <Box display="flex" alignItems="center" pb={1}>
                  <Checkbox
                    checked={creditCardProfile === profile.value}
                    onChange={() => setCreditCardProfile(profile.value)}
                    name={profile.value}
                    color="primary"
                    id={profile.value}
                  />
                  <Typography variant="body1" fontSize="16px" textAlign="left">
                    {profile.label}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {shouldAskForResidencyStatus && (
          <Box py={{ xs: '20px' }}>
            <Typography pb="10px" fontWeight={500} fontSize="16px" lineHeight="20px" textAlign="left">
              ¿Cuál es tu situación de residencia?
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
              }}
            >
              <ButtonGroup variant="outlined" aria-label="outlined primary button group">
                <Button
                  onClick={() => {
                    setResidencyStatus('definitiva');
                  }}
                  variant={residencyStatus === 'definitiva' ? 'contained' : 'outlined'}
                >
                  Definitiva
                </Button>
                <Button
                  onClick={() => {
                    setResidencyStatus('temporal');
                  }}
                  variant={residencyStatus === 'temporal' ? 'contained' : 'outlined'}
                >
                  Temporal
                </Button>
              </ButtonGroup>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            pt: { xs: '20px', sm: '40px' },
          }}
        >
          <CajaTooltip title={getErrorMessage()} placement="top">
            <span>
              <PrimaryBtn onClick={handleSave} disabled={!validateDebtProfile()}>
                Guardar
              </PrimaryBtn>
            </span>
          </CajaTooltip>
        </Box>
      </Box>
    </Box>
  );
};

export default UserDebtProfiling;
