interface DebtType {
  name: string;
  familyType: 'crédito en cuotas' | 'revolving' | 'uf' | 'installments';
}

const debtTypeList: DebtType[] = [
  { name: 'crédito de consumo', familyType: 'crédito en cuotas' },
  { name: 'crédito repactado', familyType: 'crédito en cuotas' },
  { name: 'crédito automotriz', familyType: 'crédito en cuotas' },
  { name: 'tarjeta de crédito', familyType: 'revolving' },
  { name: 'linea de crédito', familyType: 'revolving' },
  { name: 'crédito hipotecario', familyType: 'uf' },
  { name: 'crédito de educacion', familyType: 'uf' },
];

export default debtTypeList;
