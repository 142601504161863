import React, { FC, useState } from 'react';

import { Modal, Paper, TextField, Typography } from '@mui/material';

import PrimaryBtn from 'components/UI/Buttons';
import { useGetRoiCalculatorMutation } from 'services/api/roiCalculators.api';

import RoiCalculatorComponent from './RoiCalculatorComponent';

const RoiCalculator: FC = () => {
  const [getRoiCalculator, { data, isLoading, error }] = useGetRoiCalculatorMutation();
  const [email, setEmail] = useState('');

  const handleSubmit = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getRoiCalculator(email);
  };
  return (
    <>
      <Modal open={data == null || error !== undefined} sx={{ ml: { xs: 0, lg: 10 }, mt: { xs: 0, lg: 10 } }}>
        <Paper
          sx={{
            px: 5,
            py: 4,
            borderRadius: '20px',
            display: 'flex',
            flexDirection: 'column',
            width: { xs: '100%', lg: '500px' },
            backgroundImage: 'url("/contact-background.svg")',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
          }}
        >
          <Typography variant="h2" mb={2} color="white">
            Ingresa el correo electrónico de acceso
          </Typography>
          <TextField
            label="Email"
            variant="filled"
            placeholder='Ej: "email@gmail.com"'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ mb: 2, bgcolor: '#fff', borderRadius: '10px' }}
          />
          <PrimaryBtn onClick={handleSubmit} sx={{ mt: 4, width: '150px' }} disabled={isLoading}>
            Continuar
          </PrimaryBtn>
        </Paper>
      </Modal>
      {data != null && error === undefined && <RoiCalculatorComponent data={data} />}
    </>
  );
};

export default RoiCalculator;
