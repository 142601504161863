import React, { FC } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import PaperContainer from 'components/UI/PaperContainer';
import { BlogPartialPage } from 'services/types/notion';

import ArticleHorizontalCard from './ArticleCard';

interface ArticleRecommendationsInterface {
  blogPages: BlogPartialPage[] | undefined;
  isFetching: boolean;
}

const ArticleRecommendations: FC<ArticleRecommendationsInterface> = ({ blogPages, isFetching }) => {
  return (
    <PaperContainer
      sx={{
        width: { lg: '38%', xl: '35%' },
        position: 'sticky',
        display: { xs: 'none', lg: 'block' },
      }}
      fullHeight
      title="Artículos recomendados"
    >
      <Stack spacing={1} divider={<Divider orientation="horizontal" flexItem />} sx={{ width: '100%', mt: 2 }}>
        {isFetching ? (
          <Grid container sx={{ width: '100%' }}>
            <Grid item xs={5} xl={4}>
              <Skeleton variant="rectangular" width="100%" height={175} />
            </Grid>
            <Grid item xs={7} xl={8} sx={{ pl: 4 }}>
              <Skeleton variant="rectangular" width="100%" height={175} />
            </Grid>
          </Grid>
        ) : (
          blogPages?.map((data) => <ArticleHorizontalCard blogPage={data} key={data.title} />)
        )}
      </Stack>
    </PaperContainer>
  );
};

export default ArticleRecommendations;
