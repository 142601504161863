import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';

import Companies from './components/Companies';
import ContactUs from './components/ContactUs';
import Features from './components/Features';
import FinancialHealth from './components/FinancialHealth';
import Footer from './components/Footer';
import Header from './components/Header';
import How from './components/How';
import Navigation from './components/Navigation';
import RefiRobot from './components/RefiRobot';
import WhoWeAre from './components/WhoWeAre';

const App = () => {
  const [openSection, setOpenSection] = useState<null | string>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [openSection]);

  return (
    <Box sx={{ backgroundColor: '#FBFCFE', zoom: '100%' }}>
      <Navigation openSection={openSection} setOpenSection={setOpenSection} />
      {openSection === null && (
        <>
          <Header />
          <RefiRobot />
          <Features />
          <How />
          <Companies />
          <FinancialHealth />
        </>
      )}
      {openSection === 'WhoWeAre' && <WhoWeAre />}
      {openSection === 'ContactUs' && <ContactUs />}
      <Footer />
    </Box>
  );
};

export default App;
