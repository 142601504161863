import type {
  BankOfferMetrics,
  UserWithFinancialStatus,
  Last12MonthOnlineCompanyMetrics as MetricsByMonth,
  MetricName,
  OnlineCompanyMetrics,
} from '@relif/backend';
import priceFormat from 'services/format/priceFormat';

export const metricNameParser = (metricName: MetricName) => {
  switch (metricName) {
    case 'registeredUsers':
      return 'Usuarios registrados';
    case 'validatedUsers':
      return 'Usuarios que aceptaron T&C';
    case 'usersWithCMFRequest':
      return 'Usuarios con clave única exitosa';
    case 'usersWithBlockedCMFRequest':
      return 'Usuarios con clave única bloqueada';
    case 'usersWithUnpaidDebts':
      return 'Usuarios con deudas morosas';
    case 'usersWithOnDueDebts':
      return 'Usuarios con deudas al día';
    case 'refinanceOffersMetrics':
      return 'Usuarios con ofertas de refinanciamiento';
    case 'normalizeOffersMetrics':
      return 'Usuarios con ofertas de normalización';
    case 'usersWithFinancialStatus':
      return 'Usuarios con estado financiero';
    case 'usersWithHubspotContact':
      return 'Usuarios con contacto en Hubspot';
    case 'usersWithWhatsappChats':
      return 'Usuarios con chats de Whatsapp';
    default:
      return metricName;
  }
};

export const offerNameParser = (offerName: keyof BankOfferMetrics, refinanceType: string) => {
  switch (offerName) {
    case 'bankOffers':
      return `Ofertas de ${refinanceType}`;
    case 'bankOfferUsers':
      return `Usuarios con ofertas de ${refinanceType}`;
    case 'totalAmount':
      return `Monto de ofertas de ${refinanceType}`;
    default:
      return '';
  }
};

export const financialStatusNameParser = (financialStatusName: string) => {
  switch (financialStatusName) {
    case 'averageIncome':
      return 'Ingreso promedio';
    case 'averageDebtsAmount':
      return 'Monto de deudas promedio';
    case 'averageMonthlyPayments':
      return 'Total de cuotas promedio';
    case 'averageVR':
      return 'VR promedio';
    case 'averageRCR':
      return 'Carga financiera promedio';
    default:
      return '';
  }
};

export const getFinancialStatusMetrics = (users: UserWithFinancialStatus[]) => {
  if (users.length === 0) {
    return {
      averageIncome: 0,
      averageDebtsAmount: 0,
      averageMonthlyPayments: 0,
      averageVR: 0,
      averageRCR: 0,
    };
  }
  const averageIncome = users.reduce((acc, user) => acc + user.income, 0) / users.length;
  const usersWithDebtsAmount = users.filter((user) => user.totalDebtsAmount > 0);
  const averageDebtsAmount =
    usersWithDebtsAmount.reduce((acc, user) => acc + user.totalDebtsAmount, 0) / usersWithDebtsAmount.length;

  const usersWithMonthlyPayments = users.filter((user) => user.totalDebtsMonthlyPayment > 0);
  const averageMonthlyPayments =
    usersWithMonthlyPayments.reduce((acc, user) => acc + user.totalDebtsMonthlyPayment, 0) /
    usersWithMonthlyPayments.length;
  const averageVR = usersWithDebtsAmount.reduce((acc, user) => acc + user.VR, 0) / usersWithDebtsAmount.length;
  const averageRCR =
    usersWithMonthlyPayments.reduce((acc, user) => acc + user.RCR, 0) / usersWithMonthlyPayments.length;
  return {
    averageIncome: priceFormat.format(averageIncome),
    averageDebtsAmount: priceFormat.format(averageDebtsAmount),
    averageMonthlyPayments: priceFormat.format(averageMonthlyPayments),
    averageVR: averageVR.toFixed(2),
    averageRCR: `${Math.round(averageRCR * 100)} %`,
  };
};

export const getFinancialStatusMetricsByMonth = (metricsByMonth: MetricsByMonth) => {
  return metricsByMonth.metricsByMonth.map((metric) => {
    const users = metric.userMetrics.usersWithFinancialStatus;
    return getFinancialStatusMetrics(users);
  });
};

export const navigateToUser = (userId: number) => {
  window.open(`/admin/users?userId=${userId}&prevPath=/admin/metrics`, '_blank');
};

export const navigateToBankOffer = (offerUUID: string) => {
  window.open(`/bank-offers?uuid=${offerUUID}&prevPath=/admin/metrics`, '_blank');
};

export const getMetricPercentage = (metric: MetricName, metrics: OnlineCompanyMetrics) => {
  const { userMetrics } = metrics;
  const { bankOfferMetrics } = metrics;
  switch (metric) {
    case 'validatedUsers':
      return userMetrics.registeredUsers.length
        ? `${Math.round((userMetrics.validatedUsers.length / userMetrics.registeredUsers.length) * 100)} %`
        : '0 %';
    case 'usersWithCMFRequest':
      return userMetrics.validatedUsers.length
        ? `${Math.round((userMetrics.usersWithCMFRequest.length / userMetrics.validatedUsers.length) * 100)} %`
        : '0 %';
    case 'usersWithBlockedCMFRequest':
      return userMetrics.validatedUsers.length
        ? `${Math.round((userMetrics.usersWithBlockedCMFRequest.length / userMetrics.validatedUsers.length) * 100)} %`
        : '0 %';
    case 'usersWithOnDueDebts':
      return userMetrics.registeredUsers.length
        ? `${Math.round((userMetrics.usersWithOnDueDebts.length / userMetrics.registeredUsers.length) * 100)} %`
        : '0 %';
    case 'usersWithUnpaidDebts':
      return userMetrics.registeredUsers.length
        ? `${Math.round((userMetrics.usersWithUnpaidDebts.length / userMetrics.registeredUsers.length) * 100)} %`
        : '0 %';
    case 'usersWithFinancialStatus':
      return userMetrics.usersWithCMFRequest.length
        ? `${Math.round(
            (userMetrics.usersWithFinancialStatus.length / userMetrics.usersWithCMFRequest.length) * 100
          )} %`
        : '0 %';
    case 'usersWithHubspotContact':
      return userMetrics.registeredUsers.length
        ? `${Math.round((userMetrics.usersWithHubspotContact.length / userMetrics.registeredUsers.length) * 100)} %`
        : '0 %';
    case 'usersWithWhatsappChats':
      return userMetrics.registeredUsers.length
        ? `${Math.round((userMetrics.usersWithWhatsappChats.length / userMetrics.registeredUsers.length) * 100)} %`
        : '0 %';
    case 'normalizeOffersMetrics':
      return userMetrics.usersWithUnpaidDebts.length
        ? `${Math.round(
            (bankOfferMetrics.normalizeOffersMetrics.bankOfferUsers.length / userMetrics.usersWithUnpaidDebts.length) *
              100
          )} %`
        : '0 %';
    case 'refinanceOffersMetrics':
      return userMetrics.usersWithOnDueDebts.length
        ? `${Math.round(
            (bankOfferMetrics.refinanceOffersMetrics.bankOfferUsers.length / userMetrics.usersWithOnDueDebts.length) *
              100
          )} %`
        : '0 %';

    default:
      return null;
  }
};
