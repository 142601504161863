import { createSlice, current } from '@reduxjs/toolkit';
import { DebtProfile as UserDebtProfile } from '@relif/backend';
import type { FloidCMFResponse } from '@relif/floid-helpers';
import debtApi from 'services/api/debt.api';
import floidAPI from 'services/api/floid.api';
import scraperRequestsAPI from 'services/api/scraperRequests.api';
import userApi from 'services/api/user.api';
import { Debt } from 'services/types/debt';
import { ScraperDebts } from 'services/types/scraperRequest';

export interface DebtFlowState {
  debts: Debt[] | null;
  scraperDebts: ScraperDebts | null;
  hasCMFResponseInLast30Days: boolean;
  CMFResponse: FloidCMFResponse | null;
  userDebtProfile: UserDebtProfile | null;
  isDebtAssignmentComplete: boolean;
  shouldUpdateDebts: boolean;
}
const getIsDebtAssignmentComplete = (CMFRequest: FloidCMFResponse | null, debts: Debt[] | null) => {
  if (!CMFRequest || !debts) return false;
  const CMFDebt = debts.find((debt) => debt.source?.includes('CMF'));

  return (CMFRequest.data.directDebt.length > 0 && CMFDebt !== undefined) || CMFRequest.data.directDebt.length === 0;
};

const ONE_MONTH = 30 * 24 * 60 * 60 * 1000;
const getShouldUpdateDebts = (debts: Debt[] | null, CMFRequest: FloidCMFResponse | null) => {
  if (!CMFRequest || !debts) return false;
  const hasOldCMFRequest = new Date(CMFRequest.data.updated) < new Date(Date.now() - ONE_MONTH);
  return hasOldCMFRequest;
};

const initialState: DebtFlowState = {
  debts: null,
  scraperDebts: null,
  hasCMFResponseInLast30Days: false,
  CMFResponse: null,
  userDebtProfile: null,
  isDebtAssignmentComplete: true,
  shouldUpdateDebts: false,
};

const debtFlowSlice = createSlice({
  name: 'debtFlow',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(debtApi.endpoints.listDebts.matchFulfilled, (state, action) => {
      const isDebtAssignmentComplete = getIsDebtAssignmentComplete(current(state).CMFResponse, action.payload);
      const shouldUpdateDebts = getShouldUpdateDebts(action.payload, current(state).CMFResponse);
      return {
        ...state,
        isDebtAssignmentComplete,
        debts: action.payload,
        shouldUpdateDebts,
      };
    });
    builder.addMatcher(userApi.endpoints.getUserDebtProfile.matchFulfilled, (state, action) => {
      return {
        ...state,
        userDebtProfile: action.payload,
      };
    });
    builder.addMatcher(floidAPI.endpoints.getLastCMFRequest.matchFulfilled, (state, action) => {
      const isDebtAssignmentComplete = getIsDebtAssignmentComplete(action.payload, current(state).debts);
      const shouldUpdateDebts = getShouldUpdateDebts(current(state).debts, action.payload);
      return {
        ...state,
        isDebtAssignmentComplete,
        CMFResponse: action.payload,
        shouldUpdateDebts,
      };
    });
    builder.addMatcher(userApi.endpoints.editInformationWithClaveUnica.matchFulfilled, (state, action) => {
      return {
        ...state,
        CMFResponse: action.payload,
      };
    });
    builder.addMatcher(userApi.endpoints.updateCMFWithClaveUnica.matchFulfilled, (state, action) => {
      return {
        ...state,
        CMFResponse: action.payload,
      };
    });
    builder.addMatcher(floidAPI.endpoints.getCMFRequestLast30Days.matchFulfilled, (state, action) => {
      return {
        ...state,
        hasCMFResponseInLast30Days: action.payload,
      };
    });
    builder.addMatcher(scraperRequestsAPI.endpoints.getParsedScraperDebts.matchFulfilled, (state, action) => {
      return {
        ...state,
        scraperDebts: action.payload,
      };
    });
  },
});
export default debtFlowSlice.reducer;
