import jwtDecode from 'jwt-decode';
import { posthogReset } from 'utils/posthogUtils';

import { RootState } from './redux/store';

const deleteToken = () => {
  const persist = localStorage.getItem('persist:root');
  if (persist) {
    const parsedPersist = JSON.parse(persist) as RootState;
    localStorage.setItem('persist:root', JSON.stringify({ ...parsedPersist, accessToken: null }));
  }
  posthogReset();
};

// eslint-disable-next-line import/prefer-default-export
export const checkIfTokenIsValid = (token: RootState['accessToken'] | null) => {
  try {
    if (token && token.accessToken) {
      const decodedJwt: { exp: number } = jwtDecode(token.accessToken);
      if (decodedJwt.exp >= Date.now() / 1000) {
        return true;
      }
      deleteToken();
    }
    return false;
  } catch (error) {
    return false;
  }
};
