import React, { FC, useEffect } from 'react';

import { Box, Typography } from '@mui/material';
import { DataGrid, GridToolbar, GridCellParams } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';

import SearchBar from 'components/UI/SearchBar';
import { useAdminListDebtsQuery } from 'services/api/debt.api';
import priceFormat from 'services/format/priceFormat';
import { AdminDebt, Debt, DebtStatus } from 'services/types/debt';
import removeCSVInjection from 'utils/removeCSVInjection';

interface Props {
  userId: number | null;
  setSelectedDebt: (debt: Debt | null) => void;
  selectedDebt: Debt | null;
  refetchFlag: boolean;
}

const DebtsTable: FC<Props> = ({ userId, setSelectedDebt, refetchFlag, selectedDebt }) => {
  const { data: debts, refetch } = useAdminListDebtsQuery(null);

  const [filteredDebts, setFilteredDebts] = React.useState<AdminDebt[]>(debts || []);

  const DEBT_ATTRIBUTES = [
    {
      field: 'id',
      headerName: 'ID',
      width: 60,
    },
    {
      field: 'userId',
      headerName: 'ID Usuario',
      width: 60,
    },
    {
      field: 'userFullName',
      headerName: 'Nombre Usuario',
      width: 230,
    },
    {
      field: 'userRut',
      headerName: 'Rut Usuario',
      width: 150,
    },
    {
      field: 'institutionName',
      headerName: 'Nombre Institución',
      width: 150,
    },
    {
      field: 'institutionType',
      headerName: 'Tipo Institución',
      width: 220,
    },
    {
      field: 'debtType',
      headerName: 'Tipo de deuda',
      width: 200,
    },
    {
      field: 'source',
      headerName: 'Fuente',
      width: 230,
    },
    {
      field: 'amount',
      headerName: 'Monto',
      width: 150,
      renderCell: (params: GridCellParams) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { row } = params;
        const debt = row as Debt;
        if (debt.currency === 'UF') {
          return `${debt.amount || '-'} UF`;
        }
        return `${priceFormat.format(Number(debt.amount)) || '-'}`;
      },
    },
    {
      field: 'monthlyPayment',
      headerName: 'Pago mensual',
      width: 150,
      valueFormatter: (value: number) => Math.ceil(value),
      renderCell: (params: GridCellParams) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { row } = params;
        const debt = row as Debt;
        if (debt.currency === 'UF') {
          return `${debt.monthlyPayment || '-'} UF`;
        }
        return `${priceFormat.format(Number(debt.monthlyPayment)) || '-'}`;
      },
    },
    {
      field: 'term',
      headerName: 'Plazo (meses)',
      width: 100,
    },
    {
      field: 'pendingPayments',
      headerName: 'Pagos pendientes (meses)',
      width: 100,
    },
    {
      field: 'monthsBehind',
      headerName: 'Atraso (meses)',
      width: 100,
    },
    {
      field: 'status',
      headerName: 'Estado',
      width: 300,
      valueFormatter: (value: DebtStatus) => {
        switch (value) {
          case 'incomplete':
            return 'datos faltantes';
          case 'waitingToTransfer':
            return 'esperando portabilidad';
          case 'hasNewOptions':
            return 'con opciones de refinanciamiento';
          case 'waitingForOptions':
            return 'esperando opciones de refinanciamiento';
          case 'onDebt':
            return 'en mora';
          case 'onDue':
            return 'al día';
          default:
            return 'desconocido';
        }
      },
    },
    {
      field: 'createdAt',
      headerName: 'Fecha de creación',
      width: 200,
      valueFormatter: (value: string) => new Date(value).toLocaleString('es-CL'),
    },
    {
      field: 'updatedAt',
      headerName: 'Fecha de actualización',
      width: 200,
      valueFormatter: (value: string) => new Date(value).toLocaleString('es-CL'),
    },
  ];
  // remove possible CSV injection
  const debtRows = filteredDebts?.map((debt) => {
    return {
      ...debt,
      userFullName: removeCSVInjection(`${debt.user.firstName} ${debt.user.lastName}`),
      userRut: removeCSVInjection(debt.user.rut || ''),
      institutionName: removeCSVInjection(debt.institutionName),
      institutionType: removeCSVInjection(debt.institutionType || ''),
      debtType: removeCSVInjection(debt.debtType || ''),
      source: removeCSVInjection(debt.source || ''),
    };
  });

  useEffect(() => {
    refetch().catch((e) => console.error(e)); // eslint-disable-line no-console
  }, [refetchFlag]);

  useEffect(() => {
    if (selectedDebt && debts) {
      const debt = debts.find((d) => d.id === selectedDebt.id);
      if (debt) {
        setSelectedDebt(debt);
      }
    }
    setFilteredDebts(debts || []);
  }, [debts]);

  return (
    <Box style={{ width: '100%' }} p={1}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Typography variant="h3">Lista de deudas</Typography>
        <SearchBar data={debts || []} setFilteredData={setFilteredDebts as (data: unknown[]) => void} />
      </Box>
      <Box
        sx={{
          backgroundColor: 'White',
          borderRadius: 4,
          p: 2,
        }}
      >
        <DataGrid
          rows={debtRows || []}
          columns={DEBT_ATTRIBUTES}
          slots={{
            toolbar: GridToolbar,
          }}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          sx={{
            color: 'Black',
            height: 'calc(100vh - 300px)',
            border: 'none',
          }}
          filterModel={
            userId
              ? {
                  items: [{ field: 'userId', operator: 'equals', value: `${userId}` }],
                }
              : undefined
          }
          onRowClick={(params) => {
            setSelectedDebt(params.row as Debt);
          }}
        />
      </Box>
    </Box>
  );
};

export default DebtsTable;
