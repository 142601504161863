import React, { useEffect } from 'react';
import { NumericFormat } from 'react-number-format';

import RemoveRedEye from '@mui/icons-material/RemoveRedEye';
import { TableRow, TableCell, TextField, Typography, Box } from '@mui/material';

import type { OfferDraft } from '@relif/backend';
import { DeleteIcon } from 'assets/icons';
import { DeleteBtn, EditBtn } from 'components/UI/Buttons';
import { format } from 'rut.js';

interface BankOfferRowProps {
  offer: OfferDraft;
  handleOfferChange: (offer: OfferDraft) => void;
  getOfferErrors: (offer: OfferDraft) => string[];
  getOfferWarnings: (offer: OfferDraft) => string[];
  removeOffer: (offer: OfferDraft) => void;
}

const BankOfferRow = ({
  offer,
  handleOfferChange,
  getOfferErrors,
  getOfferWarnings,
  removeOffer,
}: BankOfferRowProps) => {
  const [newTerms, setNewTerms] = React.useState<string>(JSON.stringify(offer.terms));
  const handleTermsChange = (terms: string) => {
    setNewTerms(terms);
    try {
      const parsedTerms = JSON.parse(terms) as OfferDraft['terms'];
      handleOfferChange({ ...offer, terms: parsedTerms });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  useEffect(() => {
    setNewTerms(JSON.stringify(offer.terms));
  }, [offer.terms]);

  const handleNavigateToOffer = () => {
    window.open(`/bank-offers?bankOffer=${JSON.stringify(offer)}`, '_blank');
  };
  return (
    <TableRow>
      <TableCell>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <DeleteBtn onClick={() => removeOffer(offer)} sx={{ marginRight: '10px' }}>
            <DeleteIcon />
          </DeleteBtn>
          <EditBtn onClick={handleNavigateToOffer}>
            <RemoveRedEye />
          </EditBtn>
        </Box>
      </TableCell>
      <TableCell>{getOfferErrors(offer).join(', ')}</TableCell>
      <TableCell>{getOfferWarnings(offer).join(', ')}</TableCell>
      <TableCell>
        <NumericFormat
          customInput={TextField}
          id="userId"
          name="userId"
          InputProps={{
            inputProps: { min: 0, step: 1 },
          }}
          fullWidth
          value={offer.userId || 0}
          onValueChange={(values) => {
            const { value } = values;
            if (!value) {
              handleOfferChange({ ...offer, userId: null });
              return;
            }
            handleOfferChange({ ...offer, userId: Number(value) });
          }}
          decimalScale={0}
        />
      </TableCell>
      <TableCell>
        <TextField
          id="rut"
          name="rut"
          fullWidth
          value={offer.rut}
          onChange={(event) => {
            handleOfferChange({ ...offer, rut: format(event.target.value) });
          }}
        />
      </TableCell>
      <TableCell>
        <input
          type="checkbox"
          checked={offer.isBankClient}
          onChange={() => handleOfferChange({ ...offer, isBankClient: !offer.isBankClient })}
        />
      </TableCell>
      <TableCell>
        <NumericFormat
          customInput={TextField}
          id="originalAmount"
          name="originalAmount"
          InputProps={{
            inputProps: { min: 0, step: 1 },
            startAdornment: <Typography>$</Typography>,
          }}
          fullWidth
          value={offer.originalAmount}
          onValueChange={(values) => {
            const { value } = values;
            handleOfferChange({ ...offer, originalAmount: Number(value) });
          }}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={0}
        />
      </TableCell>
      <TableCell>
        <NumericFormat
          customInput={TextField}
          id="paymentPlanAmount"
          name="paymentPlanAmount"
          InputProps={{
            inputProps: { min: 0, step: 1 },
            startAdornment: <Typography>$</Typography>,
          }}
          fullWidth
          value={offer.paymentPlanAmount}
          onValueChange={(values) => {
            const { value } = values;
            handleOfferChange({ ...offer, paymentPlanAmount: Number(value) });
          }}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={0}
        />
      </TableCell>
      <TableCell>
        <NumericFormat
          customInput={TextField}
          id="cashPaymentAmount"
          name="cashPaymentAmount"
          InputProps={{
            inputProps: { min: 0, step: 1 },
            startAdornment: <Typography>$</Typography>,
          }}
          fullWidth
          value={offer.cashPaymentAmount}
          onValueChange={(values) => {
            const { value } = values;
            handleOfferChange({ ...offer, cashPaymentAmount: Number(value) });
          }}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={0}
        />
      </TableCell>
      <TableCell>
        <textarea
          id="terms"
          name="terms"
          value={newTerms}
          onChange={(event) => {
            handleTermsChange(event.target.value);
          }}
          style={{ width: '250px', height: '60px' }}
        />
      </TableCell>
      <TableCell>
        <NumericFormat
          customInput={TextField}
          id="maxTerm"
          name="maxTerm"
          InputProps={{
            inputProps: { min: 0, step: 1 },
            endAdornment: <Typography> months</Typography>,
          }}
          fullWidth
          value={offer.maxTerm}
          onValueChange={(values) => {
            const { value } = values;
            handleOfferChange({ ...offer, maxTerm: Number(value) });
          }}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={0}
        />
      </TableCell>
      <TableCell>
        <NumericFormat
          customInput={TextField}
          id="interestRate"
          name="interestRate"
          InputProps={{
            inputProps: { min: 0, step: 0.01 },
            endAdornment: <Typography>%</Typography>,
          }}
          fullWidth
          value={offer.interestRate * 100}
          onValueChange={(values) => {
            const { value } = values;
            handleOfferChange({ ...offer, interestRate: Number(value) / 100 });
          }}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
        />
      </TableCell>
      <TableCell>
        <textarea
          id="instructions"
          name="instructions"
          style={{ width: '250px', height: '60px' }}
          value={offer.instructions}
          onChange={(event) => {
            handleOfferChange({ ...offer, instructions: event.target.value });
          }}
        />
      </TableCell>
      <TableCell>
        <textarea
          id="description"
          name="description"
          style={{ width: '250px', height: '60px' }}
          value={offer.description || ''}
          onChange={(event) => {
            handleOfferChange({ ...offer, description: event.target.value });
          }}
        />
      </TableCell>
      <TableCell>
        <input
          type="date"
          value={offer.limit ? new Date(offer.limit).toISOString().split('T')[0] : ''}
          onChange={(event) => {
            handleOfferChange({ ...offer, limit: new Date(event.target.value) });
          }}
        />
      </TableCell>
      <TableCell>
        <input
          type="date"
          value={offer.firstPaymentDate ? new Date(offer.firstPaymentDate).toISOString().split('T')[0] : ''}
          onChange={(event) => {
            handleOfferChange({ ...offer, firstPaymentDate: new Date(event.target.value) });
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default BankOfferRow;
