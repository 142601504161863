import React, { FC, useState, useEffect } from 'react';

import DuoRounded from '@mui/icons-material/DuoRounded';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { HostsData } from 'assets/data/meetingHostsData';
import { SecondaryBtn } from 'components/UI/Buttons';
import { useGetUserInformationQuery } from 'services/api/user.api';

const BookMeeting: FC = () => {
  const { data: user } = useGetUserInformationQuery(null);
  const [randomHosts, setRandomHosts] = useState(HostsData);

  useEffect(() => {
    const shuffled = HostsData.map((host) => ({ host, sortValue: Math.random() }))
      .sort((a, b) => a.sortValue - b.sortValue)
      .map((a) => a.host);
    setRandomHosts(shuffled);
  }, []);
  let userData = '?';
  if (user) {
    if (user.email) {
      userData += `email=${user.email}&`;
    }
    if (user.firstName) {
      userData += `firstName=${user.firstName}&`;
    }
    if (user.lastName) {
      userData += `lastName=${user.lastName}`;
    }
  }
  return (
    <Box sx={{ margin: 0, padding: 0 }}>
      <div
        style={{
          backgroundImage: "url('./relif_welcome_page.webp')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: '100vh',
          width: '100vw',
          filter: 'blur(10px)',
          margin: 0,
          padding: 0,
        }}
      />
      <Container
        sx={{
          backgroundColor: 'primary.main',
          borderRadius: '30px',
          padding: { lg: '20px 50px 50px', xs: '20px 10px' },
          position: 'absolute',
          mt: 3,
          top: '10%',
          left: '50%',
          transform: { md: 'translate(-50%, -10%)', xs: 'translate(-50%, -5%)' },
          flexDirection: 'column',
          width: { xl: '55%', md: '70%', xs: '90%' },
        }}
      >
        <Box display="grid">
          <Box display="flex" my={1}>
            <Typography
              variant="h2"
              sx={{
                width: '100%',
                mb: '25px',
                color: 'white',
              }}
            >
              Agenda una reunión online con alguien de Relif
            </Typography>
          </Box>
          <Grid container display="flex" justifyContent="flex-start" alignItems="center" spacing={2}>
            {randomHosts.map((member) => (
              <Grid item xs={12} sm={6} md={4} key={member.meetLink} px={2}>
                <Card
                  sx={{
                    borderRadius: '20px',
                    borderColor: 'white',
                    borderStyle: 'solid',
                    backgroundColor: 'white',
                  }}
                >
                  <a
                    href={userData !== '?' ? member.meetLink + userData : member.meetLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Box>
                      <CardContent>
                        <Box display="flex" justifyContent="center">
                          <img
                            src={member.img}
                            alt={member.name}
                            style={{ width: '110px', height: '110px', borderRadius: '50%' }}
                          />
                        </Box>
                        <Box mt={1} textAlign="center">
                          <Typography variant="h3" sx={{ width: '100%' }} color="primary.main" fontSize={18}>
                            {member.name}
                          </Typography>
                        </Box>
                      </CardContent>
                      <CardActions>
                        <SecondaryBtn
                          fullWidth
                          sx={{
                            height: '40px',
                          }}
                          startIcon={<DuoRounded />}
                        >
                          Agendar reunión
                        </SecondaryBtn>
                      </CardActions>
                    </Box>
                  </a>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default BookMeeting;
