import type { Last12MonthOnlineCompanyMetrics, OnlineCompanyMetrics } from '@relif/backend';
import {
  CompanyInvestmentMetric,
  CompanyMetrics,
  GetMetricsByMonthBody,
  GetMetricsResponse,
} from 'services/types/companyMetrics';

import { GetMetricsBody } from '../types/companyMetrics';
import baseApi from './baseApi';

const companyMetricsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCompanyMetrics: build.mutation({
      query: (body: GetMetricsBody) => ({
        url: '/admin/companyMetrics',
        method: 'Post',
        body,
      }),
      transformResponse: (response: GetMetricsResponse) => response,
    }),
    getCompanyInvestmentMetrics: build.mutation({
      query: (body: GetMetricsBody) => ({
        url: '/admin/companyMetrics/investments',
        method: 'Post',
        body,
      }),
      transformResponse: (response: CompanyInvestmentMetric[]) => response,
    }),
    getCompanyMetricsSummary: build.query({
      query: () => ({
        url: '/admin/companyMetrics/summary',
        method: 'GET',
      }),
      transformResponse: (response: CompanyMetrics) => response,
    }),
    getOnlineCompanyMetrics: build.mutation({
      query: (body: GetMetricsBody) => ({
        url: '/admin/companyMetrics/online',
        method: 'POST',
        body,
      }),
      transformResponse: (response: OnlineCompanyMetrics) => response,
    }),
    getCompanyMetricsByMonth: build.mutation({
      query: (body: GetMetricsByMonthBody) => ({
        url: '/admin/companyMetrics/byMonth',
        method: 'POST',
        body,
      }),
      transformResponse: (response: Last12MonthOnlineCompanyMetrics) => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCompanyMetricsMutation,
  useGetCompanyInvestmentMetricsMutation,
  useGetCompanyMetricsSummaryQuery,
  useGetOnlineCompanyMetricsMutation,
  useGetCompanyMetricsByMonthMutation,
} = companyMetricsApi;
