import React, { FC, useEffect, useState } from 'react';

import { Grid, Stack, Typography } from '@mui/material';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import PrimaryBtn from 'components/UI/Buttons';
import DrawerContent from 'components/UI/DrawerContent';
import RoundedInput from 'components/UI/RoundedInput';
import SideDrawer from 'components/UI/SideDrawer';
import SuccessDialog from 'components/UI/SuccessDialog';
import moment from 'moment';
import { useGetScheduledMessagesMutation, useScheduleMessageMutation } from 'services/api/slack.api';
import { SlackReminder } from 'services/types/slack';

interface Props {
  userId: number;
}

const ReminderDrawer: FC<Props> = ({ userId }) => {
  const now = new Date();
  const futureDate = moment(new Date().setMinutes(new Date().getMinutes() + 15));
  const [date, setDate] = useState<moment.Moment | null>(futureDate);

  const [isOpen, setIsOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState('');

  const [getScheduledMessages, { data: scheduledMessages }] = useGetScheduledMessagesMutation();
  const [scheduleMessage, { data: newScheduleMessage }] = useScheduleMessageMutation();

  const pastReminders = scheduledMessages?.filter((m) => new Date(m.postAt) < now);
  const futureReminders = scheduledMessages?.filter((m) => new Date(m.postAt) >= now);

  const momentNow = moment(new Date());
  const checkFormData = () => message !== '' && date !== null && date > momentNow;

  useEffect(() => {
    if (newScheduleMessage) {
      setOpenSuccess(true);
    }
  }, [newScheduleMessage]);

  const renderReminders = (reminders: SlackReminder[]) => (
    <Grid container spacing={2} pb={2}>
      {reminders.map((m) => (
        <Grid item key={m.id} xs={12}>
          <Stack>
            <Typography variant="body1" fontWeight="bold">
              Admin {m.admin.firstName}:
            </Typography>
            <Typography variant="body1">{m.text}</Typography>
            <Typography variant="body2">{new Date(m.postAt).toLocaleString('es-CL')}</Typography>
          </Stack>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <>
      <PrimaryBtn
        sx={{ ml: 1 }}
        onClick={() => {
          getScheduledMessages(userId).catch((e) => console.error(e)); // eslint-disable-line no-console
          setIsOpen(true);
        }}
      >
        Recordatorios
      </PrimaryBtn>
      <SuccessDialog
        openDialog={openSuccess}
        setOpenDialog={setOpenSuccess}
        header="Recordatorio creado exitosamente!"
        msg=""
      />
      <SideDrawer
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title="Recordatorios"
        activeStep={null}
        setActiveStep={null}
      >
        <DrawerContent title={null}>
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant="h3" fontWeight="bold">
                Crear recordatorio
              </Typography>
            </Grid>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  slotProps={{ textField: { variant: 'outlined' } }}
                  label="Fecha recordatorio"
                  value={date}
                  onChange={(newDate) => setDate(newDate)}
                />
              </LocalizationProvider>
              {date && date < momentNow && (
                <Typography variant="body1" color="error">
                  *Fecha no válida, tiene que ser mayor que la hora actual.
                </Typography>
              )}
            </Grid>
            <Grid item width="100%">
              <RoundedInput
                fullWidth
                id="message"
                variant="outlined"
                label="Mensaje"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Grid>
            <Grid item width="100%">
              <PrimaryBtn
                variant="contained"
                sx={{ my: 2 }}
                fullWidth
                onClick={() => {
                  if (checkFormData() && date) {
                    scheduleMessage({ text: message, postAt: date.toDate(), userId })
                      .then(() => setIsOpen(false))
                      .catch((e) => console.error(e)); // eslint-disable-line no-console
                  }
                }}
                disabled={!checkFormData()}
              >
                Crear
              </PrimaryBtn>
            </Grid>
          </Grid>
          {futureReminders && futureReminders.length > 0 && (
            <>
              <Typography variant="h3" fontWeight="bold">
                Próximos recordatorios:
              </Typography>
              {renderReminders(futureReminders)}
            </>
          )}
          {pastReminders && pastReminders.length > 0 && (
            <>
              <Typography variant="h3" fontWeight="bold">
                Recordatorios anteriores:
              </Typography>
              {renderReminders(pastReminders)}
            </>
          )}
        </DrawerContent>
      </SideDrawer>
    </>
  );
};

export default ReminderDrawer;
