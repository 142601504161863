import React, { FC } from 'react';

import { Box, Paper, Modal, ListItemButton } from '@mui/material';
import Typography from '@mui/material/Typography';

import { useSearchMessagesQuery } from 'services/api/whatsApp.api';
import { WhatsAppUser } from 'services/types/whatsApp';

const MAX_RESULTS = 100;

interface Props {
  searchQuery: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  selectUser: (user: WhatsAppUser) => void;
}

const AdvancedSearch: FC<Props> = ({ searchQuery, open, setOpen, selectUser }) => {
  const { data } = useSearchMessagesQuery(searchQuery);

  if (!open) {
    return null;
  }

  return (
    <Modal open onClose={() => setOpen(false)}>
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '100%',
          bgcolor: 'background.paper',
          borderRadius: '20px',
          boxShadow: 24,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '90vh',
        }}
      >
        <Box p={2} minWidth="250px">
          <Typography variant="h3">
            {Math.min(data?.length ?? 0, MAX_RESULTS)} resultados de búsqueda (máximo {MAX_RESULTS})
          </Typography>
          {data === undefined && <Typography variant="h4">Cargando...</Typography>}
        </Box>
        <Box overflow="scroll">
          {data?.slice(0, MAX_RESULTS).map((user) => (
            <ListItemButton
              key={user.lastMessageId}
              onClick={() => {
                setOpen(false);
                selectUser(user);
              }}
              sx={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', pb: 2 }}
            >
              <Typography fontSize="18px">
                {user.firstName} {user.lastName} ({user.id}) - {user.companyName} - {user.phone} - Mensaje{' '}
                {user.lastMessageId}
              </Typography>
              <Typography variant="h5" fontSize="16px" textOverflow="ellipsis" whiteSpace="nowrap">
                {user.lastMessageText.substring(0, 200)}
              </Typography>
            </ListItemButton>
          ))}
        </Box>
      </Paper>
    </Modal>
  );
};

export default AdvancedSearch;
