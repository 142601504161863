export interface Definition {
  name: string;
  description: string;
  link?: string;
}

const definitionsData = [
  {
    name: 'Administradora general de fondos (AGF)',
    description:
      'Una Administradora General de Fondos (AGF) es una institución financiera que se encarga de administrar y gestionar fondos de inversión, como fondos mutuos y de pensión, en nombre de sus clientes. ',
    link: 'https://www.cmfchile.cl/educa/621/w3-propertyvalue-998.html#:~:text=Son%20sociedades%20an%C3%B3nimas%20especiales%20que,fondo%20fiscalizado%20por%20esta%20Comisi%C3%B3n',
  },
  {
    name: 'Boletín Comercial',
    description:
      'Es un registro donde las instituciones financieras y comerciales informan sobre las deudas que no han sido pagadas por las personas. Es para que otras empresas puedan saber si una persona tiene deudas pendientes y así no prestarle dinero.',
    link: 'https://www.boletincomercial.cl/boletincomercial/nosotros',
  },
  {
    name: 'Caja de Compensación',
    description:
      'Entidades privadas que administran los beneficios y ayudas que el Estado da a las personas. Pagan los subsidios y otorgan préstamos sociales. ',
    link: 'https://www.suseso.cl/606/w3-propertyvalue-34003.html#presentacion',
  },
  {
    name: 'Carga financiera',
    description:
      'La carga financiera es lo que una familia tiene que pagar cada mes por sus deudas, como por ejemplo la hipoteca, los créditos o la tarjeta de crédito. Se calcula dividiendo la cantidad total de deudas por los ingresos mensuales de la familia.',
    link: 'https://portales.bancochile.cl/cuentasconelchile/educacion-financiera/endeudamiento-responsable/detalles/que-es-carga-financiera#:~:text=La carga financiera está compuesta,porcentaje de los ingresos mensuales.',
  },
  {
    name: 'Cooperativas de ahorro y crédito',
    description:
      'Son entidades en las que los socios se unen para crear un fondo de dinero propio, que utilizan para prestarles crédito a sus miembros entre sí.',
    link: 'https://www.cmfchile.cl/portal/principal/613/w3-propertyvalue-28993.html',
  },
  {
    name: 'Crédito',
    description:
      'Es cuando prestas plata o algún bien a alguien y esa persona se compromete a pagarte el valor que le diste más unos intereses adicionales en un tiempo acordado.',
    link: 'https://www.cmfchile.cl/educa/621/w3-propertyvalue-1342.html',
  },
  {
    name: 'Deuda morosa',
    description: 'Es cuando una persona o empresa no ha pagado una deuda a tiempo. ',
    link: 'https://www.cmfchile.cl/educa/621/w3-article-27646.html',
  },
  {
    name: 'Dicom',
    description:
      'Es una base de datos administrada por la compañía Equifax que contiene información de aquellas personas y empresas que tienen problemas con la deuda. Estos datos vienen del antiguo Directorio de Información Comercial.',
  },
  {
    name: 'Estrés financiero',
    description:
      'El estrés financiero es una situación en la que una persona se siente abrumada por la cantidad de deudas y gastos que debe cubrir con sus ingresos, lo que resulta en una sensación de incertidumbre y ansiedad financiera. ',
  },
  {
    name: 'Fondo de emergencias',
    description:
      'Un fondo de emergencia es una cantidad de dinero que guardas aparte para usar en caso de un imprevisto o situación inesperada, como una enfermedad, un despido o cualquier otro evento que requiera un gasto adicional. ',
    link: 'https://www.consumerfinance.gov/es/empiece-poco-a-poco-y-ahorre/guia-para-hacer-un-fondo-de-emergencia/',
  },
  {
    name: 'Gastos de cobranza extrajudicial',
    description:
      'Son cobros por los gastos realizados en las gestiones para cobrarle la deuda impaga (mensajes, contactos telefónicos, cartas o visitas). Estos gastos empiezan a rejir 20 días desde que venció la fecha de pago. Hay topes máximos que te pueden cobrar por estos gastos: 9% de la deuda si es menor a 10 UF, 6% si es entre 10 y 50 UF, 3% si es mayor a 50 UF',
    link: 'https://www.sernac.cl/app/calculadora_financiera/',
  },
  {
    name: 'Inversión',
    description:
      'La inversión es cuando compras algo, ya sea un bien físico o un instrumento financiero, con la esperanza de obtener ganancias en el futuro. La idea es que inviertas hoy para tener más plata en el futuro.',
    link: 'https://www.cmfchile.cl/educa/621/w3-propertyvalue-1343.html',
  },
  {
    name: 'Liquidez',
    description:
      'La liquidez es la capacidad que tiene un activo para ser convertido en dinero en un corto período de tiempo. Por ejemplo, si tienes un auto, es más difícil convertirlo en dinero que si tienes una cuenta corriente.',
    link: 'https://www.cmfchile.cl/educa/621/w3-propertyvalue-1344.html',
  },
  {
    name: 'Mora',
    description:
      'La mora ocurre cuando alguien tiene una deuda y no la paga a tiempo. Es importante tratar de evitar la mora, ya que puede tener consecuencias negativas para tu historial crediticio y puede costarte más dinero en intereses y multas.',
    link: 'https://es.wikipedia.org/wiki/Mora_(Derecho)',
  },
  {
    name: 'Mutuarias',
    description:
      'Entidades financieras que ofrecen préstamos hipotecarios y otras cosas a sus clientes con un poco de interés extra pero con mayor flexibilidad.',
  },
  {
    name: 'Normalizar',
    description:
      'Normalizar un crédito significa llegar a un acuerdo con la entidad prestamista para regularizar una deuda impagada o en mora.',
    link: 'https://dfmas.df.cl/renegociar-repactar-y-refinanciar-las-tres-r-para-sortear-las-deudas',
  },
  {
    name: 'Presupuesto',
    description:
      'El presupuesto es cuando planeas cuánto dinero vas a ganar y cuánto vas a gastar en un período de tiempo determinado, como un año o un mes. ',
    link: 'https://es.wikipedia.org/wiki/Presupuesto',
  },
  {
    name: 'Refinanciar',
    description:
      'Refinanciar un crédito significa solicitar un nuevo préstamo para pagar uno o varios créditos previos. La idea detrás de esto es generalmente obtener una tasa de interés más baja, un plazo de pago más largo o ambas, para reducir las mensualidades y hacer el pago de la deuda más manejable.',
    link: 'https://dfmas.df.cl/renegociar-repactar-y-refinanciar-las-tres-r-para-sortear-las-deudas',
  },
  {
    name: 'Sueldo líquido',
    description:
      'El sueldo líquido corresponde al monto final que se paga al trabajador, luego de realizar los descuentos establecidos en el Código del Trabajo. Es decir, el sueldo líquido es lo que recibe el trabajador tras las cotizaciones obligatorias como salud y AFP. ',
    link: 'https://www.buk.cl/blog/como-hacer-una-liquidacion-de-sueldo-paso-a-paso#:~:text=El sueldo líquido corresponde al,obligatorias como salud y AFP.',
  },
  {
    name: 'Tasa de interés',
    description:
      'La tasa de interés es un porcentaje extra que te cobran encima de lo que pediste prestado cuando tomas un crédito.',
    link: 'https://www.cmfchile.cl/educa/621/w3-article-27169.html',
  },
  {
    name: 'Informe de deudas CMF',
    description: `Es un documento que entrega la Comisión para el Mercado Financiero (CMF) que contiene información sobre las deudas vigentes que tienes con instituciones financieras.
       
    El informe de deudas contiene información que se actualiza semanalmente, con un desfase de entre 11 y 16 días respecto de la fecha en que se realiza la consulta.`,
    link: 'https://www.cmfchile.cl/portal/principal/605/w3-propertyvalue-28993.html',
  },
];

export default definitionsData;
