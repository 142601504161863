import { QuizResultAttributes, SaveAnswerSchema } from '@relif/backend';

import baseApi from './baseApi';

const quizApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getQuizResultByName: build.query({
      query: (name: string) => ({
        url: `/quizResults/${name}`,
        method: 'GET',
      }),
      transformResponse: (response: QuizResultAttributes | null) => response,
    }),
    getOrCreateQuizResult: build.mutation({
      query: (body: { quizName: string }) => ({
        url: '/quizResults',
        method: 'POST',
        body,
      }),
      transformResponse: (response: QuizResultAttributes) => response,
    }),
    saveQuizResultAnswer: build.mutation({
      query: (props: { quizName: string; body: SaveAnswerSchema }) => ({
        url: `/quizResults/${props.quizName}/saveAnswer`,
        method: 'POST',
        body: props.body,
      }),
      transformResponse: (response: string) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useGetQuizResultByNameQuery, useGetOrCreateQuizResultMutation, useSaveQuizResultAnswerMutation } =
  quizApi;
