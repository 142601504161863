import React, { FC } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { TeamMemberTranslation } from 'assets/data/translations/interface';
import { useTranslation } from 'contexts/translationContext';

import useIsMobile from '../../../hooks/useIsMobile';
import '../App.scss';
import { TeamData } from '../data';

// this is a Fisher-Yates shuffle
function shuffle(array: unknown[]) {
  let currentIndex = array.length;
  let randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    // And swap it with the current element.
    // eslint-disable-next-line no-param-reassign
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}

const Team: FC = () => {
  const { isMobile } = useIsMobile();
  const iconsLimit = isMobile ? 2 : 4;
  const { translation } = useTranslation();
  const teamTranslation = translation.landingPage.team;
  shuffle(TeamData.members);
  return (
    <Box id="team" pb={8}>
      <Box display="grid">
        <Typography
          variant="h2"
          sx={{
            fontFamily: 'Roboto',
            fontWeight: 800,
            fontSize: '45px',
            lineHeight: '50px',
            textAlign: 'center',
            py: 3,
          }}
        >
          Nuestro Equipo
        </Typography>
        <Grid container display="flex" justifyContent="center">
          {TeamData.members.map((member, index) => {
            const memberTranslation = teamTranslation[
              member.translatorKey as keyof typeof teamTranslation
            ] as TeamMemberTranslation;
            return (
              <Box key={`${member.name}-${index + 1}`} className="team">
                <Box className="thumbnail front" key={`${member.name}-${index + 1}`}>
                  <img src={member.img} alt="..." className="team-img" />
                  <Box display="flex" flexDirection="column" mt={2} textAlign="center">
                    <Typography variant="h2" sx={{ width: '100%', fontSize: { xs: '20px' } }}>
                      {member.name}
                    </Typography>
                    <Typography variant="h5" sx={{ width: '100%', fontSize: { lg: '17px', xs: '15px' } }} mt="1px">
                      {memberTranslation?.job ?? member.job}
                    </Typography>
                  </Box>
                </Box>
                <Box className="thumbnail back">
                  <Box display="flex" flexDirection="column" textAlign="center">
                    <Typography variant="h2" sx={{ width: '100%', fontSize: { xs: '20px' } }}>
                      {member.name}
                    </Typography>
                    <Typography variant="h5" sx={{ width: '100%', fontSize: { lg: '17px', xs: '15px' } }}>
                      {memberTranslation?.job ?? member.job}
                    </Typography>
                  </Box>
                  {member.jobSummary && (
                    <Box className="job-summary">
                      <Typography variant="h2" sx={{ width: '100%', fontSize: { lg: '20px', xs: '17px' } }}>
                        {member.jobSummary}
                      </Typography>
                    </Box>
                  )}
                  {(memberTranslation?.prevJob || member.prevJob).map((job, i) => (
                    <Box className="jobs" key={`${i + 1}`}>
                      <Typography variant="h2" sx={{ width: '100%', fontSize: { lg: '17px', xs: '15px' } }}>
                        {job.place}
                      </Typography>
                      <Typography sx={{ width: '100%', fontSize: { lg: '20px', xs: '17px' } }}>{job.title}</Typography>
                    </Box>
                  ))}
                  <Box className="job-icon">
                    {member.icons.slice(0, iconsLimit).map((memberCompany, i) => (
                      <img src={memberCompany} alt="" key={`Company ${i + 1}`} />
                    ))}
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default Team;
