import React, { FC } from 'react';

import Box from '@mui/material/Box';

import { RefreshIcon } from 'assets/icons';
import PrimaryBtn from 'components/UI/Buttons';
import SectionHeader from 'components/layout/SectionHeader';
import useIsAdmin from 'hooks/useIsAdmin';
import { useUpdateBlogMutation } from 'services/api/notion.api';

import Glossary from './components/Glossary';
import HighLights from './components/HighLights';

const BlogIndex: FC = () => {
  const isAdmin = useIsAdmin();
  const [updateBlog, { isLoading }] = useUpdateBlogMutation();
  let button = null;
  if (isAdmin) {
    button = (
      <PrimaryBtn
        disabled={isLoading}
        onClick={() => {
          // eslint-disable-next-line no-console
          updateBlog({}).catch((e) => console.warn(e));
        }}
        startIcon={<RefreshIcon />}
      >
        Actualizar
      </PrimaryBtn>
    );
  }
  return (
    <Box sx={{ px: { xs: 2, xl: 0 } }}>
      <SectionHeader text="Educación Financiera" button={button} />
      <HighLights />
      <Glossary />
    </Box>
  );
};

export default BlogIndex;
