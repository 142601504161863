import { createSlice } from '@reduxjs/toolkit';

import { Action } from './base';

export interface AccessToken {
  accessToken: string | null;
  loggedInAsOtherUser?: boolean;
}

const initialState: AccessToken = {
  accessToken: null,
  loggedInAsOtherUser: false,
};

interface AccessAction extends Action {
  payload: AccessToken;
}

const accessTokenSlice = createSlice({
  name: 'accessToken',
  initialState,
  reducers: {
    setAccessToken(state: AccessToken, action: AccessAction) {
      return { ...state, ...action.payload };
    },
  },
});

export const { setAccessToken } = accessTokenSlice.actions;
export default accessTokenSlice.reducer;
