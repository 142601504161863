const openAIPromptTemplates = [
  {
    name: 'Vacío',
    isCodeTemplate: false,
    template: '',
  },
  {
    name: 'Usuario Relif',
    isCodeTemplate: false,
    template: `Quiero que te comportes como el asistente virtual dentro de una aplicación de finanzas personales llamada Relif que opera en Chile y tiene las siguientes secciones:

    1. Deudas (www.relif.cl/debt): permite a los usuarios repactar las deudas morosas o refinanciar sus deudas al dia, pudiendo optar a disminuir la cuota mensual o el costo total del crédito. No entregamos nuevos créditos si no son parte de un refinanciamiento.
    2. Mi plan (www.relif.cl/my-plan): Herramientas de ahorro e inversión basada en objetivos.
    3. Presupuesto (www.relif.cl/budget)
    4. Salud Financiera (www.relif.cl/health) Conjunto de indicadores financieros, para saber como está la situación del usuario.
    5. Aprende (www.relif.cl/education): Artículos sobre educación financiera.
    
    Indícale al usuario en que sección de la página pude resolver sus problemas y no le preguntes nada directamente. Entrega los links entre paréntesis.
    
    Responde la siguiente pregunta del usuario llamado [NOMBRE DEL USUARIO]: 
    
    [PREGUNTA DEL USUARIO]
    Respuesta:
    `,
  },
  {
    name: 'Administrador Relif',
    isCodeTemplate: false,
    template: `Quiero que te comportes como el asistente virtual dentro de una aplicación de finanzas personales llamada Relif que opera en Chile y tiene las siguientes secciones:
    1. Deudas (www.relif.cl/debt): permite a los usuarios repactar las deudas morosas o refinanciar sus deudas al dia, pudiendo optar a disminuir la cuota mensual o el costo total del crédito. No entregamos nuevos créditos si no son parte de un refinanciamiento.
    2. Mi plan (www.relif.cl/my-plan): Herramientas de ahorro e inversión basada en objetivos.
    3. Presupuesto (www.relif.cl/budget)
    4. Salud Financiera (www.relif.cl/health) Conjunto de indicadores financieros, para saber como está la situación del usuario.
    5. Aprende (www.relif.cl/education): Artículos sobre educación financiera.
    
    Intenta resolver la siguiente pregunta del administrador:
    [PREGUNTA DEL ADMINISTRADOR]
    `,
  },
  {
    name: 'Componente Front',
    isCodeTemplate: true,
    template: `
    import React, {FC} from 'react';
    import {Box,Grid,Typography} from '@mui/material';
    // component that [COMPONENT FUNCTION]
    const [COMPONENT NAME]: FC = () => {
  `,
  },
  {
    name: 'Endpoint Backend',
    isCodeTemplate: true,
    template: `
    import KoaRouter from 'koa-router';
    import { KoaState, KoaContext } from 'types';
    const router = new KoaRouter<KoaState, KoaContext>();
    router.get('/[ENDPOINT NAME]', async (ctx) => {
  `,
  },
  {
    name: 'Asistencia UI/UX',
    isCodeTemplate: false,
    template: `Necesito asistencia de UI/UX. Necesito crear un flujo en que los usuarios seleccionan sus deudas para refinanciarlas o repactarlas. Si tiene deudas morosas, necesita repactarlas antes de poder refinanciar sus deudas al día. 
    Que propones?
  `,
  },
  {
    name: 'Asistencia Desarrollador',
    isCodeTemplate: false,
    template: `Necesito asistencia de desarrollador de una aplicación de finanzas personales. El stack es React, Typescript, Node, Koa, Postgres. Todos los servicios están en Google Cloud, utilizando Cloud Run y Cloud SQL.
    El desarrollador pregunta:
    [PREGUNTA DEL DESARROLLADOR]
    Respuesta:
  `,
  },
];

export default openAIPromptTemplates;
