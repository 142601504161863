import React, { FC, useEffect, useState } from 'react';
import { useAutosave } from 'react-autosave';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { BlackBtn } from 'components/UI/Buttons';
import { useUpdateAdminNotesMutation } from 'services/api/admin-users.api';
import { useGetUserInformationQuery } from 'services/api/user.api';
import { User } from 'services/types/user';

const currentDate = new Date();

interface Props {
  userId: number;
  userData: User | null;
  isLoadingUserData: boolean;
}
const AdminNotes: FC<Props> = ({ userId, userData, isLoadingUserData }) => {
  const [adminNotes, setAdminNotes] = useState('');
  const [updateAdminNotes, { reset: resetUpdateAdminNotes, isLoading: isLoadingUpdateAdminNotes }] =
    useUpdateAdminNotesMutation();
  const [lastSavedBy, setLastSavedBy] = useState('');
  const [isOnFocus, setIsOnFocus] = useState(false);
  const { data: currentUser } = useGetUserInformationQuery(null);

  const handleSave = () => {
    updateAdminNotes({ id: userId, adminNotes })
      .then(() => {
        resetUpdateAdminNotes();
        const date = new Date();
        setLastSavedBy(`Guardado por última vez a las ${date.toLocaleTimeString()}`);
      })
      .catch(() => {});
  };

  const saveOnFocus = () => {
    if (!isOnFocus || adminNotes === '' || isLoadingUpdateAdminNotes) return;
    handleSave();
  };

  const addTemplate = () => {
    const ActionPlanTemplate = `
    ______________________________________________________\n
    Notas: \n

    Notas tomadas el ${currentDate.toLocaleDateString()} por ${currentUser?.firstName ?? 'Relif'} ${
      currentUser?.lastName ?? ''
    }
    ______________________________________________________\n 
    `;
    setAdminNotes(adminNotes + ActionPlanTemplate);
  };

  useAutosave({
    data: adminNotes,
    onSave: saveOnFocus,
    interval: 2000,
  });

  useEffect(() => {
    if (!userData) return;
    setAdminNotes(userData.adminNotes || '');
    setLastSavedBy('');
  }, [userData]);

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h3">Notas de administrador</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <BlackBtn onClick={addTemplate} sx={{ width: 150, height: 40, alignSelf: 'flex-end', mr: 1 }}>
            Plantilla
          </BlackBtn>
          <Typography variant="body1" lineHeight={2} mr={1}>
            {lastSavedBy}
          </Typography>
          <BlackBtn
            onClick={handleSave}
            sx={{ width: 150, height: 40, alignSelf: 'flex-end' }}
            disabled={isLoadingUpdateAdminNotes}
          >
            Guardar
          </BlackBtn>
        </Box>
      </Box>
      {isLoadingUserData ? (
        <CircularProgress />
      ) : (
        <TextField
          multiline
          rows={10}
          fullWidth
          value={adminNotes}
          onChange={(e) => setAdminNotes(e.target.value)}
          onFocus={() => setIsOnFocus(true)}
          onBlur={() => setIsOnFocus(false)}
        />
      )}
    </Box>
  );
};

export default AdminNotes;
