/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, useEffect, useState } from 'react';

import { CheckCircle } from '@mui/icons-material';
import { Avatar, Box, Collapse, Stack, Typography } from '@mui/material';

import { findCMFInstitutionsLogo } from 'assets/data/institutionTypeList';
import { ExpandLessIcon, ExpandMoreIcon, InstitutionIcon } from 'assets/icons';
import PrimaryBtn, { BlackBtn } from 'components/UI/Buttons';
import useDebtFlow from 'hooks/useDebtFlow';
import { useCreateDebtMutation } from 'services/api/debt.api';
import priceFormat from 'services/format/priceFormat';
import { DebtToCategorize } from 'services/types/debt';
import parseCMFDirectDebt from 'utils/parseCMFDirectDebt';

import CategorizeInstitutionDebts from './CategorizeInstitutionDebts';
import DebtSummaryTable from './DebtSummaryTable';
import ManualDebtLoop from './ManualDebtLoop';

interface DebtPaperProps {
  i: number;
  debt: DebtToCategorize;
  save: boolean;
  disabled: boolean[];
  setDisabled: React.Dispatch<React.SetStateAction<boolean[]>>;
  openDropdowns: boolean[];
  setOpenDropdowns: React.Dispatch<React.SetStateAction<boolean[]>>;
}

const DebtPaper: FC<DebtPaperProps> = ({ i, debt, save, disabled, setDisabled, openDropdowns, setOpenDropdowns }) => (
  <Box
    sx={{
      borderRadius: '30px',
      padding: { xs: '15px 2px', sm: '20px' },
    }}
  >
    <Box
      display="flex"
      justifyContent="space-between"
      width="100%"
      sx={{
        cursor: 'pointer',
      }}
      onClick={() => {
        setOpenDropdowns((prev) => prev.map((item, index) => (index === i ? !item : false)));
      }}
    >
      <Box display="flex">
        <Avatar
          sx={{
            bgcolor: '#EDF1FA',
            color: 'primary.main',
            width: '35px',
            height: '35px',
          }}
        >
          {findCMFInstitutionsLogo(debt.institutionName || '') !== '' ? (
            <img
              src={findCMFInstitutionsLogo(debt.institutionName || '')}
              style={{
                borderRadius: '25px',
                width: '35px',
                height: '35px',
              }}
              alt="company logo"
            />
          ) : (
            <InstitutionIcon
              sx={{
                width: '29px',
                height: '29px',
              }}
            />
          )}
        </Avatar>
        <Typography fontSize="20px" fontWeight={600} pl="15px">
          {debt.institutionName}
        </Typography>
      </Box>
      <Box display="flex">
        <Typography fontSize="18px" fontWeight={600} justifySelf="flex-end" pr="10px">
          {debt.totalAmount > 0 && priceFormat.format(Number(debt.totalAmount))}
          {!debt.totalAmount && debt.debtType}
        </Typography>
        {!disabled[i] && <CheckCircle color="info" />}
        <Avatar
          sx={{
            borderRadius: '10px',
            bgcolor: '#EDF1FA',
            width: '25px',
            height: '25px',
            ml: '10px',
            cursor: 'pointer',
          }}
        >
          {openDropdowns[i] ? (
            <ExpandLessIcon sx={{ color: '#51526B' }} />
          ) : (
            <ExpandMoreIcon sx={{ color: '#51526B' }} />
          )}
        </Avatar>
      </Box>
    </Box>
    <Collapse in={openDropdowns[i]}>
      <Box>
        <CategorizeInstitutionDebts
          directDebt={debt}
          save={save}
          index={i}
          disabled={disabled}
          setDisabled={setDisabled}
        />
      </Box>
    </Collapse>
  </Box>
);

interface CMFDataInterface {
  onSave: () => void;
}

const CategorizeCMFData: FC<CMFDataInterface> = ({ onSave }) => {
  const [save, setSave] = React.useState(false);
  const debtFlow = useDebtFlow();
  const CMFDebts = debtFlow.CMFResponse ? parseCMFDirectDebt(debtFlow.CMFResponse) : [];
  const manualDebts = debtFlow.debts?.filter((debt) => debt.source === 'Manual') || [];
  const debtList = [
    ...CMFDebts,
    ...manualDebts.map((debt) => ({
      ...debt,
      totalAmount: debt.amount || 0,
      totalMonthlyPayment: debt.monthlyPayment || 0,
    })),
  ];
  const [disabled, setDisabled] = React.useState(Array.from({ length: 0 }, () => true));
  const initialDropdown = Array.from({ length: 0 }, () => false);
  initialDropdown[0] = true;
  const [openDropdowns, setOpenDropdowns] = React.useState(initialDropdown);

  const [debtCreationFormOpen, setDebtCreationFormOpen] = useState(false);
  const [createDebt] = useCreateDebtMutation();

  const scraperDebts = debtFlow.scraperDebts || { servipag: [] };

  const createCMFDebts = async () => {
    const promises = CMFDebts.map((debt) =>
      createDebt({
        institutionName: debt.institutionName || '',
        institutionType: debt.institutionType || 'Banco',
        amount: debt.totalAmount,
        source: debt.source || 'CMF',
        debtType: debt.debtType || 'crédito de consumo',
        currency: 'CLP',
        term: 0,
        monthlyPayment: debt.totalMonthlyPayment,
        interestRate: 0,
        pendingPayments: 0,
        monthsBehind: 0,
      })
    );
    await Promise.all(promises);
  };

  const handleOnSave = async () => {
    await createCMFDebts();
    onSave();
  };

  useEffect(() => {
    if (save) {
      handleOnSave().catch((e) => console.error(e)); // eslint-disable-line no-console
    }
  }, [save]);

  useEffect(() => {
    if (!scraperDebts || scraperDebts.servipag.length === 0) return;
    const newDisabled = Array.from({ length: scraperDebts.servipag.length }, () => true);
    setDisabled(newDisabled);
    const newInitialDropdowns = Array.from({ length: scraperDebts.servipag.length }, () => false);
    newInitialDropdowns[0] = true;
    setOpenDropdowns(newInitialDropdowns);
  }, [debtFlow.scraperDebts]);

  return (
    <Box maxWidth="800px">
      <ManualDebtLoop debtCreationFormOpen={debtCreationFormOpen} setDebtCreationFormOpen={setDebtCreationFormOpen} />
      {!debtCreationFormOpen && (
        <Stack spacing={2}>
          <Typography variant="h3" fontSize="25px" textAlign="center">
            Categorizar deudas
          </Typography>
          {(debtFlow.scraperDebts?.servipag?.length || 0) > 0 && (
            <Typography variant="body1" fontSize="22px" pb="9px" textAlign="left">
              Hemos encontrado las siguientes deudas de cajas de compensación:
            </Typography>
          )}
          {debtFlow.scraperDebts?.servipag.map((debt, i) => (
            <DebtPaper
              key={`${debt.institutionName}-${debt.amount}-${debt.source}`}
              i={i}
              debt={{
                institutionName: debt.institutionName,
                institutionType: debt.institutionType || 'Caja de compensación',
                totalAmount: 0,
                totalMonthlyPayment: Number(debt.monthlyPayment || debt.amount),
                source: debt.source || 'Servipag',
                debtType: 'Crédito de consumo',
              }}
              save={save}
              disabled={disabled}
              setDisabled={setDisabled}
              openDropdowns={openDropdowns}
              setOpenDropdowns={setOpenDropdowns}
            />
          ))}
          {debtList.length > 0 && (
            <div>
              <Typography variant="body1" fontSize="22px" pb="9px" textAlign="left">
                Estas son las otras deudas vigentes que encontramos a tu nombre. Si falta alguna, agrégala manualmente.
              </Typography>
              <DebtSummaryTable debtList={debtList} />
            </div>
          )}

          {!debtList.length && (
            <Typography variant="body1" fontSize="22px" pb="9px" textAlign="left">
              No encontramos deudas vigentes a tu nombre. Si tienes alguna, agrégala manualmente.
            </Typography>
          )}

          {disabled.some((item) => item === true) && (
            <Typography variant="body1" fontSize="18px" color="info">
              *Debes asignar el monto de todas las instituciones para continuar
            </Typography>
          )}
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection={{ xs: 'column', sm: 'row' }}
            gap={2}
          >
            <BlackBtn
              onClick={() => {
                console.log('open debt creation form'); // eslint-disable-line no-console
                setDebtCreationFormOpen(true);
              }}
              disabled={save || disabled.some((item) => item === true)}
            >
              Agregar deuda
            </BlackBtn>
            <PrimaryBtn
              type="submit"
              onClick={() => {
                if (!(save || disabled.some((item) => item === true))) {
                  setSave(true);
                }
              }}
              disabled={save || disabled.some((item) => item === true)}
            >
              Continuar
            </PrimaryBtn>
          </Box>
        </Stack>
      )}
    </Box>
  );
};

export default CategorizeCMFData;
