import {
  OpenAIRequestCompletionBody,
  OpenAIRequest,
  OpenAIQuestionResponse,
  OpenAIRequestLikeBody,
  OpenAIQuestionBody,
} from '../types/openAIRequests';
import baseApi from './baseApi';

const openAIRequestsAPI = baseApi.injectEndpoints({
  endpoints: (build) => ({
    createQuestion: build.mutation({
      query: (body: OpenAIQuestionBody) => ({
        url: '/openAIRequests/question',
        method: 'POST',
        body,
      }),
      transformResponse: (response: OpenAIQuestionResponse) => response,
    }),
    likeRequest: build.mutation({
      query: (props: { body: OpenAIRequestLikeBody; id: number }) => ({
        url: `/openAIRequests/${props.id}/like`,
        method: 'PATCH',
        body: props.body,
      }),
      transformResponse: (response: OpenAIQuestionResponse) => response,
    }),
    getLastQuestion: build.query({
      query: () => ({
        url: '/openAIRequests/last-question',
        method: 'GET',
      }),
      transformResponse: (response: OpenAIQuestionResponse) => response,
    }),
    getRequests: build.query({
      query: () => ({
        url: 'admin/openAIRequests',
        method: 'GET',
      }),
      transformResponse: (response: OpenAIRequest[]) => response,
    }),
    createCompletion: build.mutation({
      query: (body: OpenAIRequestCompletionBody) => ({
        url: 'admin/openAIRequests/completion',
        method: 'POST',
        body,
      }),
      transformResponse: (response: OpenAIQuestionResponse) => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateQuestionMutation,
  useLikeRequestMutation,
  useGetLastQuestionQuery,
  useGetRequestsQuery,
  useCreateCompletionMutation,
} = openAIRequestsAPI;
