import type { BudgetRowDraft } from '../../services/types/budget';

export const EXPENSE_CATEGORIES: string[] = ['Hogar', 'Personal', 'Hijos', 'Entretención', 'Financiero'];

export const INCOME_CATEGORIES: string[] = ['Principal', 'Adicional'];
export const HOME_SUBCATEGORIES: string[] = ['Vivienda', 'Cuentas', 'Seguros', 'Mercadería', 'Otros'];
export const PERSONAL_SUBCATEGORIES: string[] = ['Compras', 'Transporte', 'Salud', 'Conectividad', 'Otros'];
export const CHILD_SUBCATEGORIES: string[] = ['Cuidado', 'Extra Hijos', 'Ropa', 'Educación', 'Salud', 'Otros'];

export const ENTERTAINMENT_SUBCATEGORIES: string[] = [
  'Salidas',
  'Regalos',
  'Suscripciones',
  'Deporte',
  'Clases',
  'Hobby',
  'Otros',
];

export const FINANCE_SUBCATEGORIES: string[] = ['Créditos', 'Seguros', 'Otros'];
export const DEFAULT_INCOME_ROWS: BudgetRowDraft[] = [
  {
    name: 'Mi sueldo',
    category: INCOME_CATEGORIES[0],
    subcategory: '',
    amount: '',
    isExpense: false,
  },
  {
    name: 'Sueldo pareja',
    category: INCOME_CATEGORIES[0],
    subcategory: '',
    amount: '',
    isExpense: false,
  },
  {
    name: 'Trabajo Extra',
    category: INCOME_CATEGORIES[1],
    subcategory: '',
    amount: '',
    isExpense: false,
  },
];
export const DEFAULT_HOME_ROWS: BudgetRowDraft[] = [
  {
    name: 'Arriendo',
    category: EXPENSE_CATEGORIES[0],
    amount: '',
    isExpense: true,
    subcategory: HOME_SUBCATEGORIES[0],
    average: '500.650',
  },
  {
    name: 'Gastos comunes',
    category: EXPENSE_CATEGORIES[0],
    amount: '',
    isExpense: true,
    subcategory: HOME_SUBCATEGORIES[0],
  },
  {
    name: 'Agua',
    category: EXPENSE_CATEGORIES[0],
    amount: '',
    isExpense: true,
    subcategory: HOME_SUBCATEGORIES[1],
    average: '16.000',
  },
  {
    name: 'Luz',
    category: EXPENSE_CATEGORIES[0],
    amount: '',
    isExpense: true,
    subcategory: HOME_SUBCATEGORIES[1],
    average: '16.000',
  },
  {
    name: 'Gas',
    category: EXPENSE_CATEGORIES[0],
    amount: '',
    isExpense: true,
    subcategory: HOME_SUBCATEGORIES[1],
    average: '23.325',
  },
  {
    name: 'TV Cable',
    category: EXPENSE_CATEGORIES[0],
    amount: '',
    isExpense: true,
    subcategory: HOME_SUBCATEGORIES[1],
    average: '20.000',
  },
  {
    name: 'Cuenta Internet',
    category: EXPENSE_CATEGORIES[0],
    amount: '',
    isExpense: true,
    subcategory: HOME_SUBCATEGORIES[1],
    average: '20.000',
  },
  {
    name: 'Supermercado',
    category: EXPENSE_CATEGORIES[0],
    amount: '',
    isExpense: true,
    subcategory: HOME_SUBCATEGORIES[3],
    average: '243.000',
  },
  {
    name: 'Alarma',
    category: EXPENSE_CATEGORIES[0],
    amount: '',
    isExpense: true,
    subcategory: HOME_SUBCATEGORIES[0],
  },
  {
    name: 'Limpieza',
    category: EXPENSE_CATEGORIES[0],
    amount: '',
    isExpense: true,
    subcategory: HOME_SUBCATEGORIES[0],
    average: '6.533',
  },
];

export const DEFAULT_PERSONAL_ROWS: BudgetRowDraft[] = [
  {
    name: 'Cuidado Personal',
    category: EXPENSE_CATEGORIES[1],
    amount: '',
    isExpense: true,
    subcategory: PERSONAL_SUBCATEGORIES[0],
    average: '21.548',
  },
  {
    name: 'Ropa',
    category: EXPENSE_CATEGORIES[1],
    amount: '',
    isExpense: true,
    subcategory: PERSONAL_SUBCATEGORIES[0],
    average: '42.685',
  },
  {
    name: 'Maquillaje',
    category: EXPENSE_CATEGORIES[1],
    amount: '',
    isExpense: true,
    subcategory: PERSONAL_SUBCATEGORIES[0],
  },
  {
    name: 'Bencina',
    category: EXPENSE_CATEGORIES[1],
    amount: '',
    isExpense: true,
    subcategory: PERSONAL_SUBCATEGORIES[1],
    average: '40.000',
  },
  {
    name: 'Bip',
    category: EXPENSE_CATEGORIES[1],
    amount: '',
    isExpense: true,
    subcategory: PERSONAL_SUBCATEGORIES[1],
    average: '40.406',
  },
  {
    name: 'Medicamentos mensuales',
    category: EXPENSE_CATEGORIES[1],
    amount: '',
    isExpense: true,
    subcategory: PERSONAL_SUBCATEGORIES[2],
    average: '9.871',
  },
  {
    name: 'Plan de Celular',
    category: EXPENSE_CATEGORIES[1],
    amount: '',
    isExpense: true,
    subcategory: PERSONAL_SUBCATEGORIES[3],
    average: '6.000',
  },
];

export const DEFAULT_CHILD_ROWS: BudgetRowDraft[] = [
  {
    name: 'Cuidado de niños',
    category: EXPENSE_CATEGORIES[2],
    amount: '',
    isExpense: true,
    subcategory: CHILD_SUBCATEGORIES[0],
  },
  {
    name: 'Mesada',
    category: EXPENSE_CATEGORIES[2],
    amount: '',
    isExpense: true,
    subcategory: CHILD_SUBCATEGORIES[1],
  },
  {
    name: 'Extracurriculares',
    category: EXPENSE_CATEGORIES[2],
    amount: '',
    isExpense: true,
    subcategory: CHILD_SUBCATEGORIES[1],
  },
  {
    name: 'Vestuario',
    category: EXPENSE_CATEGORIES[2],
    amount: '',
    isExpense: true,
    subcategory: CHILD_SUBCATEGORIES[2],
  },
  {
    name: 'Mensualidad',
    category: EXPENSE_CATEGORIES[2],
    amount: '',
    isExpense: true,
    subcategory: CHILD_SUBCATEGORIES[3],
    average: '225.000',
  },
  {
    name: 'Transporte Escolar',
    category: EXPENSE_CATEGORIES[2],
    amount: '',
    isExpense: true,
    subcategory: CHILD_SUBCATEGORIES[3],
  },
  {
    name: 'Fotocopias',
    category: EXPENSE_CATEGORIES[2],
    amount: '',
    isExpense: true,
    subcategory: CHILD_SUBCATEGORIES[3],
  },
  {
    name: 'Libros',
    category: EXPENSE_CATEGORIES[2],
    amount: '',
    isExpense: true,
    subcategory: CHILD_SUBCATEGORIES[3],
  },
  {
    name: 'Uniformes',
    category: EXPENSE_CATEGORIES[2],
    amount: '',
    isExpense: true,
    subcategory: CHILD_SUBCATEGORIES[2],
    average: '80.000',
  },
  {
    name: 'Gastos Medicos',
    category: EXPENSE_CATEGORIES[2],
    amount: '',
    isExpense: true,
    subcategory: CHILD_SUBCATEGORIES[4],
  },
];

export const DEFAULT_ENTRETE_ROWS: BudgetRowDraft[] = [
  {
    name: 'Cine',
    category: EXPENSE_CATEGORIES[3],
    amount: '',
    isExpense: true,
    subcategory: ENTERTAINMENT_SUBCATEGORIES[0],
    average: '9.310',
  },
  {
    name: 'Restaurante',
    category: EXPENSE_CATEGORIES[3],
    amount: '',
    isExpense: true,
    subcategory: ENTERTAINMENT_SUBCATEGORIES[0],
    average: '22.337',
  },
  {
    name: 'Regalos',
    category: EXPENSE_CATEGORIES[3],
    amount: '',
    isExpense: true,
    subcategory: ENTERTAINMENT_SUBCATEGORIES[1],
  },
  {
    name: 'Spotify',
    category: EXPENSE_CATEGORIES[3],
    amount: '',
    isExpense: true,
    subcategory: ENTERTAINMENT_SUBCATEGORIES[2],
    average: '4.150',
  },
  {
    name: 'Netflix',
    category: EXPENSE_CATEGORIES[3],
    amount: '',
    isExpense: true,
    subcategory: ENTERTAINMENT_SUBCATEGORIES[2],
    average: '5.940',
  },
  {
    name: 'Gimnasio',
    category: EXPENSE_CATEGORIES[3],
    amount: '',
    isExpense: true,
    subcategory: ENTERTAINMENT_SUBCATEGORIES[3],
    average: '30.000',
  },
  {
    name: 'Clases',
    category: EXPENSE_CATEGORIES[3],
    amount: '',
    isExpense: true,
    subcategory: ENTERTAINMENT_SUBCATEGORIES[4],
  },
  {
    name: 'Hobby',
    category: EXPENSE_CATEGORIES[3],
    amount: '',
    isExpense: true,
    subcategory: ENTERTAINMENT_SUBCATEGORIES[5],
  },
];
export const DEFAULT_FINANCE_ROWS: BudgetRowDraft[] = [
  {
    name: 'Seguro de vida',
    category: EXPENSE_CATEGORIES[4],
    amount: '',
    isExpense: true,
    subcategory: FINANCE_SUBCATEGORIES[1],
  },
  {
    name: 'Seguro de auto',
    category: EXPENSE_CATEGORIES[4],
    amount: '',
    isExpense: true,
    subcategory: FINANCE_SUBCATEGORIES[1],
    average: '40.000',
  },
  {
    name: 'Seguro de salud',
    category: EXPENSE_CATEGORIES[4],
    amount: '',
    isExpense: true,
    subcategory: FINANCE_SUBCATEGORIES[1],
    average: '300.000',
  },
];
