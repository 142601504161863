import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { CompanyUserMetrics } from 'services/types/companyMetrics';

interface UserMetricsSummaryProps {
  userMetrics: CompanyUserMetrics;
}

const UserMetricsSummary: FC<UserMetricsSummaryProps> = ({ userMetrics }) => {
  const sortedAges = Object.keys(userMetrics.byAge).sort((a, b) => {
    return parseInt(a, 10) - parseInt(b, 10);
  });
  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        borderRadius: 2,
        p: 3,
      }}
    >
      <Typography variant="h5">Resumen de usuarios</Typography>
      <Typography variant="body1">Por edad:</Typography>
      {sortedAges.map((age) => (
        <Typography key={age} variant="body1">
          <strong>{age}: </strong>
          {userMetrics.byAge[age]}
        </Typography>
      ))}
    </Box>
  );
};

export default UserMetricsSummary;
