import React, { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Phone from '@mui/icons-material/Phone';
import { Box, Grid, IconButton, MenuItem, Select, Typography } from '@mui/material';

import { MeetingStatus } from '@relif/backend';
import { EmailIcon, WhatsAppIcon } from 'assets/icons';
import { useEditUserMutation } from 'services/api/admin-users.api';
import { dateFormat } from 'services/format/dateFormat';
import priceFormat from 'services/format/priceFormat';
import { User } from 'services/types/user';

interface Props {
  user: User;
}

const UserAttributes = (user: User): { name: string; value: string | number | undefined | null }[] => {
  return [
    { name: 'ID', value: user.id },
    { name: 'RUT', value: user.rut },
    { name: 'Email', value: user.email },
    { name: 'Teléfono', value: user.phone },
    { name: 'Empresa', value: (user.company?.name ?? '') + (user.origin ? `(${user.origin})` : '') },
    { name: 'Fecha de nacimiento', value: user.dateOfBirth },
    { name: 'Género', value: user.gender?.toLowerCase() },
    { name: 'Nacionalidad', value: user.nationality?.toLowerCase() },
    { name: 'Residencia', value: user.residencyStatus?.toLowerCase() },
    { name: 'Estado civil', value: user.maritalStatus?.toLowerCase() },
    {
      name: 'Ingreso mensual',
      value: user.financialStatusData?.income ? priceFormat.format(user.financialStatusData.income) : 'Sin datos',
    },
    {
      name: 'Carga Financiera',
      value: user.financialStatusData?.RCR ? `${user.financialStatusData.RCR.toFixed(2)}%` : 'Sin datos',
    },
    {
      name: 'Veces renta',
      value: user.financialStatusData?.VR ? `${user.financialStatusData.VR.toFixed(2)}` : 'Sin datos',
    },
    { name: 'Es administrador?', value: user.isAdmin ? 'Si' : 'No' },
    { name: 'Es de prueba?', value: user.isTestUser ? 'Si' : 'No' },
    { name: 'En wait list?', value: user.waitList ? 'Si' : 'No' },
    { name: 'Está bloqueado?', value: user.isBlocked ? 'Si' : 'No' },
    { name: 'Fecha de creación', value: dateFormat(new Date(user.createdAt)) },
    { name: 'Fecha de actualización', value: dateFormat(new Date(user.updatedAt)) },
    {
      name: 'Fecha de última actividad',
      value: user.userActions?.length ? dateFormat(new Date(user.userActions[0].createdAt)) : 'Sin actividad',
    },
    { name: 'Objetivos creados', value: user.goals?.length ? user.goals.length.toString() : 0 },
    { name: 'Presupuestos creados', value: user.budgets?.length ? user.budgets.length.toString() : 0 },
    { name: '¿Qué quiere hacer?', value: user.relifGoals?.join(', ') },
    { name: 'Estado', value: user.userStatus || 'Sin datos' },
    { name: 'Tipo de contrato', value: user.employerContractType || 'Sin datos' },
    {
      name: 'Fecha de inicio de contrato',
      value: user.employerContractStartDate ? dateFormat(new Date(user.employerContractStartDate)) : 'Sin datos',
    },
  ];
};

const UserDetailsCard: FC<Props> = ({ user }) => {
  const [editUser] = useEditUserMutation();
  const [meetingStatus, setMeetingStatus] = React.useState(user.meetingStatus || '');

  useEffect(() => {
    if (user.meetingStatus) {
      setMeetingStatus(user.meetingStatus);
    }
  }, [user.meetingStatus]);

  useEffect(() => {
    if (meetingStatus && meetingStatus !== user.meetingStatus) {
      editUser({ id: user.id, user: { meetingStatus: meetingStatus as MeetingStatus } }).catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    }
  }, [meetingStatus]);

  return (
    <Box p={2} sx={{ backgroundColor: 'background.paper' }} textAlign={{ xs: 'left' }} borderRadius={4}>
      <Grid container>
        <Grid item xs={12} display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">
            {user.firstName} {user.lastName}
          </Typography>
          <Box display="flex" alignItems="center">
            <IconButton sx={{ color: 'text.secondary' }} aria-label="chat" disabled={!user.phone}>
              <Link to={`/admin/chat?userId=${user.id}`} style={{ color: 'inherit' }}>
                <WhatsAppIcon />
              </Link>
            </IconButton>
            <IconButton
              onClick={() => window.open(`mailto:${user.email || ''}`)}
              sx={{ color: 'text.secondary' }}
              aria-label="email"
              disabled={!user.email}
            >
              <EmailIcon />
            </IconButton>
            {/* call button */}
            <IconButton
              onClick={() => window.open(`tel:${user.phone || ''}`)}
              sx={{ color: 'text.secondary' }}
              aria-label="call"
              disabled={!user.phone}
            >
              <Phone />
            </IconButton>
          </Box>
        </Grid>
        {UserAttributes(user).map((attribute) => (
          <Grid item xs={6} md={3} key={attribute.name} py={1}>
            <Typography variant="subtitle2">{attribute.name}</Typography>
            <Typography variant="body1" fontWeight={600} maxWidth="100%" style={{ wordWrap: 'break-word' }}>
              {attribute.value}
            </Typography>
          </Grid>
        ))}
        <Grid item xs={6} md={3} py={1}>
          <Typography variant="subtitle2">Estado de reunión</Typography>
          <Select
            fullWidth
            value={meetingStatus}
            onChange={(e) => setMeetingStatus(e.target.value)}
            label="Estado de reunión"
          >
            <MenuItem value="attended">Asistió</MenuItem>
            <MenuItem value="not_attended">No asistió</MenuItem>
            <MenuItem value="">Sin datos</MenuItem>
          </Select>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserDetailsCard;
