import React from 'react';

import SectionHeader from 'components/layout/SectionHeader';

import BankOfferRequestsTable from './BankOfferRequestsTable';

const BankOfferRequests = () => {
  return (
    <div>
      <SectionHeader text="Solicitudes de ofertas" button={null} />
      <BankOfferRequestsTable />
    </div>
  );
};

export default BankOfferRequests;
