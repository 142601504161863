import React, { FC } from 'react';

import Typography from '@mui/material/Typography';

const BodyTypography: FC = ({ children }) => (
  <Typography mt={2} textAlign="start" fontSize="22px" lineHeight={1.5}>
    {children}
  </Typography>
);

export default BodyTypography;
