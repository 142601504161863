import React from 'react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Accordions, AccordionItem } from '@dscla/ds-accordions/react';
import { SupportIcon, EmailIcon, ChatIcon } from 'assets/icons';
import PaperContainer from 'components/UI/PaperContainer';
import ChatWrapper from 'components/common/ChatWrapper';
import SectionHeader from 'components/layout/SectionHeader';

import styles from './FAQ.module.scss';

const FAQ = () => {
  return (
    <>
      <SectionHeader text="Ayuda" button={null} />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          <PaperContainer fullWidth fullHeight title="Preguntas Frecuentes">
            <Accordions>
              <AccordionItem slot="item" label="¿Qué hace Relif?">
                <div slot="body">
                  Relif es una plataforma para entender y mejorar tus créditos 💳. ¿Qué significa eso? Junto a ti,
                  revisamos tus créditos y buscamos formas de que estés mejor, ya sea refinanciando o renegociando tus
                  deudas. Todo lo hacemos a través de tecnología 💻, para que esto sea rápido y seguro. Con Relif
                  podrás:
                  <ul>
                    <li>Consolidar o refinanciar tus créditos para bajar tu carga financiera</li>
                    <li>
                      Si estás apretado/a hoy, puedes postergar pagos repactando a condiciones mejores que las que te
                      darán en la sucursal
                    </li>
                    <li>Salir de DICOM o evitar caer en él</li>
                    <li>Negociar créditos morosos o castigados</li>
                  </ul>
                </div>
              </AccordionItem>
              <AccordionItem slot="item" label="¿Por qué mi empleador lo está ofreciendo?">
                <div slot="body">
                  Tu empleador quiere que los colaboradores estén felices 😀, y una buena salud financiera es muy
                  importante para esto. Con el aumento en el costo de vida y en el costo de los créditos que hemos visto
                  en el último tiempo, tu empleador ha querido buscar formas de ayudar a los colaboradores, y ahí es
                  donde entramos nosotros. 💪
                </div>
              </AccordionItem>
              <AccordionItem slot="item" label="¿Si no tengo créditos, la plataforma me sirve?">
                <div slot="body">
                  ¡Claro! Además de ayudar con el endeudamiento, la plataforma tiene otras funcionalidades como:
                  <ul>
                    <li>💰 Herramientas para ordenar tus finanzas personales (presupuestos, objetivos, etc.)</li>
                    <li>📚 Educación financiera (artículos, charlas, consejos, etc.)</li>
                    <li>
                      🔜 ¡Y próximamente mucho más! Estamos construyendo la plataforma donde podrás encontrar todo lo
                      necesario para tener tus finanzas sanas y controladas. Escríbenos si quieres sugerir ideas.
                    </li>
                  </ul>
                </div>
              </AccordionItem>
              <AccordionItem slot="item" label="¿Mi empleador tiene acceso a mi información personal?">
                <div slot="body">
                  ¡No! Actuamos de manera independiente a la empresa y no compartimos información personal con ellos 🤐.
                  A esto le llamamos la “muralla china” entre empleador y usuarios.
                </div>
              </AccordionItem>
              <AccordionItem slot="item" label="¿Tiene algún costo para mí?">
                <div slot="body">¡No! Relif no le cobra por ninguno de sus servicios a los colaboradores.🙌</div>
              </AccordionItem>
              <AccordionItem slot="item" label="¿Mi empleador revisó la seguridad de la plataforma?">
                <div slot="body">
                  ¡Sí! Llevamos semanas trabajando con la empresa, donde ellos han revisado 🔍 todos los estándares de
                  seguridad con los que contamos.
                </div>
              </AccordionItem>
              <AccordionItem slot="item" label="¿Pueden hacer algo sin mi autorización?">
                <div slot="body">
                  ¡No! Somos muy cuidadosos con la información personal de nuestros usuarios 🔒. Por esto, les pedimos
                  que firmen un poder simple en caso que quieran que los ayudemos.
                </div>
              </AccordionItem>
              <AccordionItem
                slot="item"
                label="Quiero refinanciar un crédito en la plataforma y me piden firmar un poder con firma digital. ¿Para qué es
              esto?"
              >
                <div slot="body">
                  Lo que pedimos firmar es un poder simple, que nos permite hacer tres cosas:
                  <ul>
                    <li>Cotizar nuevos créditos</li>
                    <li>Solicitar certificados de créditos actuales</li>
                    <li>Solicitar al empleador tus liquidaciones de sueldo.</li>
                  </ul>
                  Con esas tres cosas, nosotros podemos ir a buscarte las mejores condiciones crediticias en el mercado
                  y así mejorar tu situación financiera. El poder NO permite tomar decisiones por ti, por lo que tú
                  siempre tienes la última palabra.
                </div>
              </AccordionItem>
            </Accordions>
          </PaperContainer>
        </Grid>
        <Grid item xs={12} lg={4}>
          <PaperContainer fullWidth title="¿Sigues con dudas?">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ChatWrapper>
                  <Box display="flex" alignItems="center">
                    <Avatar sx={{ backgroundColor: 'info.main', width: '65px', height: '65px' }}>
                      <ChatIcon sx={{ height: '60%', width: '60%' }} />
                    </Avatar>
                    <Typography variant="h4" ml={3} fontSize={{ xs: '19px', lg: '22px' }} lineHeight="35px">
                      <Box sx={{ '&:hover': { color: 'info.main' } }} className={styles.ContactLinks}>
                        Háblanos
                      </Box>{' '}
                      a nuestro chat
                    </Typography>
                  </Box>
                </ChatWrapper>
              </Grid>

              <Grid item xs={12}>
                <a
                  href="mailto:contacto@relif.cl"
                  className={styles.ContactLinksWrapper}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Box display="flex" alignItems="center">
                    <Avatar sx={{ backgroundColor: 'info.main', width: '65px', height: '65px' }}>
                      <EmailIcon sx={{ height: '60%', width: '60%' }} />
                    </Avatar>
                    <Typography variant="h4" ml={3} fontSize={{ xs: '19px', lg: '22px' }} lineHeight="35px">
                      <Box sx={{ '&:hover': { color: 'info.main' } }} className={styles.ContactLinks}>
                        Escríbenos
                      </Box>{' '}
                      a nuestro mail: contacto@relif.cl
                    </Typography>
                  </Box>
                </a>
              </Grid>

              <Grid item xs={12}>
                <a href="/book-meeting" className={styles.ContactLinksWrapper} target="_blank" rel="noreferrer">
                  <Box display="flex" alignItems="center">
                    <Avatar sx={{ backgroundColor: 'info.main', width: '65px', height: '65px' }}>
                      <SupportIcon sx={{ height: '60%', width: '60%' }} />
                    </Avatar>
                    <Typography variant="h4" ml={3} fontSize={{ xs: '19px', lg: '22px' }} lineHeight="35px">
                      <Box sx={{ '&:hover': { color: 'info.main' } }} className={styles.ContactLinks}>
                        AGENDA UNA HORA
                      </Box>{' '}
                      con nuestro equipo (sí, nosotros mismos te ayudamos)
                    </Typography>
                  </Box>
                </a>
              </Grid>
            </Grid>
          </PaperContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default FAQ;
