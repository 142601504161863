import React, { FC, useState, useEffect } from 'react';

import CallMade from '@mui/icons-material/CallMade';
import Send from '@mui/icons-material/Send';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { usePostMessageMutation } from 'services/api/whatsApp.api';
import { Message, WhatsAppUser } from 'services/types/whatsApp';

interface Props {
  selectedUser: WhatsAppUser;
  conversation: Message[] | undefined;
  setConversation: (messages: Message[]) => void;
}

const ChatFooter: FC<Props> = ({ selectedUser, conversation, setConversation }) => {
  const [active, setActive] = useState(false);
  const [messageToSend, setMessage] = useState('');
  const [postMessageMutation, { data: newMessage }] = usePostMessageMutation();

  const handleSendMessage = () => {
    // eslint-disable-next-line no-console
    postMessageMutation({ toPhone: selectedUser.phone, message: messageToSend }).catch((err) => console.error(err));
    setMessage('');
  };

  useEffect(() => {
    if (conversation) {
      const yesterday = new Date();
      yesterday.setHours(new Date().getHours() - 24);
      if (conversation.find((message) => message.isFromUser && new Date(message.createdAt) > yesterday)) {
        setActive(true);
      } else {
        setActive(false);
      }
    }
  }, [conversation]);

  useEffect(() => {
    if (newMessage && conversation) {
      setConversation([...conversation, newMessage]);
    }
  }, [newMessage]);

  if (selectedUser.notificationsDisabled) {
    return (
      <Grid container style={{ padding: '20px' }} justifyContent="center">
        <Typography>
          Este usuario tiene las notificaciones deshabilitadas, si quieres enviarle un mensaje, actívalas
        </Typography>
      </Grid>
    );
  }

  if (!active) {
    return (
      <Grid container style={{ padding: '20px' }} justifyContent="center">
        {conversation?.length === 0 ? (
          <Typography>No se ha hablado con este usuario, envíe una plantilla para comenzar una conversación</Typography>
        ) : (
          <Typography>Chat no está activo, pasaron más de 24 horas desde el último contacto del usuario</Typography>
        )}
        <Typography>Puedes enviar un template usando las opciones del chat</Typography>
        <CallMade fontSize="small" sx={{ ml: 1 }} />
      </Grid>
    );
  }
  return (
    <Grid container style={{ padding: '20px', width: '80%' }} justifyContent="space-between">
      <TextField
        id="outlined-basic-email"
        label="Mensaje"
        fullWidth
        multiline
        sx={{
          maxWidth: '90%',
          backgroundColor: '#fff',
          borderRadius: '40px',
          opacity: 1,
          mr: 1,
          '& .MuiOutlinedInput-root': { borderRadius: '40px' },
        }}
        value={messageToSend}
        onChange={(e) => setMessage(e.target.value)}
      />
      <IconButton
        sx={{
          backgroundColor: 'info.main',
          height: {
            xs: '3.5rem',
          },
        }}
        onClick={handleSendMessage}
      >
        <Send
          sx={{
            color: '#fff',
            fontSize: {
              xs: '2.5rem',
            },
          }}
        />
      </IconButton>
    </Grid>
  );
};

export default ChatFooter;
