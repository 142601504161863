import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

import promptTemplates from 'assets/data/openAIPromptTemplates';
import { DislikeIcon, LikeIcon } from 'assets/icons';
import PrimaryBtn from 'components/UI/Buttons';
import SectionHeader from 'components/layout/SectionHeader';
import { useCreateCompletionMutation, useGetRequestsQuery, useLikeRequestMutation } from 'services/api/openAIRequests';

const PRICER_PER_THOUSAND_TOKENS = 0.00175;
const PRICE_PER_TOKEN = PRICER_PER_THOUSAND_TOKENS / 1000;

const OpenAIAdministrator = () => {
  const [prompt, setPrompt] = useState('');
  const [model, setModel] = useState('text-davinci-003');
  const [temperature, setTemperature] = useState(0.9);
  const [maxTokens, setMaxTokens] = useState(150);
  const [likedByUser, setLikedByUser] = useState<boolean | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState(0);

  const { data: requests } = useGetRequestsQuery(null);
  const [createCompletion, { data: completionResponse, isLoading: isCompletionLoading }] =
    useCreateCompletionMutation();
  const [likeRequest, { data: likeResponse }] = useLikeRequestMutation();

  const navigate = useNavigate();

  const handleCompletion = () => {
    createCompletion({
      prompt,
      model,
      temperature,
      maxTokens,
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
    });
  };

  const handleLike = (like: boolean) => {
    if (completionResponse) {
      likeRequest({
        id: completionResponse.id,
        body: { like },
      }).catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
    }
  };

  const getLike = (liked: boolean | null) => {
    if (liked === null) {
      return '-';
    }
    return liked ? 'Si' : 'No';
  };

  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
  const totalMonthlyCost = requests?.reduce((acc, request) => {
    return acc + (new Date(request.createdAt) > oneMonthAgo ? request.totalTokens * PRICE_PER_TOKEN : 0);
  }, 0);

  useEffect(() => {
    if (likeResponse) {
      setLikedByUser(likeResponse.likedByUser);
    }
  }, [likeResponse]);

  useEffect(() => {
    if (completionResponse) {
      setLikedByUser(completionResponse.likedByUser);
    }
  }, [completionResponse]);

  useEffect(() => {
    const template = promptTemplates[selectedTemplate];
    setPrompt(template.template);
    if (template.isCodeTemplate) {
      setModel('code-davinci-002');
      setMaxTokens(3000);
    }
  }, [selectedTemplate]);

  return (
    <Box>
      <SectionHeader
        text="Administrador OpenAi"
        button={<PrimaryBtn onClick={() => navigate('/admin')}>Volver</PrimaryBtn>}
      />
      <Typography variant="h4" sx={{ mt: 2 }}>
        Costo mensual estimado: <b> US${totalMonthlyCost?.toFixed(3)}</b>
      </Typography>
      <Typography variant="h5" sx={{ mt: 2 }}>
        Relif AI
      </Typography>
      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12} md={6} flexDirection="column" display="flex" p={2}>
          <Typography variant="h6">Modelo</Typography>
          <Typography variant="caption">
            El modelo es el algoritmo que genera la respuesta. Puedes probar con diferentes modelos. Están ordenados de
            más a menos potente. Los modelos con nombre Code son modelos para generar código.
          </Typography>

          <Select fullWidth label="Modelo" value={model} onChange={(e) => setModel(e.target.value)}>
            <MenuItem value="text-davinci-003">Text Davinci</MenuItem>
            <MenuItem value="text-curie-001">Text Curie</MenuItem>
            <MenuItem value="text-babbage-001">Text Babbage</MenuItem>
            <MenuItem value="text-ada-001">Text Ada</MenuItem>
            <MenuItem value="code-davinci-002">Code Davinci</MenuItem>
            <MenuItem value="code-cushman-001">Code Cushman</MenuItem>
          </Select>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Temperatura: {temperature}
          </Typography>
          <Typography variant="caption" sx={{ mt: 2 }}>
            Este valor indica que tan creativo puede ser el modelo. 0 es muy conservador y 1 es muy creativo.
          </Typography>
          <input
            type="range"
            min="0"
            max="1"
            step="0.1"
            value={temperature}
            onChange={(e) => setTemperature(parseFloat(e.target.value))}
          />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Max Tokens: {maxTokens}
          </Typography>
          <Typography variant="caption" sx={{ mt: 2 }}>
            Este valor indica que tan larga puede ser la respuesta. 1000 tokens equivale a 750 palabras aprox.
          </Typography>
          <input
            type="range"
            min="0"
            max={model.includes('code') ? '3000' : '1000'}
            step="10"
            value={maxTokens}
            onChange={(e) => setMaxTokens(parseInt(e.target.value, 10))}
          />
        </Grid>
        <Grid item xs={12} md={6} p={2}>
          <Typography variant="h6">Prompt</Typography>
          <Typography variant="caption">
            El prompt es el texto que se le da al modelo para que genere la respuesta. Puedes usar uno de los templates
            para guiarte.
          </Typography>
          <Select
            fullWidth
            label="Template"
            value={selectedTemplate}
            onChange={(e) => setSelectedTemplate(Number(e.target.value))}
          >
            {promptTemplates.map((template, index) => (
              <MenuItem key={template.name} value={index}>
                {template.name}
              </MenuItem>
            ))}
          </Select>
          <TextField
            fullWidth
            label="Prompt"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            multiline
            rows={8}
            sx={{ mt: 2 }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mt: 2,
            }}
          >
            <PrimaryBtn onClick={handleCompletion} sx={{ mt: 1 }}>
              Generar respuesta
            </PrimaryBtn>
          </Box>
        </Grid>
        <Grid item xs={12} p={2} sx={{ mt: 2, display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h6">Respuesta</Typography>
          <Typography variant="caption">Esta es la respuesta generada por el modelo.</Typography>
          {isCompletionLoading ? (
            <CircularProgress />
          ) : (
            <TextField
              fullWidth
              label="Respuesta"
              value={completionResponse?.answer || ''}
              multiline
              rows={8}
              InputProps={{
                readOnly: true,
              }}
              sx={{ mt: 1 }}
            />
          )}
          {completionResponse?.answer && (
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
              <Typography variant="caption" sx={{ mt: 1 }}>
                ¿Te pareció útil esta respuesta?
              </Typography>
              <Box sx={{ ml: 1 }}>
                <IconButton sx={{ color: likedByUser ? 'info.main' : 'inherit' }} onClick={() => handleLike(true)}>
                  <LikeIcon />
                </IconButton>
              </Box>
              <Box sx={{ ml: 1 }}>
                <IconButton
                  sx={{ color: likedByUser === false ? 'error.main' : 'inherit' }}
                  onClick={() => handleLike(false)}
                >
                  <DislikeIcon />
                </IconButton>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>

      <Typography variant="h5" sx={{ mt: 2 }}>
        Lista de preguntas
      </Typography>
      <Typography variant="caption" sx={{ mt: 2 }}>
        * El costo es estimado, suponiendo que se ocupó el modelo más potente (Text Davinci)
      </Typography>
      <TableContainer sx={{ maxHeight: 600, overflow: 'auto' }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="h6">ID Usuario</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6">Pregunta</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6">Respuesta</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6">Like</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6">Tokens</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6">Precio (US$) *</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6">Tiempo (ms)</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {requests?.map((request) => (
            <TableRow key={request.id}>
              <TableCell>{request.userId}</TableCell>
              <TableCell>{request.userInput}</TableCell>
              <TableCell>{request.answer}</TableCell>
              <TableCell>{getLike(request.likedByUser)}</TableCell>
              <TableCell>{request.totalTokens}</TableCell>
              <TableCell>{(request.totalTokens * PRICE_PER_TOKEN).toFixed(3)}</TableCell>
              <TableCell>{request.executionTime}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableContainer>
    </Box>
  );
};

export default OpenAIAdministrator;
