import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Container, Typography } from '@mui/material';

import { SecondaryBtn } from 'components/UI/Buttons';
import trpc from 'trpc';

const SubscribedToWebinar: FC = () => {
  const [clicked, setClicked] = useState(false);
  const { userId } = useParams<{ userId: string }>();
  const subscribeUser = trpc.subscribeToWebinar.subscribeToDecemberWebinar.useMutation();
  if (subscribeUser.error) {
    console.log(subscribeUser.error); // eslint-disable-line no-console
  }
  const handleClick = () => {
    setClicked(true);
    subscribeUser.mutate({ userId: Number(userId) });
  };
  return (
    <Box sx={{ margin: 0, padding: 0 }}>
      <div
        style={{
          backgroundImage: "url('/relif_background.webp')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: '100vh',
          width: '100vw',
          filter: 'blur(10px)',
          margin: 0,
          padding: 0,
        }}
      />
      <Container
        sx={{
          backgroundImage: 'url("/contact-background.svg")',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          borderRadius: '30px',
          padding: { lg: '20px 50px 50px', xs: '20px 10px' },
          position: 'absolute',
          mt: 3,
          top: '10%',
          left: '50%',
          transform: 'translate(-50%, -10%)',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            width: '100%',
          }}
        >
          <img alt="Relif" src="/relif_logo_white.webp" height="120px" />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              mr: 2,
              ml: 2,
            }}
          >
            {clicked ? (
              <>
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: { lg: '30px', xs: '25px' },
                    width: '100%',
                    mb: '15px',
                    textTransform: 'uppercase',
                    fontWeight: 600,
                    color: 'white',
                    textAlign: 'center',
                  }}
                >
                  ¡Felicidades! ya estás inscrito al webinar
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: { lg: 22, xs: 18 },
                    mb: 3,
                    fontWeight: 500,
                    color: 'white',
                    marginBottom: 3,
                    textAlign: 'center',
                    lineHeight: '28px',
                  }}
                >
                  El webinar será el 12 de diciembre a las 19:00 p.m. por YouTube live. Este es el link por si lo quiere
                  copiar: (
                  <a
                    href="https://youtube.com/live/qiUkQ-71XRE?feature=share"
                    style={{ color: 'white', textDecoration: 'underline' }}
                  >
                    https://youtube.com/live/qiUkQ-71XRE?feature=share
                  </a>
                  ).
                  <Box>Igualmente te enviaremos un recordatorio con el link media hora antes del webinar.</Box>
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: { lg: '30px', xs: '25px' },
                    width: '100%',
                    mb: '15px',
                    textTransform: 'uppercase',
                    fontWeight: 600,
                    color: 'white',
                    textAlign: 'center',
                  }}
                >
                  Inscríbete a nuestro webinar &quot;Por un diciembre sin estrés&ldquo;
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: { lg: 22, xs: 18 },
                    mb: 3,
                    fontWeight: 500,
                    color: 'white',
                    marginBottom: 3,
                    textAlign: 'center',
                    lineHeight: '28px',
                  }}
                >
                  Recuerda, MAÑANA a las 19:00 p.m. tendremos nuestro último webinar del año!
                </Typography>
                <SecondaryBtn onClick={handleClick}>Quiero participar</SecondaryBtn>
              </>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default SubscribedToWebinar;
