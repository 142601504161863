import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Divider } from '@mui/material';
import Box from '@mui/material/Box';

import logo from 'assets/images/logo.webp';
import logoBChile from 'assets/images/logos/banco-de-chile-long.png';
import logoCajaAndes from 'assets/images/logos/caja-los-andes-blue.svg';
import { Origin } from 'services/types/origin';

import COMPANIES from './Companies';

interface Props {
  origin: Origin;
}

const HeaderLogos: FC<Props> = ({ origin }) => {
  const { companyName } = useParams<{ companyName: string }>();
  const company = COMPANIES.find((x) => x.id === companyName);
  if (!company) {
    return (
      <Box display="flex" alignContent="center" justifyContent="center">
        <img src={logo} alt="logo" style={{ width: '150px', height: '100%', alignSelf: 'center' }} />
        {(origin === 'Banco de Chile' || origin === 'Caja Los Andes') && (
          <Divider orientation="vertical" variant="middle" flexItem />
        )}
        {origin === 'Banco de Chile' && (
          <img
            src={logoBChile}
            alt="logo"
            style={{ width: '220px', height: '100%', alignSelf: 'center', paddingLeft: '25px' }}
          />
        )}
        {origin === 'Caja Los Andes' && (
          <img src={logoCajaAndes} alt="logo" style={{ height: '100px', paddingLeft: '25px' }} />
        )}
      </Box>
    );
  }
  return (
    <Box display="flex" alignContent="center" justifyContent="center">
      <img src={logo} alt="logo" style={{ width: '150px', height: '100%', alignSelf: 'center' }} />
      {(origin === null || origin === 'UniqueFlow' || origin === 'Caja Los Andes') && (
        <>
          {company.masterCompanyLogo && (
            <>
              <Divider orientation="vertical" variant="middle" flexItem />
              <img
                src={company.masterCompanyLogo}
                alt={company.name}
                style={{ height: '80px', padding: '0 25px', alignSelf: 'center' }}
              />
            </>
          )}
          <Divider orientation="vertical" variant="middle" flexItem />
          {company.id === 'rrss' ? (
            <Box display="flex" alignItems="center" pl="25px">
              <InstagramIcon style={{ fontSize: '60px' }} />
              <YouTubeIcon style={{ fontSize: '60px' }} />
              <FacebookIcon style={{ fontSize: '60px' }} />
            </Box>
          ) : (
            <img src={company.logo} alt={company.name} style={{ ...company.logoStyle, paddingLeft: '25px' }} />
          )}
        </>
      )}
    </Box>
  );
};
export default HeaderLogos;
