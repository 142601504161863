const validatePassword = (password: string, rut: string, email: string) => {
  if (rut !== '' && password.includes(rut)) {
    return false;
  }
  if (email !== '' && password.includes(email)) {
    return false;
  }
  // password must have at least 8 characters long
  const re = /^.{8,}$/;
  // for a more secure password, uncomment the following lines
  /* // password must have at least 8 characters, one uppercase letter, one lower case letter, one number and one special character (!@#$%^&+=.:/?<>-_)
  const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&+=.:/?<>_-]).{8,}$/; */
  return re.test(password);
};

export const getPasswordErrorMessage = (password: string, rut: string, email: string) => {
  if (rut !== '' && password.includes(rut)) {
    return 'Por favor, no ocupes tu rut como contraseña 😬';
  }
  if (email !== '' && password.includes(email)) {
    return 'Por favor, no ocupes tu email como contraseña 😬';
  }
  // for a more secure password, uncomment the following lines
  /* if (!/[a-z]/.test(password)) {
    return 'La contraseña debe contener al menos una letra minúscula 🔡';
  }
  if (!/[A-Z]/.test(password)) {
    return 'La contraseña debe contener al menos una letra mayúscula 🔠';
  }
  if (!/\d/.test(password)) {
    return 'La contraseña debe contener al menos un número 🔢';
  }
  if (!/[!@#$%^&+=.:/?<>_-]/.test(password)) {
    return 'La contraseña debe contener al menos un carácter especial (!@#$%^&+=.:/?<>_-) 🤯';
  } */
  if (password.length < 8) {
    return `Te faltan ingresar ${8 - password.length} caracteres 😓`;
  }
  return '';
};

export default validatePassword;
