import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Quiz from '@mui/icons-material/Quiz';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { ChatIcon, EmailIcon, SupportIcon } from 'assets/icons';
import PaperContainer from 'components/UI/PaperContainer';
import ChatWrapper from 'components/common/ChatWrapper';
import SectionHeader from 'components/layout/SectionHeader';
import AddInformationModal from 'pages/WelcomePanel/components/AddInformationModal';
import { useGetIsUserValidatedQuery } from 'services/api/firmaYa.api';
import { useGetUserInformationQuery, useGetUserQuery } from 'services/api/user.api';

import styles from './WelcomePanel.module.scss';
import AIAssistant from './components/AIAssistant';
import Journey from './components/Journey';
import RedirectToDebtsModal from './components/RedirectToDebtsModal';
import SwipeableTextMobileStepper from './components/Video';

const QuestionsItem = ({ text, Icon }: { text: React.ReactNode; Icon: React.ElementType }) => {
  const [isOnHover, setIsOnHover] = React.useState(false);
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{ backgroundColor: isOnHover ? 'info.main' : 'info.light', borderRadius: 4, p: 1, height: '95px' }}
      onMouseEnter={() => setIsOnHover(true)}
      onMouseLeave={() => setIsOnHover(false)}
    >
      <Avatar sx={{ backgroundColor: isOnHover ? 'info.light' : 'info.main', width: '55px', height: '55px' }}>
        <Icon sx={{ height: '60%', width: '60%', color: isOnHover ? 'info.main' : 'info.light' }} />
      </Avatar>

      <Typography variant="h4" ml={3} fontSize={{ xs: '16px', lg: '18px' }} lineHeight="35px">
        {text}
      </Typography>
    </Box>
  );
};

const WelcomePanel = () => {
  const { data: user, refetch } = useGetUserQuery(null);
  const {
    data: isUserValidated,
    isLoading: isUserLoading,
    refetch: refetchIsUserValidated,
  } = useGetIsUserValidatedQuery(null);
  const { data: currentUser } = useGetUserInformationQuery(null);
  const [addInfoModalOpen, setAddInfoModalOpen] = React.useState(false);
  const [redirectToDebtModalOpen, setRedirectToDebtModalOpen] = React.useState(false);
  const [addEmail, setAddEmail] = React.useState(false);

  useEffect(() => {
    if (!addInfoModalOpen) {
      refetch().catch((e) => console.error(e)); // eslint-disable-line no-console
      refetchIsUserValidated().catch((e) => console.error(e)); // eslint-disable-line no-console
    }
  }, [addInfoModalOpen]);

  useEffect(() => {
    if (user) {
      if (
        (user.email || user.phone) &&
        user.rut &&
        ((isUserValidated && isUserValidated.isValidated) || isUserLoading)
      ) {
        setAddInfoModalOpen(false);
      } else if (user.rut) {
        setAddEmail(true);
        setAddInfoModalOpen(true);
      } else if (user.email) {
        setAddEmail(false);
        setAddInfoModalOpen(true);
      }
    }
  }, [user, isUserValidated]);

  return (
    <Box sx={{ textAlign: 'start' }}>
      {addInfoModalOpen && (
        <AddInformationModal
          open={addInfoModalOpen}
          setAddInfoModalOpen={setAddInfoModalOpen}
          addEmail={addEmail}
          onSuccessSubmit={() => setRedirectToDebtModalOpen(true)}
        />
      )}
      {redirectToDebtModalOpen && !currentUser?.company?.disableStraightToDebtsFeature && (
        <RedirectToDebtsModal open={redirectToDebtModalOpen} setOpen={setRedirectToDebtModalOpen} />
      )}
      {user?.rut && isUserValidated && isUserValidated.isValidated && (
        <>
          <SectionHeader text={`Hola ${currentUser?.firstName}!`} button={null} />
          <PaperContainer title="Asistente virtual 🤖" fullWidth>
            <AIAssistant />
          </PaperContainer>
          <Journey />
          <Box display="flex" justifyContent="space-between" pb="40px" flexDirection={{ xs: 'column', lg: 'row' }}>
            <PaperContainer title="Aprende con el Dr Ahorro y Relif" sx={{ flex: 3, mr: 2 }} fullHeight>
              <SwipeableTextMobileStepper />
            </PaperContainer>

            <PaperContainer title="¿Tienes dudas?" sx={{ flex: 2 }} fullHeight>
              <Stack spacing={3} justifyContent="flex-start">
                <Link to="/faq" className={styles.ContactLinksWrapper}>
                  <QuestionsItem
                    text={
                      <>
                        Revisa nuestra sección de <b>preguntas frecuentes</b>
                      </>
                    }
                    Icon={Quiz}
                  />
                </Link>
                <ChatWrapper>
                  <QuestionsItem
                    text={
                      <>
                        <b>Háblanos</b> a nuestro chat
                      </>
                    }
                    Icon={ChatIcon}
                  />
                </ChatWrapper>
                <a
                  href="mailto:contacto@relif.cl"
                  className={styles.ContactLinksWrapper}
                  target="_blank"
                  rel="noreferrer"
                >
                  <QuestionsItem
                    text={
                      <>
                        <b>Escríbenos</b> a nuestro mail: contacto@relif.cl
                      </>
                    }
                    Icon={EmailIcon}
                  />
                </a>
                <a href="/book-meeting" className={styles.ContactLinksWrapper} target="_blank" rel="noreferrer">
                  <QuestionsItem
                    text={
                      <>
                        <b>Agenda una hora</b> con nuestro equipo (sí, nosotros mismos te ayudamos)
                      </>
                    }
                    Icon={SupportIcon}
                  />
                </a>
              </Stack>
            </PaperContainer>
          </Box>
        </>
      )}
    </Box>
  );
};

export default WelcomePanel;
