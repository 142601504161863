/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';

// @ts-ignore
import { Button } from '@dscla/ds-button/react';
// @ts-ignore
import { DataTable } from '@dscla/ds-data-table/react';
// @ts-ignore
import { MessageAlerta } from '@dscla/ds-message-alerta/react';
import priceFormat from 'services/format/priceFormat';

import type { BudgetRowDraft } from '../../../services/types/budget';

interface Props {
  budgetRows: BudgetRowDraft[];
}

const IncomeTable: FC<Props> = ({ budgetRows }) => {
  if (budgetRows.length === 0) {
    return (
      <Typography fontSize="18px" fontWeight={500}>
        No tienes ingresos en este presupuesto
      </Typography>
    );
  }
  return (
    <DataTable aria-label="Tabla Ingreso" variant="card">
      <table slot="card">
        <thead>
          <tr>
            <td width="50%">Nombre</td>
            <td>Categoría</td>
            <td align="right">Monto ($)</td>
          </tr>
        </thead>
        <tbody>
          {budgetRows.map((row, index) => (
            <tr key={`${index + 1}`}>
              <td data-first="" data-mobile data-mobile-width="100%">
                {row.name}
              </td>
              <td data-desktop>{row.name}</td>
              <td data-mobile data-label="Categoría">
                {row.category}
              </td>
              <td data-desktop>{row.category}</td>
              <td data-mobile data-label="Monto ($)">
                {priceFormat.format(Number(row.amount))}
              </td>
              <td data-desktop align="right">
                {priceFormat.format(Number(row.amount))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </DataTable>
  );
};

const ExpenseTable: FC<Props> = ({ budgetRows }) => {
  if (budgetRows.length === 0) {
    return (
      <Typography fontSize="18px" fontWeight={500}>
        No tienes gastos en esta sección
      </Typography>
    );
  }
  return (
    <DataTable aria-label="Tabla Ingreso" variant="card">
      <table slot="card">
        <thead>
          <tr>
            <td width="50%">Nombre</td>
            <td>Subcategoría</td>
            <td align="right">Monto ($)</td>
          </tr>
        </thead>

        <tbody>
          {budgetRows
            .filter((row) => row.isExpense)
            .map((row, index) => (
              <tr key={`${index + 1}`}>
                <td data-first="" data-mobile data-mobile-width="100%">
                  {row.name}
                </td>
                <td data-desktop>{row.name}</td>
                <td data-mobile data-label="Subcategoría">
                  {row.subcategory}
                </td>
                <td data-desktop>{row.category}</td>
                <td data-mobile data-label="Monto ($)">
                  {priceFormat.format(Number(row.amount))}
                </td>
                <td data-desktop align="right">
                  {priceFormat.format(Number(row.amount))}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </DataTable>
  );
};

const ObjectiveTable: FC<Props> = ({ budgetRows }) => {
  const navigate = useNavigate();

  if (budgetRows.length === 0) {
    return (
      <MessageAlerta color="advertencia">
        <span slot="label">No tienes objetivo para este presupuesto</span>
        <div slot="footer">
          <Button onClick={() => navigate('/my-plan')} variant="secundario">
            Crear objetivos
          </Button>
        </div>
      </MessageAlerta>
    );
  }
  return (
    <DataTable aria-label="Tabla Ingreso" variant="card">
      <table slot="card">
        <thead>
          <tr>
            <td width="50%">Nombre</td>
            <td>Categoría</td>
            <td align="right">Monto ($)</td>
          </tr>
        </thead>
        <tbody>
          {budgetRows.map((row, index) => (
            <tr key={`${index + 1}`}>
              <td data-first="" data-mobile data-mobile-width="100%">
                {row.name}
              </td>
              <td data-desktop>{row.name}</td>
              <td data-mobile data-label="Categoría">
                {row.category}
              </td>
              <td data-desktop>{row.category}</td>
              <td data-mobile data-label="Monto ($)">
                {priceFormat.format(Number(row.amount))}
              </td>
              <td data-desktop align="right">
                {priceFormat.format(Number(row.amount))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </DataTable>
  );
};

export { IncomeTable, ExpenseTable, ObjectiveTable };
