import { Translation } from './interface';

const spanishTranslation: Translation = {
  id: 'es',
  landingPage: {
    header: {
      title: 'Mejora tus créditos y ordena tus finanzas',
      subtitle1: 'Somos la plataforma donde los colaboradores de empresas mejoran su situación financiera.',
      subtitle2: 'Tu empleador nos contrata, nosotros te ayudamos.',
      knowMoreButton: 'Conocer más',
    },
    navigation: {
      featuresButton: 'Servicios',
      companiesButton: 'Empleadores',
      teamButton: 'Equipo',
      contactButton: 'Contáctanos',
      hello: 'Hola',
      dashboardButton: 'Mi panel',
      signUpButton: 'Registrarse',
      signInButton: 'Iniciar sesión',
      blog: 'Blog',
    },
    features: {
      signUpButton: 'Regístrate',
      workCafeFeature1: {
        title: 'Accede a tu reporte comercial',
        description: 'Revisa cuanto debes y si es que estás atrasado con tus deudas',
      },
      feature1: {
        title: 'Te sacamos de Dicom',
        description: 'Miguelina revisó su reporte comercial gratis y encontró un descuento de 70% en su deuda morosa',
      },
      feature2: {
        title: 'Mejoramos las condiciones de tus créditos al día',
        description: 'Luis bajó la cuota de su deuda de $200.000 a $62.000 sin aumentar el monto total a pagar',
      },
      feature3: {
        title: 'Ordena tu plata',
        description:
          'Pablo hizo su presupuesto, ahora llega tranquilo a fin de mes y ahorra para la educación de su hijo',
      },
      question: '¿Qué puedes hacer con',
    },
    steps: {
      title: 'Todo eso en 3 simples pasos',
      step1: 'Regístrate y completa tus datos en 2 minutos',
      step2: 'Nosotros analizamos tu información',
      step3: 'Te entregamos las mejores soluciones',
    },
    company: {
      title: 'Muéstrale esta sección a tu empleador',
      subtitle1: 'Entrégale a tus colaboradores el mejor beneficio que pueden tener,',
      boldSubtitle: 'salud financiera',
      subtitle2: '. Así obtendrás:',
      benefit1: 'Colaboradores más productivos',
      benefit2: 'Menor ausentismo',
      benefit3: 'Mayor calidad de vida en la empresa',
      benefit4: 'Menor rotación',
      benefit5: 'Súmate a las empresas que ya están usando Relif',
      title2: 'Algunas de las empresas que ya están usando Relif',
    },
    team: {
      title1: 'Equipo de',
      specialTitle: 'Relif',
      title2: '',
      jorge: {
        job: 'Co-fundador & CTO',
        prevJob: [
          {
            place: 'Meta',
            title: 'Software Engineer',
          },
          {
            place: 'PUC',
            title: 'Ingeniero Civil Computación',
          },
        ],
      },
      benja: {
        job: 'Co-fundador & CEO',
        prevJob: [
          {
            place: 'London Business School',
            title: 'MBA',
          },
          { place: 'Credicorp Capital', title: 'Investment Banking Associate' },
        ],
      },
      carlos: {
        job: 'Co-fundador & COO',
        prevJob: [
          {
            place: 'London Business School',
            title: 'MBA',
          },
          { place: 'Banco Bice', title: 'Wealth Management Advisor' },
        ],
      },
      juan: {
        job: 'Desarrollador',
        prevJob: [
          { place: 'PUC', title: 'Ingeniero Industrial TI' },
          { place: 'Forcast', title: 'Desarrollador Full-Stack' },
        ],
      },
      tomas: {
        job: 'Desarrollador',
        prevJob: [
          { place: 'PUC', title: 'Ingeniero Civil Computación' },
          { place: 'Celcom', title: 'Desarrollador Full-Stack' },
        ],
      },
      trinidad: {
        job: 'Key Account Manager',
        prevJob: [
          { place: 'Universidad de los Andes', title: 'Administradora de servicios' },
          { place: 'Promedon', title: 'Gestión de personas' },
        ],
      },
      maria_jose: {
        job: 'Chief Commercial Officer (CCO)',
        prevJob: [
          { place: 'PUC', title: 'Ingeniera Comercial' },
          { place: 'BUK', title: 'Sales Lead' },
        ],
      },
      harold: {
        job: 'Desarrollador',
        prevJob: [
          { place: 'PUC', title: 'Ingeniero Industrial TI' },
          { place: 'MACH', title: 'Ingeniero de Software' },
        ],
      },
    },
    contact: {
      title: '¿Quieres saber más?',
      subtitle: 'Danos tu correo y te contactaremos lo antes posible.',
      sendEmailButton: 'Enviar',
      details: {
        title: 'Contacto',
        email: 'Correo',
        phone: 'Teléfono',
        address: 'Dirección',
        investors: 'Nos apoyan',
      },
    },
    education: {
      title: 'Aprende con Relif',
      tipsTitle: 'Nuestro consejo',
      interactiveExamples: [
        {
          title: '¿Cómo financio mi compra?',
          description: 'Esto es cuanto gastarías al comprar un refrigerador con distintos medios de financiamiento',
          chartBars: [
            {
              name: 'Pago al contado',
              value: 500000,
              label: '$500.000',
            },
            {
              name: 'Crédito de consumo (a 2 años)',
              value: 821000,
              label: '$821.000 (+64%)',
            },
            {
              name: 'Préstamo informal (a 2 años)',
              value: 2000000,
              label: '$2.000.000 (+300%)',
            },
          ],
          tips: [
            'En el óptimo, los créditos son para proyectos (casa propia, estudios hijos, etc), y no para el día a día',
            'Aunque llegues justo a fin de mes, empieza a trabajar el ahorro. (cualquier monto sirve)',
          ],
        },
        {
          title: '¿Cuánto tengo que pagar de la tarjeta?',
          description:
            'Si el monto facturado de tu tarjeta es de $100.000, esto es cuanto pagarías en total, dependiendo de lo que abones a fin de mes',
          chartBars: [
            {
              name: 'Pagando todo',
              value: 100000,
              label: '$100.000',
            },
            {
              name: 'Pagando en 4 cuotas',
              value: 105000,
              label: '$105.000 (+5%)',
            },
            {
              name: 'Pagando el mínimo (5%)',
              value: 175000,
              label: '$175.000 (+75%)',
            },
          ],
          tips: [
            'Siempre que puedas, paga el total de la tarjeta',
            'Si no puedes pagar todo, paga la mayor cantidad posible',
            'Evita pagar el mínimo',
          ],
        },
        {
          title: '¿Qué pasa si me atraso en mis cuotas?',
          description: 'Cómo va aumentando el costo de una cuota de $200.000 a medida que me atraso en el pago',
          chartBars: [
            {
              name: 'Pago al día',
              value: 200000,
              label: '$200.000',
            },
            { name: 'Atraso: 15 días', value: 203500, label: '$203.500 (+1.75%)' },
            {
              name: 'Atraso: 1 mes',
              value: 225000,
              label: '$225.000 (+12.5%)',
            },
            {
              name: 'Atraso: 3 meses',
              value: 240000,
              label: '$240.000 (+20%)',
            },
          ],
          tips: [
            'Generalmente es mejor repactar un crédito antes que estar viviendo con 1 o 2 cuotas vencidas',
            'Trata de evitar un atraso de más de 3 meses, puedes entrar a una cobranza judicial y podrían embargar tus bienes',
          ],
        },
      ],
    },
  },
};

export default spanishTranslation;
