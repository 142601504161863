import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import { CloseIcon } from 'assets/icons';
import { BlackBtn, SecondaryBtn } from 'components/UI/Buttons';
import { BigCheckWithText, CheckWithText } from 'components/common/IconsWithText';

import styles from '../WelcomePanel.module.scss';

interface Props {
  open: boolean;
  setOpen: (state: boolean) => void;
}

const RedirectToDebtsModal: FC<Props> = (props: Props) => {
  const { open, setOpen } = props;

  const navigate = useNavigate();

  return (
    <Modal
      open={open}
      slotProps={{
        backdrop: {
          style: {
            backgroundImage: "url('./relif_welcome_page.webp')",
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            height: '100vh',
            width: '100vw',
            // blur and make it darker
            filter: 'blur(8px) brightness(0.8)',
            margin: 0,
            padding: 0,
          },
        },
      }}
    >
      <Box className={styles.RedirectToDebtsModal} display="flex">
        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h2" pt={0} textAlign="left" width="100%" py={2} fontSize={30}>
              Bienvenid@
            </Typography>
            <BlackBtn sx={{ border: 'none', mr: '-27px' }} onClick={() => setOpen(false)}>
              <CloseIcon />
            </BlackBtn>
          </Box>
          <Box width="100%" display="flex" flexDirection="column" justifyContent="space-between" mt={2}>
            <Box className={styles.ContentBox}>
              <Typography variant="body1" fontSize={24} width="100%" textAlign="left" py={1}>
                El primer paso del viaje Relif es entender y mejorar tus deudas.
              </Typography>
              <BigCheckWithText text="Para esto te mostraremos:" fontSize="22px" />
              <CheckWithText text="Tu boletín comercial" fontSize="22px" />
              <CheckWithText text="Tu informe de deudas CMF" fontSize="22px" />
            </Box>
            <Box display="grid" justifyContent="center" alignItems="center" mt={2}>
              <SecondaryBtn
                onClick={() => navigate('/debt?openClaveUnica=true')}
                variant="contained"
                sx={{
                  height: 50,
                  width: 300,
                }}
              >
                comenzar
              </SecondaryBtn>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default RedirectToDebtsModal;
