import React, { FC, useEffect, useState } from 'react';

import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Box, Typography } from '@mui/material';

import { EditIcon } from 'assets/icons';
import { EditBtn } from 'components/UI/Buttons';
import SearchBar from 'components/UI/SearchBar';
import { useGetConstantsQuery } from 'services/api/constants.api';
import { Constant } from 'services/types/constant';

interface Props {
  handleEditConstant: (constant: Constant) => void;
  refetchFlag: boolean;
}

const ConstantsTable: FC<Props> = ({ handleEditConstant, refetchFlag }) => {
  const { data, refetch } = useGetConstantsQuery(null);
  const [filteredConstants, setFilteredConstants] = useState(data || []);

  useEffect(() => {
    refetch().catch((e) => console.error(e)); // eslint-disable-line no-console
  }, [refetchFlag]);

  useEffect(() => {
    if (data) {
      setFilteredConstants(data);
    }
  }, [data]);

  return (
    <Box>
      <Box display="flex" justifyContent="flex-start" mb={2} alignItems="center">
        <Typography variant="h3">Lista de constantes</Typography>
        <SearchBar data={data || []} setFilteredData={setFilteredConstants as (data: unknown[]) => void} />
      </Box>
      <TableContainer
        sx={{ height: 'calc(100vh - 300px)', overflow: 'auto', backgroundColor: 'white', borderRadius: 4, p: 2 }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Constante</TableCell>
              <TableCell>Valor numérico</TableCell>
              <TableCell>Valor string</TableCell>
              <TableCell>Descripción</TableCell>
              <TableCell>Creado en</TableCell>
              <TableCell>Actualizado en</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredConstants?.map((constant) => (
              <TableRow key={constant.id}>
                <TableCell>{constant.name}</TableCell>
                <TableCell>{constant.numberValue}</TableCell>
                <TableCell
                  sx={{
                    maxWidth: '200px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {constant.stringValue}
                </TableCell>
                <TableCell
                  sx={{
                    maxWidth: '200px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {constant.description}
                </TableCell>
                <TableCell>{new Date(constant.createdAt).toLocaleString('es-CL')}</TableCell>
                <TableCell>{new Date(constant.updatedAt).toLocaleString('es-CL')}</TableCell>
                <TableCell>
                  <EditBtn onClick={() => handleEditConstant(constant)}>
                    <EditIcon />{' '}
                  </EditBtn>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ConstantsTable;
