import { Company, CompanyDraft } from 'services/types/companies';

import baseApi from './baseApi';

const companiesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCompanies: build.query({
      query: () => ({
        url: '/admin/companies',
        method: 'GET',
      }),
      transformResponse: (response: Company[]) => response,
    }),
    editCompany: build.mutation({
      query: (props: { company: CompanyDraft; id: number }) => ({
        url: `admin/companies/${props.id}`,
        method: 'PUT',
        body: props.company,
      }),
      transformResponse: (response: Company) => response,
    }),
    createCompany: build.mutation({
      query: (company: CompanyDraft) => ({
        url: `admin/companies`,
        method: 'POST',
        body: company,
      }),
      transformResponse: (response: Company) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useGetCompaniesQuery, useEditCompanyMutation, useCreateCompanyMutation } = companiesApi;
