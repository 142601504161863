import React from 'react';

import { Paper, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

interface InitialPaperInterface {
  width: number;
  height: number;
  title: string;
  paragraph: string;
  button: React.ReactNode;
  isLoading: boolean;
}
const InitialPaper: React.FC<InitialPaperInterface> = ({
  width,
  height,
  title,
  paragraph,
  button,
  isLoading,
  children,
}) => {
  return (
    <Paper
      sx={{
        width,
        maxWidth: '100%',
        height,
        borderRadius: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        padding: '50px',
      }}
    >
      <Typography variant="h3">{isLoading ? 'Cargando...' : title}</Typography>
      {isLoading ? <CircularProgress /> : <Typography variant="h4">{paragraph}</Typography>}
      {isLoading ? null : button}
      {children}
    </Paper>
  );
};

export default InitialPaper;
