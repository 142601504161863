/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { useNavigate } from 'react-router-dom';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { ForwardIcon } from 'assets/icons';
import BudgetImage from 'assets/images/journey/BudgetImage.webp';
import CreateDebtImage from 'assets/images/journey/CreateDebtImage.webp';
import GoalImage from 'assets/images/journey/GoalImage.webp';
import SavingImage from 'assets/images/journey/SavingImage.webp';
import { useGetUserInformationQuery } from 'services/api/user.api';
import trpc from 'trpc';

interface JourneyGridItemProps {
  checked: boolean;
  imageSrc: string;
  text: string;
  step: number;
  url: string;
}

const DesktopJourneyGridItem: React.FC<JourneyGridItemProps> = ({ checked, imageSrc, text, step, url }) => {
  const [isOnHover, setIsOnHover] = React.useState(false);
  const navigate = useNavigate();
  return (
    <Grid
      item
      xs={12}
      sx={{ cursor: 'pointer' }}
      onClick={() => navigate(url)}
      py={2}
      pl={step === 1 ? 0 : 2}
      pr={step === 5 ? 0 : 2}
      onMouseEnter={() => setIsOnHover(true)}
      onMouseLeave={() => setIsOnHover(false)}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyItems="space-between"
        sx={{ borderRadius: '20px', padding: 1, cursor: 'pointer', opacity: !checked && !isOnHover ? 0.5 : 1 }}
        bgcolor="white"
        boxShadow={isOnHover ? 5 : 0}
        height="100%"
      >
        <Grid
          item
          xs={2}
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', height: '100%' }}
        >
          <Checkbox
            checked={checked}
            sx={{ height: '25px' }}
            icon={<RadioButtonUncheckedIcon sx={{ opacity: '0.5', color: 'newBlue.A700' }} />}
            checkedIcon={<CheckCircleIcon sx={{ color: 'newBlue.A700' }} />}
          />
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2" color="newBlue.A700">
            Paso {step}
          </Typography>
          <Typography variant="body1" color="black">
            {text}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100px',
              py: 1,
            }}
          >
            <img src={imageSrc} height="80px" />
          </Box>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            height: '100%',
            alignItems: 'flex-end',
          }}
        >
          <ForwardIcon
            sx={{
              color: isOnHover ? 'info.main' : 'black',
              width: isOnHover ? '30px' : '16px',
              transition: '0.3s ease-out',
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const MobileJourneyGridItem: React.FC<JourneyGridItemProps> = ({ checked, imageSrc, text, step, url }) => {
  const navigate = useNavigate();
  return (
    <Paper
      sx={{
        borderRadius: '20px',
        padding: 2,
        cursor: 'pointer',
      }}
      onClick={() => navigate(url)}
    >
      <Grid container alignItems="center" justifyItems="space-between">
        <Grid item xs={2}>
          <Checkbox
            checked={checked}
            icon={<RadioButtonUncheckedIcon sx={{ opacity: '0.5', color: 'newBlue.A700' }} fontSize="large" />}
            checkedIcon={<CheckCircleIcon fontSize="large" sx={{ color: 'newBlue.A700' }} />}
          />
        </Grid>
        <Grid item xs={3}>
          <img src={imageSrc} width="85%" style={{ maxWidth: '90px' }} />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" color="newBlue.A700">
            Paso {step}
          </Typography>
          <Typography variant="h3">{text}</Typography>
        </Grid>
        <Grid item xs={1} justifySelf="end">
          <ForwardIcon sx={{ opacity: '0.5' }} />
        </Grid>
      </Grid>
    </Paper>
  );
};

const Journey = () => {
  const getOnboardingQuery = trpc.onboarding.get.useQuery();
  const { data: user } = useGetUserInformationQuery(null);

  const totalSteps = user?.company?.disableSavingsFeature ? 3 : 4;

  const getStep = () => {
    if (getOnboardingQuery.data) {
      let completedSteps = 0;
      Object.values(getOnboardingQuery.data).forEach((c) => {
        if (c) {
          completedSteps += 1;
        }
      });
      return completedSteps;
    }
    return 0;
  };

  return (
    <>
      {/* Desktop */}
      <Grid
        sx={{
          display: { xs: 'none', md: 'grid' },
          gridTemplateColumns: `repeat(${totalSteps}, 1fr)`,
        }}
        container
        direction="row"
      >
        <DesktopJourneyGridItem
          checked={!!getOnboardingQuery.data?.hasClassifiedDebts}
          imageSrc={CreateDebtImage}
          text="Entiende y mejora tus deudas"
          step={1}
          url="/debt"
        />
        <DesktopJourneyGridItem
          checked={!!getOnboardingQuery.data?.hasGoal}
          imageSrc={GoalImage}
          text="Crea un objetivo"
          step={2}
          url="/my-plan"
        />
        <DesktopJourneyGridItem
          checked={!!getOnboardingQuery.data?.hasBudget}
          imageSrc={BudgetImage}
          text="Crea un presupuesto"
          step={3}
          url="/budget"
        />
        {!user?.company?.disableSavingsFeature ? (
          <DesktopJourneyGridItem
            checked={!!getOnboardingQuery.data?.hasSavings}
            imageSrc={SavingImage}
            text="Ahorra"
            step={4}
            url="/my-plan"
          />
        ) : null}
      </Grid>
      {/* Mobile */}
      <Stack spacing={1} sx={{ display: { xs: 'block', md: 'none' } }}>
        <Box px="12px">
          <Box display="inline-flex" alignItems="center" pb={1}>
            <Typography variant="h3" pr={2}>
              El viaje Relif
            </Typography>
            <CircularProgress
              variant="determinate"
              value={(getStep() / totalSteps) * 100}
              size={25}
              thickness={6}
              sx={{ color: 'newBlue.A700' }}
            />
            <Typography variant="body2" pl={1} color="newBlue.A700">
              {getStep()}/{totalSteps}
            </Typography>
          </Box>
          <Typography variant="body1">Completa todos los pasos para sacarle el mejor provecho a Relif</Typography>
        </Box>
        <MobileJourneyGridItem
          checked={!!getOnboardingQuery.data?.hasClassifiedDebts}
          imageSrc={CreateDebtImage}
          text="Entiende y mejora tus deudas"
          step={1}
          url="/debt"
        />
        <MobileJourneyGridItem
          checked={!!getOnboardingQuery.data?.hasGoal}
          imageSrc={GoalImage}
          text="Crea un objetivo"
          step={2}
          url="/my-plan"
        />
        <MobileJourneyGridItem
          checked={!!getOnboardingQuery.data?.hasBudget}
          imageSrc={BudgetImage}
          text="Crea un presupuesto"
          step={3}
          url="/budget"
        />
        {!user?.company?.disableSavingsFeature ? (
          <MobileJourneyGridItem
            checked={!!getOnboardingQuery.data?.hasSavings}
            imageSrc={SavingImage}
            text="Ahorra"
            step={4}
            url="/my-plan"
          />
        ) : null}
      </Stack>
    </>
  );
};

export default Journey;
