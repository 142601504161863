import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import CardWithArrow from './components/CardWithArrow';
import NewHomePaper from './components/NewHomePaper';

const NewHome: FC = () => {
  return (
    <NewHomePaper isHome>
      <Typography my={1} textAlign="start" fontSize="36px" lineHeight="44px" fontWeight="500">
        Comprar tu casa propia
      </Typography>
      <Typography variant="body1" mt={5} textAlign="start" fontSize="24px">
        La primera pregunta que debes hacerte para considerar comprar una casa es si vas a necesitar un subsidio o no.
      </Typography>
      <Box display="flex" flexWrap="wrap" justifyContent="space-between" mt={5} px={{ xs: 0, md: 8 }}>
        <Box py={{ xs: 2, lg: 0 }}>
          <CardWithArrow title="CON Subsidio" navigateTo="withSubsidy" />
        </Box>
        <Box py={{ xs: 2, lg: 0 }}>
          <CardWithArrow title="SIN Subsidio" navigateTo="noSubsidy" />
        </Box>
      </Box>
      <Typography variant="body1" mt={5} textAlign="start" fontSize="24px">
        Otros temas que podrian interesarte:
      </Typography>
      <Box display="flex" flexWrap="wrap" justifyContent="space-between" mt={5} px={{ xs: 0, md: 8 }}>
        <Box py={{ xs: 2, lg: 0 }}>
          <CardWithArrow title="Créditos Hipotecarios" navigateTo="mortgages" />
        </Box>
        <Box py={{ xs: 2, lg: 0 }}>
          <CardWithArrow title="Cuentas de Ahorro para la Vivienda" navigateTo="saving-accounts" />
        </Box>
      </Box>
    </NewHomePaper>
  );
};

export default NewHome;
