import type {
  BankOfferSheet,
  BulkBankOffersBody,
  BulkCreateBankOffersResponse,
  BulkUpdateBankOffersResponse,
  PreProcessedOffersResponse,
} from '@relif/backend';

import baseApi from './baseApi';

const bankOffersApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    preProcessFile: build.mutation({
      query: (body: FormData) => ({
        url: `/admin/bankOffer/pre-process`,
        method: 'POST',
        formData: true,
        body,
      }),
      transformResponse: (response: PreProcessedOffersResponse) => response,
    }),
    bulkCreate: build.mutation({
      query: (body: BulkBankOffersBody) => ({
        url: `/admin/bankOffer/bulk`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: BulkCreateBankOffersResponse) => response,
    }),
    bulkUpdate: build.mutation({
      query: (body: BulkBankOffersBody) => ({
        url: `/admin/bankOffer/bulk`,
        method: 'PUT',
        body,
      }),
      transformResponse: (response: BulkUpdateBankOffersResponse) => response,
    }),
    getBankOffersSheetList: build.query({
      query: () => `/admin/bankOffer/sheet-templates`,
      transformResponse: (response: BankOfferSheet[]) => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  usePreProcessFileMutation,
  useBulkCreateMutation,
  useBulkUpdateMutation,
  useGetBankOffersSheetListQuery,
} = bankOffersApi;
