import React, { FC } from 'react';

import { Typography, Box } from '@mui/material';

import styles from './Simulator.module.scss';

interface Props {
  newRCR: number;
  currentRCR: number;
  RCRThreshold: number;
  getSuggestionMessage: () => string;
}

const RelifAdviseCard: FC<Props> = ({ newRCR, currentRCR, RCRThreshold, getSuggestionMessage }) => {
  return (
    <Box
      sx={{
        backgroundColor: '#EDF1FA',
        borderRadius: '10px',
        width: '100%',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '20px',
          backgroundColor: 'secondary.main',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          height: '50px',
        }}
      >
        <Typography variant="h5" textAlign="center" color="primary.main" fontWeight={600}>
          Consejo Relif
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" p={1}>
        <Box className={styles.SimulationItemCard} maxHeight={300}>
          <Box className={styles.Status} bgcolor={newRCR > currentRCR ? 'warning.main' : 'success.main'} />
          <Box className={styles.SimulationItemDetails} textAlign="center">
            <Typography variant="body1"> Tu carga financiera {newRCR > currentRCR ? 'sube' : 'baja'} de</Typography>
            <Typography variant="h2" my={1}>
              <b>
                {Math.round(currentRCR)}% a {Math.round(newRCR)}%
              </b>
            </Typography>
            <Typography variant="caption">* Te recomendamos que esta no sea mayor a un {RCRThreshold}%</Typography>
          </Box>
        </Box>
      </Box>

      <Typography variant="body1" textAlign="justify" p={3}>
        ➡️ {getSuggestionMessage()}
      </Typography>
    </Box>
  );
};

export default RelifAdviseCard;
