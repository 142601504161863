import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from 'config';
import { RootState } from 'redux/store';

const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: config.apiUrl,
    prepareHeaders: (headers, { getState }) => {
      const { accessToken } = getState() as RootState;
      if (accessToken && accessToken.accessToken) {
        if (accessToken) {
          headers.set('Authorization', `Bearer ${accessToken.accessToken}`);
        }
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
});

export default baseApi;
