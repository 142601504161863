import React, { FC } from 'react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Button } from '@dscla/ds-button/react';
import { institutionTypeList } from 'assets/data/institutionTypeList';
import DefinitionWrapper from 'components/common/DefinitionWrapper';
import { DebtDraft } from 'services/types/debt';

interface Props {
  debtDraft: DebtDraft;
  setDebtDraft: (debtDraft: DebtDraft) => void;
  setActiveStep: (step: number) => void;
  showBanks?: boolean;
}

const NewDebtStepOne: FC<Props> = ({ debtDraft, setDebtDraft, setActiveStep, showBanks }) => {
  return (
    <Grid container>
      {institutionTypeList
        .filter((i) => (showBanks ? true : i.name !== 'Banco'))
        .map((institution) => (
          <Grid item xs={12} key={institution.name} sx={{ minHeight: '77px' }} p={1} width="100%">
            <Button
              behaviour="fijo"
              variant="secundario"
              onClick={() => {
                setDebtDraft({ ...debtDraft, institutionType: institution.name });
                setActiveStep(1);
              }}
            >
              <Box display="flex" alignItems="center">
                <Avatar sx={{ backgroundColor: 'backgroundGrey.main', color: '#3E4545' }}>
                  {React.createElement(institution.icon, {
                    style: { width: '70%', height: '70%' },
                  })}
                </Avatar>
                <DefinitionWrapper definitionName={institution.name}>
                  <Typography variant="body2" color="#3E4545" fontSize={{ xs: '14px', lg: '18px' }} mx={2}>
                    {institution.name}
                  </Typography>
                </DefinitionWrapper>
              </Box>
            </Button>
          </Grid>
        ))}
    </Grid>
  );
};

NewDebtStepOne.defaultProps = {
  showBanks: true,
};

export default NewDebtStepOne;
