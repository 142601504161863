import React, { FC } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import LockPCImage from 'assets/images/lockpc.webp';

import styles from './Password.Reset.module.scss';

const ComputerUnlocked: FC = () => {
  return (
    <Grid className={styles.MainGridContainer} container component="main">
      <Grid item xs={12} component={Paper} elevation={6} square className={styles.Container}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: { xs: '100%', lg: '80%' },
            alignItems: 'center',
            mx: 'auto',
            bgcolor: 'backgroundGrey.main',
            mt: '60px',
            pt: 4,
            borderRadius: '20px',
            border: '1px solid hsla(210,18%,87%,1)',
            boxShadow: '5px 5px 10px 5px',
          }}
        >
          <img alt="Relif" src="/logo.webp" height="50" />
          <img alt="Bancame" src={LockPCImage} height="600px" style={{ margin: '20px' }} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ComputerUnlocked;
